<template>
  <div id="app" class="d-flex flex-column h-100">
    <o-app-drawer :items="items" :admin_items="admin_items">
      <template v-slot:left-hand-side> </template>

      <template v-slot:brand>
        <div>
          <div><strong style="color: orange">TTS</strong> - Test and Tag</div>
        </div>
      </template>
      >
    </o-app-drawer>

    <b-navbar class="secondary-nav px-0" fixed="top" v-if="isLoggedIn">
      <div class="collapse navbar-collapse" id="navbarText">
        <global-show-customer @updateCustomer="beforeUpdateAssetTestCount">
          <template v-slot:total_tested>
            <span style="margin-left: 20px" v-if="AssetTestCount"
              >Total tested: {{ AssetTestCount.asset_test_count }}
            </span>
          </template>
        </global-show-customer>
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a @click="toggleShowData" class="nav-link" href="#"
              >{{ { true: "Hide", false: "Show" }[showData] }} Stats</a
            >
          </li>
        </ul>
      </div>
    </b-navbar>

    <download-code-data v-if="showData"></download-code-data>

    <span :class="{ hide: !showData }">
      <o-app-content margin-top="90px" v-if="loaded"></o-app-content>
    </span>

    <o-app-footer :version="version" year="2020"></o-app-footer>
  </div>
</template>

<style>
#nprogress .bar {
  background: #1976d2 !important;
  height: 5px;
}

.white--text a {
  color: white;
}
</style>

<script>
import Vue from "vue";
import MENU_ITEMS from "./app/menu_items";
import ADMIN_ITEMS from "./app/admin_items";
import OAppDrawer from "./vue-ocom/components/layout/app/OAppDrawer";
import OAppContent from "./vue-ocom/components/layout/app/OAppContent";
import OAppFooter from "./vue-ocom/components/layout/app/OAppFooter";
import GlobalShowCustomer from "./components/GlobalShowCustomer";
import { mapGetters } from "vuex";
import DownloadCodeData from "./components/DownloadCodeData";
import AppMixin from "@/vue-ocom/mixins/AppMixin";
import AssetTestCountMixin from "@/mixins/AssetTestCountMixin.js";

export default {
  name: "App",
  mixins: [AppMixin, AssetTestCountMixin],
  components: {
    DownloadCodeData,
    GlobalShowCustomer,
    OAppContent,
    OAppFooter,
    OAppDrawer,
  },
  data: () => ({
    admin_items: ADMIN_ITEMS,
    items: MENU_ITEMS[0].children,
    version: process.env.VUE_APP_VERSION,
    loaded: true,
    showData: false,
    root_url: process.env.VUE_APP_ROOT_API,
    temp_customer_ID: null,
  }),
  props: {
    source: String,
  },
  methods: {
    beforeUpdateAssetTestCount(id) {
      // store the customer id as component data
      this.temp_customer_ID = id;

      // submit or update asset test count
      this.updateAssetTestCount(id);

      const vm = this;
      this.loaded = false;
      Vue.nextTick(function () {
        vm.loaded = true;
      });
    },
    toggleShowData() {
      this.showData = !this.showData;
    },
  },
  created() {
    // set 1 minute
    setInterval(() => {
      if (this.data.customer_id) {
        // // submit or update asset test count
        this.updateAssetTestCount(this.data.customer_id);
      }
    }, 60000);
  },
  computed: {
    ...mapGetters("AuthModule", ["isLoggedIn"]),
    ...mapGetters("GlobalStateModule", ["data"]),
  },
};
</script>
<style>
.secondary-nav {
  top: 45px !important;
  z-index: 50 !important;
  background-color: #f7dcb9;
}
</style>
