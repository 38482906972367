import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import { RequireModule } from "./globals";
import * as CONFIG from "@/config.js";

/* Create mapActions and mapGetters for a RestModule (and similar modules)
for Code Modules ** THIS DOESN'T LOAD THEM

moduleList is an array of Module names but assume moduleList has the value
['Test']

Then the following actions and getters are created

mapActions({
    createNewTest: 'TestModule/createNew',
    saveTestItem: 'TestModule/saveItem',
    getTestById: 'TestModule/getById',
    patchTestItem: 'TestModule/pathItem',
});

mapGetters({
    optionTestItems: 'TestModule/code_items',
    optionTestItems: 'TestModule/code_itemsByID',
    optionTestWorking: 'TestModule/working',
    getTestNewItem: 'TestModule/item',
});
 */

export default function (moduleList) {
    var actionList = {};
    var fetchActionList = {};
    var getterList = {};

    _.each(moduleList, function (moduleName) {
        if (CONFIG.Debug) {
            RequireModule(moduleName);
        }

        const modModuleName = moduleName + "Module/";

        actionList["createNew" + moduleName] = modModuleName + "createNew";
        actionList["save" + moduleName + "Item"] = modModuleName + "saveItem";
        actionList["get" + moduleName + "ById"] = modModuleName + "getById";
        actionList["patch" + moduleName + "Item"] = modModuleName + "patchItem";

        fetchActionList["refresh" + moduleName] = modModuleName + "refresh";

        getterList["option" + moduleName + "Items"] =
            modModuleName + "code_items";
        getterList["option" + moduleName + "ItemsById"] =
            modModuleName + "code_itemsByID";
        getterList["option" + moduleName + "Working"] =
            modModuleName + "working";
        getterList["get" + moduleName + "NewItem"] = modModuleName + "item";
    });

    var getCodeTablesMethods = {};

    getCodeTablesMethods.OnGetAll = function (moduleName) {
        if (this.listeners && !this.listeners["get-all"]) {
            console.error("@get-all is NOT being listened for but is called!");
        }
        this.$emit("get-all", moduleName);
    };

    _.each(moduleList, function (moduleName) {
        var function_name = "GetAll" + moduleName;

        getCodeTablesMethods[function_name] = function () {
            console.log(
                function_name +
                    " is not implemented here. Using Event @get-all",
            );
            if (this.listeners && !this.listeners["get-all"]) {
                console.error(
                    "@get-all is NOT being listened for but is called!",
                );
            }
            this.$emit("get-all", moduleName);
        };
    });

    return {
        methods: {
            ...getCodeTablesMethods,
            ...mapActions({ ...fetchActionList, ...actionList }),
        },
        computed: {
            ...mapGetters(getterList),
        },
    };
}
