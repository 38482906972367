import Vue from "vue";

import OWorkingOverlay from "./OWorkingOverlay.vue";

import BooleanDisplay from "./display/BooleanDisplay.vue";
import DateDisplay from "./display/DateDisplay.vue";
import DefaultDisplay from "./display/DefaultDisplay.vue";
import LookupDisplay from "./display/LookupDisplay.vue";
import NumberDisplay from "./display/NumberDisplay.vue";
import UserDisplay from "./display/UserDisplay.vue";

import OActionButtons from "./forms/OActionButtons.vue";
import OCopyItemButton from "./forms/OCopyItemButton.vue";
import ODoServerActionButton from "./forms/ODoServerActionButton.vue";
import ODownloadFileButton from "./forms/ODownloadFileButton.vue";
import OFormGroup from "./forms/OFormGroup.vue";
import OFormWrapper from "./forms/OFormWrapper.vue";
import OSendEmailButton from "./forms/OSendEmailButton.vue";
import OWidgetWrapper from "./forms/OWidgetWrapper.vue";

import OCheckbox from "./forms/fields/OCheckbox.vue";
import OBooleanSelect from "./forms/fields/OBooleanSelect.vue";
import ODateButton from "./forms/fields/ODateButton.vue";
import ODateTime from "./forms/fields/ODateTime.vue";
import OFileInput from "./forms/fields/OFileInput.vue";
import OHtmlArea from "./forms/fields/OHtmlArea.vue";
import OMultiCheckbox from "./forms/fields/OMultiCheckbox.vue";

import OSelect from "./forms/fields/OSelect.vue";
import OTags from "./forms/fields/OTags.vue";
import OTextField from "./forms/fields/OTextField.vue";
import OTextarea from "./forms/fields/OTextarea.vue";
import OCrudDialog from "./forms/fields/private/OCrudDialog.vue";
import OFlatpickrWrapper from "./forms/fields/private/OFlatpickrWrapper.vue";
import OFormSelect from "./forms/fields/private/OFormSelect.js";
/* Layout */
import OSection from "./layout/OSection.vue";
import OAppContent from "./layout/app/OAppContent.vue";
import OAppDrawer from "./layout/app/OAppDrawer.vue";
import OAppFooter from "./layout/app/OAppFooter.vue";
import ODashboardMenu from "./layout/app/ODashboardMenu.vue";
import OSideBar from "./layout/app/OSideBar.vue";

import OLinkCell from "./list/OLinkCell.vue";
import OListMultiActions from "./list/OListMultiActions.vue";
import OListPaging from "./list/OListPaging.vue";
import OListToolbar from "./list/OListToolbar.vue";

import OInlineWidget from "./table/OInlineWidget.vue";
import OItemEditableTable from "./table/OItemEditableTable.vue";
import OListActions from "./table/OListActions.vue";
import OListAdd from "./table/OListAdd.vue";
import ORepeatingCards from "./table/ORepeatingCards.vue";
import OFilesTable from "./table/common/OFilesTable.vue";
import ONotesTable from "./table/common/ONotesTable.vue";

export default function () {
    Vue.component("OWorkingOverlay", OWorkingOverlay);
    /* Display */
    Vue.component("BooleanDisplay", BooleanDisplay);
    Vue.component("DateDisplay", DateDisplay);
    Vue.component("DefaultDisplay", DefaultDisplay);
    Vue.component("LookupDisplay", LookupDisplay);
    Vue.component("NumberDisplay", NumberDisplay);
    Vue.component("UserDisplay", UserDisplay);
    /* Forms */
    Vue.component("OActionButtons", OActionButtons);
    Vue.component("OCopyItemButton", OCopyItemButton);
    Vue.component("ODoServerActionButton", ODoServerActionButton);
    Vue.component("ODownloadFileButton", ODownloadFileButton);
    Vue.component("OFormGroup", OFormGroup);
    Vue.component("OFormWrapper", OFormWrapper);
    Vue.component("OSendEmailButton", OSendEmailButton);
    Vue.component("OWidgetWrapper", OWidgetWrapper);
    /* Fields */
    Vue.component("OCheckbox", OCheckbox);
    Vue.component("OBooleanSelect", OBooleanSelect);
    Vue.component("ODateButton", ODateButton);
    Vue.component("ODateTime", ODateTime);
    Vue.component("OFileInput", OFileInput);
    Vue.component("OHtmlArea", OHtmlArea);
    Vue.component("OMultiCheckbox", OMultiCheckbox);
    Vue.component("OSelect", OSelect);
    Vue.component("OTags", OTags);
    Vue.component("OTextField", OTextField);
    Vue.component("OTextarea", OTextarea);
    Vue.component("OCrudDialog", OCrudDialog);
    Vue.component("OFlatpickrWrapper", OFlatpickrWrapper);
    Vue.component("OFormSelect", OFormSelect);

    /* Layout */
    Vue.component("OSection", OSection);
    Vue.component("OAppContent", OAppContent);
    Vue.component("OAppDrawer", OAppDrawer);
    Vue.component("OAppFooter", OAppFooter);
    Vue.component("ODashboardMenu", ODashboardMenu);
    Vue.component("OSideBar", OSideBar);

    /* Index Table */
    Vue.component("OLinkCell", OLinkCell);
    Vue.component("OListMultiActions", OListMultiActions);
    Vue.component("OListPaging", OListPaging);
    Vue.component("OListToolbar", OListToolbar);

    /* Table */
    Vue.component("OInlineWidget", OInlineWidget);
    Vue.component("OItemEditableTable", OItemEditableTable);
    Vue.component("OListActions", OListActions);
    Vue.component("OListAdd", OListAdd);
    Vue.component("ORepeatingCards", ORepeatingCards);
    Vue.component("OFilesTable", OFilesTable);
    Vue.component("ONotesTable", ONotesTable);
}
