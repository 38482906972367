<template>
    <o-form-wrapper
        :form-state="formState"
        :schema="schema"
        :server-errors="_se"
        :v="$v.item"
        v-model="item"
    >
        <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="group"
        >
            <o-select
                :form-state="formState"
                :items="optionGroupItems"
                :loading="optionGroupWorking"
                :server-errors="_se"
                :validators="$v.item"
                add-title="Create New"
                allow-add
                name="group"
                sort-field="name"
                store-name="Group"
                text-field="name"
                v-bind="schema.fields.group"
                v-model="item.group"
                value-field="id"
            >
                <template
                    slot="form"
                    slot-scope="{ item, formState, serverErrors }"
                >
                    <group-form
                        :form-state="formState"
                        :item="item"
                        :server-errors="serverErrors"
                        in-modal
                    ></group-form>
                </template>
            </o-select>
        </o-widget-wrapper>

        <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="details"
        >
            <o-textarea
                :form-state="formState"
                :server-errors="_se"
                :validators="$v.item"
                name="details"
                v-bind="schema.fields.details"
                v-model="item.details"
            ></o-textarea>
        </o-widget-wrapper>

        <o-widget-wrapper
            :schema="schema"
            name="deny_by_default"
            :validators="$v.item"
            :server-errors="_se"
            :form-state="formState"
        >
            <o-checkbox
                v-model="item.deny_by_default"
                :form-state="formState"
                name="deny_by_default"
                :validators="$v.item"
                :server-errors="_se"
                v-bind="schema.fields.deny_by_default"
            ></o-checkbox>
        </o-widget-wrapper>

        <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="states"
        >
            <group-state-form-cards
                :form-state="formState"
                :label="schema.fields.states.label"
                :level="level + 1"
                :server-errors="_se.states"
                :validators="$v.item.states"
                v-model="item.states"
            >
            </group-state-form-cards>
        </o-widget-wrapper>
    </o-form-wrapper>
</template>

<script>
import schema from "../../schema/index.js";
import { cleanUpModel } from "../../../schema/schema_vuetify.js";
import {
    CreateCodeTableFetchMixin,
    CreateFormStateMixin,
    CreateGroupPermissionMixin,
    VuelidateMixin,
} from "../../../mixins";
import { validationMixin } from "vuelidate";
import OWidgetWrapper from "../../../components/forms/OWidgetWrapper.vue";
import OTextField from "../../../components/forms/fields/OTextField.vue";
import OSelect from "../../../components/forms/fields/OSelect.vue";
import OCheckbox from "../../../components/forms/fields/OCheckbox.vue";
import OFormWrapper from "../../../components/forms/OFormWrapper.vue";
import ODateTime from "../../../components/forms/fields/ODateTime.vue";

import { default as GroupForm } from "../../group/components/ItemForm.vue";
import { default as GroupStateFormCards } from "../../group_state/components/ItemFormCards.vue";
import { updateSchemaModelWithPermissions } from "../../utils.js";

export default {
    mixins: [
        CreateGroupPermissionMixin("GroupPermissions"),
        VuelidateMixin,
        validationMixin,
        CreateFormStateMixin("$v"),
        CreateCodeTableFetchMixin(["Group"]),
    ],
    components: {
        OFormWrapper,
        OWidgetWrapper,
        ODateTime,

        OCheckbox,
        OSelect,
        OTextField,

        GroupForm,
        GroupStateFormCards,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        level: { type: Number, default: 0 },
        inModal: { type: Boolean, default: false },
    },
    data: () => ({
        schemaModel: cleanUpModel(schema.GroupPermissions, {}),
    }),
    methods: {},
    computed: {
        _se() {
            // Return an empty Object if there are no Server Errors to avoid Binding Errors
            return this.serverErrors || {};
        },
        schema() {
            if (!this.schemaModel) {
                console.warn("No schema found! " + this.$options._componentTag);
            }

            return updateSchemaModelWithPermissions(
                this._group_permissions,
                this.schemaModel,
                this.$router.currentRoute.name
            );
        },
    },
    watch: {},
    created() {},
};
</script>
