import Vue from "vue";

import "../css/custom.scss"; // Out Bootstrap theme
import "../css/ocom.css"; // Our own CSS changes

var OcomPlugin = {
    install(Vue, options) {},
};

Vue.use(OcomPlugin);
