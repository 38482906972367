<template>
    <o-form-group
        :form-state="formState"
        :label="label"
        :message="errorMessages"
        :nolabel="nolabel"
        :type="{ 'is-danger': hasError }"
    >
        <BInputGroup
            :append="append"
            :prepend="prepend"
            v-if="hasAppend || hasPrepend"
        >
            <template v-slot:append>
                <slot name="append"
                    ><BInputGroupText v-if="append">{{
                        append
                    }}</BInputGroupText></slot
                >
            </template>
            <template v-slot:prepend>
                <slot name="prepend"
                    ><BInputGroupText v-if="prepend">{{
                        prepend
                    }}</BInputGroupText></slot
                >
            </template>
            <BFormInput
                :class="input_class"
                :readonly="_is_readonly"
                v-bind="$attrs"
                v-bind:value="value"
                v-on="inputListeners"
            >
                <slot />
            </BFormInput>
        </BInputGroup>

        <BFormInput
            v-else
            :class="input_class"
            :readonly="_is_readonly"
            v-bind="$attrs"
            v-bind:value="value"
            v-on="inputListeners"
        >
            <slot />
        </BFormInput>

        <span v-if="formState.debug">
            SE:{{ serverErrors }} [{{ serverErrorsIndex }}].{{ name }}<br />
            AL_SE:{{ all_server_errors }}
        </span>
    </o-form-group>
</template>

<script>
import { BFormInput, BInputGroup, BInputGroupText } from "bootstrap-vue";
import FormComponentMixin from "../../../mixins/form_component_mixin.js";
import OFormGroup from "../OFormGroup.vue";

export default {
    name: "OTextField",
    components: {
        BInputGroup,
        BInputGroupText,
        BFormInput,
        OFormGroup,
    },
    mixins: [FormComponentMixin],
    props: {
        prepend: { type: String, default: null },
        append: { type: String, default: null },
        /* inputClass: { type: String, default: "" }, USE class-name="" instead */
    },
    computed: {
        hasPrepend() {
            return this.prepend || !!this.$slots["prepend"];
        },
        hasAppend() {
            return this.append || !!this.$slots["append"];
        },
    },
};
</script>

<style scoped>
input[type="number"] {
    text-align: right;
}
</style>
