<template>
    <BContainer :style="container_style" fluid id="container">
        <ocom-qa-warning v-if="!noQAWarning" />

        <transition enter-active-class="animated fadeIn" name="router-anim">
            <router-view :key="$route.path"></router-view>
        </transition>

        <slot></slot>
    </BContainer>
</template>

<script>
import { BContainer } from "bootstrap-vue";
import { mapGetters } from "vuex";
import OcomQaWarning from "../../ocom/QAWarning.vue";

export default {
    components: {
        BContainer,
        OcomQaWarning,
    },
    props: {
        noNavBar: { type: Boolean, default: false },
        noQAWarning: { type: Boolean, default: false },
        marginTop: { type: String, default: "60px" },
        alertTimeout: { type: Number, default: 5000 },
        resize: { type: Boolean, default: false },
    },
    computed: {
        ...mapGetters(["messages"]),
        container_style() {
            if (this.noNavBar) {
                return {};
            } else {
                return { "margin-top": this.marginTop || "60px" };
            }
        },
    },
    watch: {
        messages() {
            var title = {
                primary: "Message",
                secondary: "Message",
                danger: "Error",
                warning: "Warning",
                success: "Success",
                info: "Info",
            }[this.messages.type];

            this.$bvToast.toast(this.messages.message, {
                bodyClass: this.resize
                    ? "bodyClass d-flex justify-content-start align-items-center"
                    : "",
                headerClass: this.resize ? "headerClass " : "",
                title: title,
                variant: this.messages.type,
                autoHideDelay: this.alertTimeout,
                solid: true,
                append: true,
            });
        },
    },
};
</script>

<style>
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css";
.bodyClass {
    height: 100px;
    font-size: 20px;
}
.headerClass {
    font-size: 18px;
}
</style>
