<template>
    <span>
        <app-login-form
            :api_error="apiError"
            @cancelled="onFormCancelled"
            @submitted="onFormSubmitted"
        >
        </app-login-form>

        <BToast id="toast-invalid" solid variant="danger">
            <div
                class="d-flex flex-grow-1 align-items-baseline"
                slot="toast-title"
            >
                <strong class="mr-auto"
                    >Cannot Login - Error status: {{ status }}</strong
                >
            </div>
            <span>There was a problem logging you in.<br /></span>
        </BToast>

        <BToast id="toast-server-down" solid variant="danger">
            <div
                class="d-flex flex-grow-1 align-items-baseline"
                slot="toast-title"
            >
                <strong class="mr-auto">Cannot Login</strong>
            </div>
            The Server is not responding, please wait a few minutes and try
            again. If this continues please contact support
            <a href="mailto:support@ocom.com.au" target="email"
                >support@ocom.com.au</a
            >
        </BToast>
    </span>
</template>

<script>
import { BToast } from "bootstrap-vue";
/* eslint-disable no-unused-vars */
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import { localUrls } from "../urls.js";
import LoginForm from "./LoginForm.vue";

export default {
    components: {
        BToast,
        appLoginForm: LoginForm,
    },

    props: {
        redirectTo: String,
        dontRedirect: Boolean,
    },
    data() {
        return {
            // whether any operations are currently running
            working: false,

            // error messages returned from API (e.g. invalid login)
            apiError: null,
            status: "",
        };
    },

    methods: {
        /**
         * Attempts to submit the current user data to the API to login.
         */
        onFormSubmitted(value, event) {
            const user = {
                username: value.username,
                password: value.password,
            };

            this.working = true;
            this.apiError = null;

            this.doLogin(user).then(
                () => {
                    this.working = false;
                    this.doRedirect();
                },
                (error) => {
                    if (!error.response) {
                        this.$bvToast.show("toast-server-down");
                    } else {
                        this.$bvToast.show("toast-invalid");
                        this.serverErrors = _.clone(error.response.data);
                        this.apiError = error.response.data;
                        this.status = error.response.status;
                        this.working = false;
                    }
                },
            );
        },

        doRedirect() {
            if (this.dontRedirect) {
                return; // Do nothing..
            }

            if (this.redirectTo) {
                this.$router.push(this.redirectTo);
            } else {
                this.$router.go(-1); // previous page..
            }
        },

        onFormCancelled(value, event) {
            this.$router.push(localUrls.accountCreate);
        },

        ...mapActions("AuthModule", ["doLogin"]),
    },
    computed: {
        ...mapGetters("AuthModule", ["isLoggedIn"]),
    },

    created() {
        if (this.isLoggedIn) {
            this.doRedirect();
        }

        console.log("Logging in....");
    },
};
</script>
