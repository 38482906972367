<template>
    <a href="http://ocom.com.au" target="_blank"
        ><span style="color: #f78f1e">O</span
        ><span style="color: #0059aa"
            >com<span v-if="!short"> Software</span></span
        ></a
    >
</template>
<script>
export default {
    name: "Ocom",
    props: {
        short: { type: Boolean, default: false },
    },
};
</script>
