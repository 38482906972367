import vSelect from "vue-select";

export default {
  mixins: [vSelect],
  name: "OVueSelect",
  watch: {
    open: function (newValue) {
      if (newValue === true) {
        // Just Opened dropdown

        for (let i = 0; i < this.filteredOptions.length; i++) {
          if (this.isOptionSelected(this.filteredOptions[i])) {
            // Highlight the first selected option
            this.typeAheadPointer = i;
            this.$nextTick(function () {
              // Scroll to it if there is a lot of options.
              this.maybeAdjustScroll();
            });
            break;
          }
        }
      }
    },
  },
};
