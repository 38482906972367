<template>
    <o-item-editable-table
        :default-new-value="newChild"
        @change="changed"
        disable-move=""
        item-name=""
        v-model="items"
    >
        <template slot="o-headers">
            <th>{{ schema.fields.field_name.label }}</th>
            <th>{{ schema.fields.deny_read.label }}</th>
            <th>{{ schema.fields.deny_update.label }}</th>
        </template>
        <tr slot="o-top-row">
            <td>
                <BFormInput
                    placeholder="Search Fields"
                    type="search"
                    v-if="false"
                    v-model="filterName"
                />
            </td>
            <td>
                <div class="btn-group" role="group">
                    <BButton
                        @click="setValues('deny_read', false)"
                        variant="success"
                        >None</BButton
                    >
                    <BButton
                        @click="setValues('deny_read', true)"
                        variant="danger"
                        >All</BButton
                    >
                </div>
            </td>
            <td>
                <div class="btn-group" role="group">
                    <BButton
                        @click="setValues('deny_update', false)"
                        variant="success"
                        >None</BButton
                    >
                    <BButton
                        @click="setValues('deny_update', true)"
                        variant="danger"
                        >All</BButton
                    >
                </div>
            </td>
        </tr>
        <template #o-body="{ item, index }">
            <td v-if="canSee(schema, 'field_name')">
                <o-widget-wrapper
                    :form-state="formState"
                    :schema="schema"
                    :server-errors="_se"
                    :server-errors-index="index"
                    :validators="validators.$each[index]"
                    name="field_name"
                >
                    <o-select
                        :form-state="formState"
                        :items="model_fields_list"
                        :server-errors="_se"
                        :server-errors-index="index"
                        :validators="validators.$each[index]"
                        name="field_name"
                        nolabel
                        v-bind="schema.fields.field_name"
                        v-model="items[index].field_name"
                    ></o-select>

                    <o-text-field
                        :form-state="formState"
                        :server-errors="_se"
                        :server-errors-index="index"
                        :validators="validators.$each[index]"
                        name="field_name"
                        nolabel
                        v-bind="schema.fields.field_name"
                        v-model="items[index].field_name"
                    ></o-text-field>
                </o-widget-wrapper>
            </td>
            <td
                :class="{
                    'table-danger': items[index].deny_read,
                    'click-cell': true,
                }"
                @click.self="set(items[index], 'deny_read')"
                v-if="canSee(schema, 'deny_read')"
            >
                <o-widget-wrapper
                    :form-state="formState"
                    :schema="schema"
                    :server-errors="_se"
                    :server-errors-index="index"
                    :validators="validators.$each[index]"
                    name="deny_read"
                >
                    <o-checkbox
                        :form-state="formState"
                        :server-errors="_se"
                        :server-errors-index="index"
                        :validators="validators.$each[index]"
                        name="deny_read"
                        nolabel
                        size="lg"
                        v-bind="schema.fields.deny_read"
                        v-model="items[index].deny_read"
                    ></o-checkbox>
                </o-widget-wrapper>
            </td>
            <td
                :class="{
                    'table-danger':
                        items[index].deny_update || items[index].deny_read,
                    'click-cell': true,
                }"
                @click.self="set(items[index], 'deny_update')"
                v-if="canSee(schema, 'deny_update')"
            >
                <o-widget-wrapper
                    :form-state="formState"
                    :schema="schema"
                    :server-errors="_se"
                    :server-errors-index="index"
                    :validators="validators.$each[index]"
                    name="deny_update"
                >
                    <o-checkbox
                        :form-state="formState"
                        :server-errors="_se"
                        :server-errors-index="index"
                        :validators="validators.$each[index]"
                        name="deny_update"
                        nolabel
                        size="lg"
                        v-bind="schema.fields.deny_update"
                        v-if="!items[index].deny_read"
                        v-model="items[index].deny_update"
                    ></o-checkbox>
                </o-widget-wrapper>
            </td>
        </template>
    </o-item-editable-table>
</template>
<script>
import { BButton, BFormInput } from "bootstrap-vue";
import _ from "lodash";
import all_schema from "../../../../app/schema/index.js";
import schema from "../../schema/index.js";
import { cleanUpModel } from "../../../schema/schema_vuetify.js";
import { CreateDefaultItem } from "../../../schema/schema_utils.js";
import {
    CreateCodeTableFetchMixin,
    CreateFormStateMixin,
    CreateGroupPermissionMixin,
} from "../../../mixins";
import OItemEditableTable from "../../../components/table/OItemEditableTable.vue";
import OSection from "../../../components/layout/OSection.vue";

export default {
    name: "GroupStateModelFieldFormTable",
    mixins: [
        CreateFormStateMixin("validators"),
        CreateGroupPermissionMixin("GroupStateModelField"),
        CreateCodeTableFetchMixin([]),
    ],
    components: {
        BFormInput,
        BButton,
        OItemEditableTable,
        OSection,
    },
    model: {
        prop: "items",
        event: "changed",
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        label: { type: String, default: null },
        validators: { type: Object, default: () => null },
        level: { type: Number, default: 0 },

        modelName: { type: String, default: null },
    },
    data: function () {
        return {
            all_schema: all_schema,
            schemaModel: cleanUpModel(schema.GroupStateModelField, {}),
            newChild: CreateDefaultItem(schema.GroupStateModelField),
            filterName: null,
        };
    },
    methods: {
        changed() {
            if (this.validators) {
                this.validators.$touch();
            }

            if (this.formState) this.formState.dirty = true;

            this.$emit("changed", this.items);
        },
        set(item, fieldName) {
            this.$set(item, fieldName, !(item[fieldName] || false));
        },
        setValues(fieldName, value) {
            var vm = this;

            _.forEach(this.items, function (item) {
                vm.$set(item, fieldName, value);
            });
        },
    },
    computed: {
        current_schema_model() {
            if (this.modelName) {
                var schema_models = _.keyBy(_.values(this.all_schema), "name");

                var schema_model = schema_models[this.modelName];

                return schema_model;
            }
            return null;
        },
        model_fields_list() {
            if (this.current_schema_model) {
                return _.map(
                    this.current_schema_model.fields,
                    function (field) {
                        return { id: field.name, description: field.label };
                    }
                );
            }
            return [];
        },
    },
    created() {
        // this.loadCodeTables()
    },
};
</script>

<style>
td.click-cell {
    text-align: center;
    vertical-align: middle !important;
}
</style>
