<template>
    <o-form-group
        :form-state="formState"
        :label="label"
        :message="errorMessages"
        :nolabel="nolabel"
        :type="{ 'is-danger': hasError }"
    >
        <o-flatpickr-wrapper
            :class="input_class"
            :config="config"
            :disabled="_is_readonly"
            :hide-date="hideDate"
            :hide-time="hideTime"
            :include-time="includeTime"
            :inline="inline"
            :name="name"
            :no-buttons="noButtons"
            :value="value"
            :placeholder="placeholder"
            :first-day-of-week="firstDayOfWeek"
            :min-date="minDate"
            :max-date="maxDate"
            @input="on_change"
            v-bind="$attrs"
            v-on="inputListeners"
        >
        </o-flatpickr-wrapper>
    </o-form-group>
</template>

<script>
import FormComponentMixin from "../../../mixins/form_component_mixin.js";
import OFormGroup from "../OFormGroup.vue";
import OFlatpickrWrapper from "./private/OFlatpickrWrapper.vue";

export default {
    name: "ODateTime",
    components: { OFlatpickrWrapper, OFormGroup },
    mixins: [FormComponentMixin],
    model: {
        value: "value",
        event: "input",
    },
    props: {
        hideTime: { type: Boolean, default: false },
        hideDate: { type: Boolean, default: false },
        includeTime: {
            type: Boolean,
            default: false,
        } /* see OFlatpickrWrapper */,
        config: {
            type: Object,
            default: () => {},
        },
        inline: { type: Boolean, default: false },
        noButtons: { type: Boolean, default: false },
        placeholder: { type: String, default: "Select date" },
        name: { type: String, default: "datetime" },
        firstDayOfWeek: { type: Number, default: 0 },
        minDate: { type: String | Date, default: "" },
        maxDate: { type: String | Date, default: "" },
    },
};
</script>

<style scoped></style>
