<template>
    <div>
        <b-input
            v-model="filterText"
            placeholder="Filter items"
            v-if="systemSelected"
        >
        </b-input>
        <table class="table-container">
            <tbody>
                <tr
                    v-for="(row, rowIndex) in divideDataIntoRows"
                    :key="rowIndex"
                >
                    <td v-for="(item, colIndex) in row" :key="colIndex">
                        <b-button
                            :variant="getVariant(item)"
                            class="button-scope"
                            v-b-modal.modal-center
                            @click="buttonClicked(item.id)"
                            >{{ item.description }}</b-button
                        >
                    </td>
                </tr>
            </tbody>
        </table>

        <b-modal
            id="modal-center"
            ref="quantity-modal"
            centered
            title="Quantity"
        >
            <b-form-input
                v-model="quantity"
                placeholder="Enter Quantity"
                type="number"
                step="1"
                :min="0"
            ></b-form-input>
            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        variant="success"
                        size="md"
                        class="float-left mr-2"
                        @click="quantityPlusOne"
                    >
                        +1
                    </b-button>
                    <b-button
                        variant="success"
                        size="md"
                        class="float-left mr-2"
                        @click="quantityPlusTen"
                    >
                        +10
                    </b-button>
                    <b-button
                        variant="warning"
                        size="md"
                        class="float-left mr-2"
                        @click="quantityMinusOne"
                    >
                        -1
                    </b-button>
                    <b-button
                        variant="warning"
                        size="md"
                        class="float-left mr-2"
                        @click="quantityMinusTen"
                    >
                        -10
                    </b-button>
                    <b-button
                        variant="danger"
                        size="md"
                        class="float-left"
                        @click="quantity = null"
                    >
                        Clear
                    </b-button>
                    <b-button
                        variant="primary"
                        size="lg"
                        class="float-right"
                        :disabled="disableOK"
                        @click="emitQuantity"
                    >
                        Ok
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import _ from "lodash";

import { filter_active_list } from "../../utils/active.js";

export default {
    name: "OTableButton",
    props: {
        data: { type: Array, default: () => [] },
        maxCols: { type: Number, default: 7 },
        itemSelected: { type: Number, default: null },
        systemSelected: { type: Number, default: null },
    },
    data() {
        return {
            dividedData: [],
            quantity: null,
            clickedValue: "",
            selectedItem: "",
            filterText: "",
        };
    },
    methods: {
        hideModal() {
            this.$refs["quantity-modal"].hide();
        },
        emitQuantity() {
            const vm = this;
            vm.$emit("quantity", vm.quantity);
            vm.$emit("item-description", vm.clickedValue);
            vm.hideModal();
            vm.quantity = null;
            vm.itemSelected = null;
        },
        buttonClicked(value) {
            this.clickedValue = value;
            this.itemSelected = value;
            this.$emit("selected-item", this.itemSelected);
        },
        quantityPlusOne() {
            if (this.quantity) {
                this.quantity = parseInt(this.quantity) + 1;
            } else {
                this.quantity += 1;
            }
        },
        quantityPlusTen() {
            if (this.quantity) {
                this.quantity = parseInt(this.quantity) + 10;
            } else {
                this.quantity += 10;
            }
        },
        quantityMinusOne() {
            if (this.quantity) {
                this.quantity = parseInt(this.quantity) - 1;
            } else {
                this.quantity -= 1;
            }
        },
        quantityMinusTen() {
            if (this.quantity) {
                this.quantity = parseInt(this.quantity) - 10;
            } else {
                this.quantity -= 10;
            }
        },
        getVariant(item) {
            return { false: "secondary", true: "success" }[
                this.itemSelected === item.id
            ];
        },
    },
    computed: {
        disableOK() {
            return this.quantity <= 0;
        },
        filteredData() {
            const vm = this;

            var filteredResults = _.map(
                filter_active_list(vm.data),
                function (item) {
                    if (
                        item.description
                            .toLowerCase()
                            .includes(vm.filterText.toLowerCase())
                    ) {
                        return item;
                    }
                }
            );

            filteredResults = _.without(filteredResults, undefined);

            return filteredResults;
        },
        divideDataIntoRows() {
            let results = [];
            if (!this.filterText) {
                results = _.chunk(this.data, this.maxCols);
            } else {
                results = _.chunk(this.filteredData, this.maxCols);
            }

            return results;
        },
    },
    watch: {
        quantity(newValue) {
            if (newValue < 0) {
                this.quantity = 0;
            }
        },
    },
};
</script>

<style scoped>
.button-scope {
    width: 140px !important;
    height: 80px;
}
.table-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
