<template>
    <div class="row mb-3">
        <div class="col-sm-10 offset-1">
            <query-logic
                :filter-option="filterOption"
                :meta-data="metaData"
                :query-select-options="querySelectOptions"
                :special-options="specialOptions"
                :value="current_query"
                @change="changed"
            />

            <span class="col-sm-12" v-if="debug"><br /><br />{{ value }}</span>

            <div class="card-footer text-right">
                <BButton @click="apply" variant="primary">Apply Query</BButton>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";

import { BButton } from "bootstrap-vue";
import { QUERY_SPECIAL_CODES } from "../special_codes.js";
import { QUERY_FIELD_OPTIONS } from "../filter_options.js";
import QueryLogic from "./private/QueryLogic.vue";

export default {
    components: {
        BButton,
        QueryLogic,
    },
    name: "QueryBuilder",
    data() {
        return {
            current_query: { and: [[null, null]] },
        };
    },
    model: {
        value: "value",
        event: "change",
    },
    props: {
        value: { type: [Object, String], default: null },
        metaData: {
            type: Object,
            default: () => {},
        },
        filterOption: { type: Object, default: () => QUERY_FIELD_OPTIONS },
        specialOptions: { type: Object, default: () => QUERY_SPECIAL_CODES },
        querySelectOptions: {
            type: Object,
            default: () => {},
        },
        debug: { type: Boolean, default: false },
    },
    methods: {
        parse_value(val) {
            if (!val) {
                this.current_query = { and: [[null, null]] };
                return;
            }

            if (_.isString(val) && val != "") {
                val = JSON.parse(val); // parse it
            }

            this.current_query = val;
        },
        apply() {
            this.$emit("change", this.current_query);
        },
        changed(value) {
            this.current_query = value;
        },
    },
    watch: {
        value: function (val) {
            this.parse_value(val);
        },
    },
    created() {
        this.parse_value(this.value);
    },
};
</script>
