<template>
  <div>
    <b-navbar variant="light">
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <strong @click="enableDebug($event)">Testing</strong>
        </b-navbar-nav>
      </b-collapse>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-form>
            <o-date-time
              :form-state="formState"
              hide-time
              label="Test Date"
              name="test_date"
              nolabel
              placeholder="Today"
              v-model="test_date"
              @input="changeTestDate(test_date)"
            ></o-date-time>
            <b-form-select
              :form-state="formState"
              :loading="optionEmployeeWorking"
              @input="changeEmployee"
              name="employee"
              placeholder="Tester"
              v-model="tester"
            >
              <b-form-select-option value=""
                >Select Tester
              </b-form-select-option>
              <template v-for="(option, index) in activeEmployeeItems">
                <b-form-select-option :key="index" :value="option.id">{{
                  option.full_name
                }}</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-select
              class="ml-4"
              :form-state="formState"
              :loading="optionCodeTestCycleWorking"
              :options="activeCodeTestCycleItems"
              @input="changeTestCycle(list_options.test_cycle)"
              name="test-cycle"
              placeholder="Test Cycle"
              text-field="description"
              v-model="list_options.test_cycle"
              value-field="id"
            >
            </b-form-select>
          </b-nav-form>
        </b-navbar-nav>

        <!-- <b-button :to="{name: 'asset.create', params:{'id':0}}" class="ml-4" size="sm">Create Asset</b-button>
        <b-button :to="{name: 'asset_test.create', params:{'id':0}}" class="ml-1" size="sm">Create Test</b-button> -->
        <b-button
          :disabled="!test_cycle_and_customer_match"
          :to="{ name: 'testing.create', params: { id: 0 } }"
          class="ml-1"
          size="sm"
          >Create Asset & Test</b-button
        >
      </b-collapse>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-form>
            <b-form-select
              :options="filter_list"
              :value="list_options.filter"
              class="mr-2"
              options-field="None"
              text-field="name"
              value-field="code"
              @input="showTab($event)"
            />

            <b-button class="ml-1 primary" size="sm" @click="loadData"
              >Reload</b-button
            >
          </b-nav-form>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <div v-if="false">
      <query-builder
        :meta-data="schemaModel"
        :query-select-options="options"
        v-model="list_options.query"
        @change="run_query"
      ></query-builder>
    </div>

    <b-alert show variant="warning" v-if="!test_cycle_and_customer_match">
      The Customer selected and the Test Cycle being used don't match. Please
      select a Test Cycle for this customer.
    </b-alert>

    <b-table
      :fields="fields"
      :items="asset_items"
      :sort-by="list_options.sort"
      :sort-desc="list_options.order === 'desc'"
      @sort-changed="change_sort"
      bordered
      class="o-list"
      head-variant="light"
      no-local-sorting
      :tbody-transition-props="transProps"
      primary-key="id"
      striped
      v-if="test_cycle_and_customer_match"
      thead-class="o-gradient-header"
    >
      <template slot="top-row" slot-scope="{ columns, fields }">
        <td :key="field.key" class="p-0" v-for="field in fields">
          <span v-if="field.key == 'customer'">
            <o-vue-select
              :get-option-label="customer_option_label"
              :options="customer_items"
              :reduce="(o) => o.id"
              @input="changeCustomer"
              class="select-style select-style-md"
              v-model="data.customer_id"
            ></o-vue-select>
          </span>
          <span v-if="field.key == 'status'">
            <o-vue-select
              :options="optionCodeAssetStatusItems"
              :reduce="(o) => o.id"
              @input="filterNow"
              class="select-style select-style-sm"
              label="description"
              v-model="filter.status"
            ></o-vue-select>
          </span>
          <span v-if="field.key == 'location'">
            <o-tree-select
              v-model="filter.location"
              :items="location_items"
              name="location_filter"
              nolabel
              value-type="integer"
              @input="changeLocation"
            ></o-tree-select>
          </span>
          <span v-if="field.key == 'description'">
            <b-form-input
              @change="filterNow"
              class="rounded-0 px-0"
              type="search"
              v-model="filter.description"
            ></b-form-input>
          </span>
          <span v-if="field.key == 'make'">
            <b-form-input
              @change="filterNow"
              class="rounded-0 px-0"
              type="search"
              v-model="filter.make"
            ></b-form-input>
          </span>
          <span v-if="field.key == 'model'">
            <b-form-input
              @change="filterNow"
              class="rounded-0 px-0"
              type="search"
              v-model="filter.model"
            ></b-form-input>
          </span>
          <span v-if="field.key == 'test_frequency'">
            <o-vue-select
              :options="optionCodeTestFrequencyItems"
              :reduce="(o) => o.id"
              @input="filterNow"
              class="select-style select-style-sm"
              label="description"
              v-model="filter.test_frequency"
            ></o-vue-select>
          </span>
          <span v-if="field.key == 'asset_color'">
            <o-vue-select
              :options="optionCodeColorItems"
              :reduce="(o) => o.id"
              @input="filterNow"
              class="select-style select-style-sm"
              label="description"
              v-model="filter.asset_color"
            ></o-vue-select>
          </span>
          <span v-if="field.key == 'length'">
            <b-form-input
              @change="filterNow"
              class="rounded-0 px-0"
              type="search"
              v-model="filter.length"
            ></b-form-input>
          </span>
          <span v-if="field.key == 'code'">
            <b-form-input
              @change="filterNow"
              class="rounded-0 px-0"
              type="search"
              v-model="filter.code"
            ></b-form-input>
          </span>
        </td>
      </template>

      <template slot="cell(customer)" slot-scope="{ index, item }">
        <lookup-display
          :list="optionCustomerItemsById"
          :value="item.customer"
          default-value="-"
          display-field="name"
        ></lookup-display>
        -
        <lookup-display
          :list="optionCustomerItemsById"
          :value="item.customer"
          default-value="-"
          display-field="division"
        ></lookup-display>
      </template>

      <template slot="cell(product)" slot-scope="{ index, item }">
        <lookup-display
          :list="optionProductItemsById"
          :value="item.product"
          default-value="-"
          display-field="product_type"
        ></lookup-display>
      </template>
      <template slot="cell(serial_number)" slot-scope="{ index, item }">
        <default-display :value="item.serial_number"></default-display>
      </template>

      <template slot="cell(location_type)" slot-scope="{ index, item }">
        {{ getLocationType(item) }}
      </template>
      <template slot="cell(location)" slot-scope="{ index, item }">
        <lookup-display
          :list="location_items_ById"
          :value="item.location"
          default-value="-"
          display-field="description"
        ></lookup-display>
        <br />
        ({{ getLocationType(item) }})
        <!--        <a class="btn btn-small" @click="saveLocation(item.location)"-->
        <!--          ><book-marker-icon></book-marker-icon-->
        <!--        ></a>-->
        <a class="btn btn-small float-right" @click="restoreLocation(item)"
          ><map-marker-left-icon
        /></a>
      </template>
      <template slot="cell(description)" slot-scope="{ index, item }">
        <default-display :value="item.description"></default-display> <br />

        <default-display
          :value="item.length"
          default-value=""
        ></default-display>
        <span
          v-if="
            !(
              item.asset_color == null ||
              item.length === '' ||
              item.length == null
            )
          "
        >
          -
        </span>
        <lookup-display
          :list="optionCodeColorItemsById"
          :value="item.asset_color"
          default-value=""
          display-field="description"
        ></lookup-display>
      </template>
      <template slot="cell(make)" slot-scope="{ index, item }">
        <default-display :value="item.make"></default-display>
        <lookup-display
          :list="optionCodeColorItemsById"
          :value="item.asset_color"
          default-value=""
          display-field="description"
        ></lookup-display>
      </template>
      <template slot="cell(model)" slot-scope="{ index, item }">
        <default-display :value="item.model"></default-display>
      </template>
      <template slot="cell(test_frequency)" slot-scope="{ index, item }">
        <lookup-display
          :list="optionCodeTestFrequencyItemsById"
          :value="item.test_frequency"
          default-value="-"
          display-field="description"
        ></lookup-display>
      </template>
      <template slot="cell(asset_color)" slot-scope="{ index, item }">
        <lookup-display
          :list="optionCodeColorItemsById"
          :value="item.asset_color"
          default-value="-"
          display-field="description"
        ></lookup-display>
      </template>
      <template slot="cell(length)" slot-scope="{ index, item }">
        <default-display :value="item.length"></default-display>
      </template>
      <template slot="cell(last_test_date)" slot-scope="{ index, item }">
        <date-display :value="item.last_test_date"></date-display>
      </template>
      <template slot="cell(status)" slot-scope="{ index, item }">
        <lookup-display
          :list="optionCodeAssetStatusItemsById"
          :value="item.status"
          default-value="-"
          display-field="description"
        ></lookup-display>
      </template>

      <template slot="cell(code)" slot-scope="{ index, item }">
        <router-link :to="{ name: 'asset.edit', params: { id: item.id } }">
          <default-display
            :value="item.code"
            default-value="--unknown--"
          ></default-display>
        </router-link>
      </template>

      <template slot="cell(actions)" slot-scope="{ index, item }">
        <b-button-group>
          <b-button
            v-if="showButton('pass', item)"
            :disabled="tester == null"
            @click="doPass(item)"
            variant="success"
            >Pass
          </b-button>
          <b-button
            v-if="showButton('fail', item)"
            :disabled="tester == null"
            @click="doFail(item)"
            variant="danger"
            >Fail
          </b-button>
        </b-button-group>
        <b-button @click="outOfService(item, index)" variant="primary"
          >Out of Service
        </b-button>
      </template>
      <template slot="empty">
        <p>
          <emoticon-sad-icon large></emoticon-sad-icon>
        </p>
        No Data Found. Please
        <router-link :to="{ name: 'asset_test.create' }"
          >create a new item</router-link
        >
      </template>
    </b-table>
    <div class="w-100 d-flex flex-row justify-content-end">
      <b-button
        :disabled="loadingUpdateStatus"
        v-if="list_options.filter == 'untested'"
        variant="primary"
        class="ml-1 primary"
        size="sm"
        @click="updateStatus()"
        >Set status to MIA</b-button
      >
    </div>

    <o-list-paging
      :limit="list_options.limit"
      :offset="list_options.offset"
      :page-options="rows_per_page_items"
      :total-items="total_items"
      @change-limit="change_limit"
      @change-page="change_page"
    />
  </div>
</template>
<script>
import * as moment from "moment";
import _ from "lodash";

import {
  CreateGroupPermissionMixin,
  CreateListPageMixin,
  UseRestModuleMixin,
} from "../../../vue-ocom/mixins";
import schema from "../../schema";
import { cleanUpModel } from "../../../vue-ocom/schema/schema_vuetify";
import { fieldToTableColumn } from "../../../vue-ocom/schema/schema_utils";
import { STATUS_FAIL, STATUS_PASS } from "../../asset_test/utils";
import "vue-select/dist/vue-select.css";
import QueryBuilder from "../../../vue-ocom/query/component/QueryBuilder";
import { object_to_and_query } from "../../../vue-ocom/query/expression";
import { sort_and_filter_active_list } from "../../../vue-ocom/utils/active";
import { mapActions, mapGetters } from "vuex";
import OVueSelect from "../../../components/OVueSelect";
import OTreeSelect from "../../../components/OTreeSelect";
import AssetLocationCodeTable from "@/mixins/AssetLocationCodeTable";
import BookMarkerIcon from "vue-material-design-icons/BookMarker";
import MapMarkerLeftIcon from "vue-material-design-icons/MapMarkerLeft";
import AssetTestCountMixin from "@/mixins/AssetTestCountMixin.js";

import UseCodeModulesMixin from "@/vue-ocom/mixins/UseCodeModulesMixin";
import FetchAndUseCodeModMixin from "@/vue-ocom/mixins/FetchAndUseCodeModMixin";

export default {
  components: {
    OVueSelect,
    QueryBuilder,
    OTreeSelect,
    BookMarkerIcon,
    MapMarkerLeftIcon,
  },
  mixins: [
    CreateListPageMixin("Testing", "testing", "untested"),
    CreateGroupPermissionMixin("Testing"),
    FetchAndUseCodeModMixin([
      /* 'Customer', */ "Employee",
      "AssetLocation",
      "CodeTestCycle",
      "CodeColor",
      "CodeAssetStatus",
      "CodeTestFrequency",
      "CodeLocationType",
    ]),
    AssetLocationCodeTable,
    UseRestModuleMixin(["AssetTest"]),
    AssetTestCountMixin,
  ],
  data: function () {
    var today = new Date(); // now

    today = today.toISOString().split("T")[0]; // Just the Date part

    var tester = null;
    var test_cycle = null;
    var filter = {
      location: null,
    };

    if (this.data) {
      tester = this.data.tester;
      test_cycle = this.data.test_cycle;
      filter = this.data.testing_filter || {
        location: null,
      };
    }
    // TODO get employee from SessionStorage or Find current Employee for login
    // TODO Get the Customer_ID from SessionStorage
    // TOOD Get the Location from SessionStorage

    return {
      debug: false,
      test_date: today,
      tester: tester,
      test_cycle: test_cycle,
      loadingUpdateStatus: false,
      schemaModel: cleanUpModel(schema.Asset, {}),
      assetTestSchema: cleanUpModel(schema.AssetTest, {}),

      filter: filter,
      filter_list: [
        { name: "Untested", code: "untested", options: {} },

        { name: "All Tested", code: "tested", options: {} },
        { name: "Passed", code: "passed", options: {} },
        { name: "Failed", code: "failed", options: {} },
      ],

      formState: {},

      transProps: {
        // Transition name
        name: "fade",
      },
    };
  },
  methods: {
    ...mapActions("GlobalStateModule", ["setGlobalValue"]),
    ...mapActions("AssetModule", { patchAssetItem: "patchItem" }),
    async updateStatus() {
      const confirmation = confirm("Update all the status to MIA?");
      if (confirmation) {
        this.loadingUpdateStatus = true;
        await _.forEach(this.items, (item) => {
          this.patchAssetItem({ id: item.id, status: 3 });
        });
        this.update_table(this.list_options);
        this.loadingUpdateStatus = false;
      }
    },
    outOfService(item, index) {
      const confirmation = confirm("Make " + item.code + " out of service?");
      if (confirmation) {
        this.patchAssetItem({ id: item.id, status: 2 });
        this.items.splice(index, 1);
      }
    },
    saveLocation(location) {
      window.sessionStorage.setItem(
        "last_location_id",
        JSON.stringify({ value: location })
      );
    },
    restoreLocation(item) {
      var lastLocationData = window.sessionStorage.getItem("last_location_id");
      if (lastLocationData) {
        var newLocation = JSON.parse(lastLocationData).value;
        item.location = newLocation;

        this.patchAssetItem({ id: item.id, location: newLocation });
      }
    },
    location_option_label(location) {
      var result = location.description;

      return result;
    },
    customer_option_label(customer) {
      var result = customer.company_name;
      if (customer.division) {
        result = result + " - " + customer.division;
      }

      if (!result) {
        result = "--unknown--";
      }

      return result;
    },
    getLocationType(item) {
      var location = this.location_items_ById[item.location];
      if (location) {
        var codelocationType = this.optionCodeLocationTypeItemsById[
          location.location_type
        ];
        if (codelocationType) {
          return codelocationType.description;
        }
      }
      return "";
    },
    changeLocation(location) {
      this.filter.location = location;
      this.loadData();
    },
    changeCustomer(customer) {
      this.setGlobalValue("customer_id", customer);
      this.changeTestDate(null);
      this.filter.customer = customer;
      this.filter.location = null;

      this.loadData();
    },
    changeEmployee(employee_id) {
      this.setGlobalValue({ key: "tester", value: employee_id });
      this.tester = employee_id;
    },
    changeTestDate(test_date) {
      if (this.test_date == null) {
        test_date = new Date().toISOString();
      } else {
        test_date = moment(test_date).toISOString();
      }
      this.setGlobalValue({ key: "test_date", value: test_date });
      this.test_date = test_date;
    },
    changeTestCycle(test_cycle_id) {
      this.setGlobalValue({ key: "test_cycle", value: test_cycle_id });
      this.changeTestDate(null);
      this.list_options.test_cycle = test_cycle_id;
      this.loadData();
    },
    filterNow() {
      this.setGlobalValue({ key: "testing_filter", value: this.filter });
      if (this.list_options.filter === "active") {
        this.list_options.filter === "untested";
      }

      this.list_options.query = object_to_and_query(this.filter);
      this.loadData();
    },
    run_query() {
      this.loadData();
    },
    refreshAssetTestCount() {
      this.updateAssetTestCount(this.data.customer_id);
    },
    doPass(item) {
      if (
        this.list_options.filter === "untested" ||
        this.list_options.filter == "active"
      ) {
        this.pass(item);
      } else {
        this.editAssetTestForAsssetItem(item);
      }
    },
    pass(asset) {
      const vm = this;

      var test_date = this.test_date;
      var retrospective = false;
      if (this.test_date == null) {
        test_date = new Date().toISOString();
      } else {
        test_date = moment(test_date).toISOString();
        retrospective = true; // It's not retrospective
      }

      if (asset.status == 3 || asset.status == 5) {
        // is MIA or Customer Gone but just tested so reactivate!
        asset.status = 1; // active for display - Django should update the value in the db.
      }

      this.createNewAssetTestItem().then(function (newAssetTest) {
        var newAssetTestResult = {
          asset: asset.id,
          status: STATUS_PASS,
          tester: vm.tester,
          test_date: test_date,
          test_cycle: vm.list_options.test_cycle,
          supplied_product: null,
          retrospective: retrospective,
        };
        // merge them
        var assetTestResult = { ...newAssetTest, ...newAssetTestResult };

        vm.saveAssetTestItem(assetTestResult).then(function (result) {
          asset.test.push(result);
          vm.refreshAssetTestCount();
        });
      });
    },
    doFail(item) {
      if (
        this.list_options.filter === "untested" ||
        this.list_options.filter == "active"
      ) {
        this.fail(item);
      } else {
        this.editAssetTestForAsssetItem(item);
      }
    },
    editAssetTestForAsssetItem(item) {
      // redirect to edit asset_test page
      if (item.test && item.test[0]) {
        this.$router.push({
          name: "asset_test.edit",
          params: { id: item.test[0].id },
        });
      }
    },
    fail(asset) {
      const vm = this;

      var test_date = this.test_date;
      var retrospective = false;
      if (this.test_date == null) {
        test_date = new Date().toISOString();
      } else {
        test_date = moment(test_date).toISOString();
        retrospective = true; // It's not retrospective
      }

      this.createNewAssetTestItem().then(function (newAssetTest) {
        var newAssetTestResult = {
          asset: asset.id,
          status: STATUS_FAIL,
          tester: vm.tester,
          test_date: test_date,
          test_cycle: vm.list_options.test_cycle,
          supplied_product: null,
          retrospective: retrospective,
        };
        // merge them
        var assetTestResult = { ...newAssetTest, ...newAssetTestResult };

        vm.saveAssetTestItem(assetTestResult).then(function (result) {
          asset.test.push(result);
          vm.refreshAssetTestCount();
          vm.$router.push({
            name: "asset_test.edit",
            params: { id: result.id },
          });
        });
      });
    },
    loadData() {
      this.setGlobalValue("testing_filter", this.filter);

      if (this.data) {
        this.list_options.customer_id = this.data.customer_id;
      }

      this.list_options.limit = 25;
      this.list_options.query = object_to_and_query(this.filter);

      this.update_table(this.list_options);
    },
    before_get_all(list_options) {
      var result = _.cloneDeep(list_options);

      if (result["filter"] === "active") {
        result["filter"] = "untested";
      }

      if (
        !result["test_cycle"] &&
        this.activeCodeTestCycleItems[0] &&
        this.activeCodeTestCycleItems[0].id
      ) {
        result["test_cycle"] = this.activeCodeTestCycleItems[0].id; // default to first one
      }

      return result;
    },
    showTab(filter_name) {
      this.list_options.filter = filter_name;
      this.loadData();
    },
    showButton(button_type, item) {
      var vm = this;
      let show = false;

      var test_status = 0;
      if (item.test && item.test[0]) {
        test_status = item.test[0].status;
      }

      switch (vm.list_options.filter) {
        case "active":
        case "untested":
          show = true;
          break;
        case "tested":
          button_type === "pass" && test_status === 1 ? (show = true) : "";
          button_type === "fail" && test_status === -1 ? (show = true) : "";
          break;
        case "passed":
          button_type === "pass" ? (show = true) : (show = false);
          break;
        case "failed":
          button_type === "fail" ? (show = true) : (show = false);
          break;
      }
      return show;
    },
  },
  computed: {
    ...mapGetters("GlobalStateModule", ["data"]),
    ...mapGetters("AssetTestModule", { asset_test_items: "items" }),
    // working() {
    //     return this.optionItemsWorking;
    // },
    asset_items() {
      if (
        this.list_options.filter === "untested" ||
        this.list_options.filter === "active"
      ) {
        return _.filter(this.items, function (o) {
          return o.test && o.test.length === 0;
        });
      }
      if (this.list_options.filter === "tested") {
        return _.filter(this.items, function (o) {
          return o.test && o.test.length > 0;
        });
      }
      if (this.list_options.filter === "passed") {
        return _.filter(this.items, function (o) {
          return o.test && o.test[0] && o.test[0].status === 1;
        });
      }
      if (this.list_options.filter === "failed") {
        return _.filter(this.items, function (o) {
          return o.test && o.test[0] && o.test[0].status === -1;
        });
      } else {
        return this.items;
      }
    },
    options() {
      return {
        Customer: {
          options: this.optionCustomerItems,
          textField: "name",
          valueField: "id",
        },
        AssetLocation: {
          options: this.optionAssetLocationItems,
          textField: "description",
          valueField: "id",
        },
        Product: {
          options: this.optionProductItems,
          textField: "product_type",
          valueField: "id",
        },
        CodeColor: {
          options: this.optionCodeColorItems,
          textField: "description",
          valueField: "id",
        },
        // 'CodePhase': {'options': this.optionCodePhaseItems, 'textField': 'description', 'valueField': 'id'},
        CodeTestFrequency: {
          options: this.optionCodeTestFrequencyItems,
          textField: "description",
          valueField: "id",
        },
        // 'CodeElectricalClass': {
        //   'options': this.optionCodeElectricalClassItems,
        //   'textField': 'description',
        //   'valueField': 'id',
        // },
        // 'CodeAssetClassification': {
        //   'options': this.optionCodeAssetClassificationItems,
        //   'textField': 'description',
        //   'valueField': 'id',
        // },
        CodeAssetStatus: {
          options: this.optionCodeAssetStatusItems,
          textField: "description",
          valueField: "id",
        },
      };
    },
    fields() {
      return this.filterTableColumns(this.schema, [
        fieldToTableColumn(this.schema, "code"),
        fieldToTableColumn(this.schema, "location"),
        fieldToTableColumn(this.schema, "description"),
        fieldToTableColumn(this.schema, "make"),
        fieldToTableColumn(this.schema, "test_frequency"),
        fieldToTableColumn(this.schema, "last_test_date"),
        fieldToTableColumn(this.schema, "status"),

        {
          key: "actions",
          label: "Actions",
          sortable: false,
          thStyle: "width:26px;",
        },
      ]);
    },
    activeCodeTestCycleItems() {
      var items = this.optionCodeTestCycleItems; // cause Vue to register we USE this value

      return sort_and_filter_active_list(
        items,
        null,
        false,
        "description",
        "(Disabled)",
        false,
        ["description"]
      );
    },
    activeEmployeeItems() {
      var items = this.optionEmployeeItems; // cause Vue to register we USE this value

      return sort_and_filter_active_list(
        items,
        null,
        false,
        "full_name",
        "(Non-active)",
        false,
        ["full_name"]
      );
    },
    customer_items() {
      var items = this.optionCustomerItems; // cause Vue to register we USE this value

      return sort_and_filter_active_list(
        items,
        null,
        false,
        "company_name",
        "(Disabled)",
        false,
        ["company_name", "division"]
      );
    },
    location_items() {
      var result = this.optionAssetLocationItems; // cause Vue to register we USE this value

      // if (this.data.customer_id) {
      //   const customer = this.data.customer_id;
      //   result = _.filter(result, function(o) {
      //     return o.customer === customer;
      //   });
      // }

      return sort_and_filter_active_list(
        result,
        null,
        false,
        "company_name",
        "(Disabled)",
        false,
        ["description"]
      );
    },
    location_items_ById() {
      return _.keyBy(this.location_items, "id");
    },
    current_test_cycle() {
      if (!this.data.test_cycle) return null;

      var test_cycle_id = this.data.test_cycle;
      return this.optionCodeTestCycleItemsById[test_cycle_id];
    },
    test_cycle_and_customer_match() {
      if (
        !this.activeCodeTestCycleItems ||
        this.activeCodeTestCycleItems.length < 1
      ) {
        return false; // No Code Test cycle then they have not picked anything
      }

      if (this.current_test_cycle) {
        return this.current_test_cycle.customer == this.data.customer_id;
      } else return false;
    },
  },
  created() {
    this.filter = this.data.testing_filter || {};
    this.test_date =
      this.data.test_date || this.test_date || new Date().toISOString();
    this.tester = this.data.tester || null;
    this.list_options.test_cycle = this.data.test_cycle || null;
    this.loadData();
  },
};
</script>

<style>
.select-style-sm {
  min-width: 120px;
  min-height: 33.1875px;
}

.select-style-md {
  min-width: 150px;
  min-height: 33.1875px;
}

.select-style .vs__selected {
  height: 24px;
  white-space: nowrap;
  overflow: hidden;
}

.select-style .vs__search::placeholder,
.select-style .vs__dropdown-toggle,
.select-style .vs__dropdown-menu {
  background: #ffffff;
  color: #404040;
}

.select-style .vs__dropdown-toggle {
  height: 33px;
}

.select-style .vs__clear,
.select-style .vs__open-indicator {
  color: #404040;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
