<template>
    <span :class="get_class">
        <input
            type="checkbox"
            :checked="value"
            v-if="showIcon"
            readonly
            :style="iconStyle"
        />
        <template v-if="!showIcon">{{ display_value }}</template>
    </span>
</template>

<script>
import DisplayMixin from "../../mixins/display_mixin.js";

export default {
    name: "BoooleanDisplay",
    props: {
        value: { default: null },
        trueText: { type: String, default: "Yes" },
        falseText: { type: String, default: "No" },
        nullText: { type: String, default: "??" },
        showIcon: { type: Boolean, default: false },
        iconSize: { type: Number, default: 50 },
    },
    mixins: [DisplayMixin("display_value")],
    computed: {
        iconStyle() {
            return {
                transform:
                    "scale(" + (1 + 100 / this.iconSize).toFixed(2) + ");",
            };
        },
        display_value() {
            return {
                true: this.trueText,
                false: this.falseText,
                null: this.nullText,
            }[this.value];
        },
    },
};
</script>

<style scoped></style>
