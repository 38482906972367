// http://vuexjs.org/en/getters.html
import _, { isEmpty } from "lodash";
import { decodeJWT, isAuthDisabled } from "../services.js";
import * as CONFIG from "../../../config.js";
import { MergePermissions } from "vue-ocom/auth/store/permissions.js";

// if has token, we assume that user is logged in our system
export const isLoggedIn = function ({ token }) {
    if (isAuthDisabled()) {
        return true; // always "logged in"
    }

    var result = !isEmpty(token);

    if (result) {
        try {
            decodeJWT(token);
        } catch (err) {
            // Something went wrong either the token is not valid or expired etc.
            result = false; // there is a problem
        }
    }
    return result;
};

export const isSUDO = function ({ prev }) {
    return prev != null;
};

// get current user data
export const currentUser = function ({ user }) {
    if (user && !isEmpty(user) && "id" in user) {
        // WE have a User object

        if (!("descriptive_name" in user)) {
            user.descriptive_name =
                (user.first_name || "") + " " + (user.last_name || "");
        }
    }

    if (isAuthDisabled()) {
        // Create a Dummy user as per https://docs.djangoproject.com/en/3.0/ref/contrib/auth/#django.contrib.auth.models.User.is_authenticated
        return {
            pk: null,
            id: null,
            username: "anonymous",
            first_name: "Ann",
            last_name: "Onymous",
            groups: [],
            user_permissions: [],
            is_staff: false,
            is_active: true,
            is_superuser: false,
            is_authenticated: false,
            is_anonymous: true,
        }; // always "logged in"
    }

    return user;
};

export const token = function ({ token }) {
    if (isAuthDisabled()) {
        return "ANONYMOUS_TOKEN";
    }

    return token;
};

// Get the permissions
export const permissions = function ({ permissions }) {
    if (isAuthDisabled()) {
        return {};
    }

    return MergePermissions(CONFIG.DefaultPermissions, permissions);
};

export const use_permissions = function ({ use_permissions }) {
    if (isAuthDisabled()) {
        return false; // don't use permissions there are none
    }

    return use_permissions;
};
