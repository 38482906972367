import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

/* Create mapActions and mapGetters for a RestModule (and similar modules)
moduleList is an array of Module names but assume moduleList has the value
['Test']

Then the following actions and getters are created

mapActions({
    getTestById: 'TestModule/getById',
    patchTestItem: 'TestModule/pathItem',
    saveTestItem: 'TestModule/saveItem',
    deleteTestItem: 'TestModule/deleteItem',

    getAllTest: 'TestModule/getAll',
});

mapGetters({
    optionTestItems: 'TestModule/items',
    optionTestItemsById: 'TestModule/itemsByID',
    optionTestWorking: 'TestModule/working',
});
 */
export default function (moduleList) {
    console.error(
        "used UseCodeModuleMixin instead of UseRestModuleMixin instead. UseCodeModuleMixin is more powerful",
    );

    var actionList = {};
    var fetchActionList = {};
    var getterList = {};

    _.each(moduleList, function (moduleName) {
        actionList["get" + moduleName + "ById"] = moduleName + "Module/getById";
        actionList["createNew" + moduleName + "Item"] =
            moduleName + "Module/createNew";
        actionList["patch" + moduleName + "Item"] =
            moduleName + "Module/patchItem";
        actionList["save" + moduleName + "Item"] =
            moduleName + "Module/saveItem";
        actionList["delete" + moduleName + "Item"] =
            moduleName + "Module/deleteItem";

        fetchActionList["getAll" + moduleName] = moduleName + "Module/getAll";

        getterList["option" + moduleName + "Items"] =
            moduleName + "Module/items";
        getterList["option" + moduleName + "ItemsById"] =
            moduleName + "Module/itemsByID";
        getterList["option" + moduleName + "Working"] =
            moduleName + "Module/working";
    });

    return {
        methods: {
            ...mapActions({ ...fetchActionList, ...actionList }),
        },
        computed: {
            ...mapGetters(getterList),
        },
    };
}
