import _ from "lodash";

function _getLevel(o, parentsArrayField) {
    const level = o[parentsArrayField];
    if (level) {
        if (level.length == 1) {
            if (level[0] === o.id) {
                return 0; // this is a Root that is pointing to it's self.
            }
        }
        return level.length;
    } else {
        return 0; // none
    }
}

export function toTreeData(
    list,
    idField,
    textField,
    parentsArrayField,
    value,
    disabled,
) {
    var result = [];

    function generateNode(current, level) {
        var children = [];
        // Find my Children!
        _.forEach(list, function (o) {
            if (o[parentsArrayField]) {
                if (
                    o[parentsArrayField][level] === current[idField] &&
                    _getLevel(o, parentsArrayField) === level + 1
                ) {
                    children.push(generateNode(o, level + 1));
                }
            }
        });

        children = _.sortBy(children, "text"); // Sort children

        var selected = value === current[idField];

        return {
            text: current[textField],
            id: current[idField],
            state: {
                selected: selected,
                expanded: true,
                visible: true,
                disabled: disabled,
            },
            children: children,
        };
    }

    _.forEach(list, function (o) {
        if (_getLevel(o, parentsArrayField) === 0) {
            // first level
            result.push(generateNode(o, 0));
        }
    });

    return result;
}

function hasChildren(list, parentsArrayField, idField, level, id) {
    var result = _.find(list, function (o) {
        // does this object have the 'id' at the level in it's parents and is on this level or lower and not the current id
        return (
            o[parentsArrayField] &&
            o[parentsArrayField][level] === id &&
            _getLevel(o, parentsArrayField) >= level + 1 &&
            o[idField] !== id
        );
    });
    return !!result; // found a result = true
}

export function toLevelOptions(
    list,
    idField,
    textField,
    parentsArrayField,
    values,
    onlyParents,
) {
    var result = [[]];

    function generateNode(current, level) {
        var current_value_at_level = null;

        if (values && level <= values.length) {
            current_value_at_level = values[level];
        }
        if (current[idField] === current_value_at_level) {
            result.push([]); // doing the NEXT level

            _.forEach(list, function (o) {
                if (
                    o[parentsArrayField] &&
                    o[parentsArrayField][level] === current[idField] &&
                    _getLevel(o, parentsArrayField) === level + 1
                ) {
                    generateNode(o, level + 1);
                }
            });
        }

        var shouldPush = true;

        if (onlyParents) {
            shouldPush = hasChildren(
                list,
                parentsArrayField,
                idField,
                level,
                current[idField],
            );
        }

        if (shouldPush) {
            result[level].push({
                text: current[textField],
                value: current[idField],
            });
        }
    }

    // Level 0
    _.forEach(list, function (o) {
        if (_getLevel(o, parentsArrayField) === 0) {
            // first level
            generateNode(o, 0);
        }
    });

    for (var l = 0; l < result.length; l++) {
        result[l] = _.sortBy(result[l], "text"); // Sort this Level
    }

    if (result[result.length - 1].length < 1) {
        result = result.slice(0, result.length - 1);
    }

    return result;
}

export function toSelectOptions(
    list,
    idField,
    textField,
    parentsArrayField,
    selfId,
    required,
) {
    var result = [];

    var nodes = toTreeData(
        list,
        idField,
        textField,
        parentsArrayField,
        null,
        false,
    );

    function generateListItems(current, level) {
        if (current[idField] === selfId) {
            return; // we  stop here!
        }

        result.push({
            value: current.id,
            text: "\xa0".repeat(level * 2) + current.text,
        });

        // Find my Children!
        _.forEach(current.children, function (o) {
            generateListItems(o, level + 1);
        });
    }

    _.forEach(nodes, function (o) {
        generateListItems(o, 0);
    });

    if (!required) {
        result.push({ text: "", value: null });
    }
    return result;
}
