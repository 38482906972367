<template>
    <BFormCheckbox
        :checked="value"
        :state="input_state"
        v-bind="$attrs"
        v-on="inputListeners"
    ></BFormCheckbox>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
import VuexInputFieldMixin from "./VuexInputFieldMixin.js";

export default {
    components: {
        BFormCheckbox,
    },
    name: "OCheckBoxInput",
    mixins: [VuexInputFieldMixin(true)],
};
</script>

<style scoped></style>
