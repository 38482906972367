var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{ref:"table",staticClass:"xo-table scrolling scrolly scrollx"},[_vm._l((_vm.all_column_headers),function(row,row_no){return _c('thead',{key:'thead' + row_no,ref:"thead",refInFor:true,staticClass:"ox-header-row scrollsync",style:(_vm.stubScrollbarStyle),attrs:{"name":"thead"},on:{"dragenter":_vm.onDragEnterHeader,"dragover":function($event){$event.preventDefault();return _vm.onDragOverHeader.apply(null, arguments)},"drop":_vm.onDropHeader}},[_vm._l((_vm.rowHeadersConfig),function(rowHeader,rowHeaderIndex){return _c('th',{key:rowHeaderIndex,style:({ width: _vm.rowLabelWidth })},[_vm._v(" "+_vm._s(rowHeader.title)+" / "+_vm._s(_vm.columnHeadersConfig[row_no].title)+" ")])}),_vm._l((row),function(cell,cell_index){return _c('th',{key:'th' + cell_index,class:{
                'xo-header-cell': true,
                'xo-active-column': _vm.is_col_active(cell),
            },style:({ width: _vm.cellWidth }),attrs:{"colspan":cell.span}},[_c('div',[_vm._t("header-cell",null,{"item":cell})],2)])}),_vm._l((_vm.totalCols),function(totalColHeader,index){return _c('th',{key:'th-total' + index,class:{
                'xo-total-col': true,
                'xo-header-cell': true,
            },style:({ width: _vm.cellWidth })},[_c('div',[_vm._t("header-total-cell",function(){return [_vm._v(_vm._s(totalColHeader))]},{"header":totalColHeader,"index":index})],2)])})],2)}),_c('tbody',{ref:"tbody",attrs:{"name":"tbody"},on:{"&scroll":function($event){return _vm.updateSyncedScroll.apply(null, arguments)}}},_vm._l((_vm.all_row_headers),function(row_index,rowno){return _c('tr',{key:'tr' + rowno,class:{ 'xo-active-row': _vm.is_row_active(_vm.last_row(row_index)) }},[_vm._l((row_index),function(row_level_header,row_level){return (row_level_header.span > 0)?_c('td',{key:'td' + row_level,class:{
                    'xo-row-header': true,
                    'xo-active-row': _vm.is_row_active(row_level_header),
                },style:({ width: _vm.rowLabelWidth }),attrs:{"rowspan":row_level_header.span},on:{"mouseover":function($event){return _vm.hover(null, row_level_header)}}},[_vm._t("row-header",null,{"item":row_level_header}),(_vm.debug)?_c('div',{staticClass:"debug"},[_vm._v(_vm._s(row_index))]):_vm._e()],2):_vm._e()}),_vm._l((_vm.last_column_header_row),function(column_header){return _c('td',{key:_vm.create_cell_key(
                        column_header.id,
                        _vm.last_row(row_index).id,
                    ),class:{
                    'xo-value-cell': true,
                    'xo-active-row': _vm.is_row_active(_vm.last_row(row_index)),
                    'xo-active-column': _vm.is_col_active(column_header),
                },style:({ width: _vm.cellWidth, height: '1px' }),on:{"mouseover":function($event){_vm.hover(column_header, _vm.last_row(row_index))}}},[_vm._t("value-cell",null,{"colHeader":column_header,"itemKey":_vm.create_cell_key(
                            column_header.id,
                            _vm.last_row(row_index).id,
                        ),"items":_vm.getCellValues(column_header, _vm.last_row(row_index)),"rowHeader":_vm.last_row(row_index)}),(_vm.debug)?_c('span',{staticClass:"debug"},[_vm._v("# "+_vm._s(_vm.create_cell_key( column_header.id, _vm.last_row(row_index).id, )))]):_vm._e()],2)}),_vm._l((_vm.totalCols),function(totalColHeader,index){return _c('td',{key:'td-total' + index,class:{
                    'xo-total-col': true,
                    'xo-value-cell': true,
                    'xo-active-row': _vm.is_row_active(_vm.last_row(row_index)),
                    'xo-active-column': _vm.is_col_active({
                        id: 'total' + index,
                    }),
                },style:({ width: _vm.cellWidth }),on:{"mouseover":function($event){_vm.hover({ id: 'total' + index }, _vm.last_row(row_index))}}},[_c('div',[_vm._t("total-col-cell",null,{"index":index,"rowId":_vm.last_row(row_index).id})],2)])})],2)}),0),_c('tfoot',{ref:"tfoot",staticClass:"scrollsync",style:(_vm.stubScrollbarStyle),attrs:{"name":"tfoot"}},_vm._l((_vm.totalRows),function(total_row_header,index){return _c('tr',{key:'td_total' + index,staticClass:"'xo-total-row"},[_vm._l((_vm.all_row_headers[0]),function(row_level_header,row_level){return (row_level_header.span > 0)?_c('td',{key:'td' + row_level,class:{
                    'xo-total-row': true,
                    'xo-row-header': true,
                    'xo-active-row': _vm.is_row_active({
                        id: 'totalrow' + index,
                    }),
                },style:({ width: _vm.rowLabelWidth }),attrs:{"rowspan":row_level_header.span},on:{"mouseover":function($event){return _vm.hover(null, row_level_header)}}},[_vm._t("row-total-header",null,{"item":row_level_header}),(_vm.debug)?_c('div',{staticClass:"debug"},[_vm._v(" "+_vm._s(_vm.all_row_headers[0])+" ")]):_vm._e()],2):_vm._e()}),_vm._l((_vm.last_column_header_row),function(column_header){return _c('td',{key:_vm.create_cell_key(column_header.id, 'totalrow' + index),class:{
                    'xo-total-row': true,
                    'xo-value-cell': true,
                    'xo-active-row': _vm.is_row_active({
                        id: 'totalrow' + index,
                    }),
                    'xo-active-column': _vm.is_col_active(column_header),
                },style:({ width: _vm.cellWidth, height: '1px' }),on:{"mouseover":function($event){return _vm.hover(column_header, { id: 'totalrow' + index })}}},[_vm._t("value-total-cell",null,{"colHeader":column_header,"itemKey":_vm.create_cell_key(
                            column_header.id,
                            'totalrow' + index,
                        ),"items":_vm.getCellValues(column_header, {
                            id: 'totalrow' + index,
                        }),"rowHeader":{ id: 'totalrow' + index }}),(_vm.debug)?_c('span',{staticClass:"debug"},[_vm._v("# "+_vm._s(_vm.create_cell_key(column_header.id, { id: "totalrow" + index, })))]):_vm._e()],2)}),_vm._l((_vm.totalCols),function(totalColHeader,total_col_index){return _c('td',{key:'td-total' + total_col_index,class:{
                    'xo-total-row': true,
                    'xo-total-col': true,
                    'xo-value-cell': true,
                    'xo-active-row': _vm.is_row_active({
                        id: 'totalrow' + index,
                    }),
                    'xo-active-column': _vm.is_col_active({
                        id: 'total' + total_col_index,
                    }),
                },style:({ width: _vm.cellWidth }),on:{"mouseover":function($event){return _vm.hover(
                        { id: 'total' + total_col_index },
                        { id: 'totalrow' + index },
                    )}}},[_c('div',[_vm._t("total-col-cell",null,{"index":total_col_index,"rowId":{ id: 'totalrow' + total_col_index }.id})],2)])})],2)}),0)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }