<template>
    <BRow class="bg-light pt-3">
        <BCol class="" md="1">
            <BFormSelect
                :options="limit_options"
                :value="limit"
                @input="change_rowsPerPage"
            >
            </BFormSelect>
        </BCol>
        <BCol class="" md="5">
            <span class="align-middle"
                >Showing {{ offset + 1 }} - {{ offset + limit }} of
                {{ totalItems }}</span
            >
        </BCol>
        <BCol md="6">
            <BPagination
                :per-page="limit"
                :total-rows="totalItems"
                :value="currentPage"
                @input="change_page"
                align="right"
                aria-controls="changePage"
            ></BPagination>
        </BCol>
    </BRow>
</template>

<script>
import { BCol, BFormSelect, BPagination, BRow } from "bootstrap-vue";
import _ from "lodash";

export default {
    components: {
        BRow,
        BCol,
        BFormSelect,
        BPagination,
    },
    name: "OListPaging",
    props: {
        limit: { type: Number, default: 0 },
        pageOptions: {
            type: Array,
            default() {
                return [5, 10, 20, 50, 100];
            },
        },
        offset: { type: Number, default: 0 },
        totalItems: { type: Number, default: 0 },
    },
    methods: {
        change_rowsPerPage(rowsPerPage) {
            this.$emit("change-limit", rowsPerPage);
        },
        change_page(page) {
            this.$emit("change-page", page);
        },
    },
    computed: {
        limit_options() {
            return _.map(this.pageOptions || [], function (opt) {
                return { value: opt, text: opt };
            });
        },
        currentPage() {
            // NOTE The first page needs to be 1.
            return (this.offset || 0) / (this.limit || 10) + 1;
        },
    },
};
</script>

<style scoped></style>
