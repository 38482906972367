import * as CONFIG from "@/config.js";

// try and load from Session Storage
var auth_state = sessionStorage.getItem("auth_state");

if (CONFIG.permanent) {
    auth_state = localStorage.getItem("auth_state");
}

if (!auth_state) {
    var angularJSUser = {};
    var token = null;

    // Load angularJS settings if they are already there!
    if (CONFIG.bridgeAngularJS === true) {
        angularJSUser = localStorage.getItem("ngStorage-user");

        if (angularJSUser) {
            if (CONFIG.Debug) {
                console.log("Using AngularJS Credentials!");
            }

            angularJSUser = JSON.parse(angularJSUser);
            token = localStorage.getItem("ngStorage-token");
            if (token) token = JSON.parse(token);
        } else {
            angularJSUser = {};
        }
    }

    auth_state = {
        user: angularJSUser,
        token: token,
        refreshing_token: false,
        permissions: null,
        use_permissions: false,
        permanent: CONFIG.permanent,
        prev: null,
    };
} else {
    auth_state = JSON.parse(auth_state); // parse it
    auth_state.refreshing_token = false; // ensure it's false
}

export default auth_state;
