<template>
    <BInputGroup>
        <BInputGroupPrepend>
            <BButton
                variant="outline-secondary"
                small
                @click="changeExpression"
                >{{ expr_symbol }}</BButton
            >
        </BInputGroupPrepend>
        <BFormInput
            class="rounded-0 px-0"
            type="search"
            lazy
            @input="update_qbe"
            @blur="blurred"
            @keydown.enter="refilter"
            :value="currentValue"
            v-bind="$attrs"
            v-on="inputListeners"
        ></BFormInput>
    </BInputGroup>
</template>

<script>
import {
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
} from "bootstrap-vue";
import FilterInputMixin from "./FilterInputMixin.js";

export default {
    components: {
        BInputGroup,
        BInputGroupPrepend,
        BButton,
        BFormInput,
    },
    name: "OFilterTextField",
    mixins: [FilterInputMixin],
    data() {
        return {
            expression: "icontains",
            expressions: [
                { code: "icontains", text: "~" },
                { code: "contains", text: "#" },
                { code: "eq", text: "=" },
            ],
        };
    },
};
</script>
