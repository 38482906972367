<template>
    <o-form-group
        :form-state="formState"
        :label="label"
        :message="errorMessages"
        :nolabel="nolabel"
        :type="{ 'is-danger': hasError }"
    >
        <span :key="index" v-for="(i, index) in edit_value">
            <span v-if="index > 0"> / </span>
            <BFormSelect
                :disabled="_is_readonly"
                :options="options[index]"
                :value="i"
                @change="change($event, index)"
                style="width: auto"
            >
                <option :value="null" v-if="!required || index !== 0"></option>
            </BFormSelect>
        </span>
        <span v-if="showBookmark">
            <a class="btn btn-small" @click="restoreLocation"
                ><map-marker-left-icon
            /></a>
            <a
                class="btn btn-small"
                @click="saveLocation(value)"
                v-if="!autoSaveBookmark"
                ><book-marker-icon
            /></a>
        </span>
    </o-form-group>
</template>

<script>
import { BFormSelect } from "bootstrap-vue";
import { toLevelOptions } from "./utils.js";
import _ from "lodash";
import FormComponentMixin from "../../mixins/form_component_mixin.js";
import TreeComponentMixin from "./TreeComponentMixin.js";
import BookMarkerIcon from "vue-material-design-icons/BookMarker.vue";
import MapMarkerLeftIcon from "vue-material-design-icons/MapMarkerLeft.vue";

export default {
    name: "OTreePathSelect",
    components: {
        BFormSelect,
        BookMarkerIcon,
        MapMarkerLeftIcon,
    },
    mixins: [FormComponentMixin, TreeComponentMixin],
    model: {
        value: "value",
        event: "input",
    },
    props: {
        selfId: { type: Number, default: null },
        onlyParents: { type: Boolean, default: false },

        required: { type: Boolean, default: false },
        showBookmark: { type: Boolean, default: false },
        autoSaveBookmark: { type: Boolean, default: false },
    },
    data() {
        return {};
    },
    methods: {
        restoreLocation() {
            var lastLocationData =
                window.sessionStorage.getItem("last_location_id");
            if (lastLocationData) {
                this.on_change(JSON.parse(lastLocationData).value);
            }
        },
        saveLocation(value) {
            window.sessionStorage.setItem(
                "last_location_id",
                JSON.stringify({ value: value }),
            );
        },
        change(node, index) {
            var values = _.clone(this.value_ids);

            if (this.valueType === "array") {
                values = values.slice(0, index + 1); // Remove others as the ids after are wrong now.
                if (node != null) {
                    // Make sure we have enough values...
                    while (values.length < index) {
                        values.push(null);
                    }

                    values[index] = node;
                } else {
                    values = values.slice(0, index); // they selected Null make last item disappear
                }

                if (this.autoSaveBookmark) {
                    this.saveLocation(values);
                }
                this.makeDirty();

                this.on_change(values);
            } else {
                // Number value
                if (this.autoSaveBookmark) {
                    this.saveLocation(node);
                }

                this.on_change(node); // this is the ID of the last selection because anything after that is wrong
            }
        },
        recalcOptions() {
            var result = toLevelOptions(
                this.items,
                this.idField,
                this.textField,
                this.parentsArrayField,
                this.value_ids,
                this.onlyParents,
            );
            this.level_options = result;
        },
    },
    watch: {
        items() {
            this.recalcOptions();
        },
        value() {
            this.recalcOptions();
        },
    },
    level_options: null, //NOTE this is not in data so it's not monitored - options() will return this data
    computed: {
        edit_value() {
            var result = [...this.value_ids];

            // Add an extra entry if there are more possibilities/levels..
            if (this.options.length > result.length) result.push(null); // still more...

            return result;
        },
        options() {
            if (this.value_ids != null) {
                if (this.items != null && this.items.length > 0) {
                    // cause Vue to calculate this as used..
                    if (
                        !this.level_options ||
                        this.level_options.length === 0
                    ) {
                        this.recalcOptions();
                    }
                }
            }
            return this.level_options || [];
        },
    },
};
</script>
