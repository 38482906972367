// http://vuexjs.org/en/mutations.html#using-constants-for-mutation-types
export const SET_TOKEN = "SET_TOKEN";
export const RECORD_PREVIOUS = "RECORD_PREVIOUS";
export const POP_RECORD_PREVIOUS = "POP_RECORD_PREVIOUS";
export const SET_PERMISSION = "SET_PERMISSION";
export const SET_USE_PERMISSION = "SET_USE_PERMISSION";
export const SET_USER = "SET_USER";
export const TOKEN_EXPIRED = "TOKEN_EXPIRED";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const DONE_REFRESH_TOKEN = "DONE_REFRESH_TOKEN";
