import { Field, FIELD_TYPES, Model } from "../../vue-ocom/schema";

export default Model("customer", {
  verbose_name: "Customer",
  verbose_name_plural: "Customers",
  fields: [
    Field("id", {
      label: "ID",
      primary_key: true,
      readonly: true,
      hide: false,
      field_type: FIELD_TYPES.AutoNumber,
    }),

    Field("modified_date", {
      label: "Modified Date",
      primary_key: false,
      readonly: true,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("created_date", {
      label: "Created Date",
      primary_key: false,
      readonly: true,
      hide: false,
      required: true,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("active_start_date", {
      label: "Active Start Date",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return new Date();
      },
    }),
    Field("active_end_date", {
      label: "Active End Date",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("company_code", {
      label: "Company Code",
      primary_key: false,
      readonly: false,
      hide: false,
      required: true,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("company_name", {
      label: "Company Name",
      primary_key: false,
      readonly: false,
      hide: false,
      required: true,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("division", {
      label: "Division",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("name", {
      label: "Name",
      primary_key: false,
      readonly: true,
      hide: false,
      required: false,
      virtual: true,
      field_type: FIELD_TYPES.String,
      getter: (item) => {
        var result = item.company_name;

        if (item.division) {
          return result + " - " + item.division;
        }

        return result;
      },
    }),
    Field("billing_address", {
      label: "Billing Address",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("address", {
      label: "Address",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("city", {
      label: "City",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("state", {
      label: "State",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("postal_code", {
      label: "Postal Code",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("phone_number", {
      label: "Phone Number",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("fax_number", {
      label: "Fax Number",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("web_address", {
      label: "Web Address",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("contact_first_name", {
      label: "Contact First Name",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("contact_last_name", {
      label: "Contact Last Name",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("contact_phone_no", {
      label: "Contact Phone No",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("contact_mobile_phone_no", {
      label: "Contact Mobile Phone No",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("contact_fax_no", {
      label: "Contact Fax No",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("contact_title", {
      label: "Contact Title",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("other_contacts", {
      label: "Other Contacts",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("email_address", {
      label: "Email Address",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("notes", {
      label: "Notes",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("logo", {
      label: "Logo",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "FileMetaData",
      ref_display_field: "original_filename",
      default: () => {
        return null;
      },
    }),
    Field("notified", {
      label: "Notified",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Boolean,
      default: () => {
        return false;
      },
    }),
    Field("customer_gone", {
      label: "Customer Gone",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Boolean,
      default: () => {
        return null;
      },
    }),
    Field("customer_on_hold", {
      label: "Customer On Hold",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Boolean,
      default: () => {
        return false;
      },
    }),
    Field("notes_to_show", {
      label: "Notes To Show",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return "";
      },
    }),
    Field("last_code", {
      label: "Last Code",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      },
    }),
    Field("status", {
      label: "Status",
      primary_key: false,
      readonly: false,
      required: true,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodeCustomerStatus",
      ref_display_field: "description",
      default: () => {
        return 1;
      },
    }),
    Field("root_location", {
      label: "Root Location",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "AssetLocation",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
  ],
});
