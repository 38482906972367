import { Field, FIELD_TYPES, Model } from "../../schema";

export default Model("group_state", {
    verbose_name: "Group State",
    verbose_name_plural: "Group State",
    fields: [
        Field("id", {
            label: "ID",
            primary_key: true,
            readonly: true,
            hide: false,
            field_type: FIELD_TYPES.AutoNumber,
        }),

        Field("state_name", {
            label: "State Name",
            primary_key: false,
            readonly: false,
            hide: false,
            required: false,
            field_type: FIELD_TYPES.String,
            default: () => {
                return null;
            },
        }),
        Field("deny", {
            label: "Deny",
            primary_key: false,
            readonly: false,
            hide: false,
            required: false,
            field_type: FIELD_TYPES.Boolean,
            default: () => {
                return false;
            },
        }),
        Field("models", {
            label: "Models",
            primary_key: false,
            readonly: false,
            hide: false,
            required: false,
            field_type: FIELD_TYPES.ManyToMany,
            ref_field_type: "GroupStateModel",
            default: () => {
                return [];
            },
        }),
        Field("details", {
            label: "Details",
            primary_key: false,
            readonly: false,
            hide: false,
            required: false,
            field_type: FIELD_TYPES.String,
            default: () => {
                return "";
            },
        }),
    ],
});
