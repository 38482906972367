<template>
    <span class="form-wrapper">
        <BAlert
            :show="!!serverErrors.non_field_errors"
            variant="danger"
            dismissible
        >
            <span v-for="err in serverErrors.non_field_errors" :key="err">{{
                err
            }}</span>
        </BAlert>

        <o-validate-display :v="v" v-if="formState.debug"></o-validate-display>
        <div v-if="formState.debug">
            <br />
            <strong>FormState : </strong>{{ formState }}
            <br />
        </div>
        <slot />

        <span v-if="formState.debug"> {{ serverErrors }} <br /> </span>
    </span>
</template>

<script>
import { BAlert } from "bootstrap-vue";
// import {FIELD_TYPES} from "../../schema";
// import schema from "../../../app/schema";
import * as CONFIG from "../../../config.js";
import OValidateDisplay from "./OValidateDisplay.vue";

export default {
    name: "OFormWrapper",
    components: {
        BAlert,
        OValidateDisplay,
    },
    model: {
        prop: "item",
        event: "changed",
    },
    props: {
        item: { type: Object, required: true },
        v: { type: Object, required: true },
        serverErrors: { type: Object, required: true },
        serverErrorsIndex: {
            type: Number,
            default: null,
        } /* If Null then server-errors is not an array */,
        formState: { type: Object, required: true },
        schema: { type: Object, required: true },
    },
    computed: {
        is_invalid() {
            if (this.v) {
                return this.v.$invalid === true;
            } else {
                return false;
            }
        },
    },
    watch: {
        is_invalid: {
            deep: true,
            handler(newVal) {
                if (this.formState == null) {
                    return;
                }

                if (CONFIG.OldValidationLogic !== true) {
                    this.formState.invalid = newVal;
                }
            },
        },
    },
    methods: {},
};
</script>

<style scoped></style>
