<template>
    <o-form-wrapper
        :form-state="formState"
        :schema="schema"
        :server-errors="_se"
        :v="$v.item"
        v-model="item"
    >
        <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="name"
        >
            <o-text-field
                :form-state="formState"
                :server-errors="_se"
                :validators="$v.item"
                name="name"
                v-bind="schema.fields.name"
                v-model="item.name"
            ></o-text-field>
        </o-widget-wrapper>

        <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="content_type"
        >
            <o-select
                :form-state="formState"
                :items="optionContentTypeItems"
                :loading="optionContentTypeWorking"
                :server-errors="_se"
                :validators="$v.item"
                add-title="Create New"
                allow-add
                name="content_type"
                sort-field="app_label"
                store-name="ContentType"
                text-field="app_label"
                v-bind="schema.fields.content_type"
                v-model="item.content_type"
                value-field="id"
            >
                <template
                    slot="form"
                    slot-scope="{ item, formState, serverErrors }"
                >
                    <content-type-form
                        :form-state="formState"
                        :item="item"
                        :server-errors="serverErrors"
                        in-modal
                    ></content-type-form>
                </template>
            </o-select>
        </o-widget-wrapper>

        <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="codename"
        >
            <o-text-field
                :form-state="formState"
                :server-errors="_se"
                :validators="$v.item"
                name="codename"
                v-bind="schema.fields.codename"
                v-model="item.codename"
            ></o-text-field>
        </o-widget-wrapper>
    </o-form-wrapper>
</template>

<script>
import schema from "../../schema/index.js";
import { cleanUpModel } from "../../../schema/schema_vuetify.js";
import {
    CreateCodeTableFetchMixin,
    CreateFormStateMixin,
    CreateGroupPermissionMixin,
    VuelidateMixin,
} from "../../../mixins";
import { validationMixin } from "vuelidate";
import OWidgetWrapper from "../../../components/forms/OWidgetWrapper.vue";
import OTextField from "../../../components/forms/fields/OTextField.vue";
import OSelect from "../../../components/forms/fields/OSelect.vue";
import OCheckbox from "../../../components/forms/fields/OCheckbox.vue";
import OFormWrapper from "../../../components/forms/OFormWrapper.vue";
import ODateTime from "../../../components/forms/fields/ODateTime.vue";

import { default as ContentTypeForm } from "../../content_type/components/ItemForm.vue";

export default {
    mixins: [
        VuelidateMixin,
        validationMixin,
        CreateFormStateMixin("$v"),
        CreateGroupPermissionMixin("Permission"),
        CreateCodeTableFetchMixin(["ContentType"]),
    ],
    components: {
        OFormWrapper,
        OWidgetWrapper,
        ODateTime,

        OCheckbox,
        OSelect,
        OTextField,

        ContentTypeForm,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        level: { type: Number, default: 0 },
        inModal: { type: Boolean, default: false },
    },
    data: () => ({
        schemaModel: cleanUpModel(schema.Permission, {}),
    }),
    methods: {},
    computed: {
        _se() {
            // Return an empty Object if there are no Server Errors to avoid Binding Errors
            return this.serverErrors || {};
        },
    },
    watch: {},
    created() {},
};
</script>
