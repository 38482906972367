import { render, staticRenderFns } from "./OListMultiActions.vue?vue&type=template&id=ec7b0fba&"
import script from "./OListMultiActions.vue?vue&type=script&lang=js&"
export * from "./OListMultiActions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports