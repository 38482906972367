<template>
    <BInputGroup>
        <BInputGroupPrepend>
            <BButton variant="outline-secondary" @click="changeExpression">{{
                expr_symbol
            }}</BButton>
        </BInputGroupPrepend>
        <BFormInput
            class="rounded-0 px-0"
            type="search"
            @input="update_qbe"
            @blur="blurred"
            @keydown.enter="refilter"
            :value="currentValue"
            number
            lazy
            v-bind="$attrs"
            v-on="inputListeners"
        ></BFormInput>
    </BInputGroup>
</template>

<script>
import {
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
} from "bootstrap-vue";
import FilterInputMixin from "./FilterInputMixin.js";

export default {
    components: {
        BInputGroup,
        BInputGroupPrepend,
        BButton,
        BFormInput,
    },
    name: "OFilterNumberField",
    mixins: [FilterInputMixin],
    data() {
        return {
            expression: "eq",
            expressions: [
                { code: "eq", text: "=" },
                { code: "gt", text: ">" },
                { code: "lt", text: "<" },
                { code: "ge", text: ">=" },
                { code: "le", text: "<=" },
            ],
        };
    },
};
</script>
