import CodeElectricalClassList from "./pages/ListPage.vue";
import CodeElectricalClassDetail from "./pages/FormPage.vue";
import CodeElectricalClassIndex from "./pages/IndexPage.vue";

export default [
  {
    path: "/code_electrical_class",
    component: CodeElectricalClassIndex,
    redirect: "/code_electrical_class/list",
    name: "code_electrical_class",
    children: [
      {
        path: "list",
        component: CodeElectricalClassList,
        name: "code_electrical_class.list",
      },
      {
        path: "create",
        component: CodeElectricalClassDetail,
        name: "code_electrical_class.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: CodeElectricalClassDetail,
        name: "code_electrical_class.edit",
        props: { create: false },
      },
    ],
  },
];
