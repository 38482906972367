<template>
  <o-form-wrapper
    :form-state="formState"
    :schema="schema"
    :server-errors="_se"
    :v="$v.item"
    v-model="item"
  >
    <!-- <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="code"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="code"
        v-bind="schema.fields.code"
        v-model="item.code"
      ></o-text-field>
    </o-widget-wrapper> -->

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="description"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="description"
        v-bind="schema.fields.description"
        v-model="item.description"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="customer"
    >
      <o-select
        :form-state="formState"
        :items="optionCustomerItems"
        :loading="optionCustomerWorking"
        :server-errors="_se"
        :validators="$v.item"
        add-title="Create New"
        allow-add
        name="customer"
        sort-field="name"
        store-name="Customer"
        text-field="name"
        v-bind="schema.fields.customer"
        v-model="item.customer"
        value-field="id"
      >
        <template slot="form" slot-scope="{ item, formState, serverErrors }">
          <customer-form
            :form-state="formState"
            :item="item"
            :server-errors="serverErrors"
            in-modal
          ></customer-form>
        </template>
      </o-select>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="colors"
    >
      <code-test-cycle-color-form-table
        :form-state="formState"
        :label="schema.fields.colors.label"
        :level="level + 1"
        :server-errors="_se.colors"
        :validators="$v.item.colors"
        v-model="item.colors"
      >
      </code-test-cycle-color-form-table>
      <!-- UNCOMMENT TO USE Cards
      <code-test-cycle-color-form-cards v-model="item.colors"
          :validators="$v.item.colors"
          :form-state="formState"
          :label="schema.fields.colors.label"
          :level="level+1"
          :server-errors="_se.colors">
      </code-test-cycle-color-form-cards>
      -->
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="active_start_date"
    >
      <o-date-time
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="active_start_date"
        v-bind="schema.fields.active_start_date"
        v-model="item.active_start_date"
      ></o-date-time>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="active_end_date"
    >
      <o-date-time
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="active_end_date"
        v-bind="schema.fields.active_end_date"
        v-model="item.active_end_date"
      ></o-date-time>
      <br />
      <div v-if="item.id && item.customer" class="form-row">
        <legend
          class="col-lg-2 col-3 bv-no-focus-ring col-form-label text-right"
        >
          Generate Report
        </legend>
        <div class="col">
          <ul style="padding: 0">
            <li style="list-style: none; padding-bottom: 10px">
              <o-report-button
                report-name="asset_location_report"
                :params="params"
                filename="Asset Location Report"
              >
                Asset Location Report
              </o-report-button>
            </li>
            <li style="list-style: none; padding-bottom: 10px">
              <o-report-button
                report-name="all_complete_details_report"
                :params="params"
                filename="Complete Details Report (All Assets)"
              >
                Complete Details Report (All Assets)
              </o-report-button>
            </li>
            <li style="list-style: none; padding-bottom: 10px">
              <o-report-button
                report-name="complete_details_report"
                :params="params"
                filename="Complete Details Report (All Tested Assets)"
              >
                Complete Details Report (All Tested Assets)
              </o-report-button>
            </li>
            <li style="list-style: none; padding-bottom: 10px">
              <o-report-button
                report-name="header_report"
                :params="params"
                filename="Header Report"
              >
                Header Report
              </o-report-button>
            </li>
            <li style="list-style: none; padding-bottom: 10px">
              <o-report-button
                report-name="maintenance_record"
                :params="paramsClientIdOnly"
                filename="Maintenance Report"
              >
                Maintenance Report
              </o-report-button>
            </li>
            <li style="list-style: none; padding-bottom: 10px">
              <o-report-button
                report-name="next_test_due_report"
                :params="paramsClientIdOnly"
                filename="Next Test Due"
              >
                Next Test Due
              </o-report-button>
            </li>
            <li style="list-style: none; padding-bottom: 10px">
              <o-report-button
                report-name="test_history_report"
                :params="paramsClientIdOnly"
                filename="Test History"
              >
                Test History
              </o-report-button>
            </li>
            <li style="list-style: none; padding-bottom: 10px">
              <o-report-button
                report-name="test_summary_report"
                :params="params"
                filename="Test Summary"
                >Test Summary
              </o-report-button>
            </li>
          </ul>
        </div>
      </div>
    </o-widget-wrapper>
  </o-form-wrapper>
</template>

<script>
import schema from "../../schema/index";
import { cleanUpModel } from "../../../vue-ocom/schema/schema_vuetify";
import {
  CreateFormStateMixin,
  CreateGroupPermissionMixin,
  VuelidateMixin,
} from "../../../vue-ocom/mixins";
import { validationMixin } from "vuelidate";
import OWidgetWrapper from "../../../vue-ocom/components/forms/OWidgetWrapper";
import OTextField from "../../../vue-ocom/components/forms/fields/OTextField";
import OSelect from "../../../vue-ocom/components/forms/fields/OSelect";
import OCheckbox from "../../../vue-ocom/components/forms/fields/OCheckbox";
import OFormWrapper from "../../../vue-ocom/components/forms/OFormWrapper";
import ODateTime from "../../../vue-ocom/components/forms/fields/ODateTime";
import OReportButton from "../../../vue-ocom/reports/OReportButton";
import { default as CustomerForm } from "../../customer/components/ItemForm";
import { default as CodeTestCycleColorFormTable } from "../../code_test_cycle_color/components/ItemFormTable";
// import { default as CodeTestCycleColorFormCards } from '../../code_test_cycle_color/components/ItemFormCards';

import UseCodeModulesMixin from "@/vue-ocom/mixins/UseCodeModulesMixin";

export default {
  mixins: [
    VuelidateMixin,
    validationMixin,
    CreateFormStateMixin("$v"),
    CreateGroupPermissionMixin("CodeTestCycle"),
    UseCodeModulesMixin(["Customer"]),
  ],
  components: {
    OFormWrapper,
    OWidgetWrapper,
    ODateTime,

    OCheckbox,
    OSelect,
    OTextField,
    OReportButton,

    CustomerForm,
    CodeTestCycleColorFormTable,
    //CodeTestCycleColorFormCards,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    level: { type: Number, default: 0 },
    inModal: { type: Boolean, default: false },
  },
  data: () => ({
    schemaModel: cleanUpModel(schema.CodeTestCycle, {}),
  }),
  methods: {},
  computed: {
    checkItemId() {
      return this.item.id;
    },
    checkItemCustomer() {
      return this.item.customer;
    },
    params() {
      if (!this.item) {
        return null; // no reports
      }

      return {
        client_id: this.item.customer,
        test_cycle_id: this.item.id,
      };
    },
    paramsClientIdOnly() {
      if (!this.item) {
        return null; // no reports
      }
      return { client_id: this.item.customer };
    },
    _se() {
      // Return an empty Object if there are no Server Errors to avoid Binding Errors
      return this.serverErrors || {};
    },
  },
  created() {},
};
</script>
