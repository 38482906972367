import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters("AuthModule", ["isLoggedIn"]),
    },
    created() {
        window.addEventListener("beforeunload", function (event) {
            if (window.lockLeave === true) {
                event.preventDefault();

                event.returnValue = "";
            } else {
                return false;
            }
        });
    },
};
