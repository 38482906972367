import CodeFileTypeList from "./pages/ListPage.vue";
import CodeFileTypeDetail from "./pages/FormPage.vue";
import CodeFileTypeIndex from "./pages/IndexPage.vue";

export default [
  {
    path: "/code_file_type",
    component: CodeFileTypeIndex,
    redirect: "/code_file_type/list",
    name: "code_file_type",
    children: [
      {
        path: "list",
        component: CodeFileTypeList,
        name: "code_file_type.list",
      },
      {
        path: "create",
        component: CodeFileTypeDetail,
        name: "code_file_type.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: CodeFileTypeDetail,
        name: "code_file_type.edit",
        props: { create: false },
      },
    ],
  },
];
