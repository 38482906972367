import MaintenanceRecordList from "./pages/ListPage.vue";
import MaintenanceRecordDetail from "./pages/FormPage.vue";
import MaintenanceRecordIndex from "./pages/IndexPage.vue";

export default [
  {
    path: "/maintenance_record",
    component: MaintenanceRecordIndex,
    redirect: "/maintenance_record/list",
    name: "maintenance_record",
    children: [
      {
        path: "list",
        component: MaintenanceRecordList,
        name: "maintenance_record.list",
      },
      {
        path: "create",
        component: MaintenanceRecordDetail,
        name: "maintenance_record.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: MaintenanceRecordDetail,
        name: "maintenance_record.edit",
        props: { create: false },
      },
    ],
  },
];
