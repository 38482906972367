<template>
    <o-do-server-action-button
        :filename="filename"
        :request="request"
        :form="form"
        :uri="uri"
        :do-save="doSave"
        v-bind="$attrs"
    >
        <slot />
    </o-do-server-action-button>
</template>

<script>
import ODoServerActionButton from "./ODoServerActionButton.vue";

export default {
    name: "ODownloadFileButton",
    components: { ODoServerActionButton },
    props: {
        uri: { type: String, required: true }, //eg '/ocom_project/generate_doc/'
        request: {}, // what is sent to the URL ie the Post data...
        form: { type: Object, default: null }, // Form Data to Post
        doSave: { type: Function, default: null },
        filename: { type: String, default: "something.txt" }, // the Download filename
    },
};
</script>

<style scoped></style>
