import _ from "lodash";

export function tidyUp(query) {
    if (_.isArray(query)) {
        // Criteria
        const criteria = query.filter(function (el) {
            return el !== null;
        });
        // if was all nulls ie [null, null] then return null
        // which removes it from criteria array
        if (criteria.length === 0) {
            return null;
        }

        return criteria;
    } else {
        if (_.isObject(query)) {
            // Logic - Check all criteria are valid
            if ("and" in query) {
                query.and = query.and.filter(function (andEl) {
                    return tidyUp(andEl) != null;
                });

                if (query.and.length === 0) {
                    return null;
                }
            }
            if ("or" in query) {
                query.or = query.or.filter(function (orEl) {
                    return tidyUp(orEl) != null;
                });
                if (query.or.length === 0) {
                    return null;
                }
            }

            return query;
        }
    }
}

export function prefixAnd(query, new_and) {
    // Expects And to be Criteria array
    if (new_and == null) {
        return query; // as is
    }

    if (query) {
        query = tidyUp(query);
    }
    if (!query) {
        if (_.isArray(new_and)) {
            return { and: [new_and] }; // there was No query so use this instead!
        } else {
            return new_and; // object ?? maybe?
        }
    } else {
        if (query.or) {
            return { and: [new_and, query] };
        } else {
            var found = false;
            _.forEach(query.and, function (criteria) {
                if (criteria[0] === new_and[0]) {
                    // Replace the current criteria as this field was already in there
                    for (var i = 1; i < new_and.length; i++) {
                        criteria[i] = new_and[i];
                    }
                    found = true;
                }
            });
            if (!found) {
                query.and.push(new_and);
            }
        }

        return query;
    }
}
