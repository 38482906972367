<template>
    <section class="hero">
        <div class="hero-body">
            <div class="container">
                <h1 class="title">Log out!</h1>
                <h2 class="subtitle">
                    <alert-icon class="alert" large />
                    We are logging you out now.
                </h2>

                <p class="is-pulled-right">
                    <router-link :to="{ name: 'home' }"
                        >Log back in.</router-link
                    >
                </p>
            </div>
        </div>
    </section>
</template>

<script>
/* eslint-disable no-unused-vars */

import { mapActions, mapGetters } from "vuex";
import AlertIcon from "vue-material-design-icons/Alert.vue";

export default {
    components: { AlertIcon },

    data() {
        return {
            snackbar: true,
        };
    },

    methods: {
        doRedirect() {
            this.$router.push({ name: "login", params: { redirectTo: "/" } }); // TODO get previous URL...
        },

        ...mapActions("AuthModule", ["logout"]),
    },
    computed: {
        ...mapGetters("AuthModule", ["isLoggedIn"]),
    },
    created() {
        if (this.isLoggedIn) {
            this.logout();
            this.doRedirect();
        }
    },
};
</script>
