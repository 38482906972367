import { mapGetters } from "vuex";
import _ from "lodash";
import { canAccessState } from "../../../grouppermissions/utils.js";

export default {
    props: {
        rules: {},
    },
    methods: {
        filter_section(section, level) {
            const vm = this;
            var section_children = [];

            if (section && section.children) {
                section.level = level;

                _.forEach(section.children, function (child) {
                    if (child) {
                        if (child.to && child.to.name) {
                            var name = child.to.name;

                            var add = canAccessState(
                                vm._group_permissions,
                                name,
                            );

                            if (child.rule && vm.rules) {
                                // Has a Rule check it,
                                add = add && vm.rules[child.rule] === true;
                            }

                            if (add) {
                                section_children.push(child);
                            }
                        }
                        if ("children" in child) {
                            // has children so filter the 'children'
                            var new_section = vm.filter_section(
                                child,
                                level + 1,
                            );
                            if (new_section) {
                                section_children.push(new_section);
                            }
                        }
                    }
                });

                if (section_children.length > 0) {
                    var newSection = _.cloneDeep(section);

                    if (section.sort === true) {
                        section_children = _.sortBy(section_children, "title");
                    }
                    newSection.children = section_children; // Use the filtered list

                    return newSection;
                }
            }
            return null; // no section
        },
    },
    computed: {
        ...mapGetters({ _group_permissions: "AuthModule/permissions" }),
        filtered_items() {
            var vm = this;

            if (!vm._group_permissions) {
                return this.items;
            } else {
                var result = [];
                _.forEach(this.items, function (section) {
                    var newSection = vm.filter_section(section, 0);
                    if (newSection) {
                        result.push(newSection);
                    }
                });

                return result;
            }
        },
        number_of_levels() {
            function search(items, levels) {
                var maxLevel = levels;

                _.forEach(items, function (item) {
                    if ("children" in item) {
                        var newLevel = search(item.children, levels + 1);
                        if (newLevel > maxLevel) {
                            maxLevel = newLevel;
                        }
                    }
                });

                return maxLevel;
            }

            return search(this.filtered_items, 0);
        },
        first_section_menu_options() {
            if (this.number_of_levels > 1) {
                return this.filtered_items[0].children;
            } else {
                return this.filtered_items;
            }
        },
    },
};
