import { render, staticRenderFns } from "./ORepeatingCards.vue?vue&type=template&id=36bd1d49&scoped=true&"
import script from "./ORepeatingCards.vue?vue&type=script&lang=js&"
export * from "./ORepeatingCards.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36bd1d49",
  null
  
)

export default component.exports