<template>
    <span v-if="!nolabel">
        <BFormGroup
            :class="changeColor"
            :invalid-feedback="invalidFeedback"
            :state="state"
            label-align="right"
            label-cols="3"
            label-cols-lg="2"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <slot />
        </BFormGroup>
        <span v-if="_fs.debug"> {{ invalidFeedback }} <br /> </span>
    </span>

    <span class="nolabel" v-else>
        <slot />

        <span v-if="_fs.debug">
            {{ invalidFeedback }} <br />

            {{ message }}
        </span>

        <BFormInvalidFeedback :state="state">
            {{ invalidFeedback }}
        </BFormInvalidFeedback>
    </span>
</template>
<style scoped>
/*.field .is-horizontal {*/
/*    margin-bottom: 10px;*/
/*}*/
</style>
<script>
import { BFormGroup, BFormInvalidFeedback } from "bootstrap-vue";
import _ from "lodash";
import { CalculateFeedback } from "../../validation/utils.js";

/*
     A wrapper around b-field so we can add nolabel attribute to hide the label in tables etc and still show message

   */
export default {
    components: {
        BFormGroup,
        BFormInvalidFeedback,
    },
    name: "OFormGroup",
    props: {
        nolabel: { type: Boolean, default: false },
        changeColor: { type: String, default: "" },
        message: { type: [String, Array, Object], default: null },
        type: {
            type: [String, Object],
        },
        formState: { type: Object, default: null, required: false },
    },
    computed: {
        _fs() {
            //Use this in render so we can handle No FormState as expected..
            return this.formState || { debug: false };
        },
        state() {
            if (this.message == null) {
                return true;
            }

            return _.isEmpty(this.message);
        },
        invalidFeedback() {
            return CalculateFeedback(this.message);
        },
    },
};
</script>
<style scoped>
.text-red {
    color: #a94442;
}
</style>
