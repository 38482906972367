<template>
    <div
        class="row"
        :class="{
            'action-buttons justify-content-between fixed-bottom': !inModal,
            'w-100 d-flex flex-row-reverse': inModal,
        }"
    >
        <div
            class="col-10"
            :class="{
                'd-flex flex-row-reverse': inModal,
            }"
        >
            <BButton
                :disabled="!isSaveAllowed"
                @click="saveItem"
                class="mr-2"
                v-if="!hideSave && !hideSaveOnly"
                variant="primary"
            >
                <slot name="saveButtonText">{{ saveButtonLabel }}</slot>
            </BButton>
            <BButton
                :disabled="!isSaveAllowed"
                @click="saveItemAndClose"
                class="mr-2"
                v-if="!(hideClose || hideSave)"
                variant="primary"
            >
                <slot name="saveButtonAndCloseText">{{
                    saveAndCloseButtonLabel
                }}</slot>
            </BButton>

            <BButton
                :disabled="!isSaveAllowed"
                @click="saveAndNew"
                class="mr-2"
                v-if="!hideSave && showCreateAndNew"
                variant="primary"
            >
                <slot name="saveAndNewButtonText">{{
                    saveAndNewButtonLabel
                }}</slot>
            </BButton>

            <BButton
                @click="closeForm"
                class="mr-2"
                v-if="!hideClose"
                variant="primary"
            >
                <slot name="closeButtonText">{{ closeLabel }}</slot>
            </BButton>

            <slot name="left"></slot>
            <slot />
        </div>
        <div class="col-2">
            <slot name="right"></slot>
            <BButton @click="deleteItem" v-if="!hideDelete" variant="danger">
                <trash-can-icon />
                {{ deleteLabel }}
            </BButton>
        </div>
    </div>
</template>

<style scoped>
.o-loader {
    position: absolute;
    margin-top: 0;
    margin-bottom: 0;
}

.action-buttons {
    background-color: silver;
    padding: 7px;
    bottom: 18px;
    /*position: fixed;*/
    /*bottom: 0px;*/
    /*left: 0px;*/
    width: 100%;
    z-index: 4;
}
</style>

<script>
import { BButton } from "bootstrap-vue";
import _ from "lodash";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";

export default {
    name: "OActionButtons",
    props: {
        create: Boolean,
        hideSaveOnly: { type: Boolean, default: false },
        showCreateAndNew: Boolean,
        item: Object,
        formState: Object,
        labelForCreate: { type: String, default: "Create" },
        labelForUpdate: { type: String, default: "Update" },
        deleteMessage: {
            type: String,
            default: "Are you sure you want to delete this item?",
        },
        deleteTitle: { type: String, default: "Deleting Item" },
        deleteConfirmText: { type: String, default: "Delete Item" },
        hideClose: { type: Boolean, default: false },
        hideSave: { type: Boolean, default: false },
        hideDelete: { type: Boolean, default: false },
        inModal: { type: Boolean, default: false },
        canSave: { type: Function, default: null },
        saveLabel: { type: String, default: null },
        saveAndNewLabel: { type: String, default: null },
        saveAndCloseLabel: { type: String, default: null },
        closeLabel: { type: String, default: "Close" },
        deleteLabel: { type: String, default: "Delete" },
    },
    components: {
        BButton,
        TrashCanIcon,
    },
    methods: {
        saveItem() {
            this.$emit("save", this.item);
        },
        saveItemAndClose() {
            this.$emit("saveAndClose", this.item); // TODO Wait somehow and close when returns...
        },
        deleteItem() {
            var options = {
                title: this.deleteTitle,
                headerBgVariant: "danger",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "YES",
                cancelVariant: "success",
                cancelTitle: "Cancel",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true,
            };
            this.$bvModal
                .msgBoxConfirm(this.deleteMessage + "", options)
                .then((value) => {
                    if (value) {
                        this.$emit("delete", this.item);
                    }
                });
        },
        closeForm() {
            this.formState.dirty = false;
            this.formState.closing = true;
            this.$emit("close");
            if (!this.inModal) {
                if (!(this.$listeners && this.$listeners.close)) {
                    // If has no "@close" handler then go back a page.
                    this.$router.go(-1);
                }
            }
        },
        saveAndNew() {
            this.$emit("saveAndNew", this.item);
        },
    },
    computed: {
        saveButtonLabel() {
            if (this.saveLabel) return this.saveLabel;
            else if (this.create) {
                return this.labelForCreate;
            } else {
                return this.labelForUpdate;
            }
        },
        saveAndCloseButtonLabel() {
            if (this.saveAndCloseLabel) return this.saveAndCloseLabel;
            else return this.saveButtonLabel + " And Close";
        },
        saveAndNewButtonLabel() {
            if (this.saveAndNewLabel) return this.saveAndNewLabel;
            else return this.saveButtonLabel + " And New";
        },
        isSaveAllowed() {
            if (this.canSave != null) {
                if (_.isFunction(this.canSave)) {
                    return this.canSave(this.item);
                }
            }

            return !this.formState.invalid && this.formState.dirty;
        },
        canCreateAndNew() {
            return this.showCreateAndNew || false;
        },
    },
    created() {},
    data: () => ({}),
};
</script>
