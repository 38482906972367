<template>
    <div class="card">
        <div :class="headClass">
            <div class="row d-flex justify-content-between m-0">
                <div class="px-2">
                    <slot name="title">{{ title }}</slot>
                </div>
                <BDropdown
                    class="mr-3"
                    id="dropdown-1"
                    right
                    size="sm"
                    variant="outline-secondary"
                >
                    <template v-slot:button-content="">
                        <slot
                            :current_index="current_index"
                            name="buttonContent"
                            >{{ current_version_text }}</slot
                        >
                    </template>

                    <BDropdownItem
                        :active="index === current_index"
                        @click="selectItem(index)"
                        :key="index"
                        v-for="(item, index) in items"
                    >
                        <slot
                            :active="index === current_index"
                            :index="index"
                            :item="item"
                            name="versionTitle"
                        >
                            {{ item[textValue] }}
                        </slot>
                    </BDropdownItem>
                    <BDropdownDivider v-if="canAdd"></BDropdownDivider>
                    <BDropdownItem @click="createNew" v-if="canAdd"
                        >Create new</BDropdownItem
                    >
                    <BDropdownDivider v-if="moreOptions"></BDropdownDivider>
                    <BDropdownItem
                        :active="option.id === current_index"
                        :key="option.id"
                        @click="selectItem(option.id)"
                        v-for="option in moreOptions"
                    >
                        {{ option.label }}
                    </BDropdownItem>
                </BDropdown>
            </div>
        </div>
        <div :class="bodyClass">
            <template v-if="current_item !== null">
                <slot
                    :index="current_index"
                    :item="current_item"
                    :level="level"
                ></slot>
            </template>
        </div>
    </div>
</template>

<script>
import { BDropdown, BDropdownDivider, BDropdownItem } from "bootstrap-vue";

export default {
    components: {
        BDropdown,
        BDropdownItem,
        BDropdownDivider,
    },
    name: "OVersionCards",
    data() {
        return {
            index: null,
        };
    },
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        items: { type: Array, default: () => [] },
        value: { type: Number, default: null },
        defaultIndex: {
            type: Number,
            default: -1,
        } /* -1 is default to Last Item */,

        textValue: { type: String, default: "description" },

        title: { type: String, default: "" },
        level: { type: Number, default: 1 }, // Might not use this but allows us have "different" levels..

        bodyClasses: { type: String, default: "" },
        headClasses: { type: String, default: "" },
        defaultButtonText: { type: String, default: "Version" },

        canAdd: { type: Boolean, default: false },
        moreOptions: { type: Array, default: null },
    },
    methods: {
        createNew() {
            this.$emit("create", {});
        },
        selectItem(index) {
            this.index = index;
            this.$emit("change", index); // notifiy index changed..
        },
    },
    watch: {
        value: function (newValue) {
            // this was changed so update index to reflect it.
            this.index = newValue;
        },
    },
    computed: {
        headClass() {
            return (
                "card-header o-bg-gradient-light container-fluid p-2" +
                (this.headClasses || "")
            );
        },
        bodyClass() {
            return "card-body " + (this.bodyClasses || "");
        },
        current_index() {
            if (this.index == null) {
                // No version selected so use default Index
                if (this.defaultIndex != null && this.defaultIndex !== -1) {
                    return this.defaultIndex;
                }

                if (this.defaultIndex === -1) {
                    // defaultIndex is -1 then pick the LAST one..
                    if (this.items) {
                        return this.items.length - 1; // last one
                    }
                }

                return null;
            }

            if (this.index === -1 && this.items) {
                return this.items.length - 1; // last one
            }

            return this.index;
        },
        current_item() {
            if (this.current_index != null) {
                if (this.current_index < this.items.length) {
                    return this.items[this.current_index];
                }
            }

            return null; // nothing selected - show Nothing.
        },
        current_version_text() {
            if (this.current_item != null) {
                return this.current_item[this.textValue];
            }
            return this.defaultButtonText;
        },
    },
};
</script>

<style scoped></style>
