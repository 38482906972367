import * as TYPES from "./mutations-types.js";
import * as CONFIG from "../../../config.js";
import { POP_RECORD_PREVIOUS, RECORD_PREVIOUS } from "./mutations-types.js";

function saveStateInSession(state) {
    if ("permanent" in CONFIG && CONFIG.permanent) {
        localStorage.setItem("auth_state", JSON.stringify(state));
    } else {
        sessionStorage.setItem("auth_state", JSON.stringify(state));
    }
}

/* eslint-disable no-param-reassign */
export default {
    [TYPES.RECORD_PREVIOUS](state) {
        if (state.prev == null) {
            // Only do if there is not already a Previous entry.
            state.prev = { user: state.user, token: state.token };
        }
        saveStateInSession(state);
    },
    [TYPES.POP_RECORD_PREVIOUS](state) {
        var value = state.prev;

        state.user = value.user;
        state.token = value.token;
        state.prev = null; // no longer in SUDO

        saveStateInSession(state);
    },
    [TYPES.SET_TOKEN](state, value) {
        state.token = value;

        saveStateInSession(state);
    },
    [TYPES.SET_USER](state, value) {
        state.user = value;
        saveStateInSession(state);
    },
    [TYPES.SET_PERMISSION](state, value) {
        state.permissions = value;

        if (value != null) {
            state.use_permissions = true;
        } else {
            state.use_permissions = false;
        }
        saveStateInSession(state);
    },
    [TYPES.SET_USE_PERMISSION](state, value) {
        state.use_permissions = value;
        saveStateInSession(state);
    },
    [TYPES.TOKEN_EXPIRED](state) {
        state.user = null;
        state.token = null;
        state.prev = null; // no previous

        saveStateInSession(state);
    },
    [TYPES.REFRESH_TOKEN](state) {
        state.refreshing_token = true;
        saveStateInSession(state);
    },
    [TYPES.DONE_REFRESH_TOKEN](state) {
        state.refreshing_token = false;
        saveStateInSession(state);
    },
};
