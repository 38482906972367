import { Field, FIELD_TYPES, Model } from "../../schema";

export default Model("permission", {
    verbose_name: "Permission",
    verbose_name_plural: "Permissions",
    fields: [
        Field("id", {
            label: "ID",
            primary_key: true,
            readonly: true,
            hide: false,
            field_type: FIELD_TYPES.AutoNumber,
        }),

        Field("name", {
            label: "Name",
            primary_key: false,
            readonly: false,
            hide: false,
            required: true,
            field_type: FIELD_TYPES.String,
            default: () => {
                return null;
            },
        }),
        Field("content_type", {
            label: "Content Type",
            primary_key: false,
            readonly: false,
            required: true,
            hide: false,
            field_type: FIELD_TYPES.ForeignKey,
            ref_field_type: "ContentType",
            ref_display_field: "app_label",
            default: () => {
                return null;
            },
        }),
        Field("codename", {
            label: "Codename",
            primary_key: false,
            readonly: false,
            hide: false,
            required: true,
            field_type: FIELD_TYPES.String,
            default: () => {
                return null;
            },
        }),
    ],
});
