import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default function (immediate) {
    return {
        data() {
            return {
                changed: false,
                newValue: null,
            };
        },
        model: {
            prop: "value",
            event: "update",
        },
        props: {
            value: { type: [String, Number, Boolean, Array, Date] },
            id: { type: Number, default: null },
            storeName: { type: String },
            fieldName: { type: String },
        },
        methods: {
            doUpdate: _.debounce(function (value) {
                var data = { id: this.id };
                data[this.fieldName] = value;

                if (this.store) {
                    this.store.patchItem(data);
                }

                this.$emit("patchItem", data); // if there is no Store we could catch this..

                this.$emit("update", value);
            }),
        },
        computed: {
            input_state() {
                //TODO If has error then make false!
                return null;
            },
            store() {
                if (this.storeName) {
                    var vm = this;

                    //Dynamically hook up the Actions and Getters - these will be accessible from this.store and just this.
                    var storeName = this.storeName + "Module";

                    vm = Object.assign(
                        vm,
                        mapActions(storeName, ["patchItem"]),
                    );
                    vm = Object.assign(vm, mapGetters(storeName, ["working"]));

                    return vm;
                }

                return new Vue({}); // empty store
            },
            inputListeners: function () {
                /*
                    used with v-on="inputListeners" to add our own listeners

                    Listen for input, change or blur to make it dirty/touched
                 */

                var vm = this;
                // `Object.assign` merges objects together to form a new object
                return Object.assign(
                    {},
                    // We add all the listeners from the parent
                    this.$listeners,
                    // Then we can add custom listeners or override the
                    // behavior of some listeners.
                    {
                        // This ensures that the component works with v-model
                        input: function (event) {
                            if (immediate) {
                                vm.doUpdate(event);
                            } else {
                                vm.changed = true;
                                vm.newValue = event;
                            }
                        },
                        // This ensures that the component works with v-model
                        change: function (event) {
                            if (immediate) {
                                vm.doUpdate(event);
                            } else {
                                vm.changed = true;
                                vm.newValue = event;
                            }
                        },
                        blur: function (event) {
                            if (vm.changed && !immediate) {
                                vm.doUpdate(vm.newValue);
                                vm.changed = false;
                            }
                        },
                    },
                );
            },
        },
    };
}
