<template>
    <o-section :level="level" :title="label" body-classes="p-0" not-closable>
        <BListGroup>
            <BListGroupItem class="note_input_item">
                <div class="d-flex w-100 justify-content-between">
                    <BFormTextarea
                        :rows="rows"
                        @blur="note_blur"
                        @focus="note_focus"
                        @keydown="note_keydown"
                        class="note_input"
                        v-model="newText"
                    ></BFormTextarea>

                    <BButton @click="save_note">Add</BButton>
                </div>
            </BListGroupItem>
            <BListGroupItem :key="index" v-for="(item, index) in list">
                <div class="d-flex w-100 justify-content-between">
                    <span style="white-space: pre">{{ item.note }}</span>
                    <small v-if="item[whoField]">
                        <user-display
                            :list="users"
                            :value="item[whoField]"
                            default-value="-"
                        ></user-display>
                        at
                        <date-display
                            :value="item[whenField]"
                            format="MM/DD/YYYY H:mm a"
                        />
                    </small>
                </div>
            </BListGroupItem>
        </BListGroup>
    </o-section>
</template>

<script>
import {
    BButton,
    BFormTextarea,
    BListGroup,
    BListGroupItem,
} from "bootstrap-vue";
/* eslint-disable no-unused-vars */
import _ from "lodash";

import { cleanUpModel } from "../../../schema/schema_vuetify.js";
import schema from "../../../../app/schema/index.js";
import { CreateFormStateMixin } from "../../../mixins";
import UserDisplay from "../../display/UserDisplay.vue";
import OWidgetWrapper from "../../forms/OWidgetWrapper.vue";
import { mapGetters } from "vuex";

export default {
    name: "ONotesTable",
    mixins: [CreateFormStateMixin("validator")],
    model: {
        prop: "items",
        event: "changed",
    },
    components: {
        BListGroup,
        BListGroupItem,
        BFormTextarea,
        BButton,
        OWidgetWrapper,
        UserDisplay,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        users: {
            type: Object,
            default: () => {},
        },
        modelName: { type: String, default: null },
        label: { type: String, default: null },
        validator: { type: Object, default: () => null },
        serverErrors: { type: Array, default: () => [] },
        usersWorking: { type: Boolean, default: false },
        level: { type: Number, default: 0 },

        whoField: {
            type: String,
            default: "who",
        } /* What Field in the Model has the User ID */,
        whenField: {
            type: String,
            default: "when",
        } /* What Field in the Model has the Date when it was created/uploaded */,
    },
    data: function () {
        return {
            defaultNewValue: { _is_new: true },
            schema: cleanUpModel(schema[this.modelName], {}),
            newText: "",
            rows: 1,
        };
    },
    methods: {
        note_keydown(event) {
            if (event.ctrlKey && event.keyCode == 13) {
                this.save_note();
            }
        },
        note_focus() {
            this.rows = 6;
        },
        note_blur() {
            if (this.newText == "") {
                this.rows = 1;
            }
        },
        save_note() {
            if (this.newText) {
                var note = { note: this.newText };

                note[this.whenField] = new Date(); // put now in there!
                note[this.whoField] = this.currentUser.id;

                this.newText = ""; // clear
                this.rows = 1; // shrink it now..

                console.log("HERE!");

                var newList = _.cloneDeep(this.list);
                newList.splice(0, 0, note);

                this.changed(newList);
            }
        },
        changed(list) {
            if (this.validator) {
                this.validator.$touch();
            }
            this.makeDirty();

            this.$emit("changed", list);
        },
        canSee(schema, field_name) {
            /* Here we can check if they have permission for the field */
            return true;
        },
    },
    computed: {
        ...mapGetters("AuthModule", ["currentUser"]),
        list() {
            // Make sure we have a list!

            return this.items || [];
        },
    },
    created() {},
};
</script>

<style>
.note_input {
}

.note_input_item {
    padding: 0px !important;
}
</style>
