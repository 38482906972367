<template>
  <o-form-wrapper
    :form-state="formState"
    :schema="schema"
    :server-errors="_se"
    :v="$v.item"
    v-model="item"
  >
    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="maintenance_date"
    >
      <o-date-time
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="maintenance_date"
        v-bind="schema.fields.maintenance_date"
        v-model="item.maintenance_date"
      ></o-date-time>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="description"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="description"
        v-bind="schema.fields.description"
        v-model="item.description"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="labour_minutes"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="labour_minutes"
        v-bind="schema.fields.labour_minutes"
        v-model="item.labour_minutes"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="labour_costs"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="labour_costs"
        v-bind="schema.fields.labour_costs"
        v-model="item.labour_costs"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="parts_replaced"
    >
      <b-dropdown text="" right class="d-block text-right">
        <b-dropdown-item
          v-for="part in optionCodeReplacementPartItems"
          :key="part.id"
          @click="item.parts_replaced = part.description"
          >{{ part.description }}</b-dropdown-item
        >
      </b-dropdown>
      <o-textarea
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="parts_replaced"
        v-bind="schema.fields.parts_replaced"
        v-model="item.parts_replaced"
        class="d-block"
      ></o-textarea>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="actual_cost"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="actual_cost"
        v-bind="schema.fields.actual_cost"
        v-model="item.actual_cost"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="parts_cost"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="parts_cost"
        v-bind="schema.fields.parts_cost"
        v-model="item.parts_cost"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="date_completed"
    >
      <o-date-time
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="date_completed"
        v-bind="schema.fields.date_completed"
        v-model="item.date_completed"
      ></o-date-time>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="comments"
    >
      <o-textarea
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="comments"
        v-bind="schema.fields.comments"
        v-model="item.comments"
      ></o-textarea>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="employee"
    >
      <o-select
        :form-state="formState"
        :items="optionEmployeeItems"
        :loading="optionEmployeeWorking"
        :server-errors="_se"
        :validators="$v.item"
        add-title="Create New"
        allow-add
        name="employee"
        sort-field="full_name"
        store-name="Employee"
        text-field="full_name"
        v-bind="schema.fields.employee"
        v-model="item.employee"
        value-field="id"
      >
        <template slot="form" slot-scope="{ item, formState, serverErrors }">
          <employee-form
            :form-state="formState"
            :item="item"
            :server-errors="serverErrors"
            in-modal
          ></employee-form>
        </template>
      </o-select>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="asset"
    >
      <o-select
        :form-state="formState"
        :items="optionAssetItems"
        :loading="optionAssetWorking"
        :server-errors="_se"
        :validators="$v.item"
        add-title="Create New"
        allow-add
        name="asset"
        sort-field="name"
        store-name="Asset"
        text-field="name"
        v-bind="schema.fields.asset"
        v-model="item.asset"
        value-field="id"
      >
        <template slot="form" slot-scope="{ item, formState, serverErrors }">
          <asset-form
            :form-state="formState"
            :item="item"
            :server-errors="serverErrors"
            in-modal
          ></asset-form>
        </template>
      </o-select>
    </o-widget-wrapper>
  </o-form-wrapper>
</template>

<script>
import schema from "../../schema/index";
import { cleanUpModel } from "../../../vue-ocom/schema/schema_vuetify";
import {
  CreateFormStateMixin,
  CreateGroupPermissionMixin,
  VuelidateMixin,
} from "../../../vue-ocom/mixins";
import { validationMixin } from "vuelidate";
import OWidgetWrapper from "../../../vue-ocom/components/forms/OWidgetWrapper";
import OTextField from "../../../vue-ocom/components/forms/fields/OTextField";
import OSelect from "../../../vue-ocom/components/forms/fields/OSelect";
import OCheckbox from "../../../vue-ocom/components/forms/fields/OCheckbox";
import OFormWrapper from "../../../vue-ocom/components/forms/OFormWrapper";
import ODateTime from "../../../vue-ocom/components/forms/fields/ODateTime";

import { default as EmployeeForm } from "../../employee/components/ItemForm";
import { default as AssetForm } from "../../asset/components/ItemForm";

import UseCodeModulesMixin from "@/vue-ocom/mixins/UseCodeModulesMixin";

export default {
  mixins: [
    VuelidateMixin,
    validationMixin,
    CreateFormStateMixin("$v"),
    CreateGroupPermissionMixin("MaintenanceRecord"),
    UseCodeModulesMixin(["Employee", "Asset", "CodeReplacementPart"]),
  ],
  components: {
    OFormWrapper,
    OWidgetWrapper,
    ODateTime,

    OCheckbox,
    OSelect,
    OTextField,

    EmployeeForm,
    AssetForm,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    level: { type: Number, default: 0 },
    inModal: { type: Boolean, default: false },
  },
  data: () => ({
    schemaModel: cleanUpModel(schema.MaintenanceRecord, {}),
  }),
  methods: {},
  computed: {
    _se() {
      // Return an empty Object if there are no Server Errors to avoid Binding Errors
      return this.serverErrors || {};
    },
  },
  watch: {},
  created() {},
};
</script>
