import { MUTATION_TYPES } from "./store_keys";

export function store_list_options(state, list_options) {
    if (list_options._code) {
        state.last_list_options = list_options;
        state.last_load = new Date();
    }
}

export function StartWorking(state) {
    state.working = true;
}

export function FinishedWorking(state) {
    state.working = false;
}
