import { Field, FIELD_TYPES, Model } from "../../vue-ocom/schema";

export default Model("testing", {
  verbose_name: "Testing",
  verbose_name_plural: "Testing",
  fields: [
    Field("id", {
      label: "ID",
      primary_key: true,
      readonly: true,
      hide: false,
      field_type: FIELD_TYPES.AutoNumber,
    }),

    Field("modified_date", {
      label: "Modified Date",
      primary_key: false,
      readonly: true,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("created_date", {
      label: "Created Date",
      primary_key: false,
      readonly: true,
      hide: false,
      required: true,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("active_start_date", {
      label: "Active Start Date",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return new Date();
      },
    }),
    Field("active_end_date", {
      label: "Active End Date",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("code", {
      label: "Code",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("customer", {
      label: "Customer",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "Customer",
      ref_display_field: "company_name",
      default: () => {
        return null;
      },
    }),
    Field("location", {
      label: "Location",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "AssetLocation",
      ref_display_field: "location_name_sub_1",
      default: () => {
        return null;
      },
    }),
    Field("product", {
      label: "Product",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "Product",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field("description", {
      label: "Description",
      primary_key: false,
      readonly: false,
      hide: false,
      required: true,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("make", {
      label: "Make",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("model", {
      label: "Model",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("serial_number", {
      label: "Serial Number",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("asset_color", {
      label: "Asset Color",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodeColor",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field("length", {
      label: "Length",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("phase", {
      label: "Phase",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodePhase",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field("test_frequency", {
      label: "Test Frequency",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodeTestFrequency",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field("elec_class", {
      label: "Class",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodeElectricalClass",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field("comments", {
      label: "Comments",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("linkedwith", {
      label: "Linkedwith",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("asset_classification", {
      label: "Asset Classification",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodeAssetClassification",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field("replacement_value", {
      label: "Replacement Value",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return 0;
      },
    }),
    Field("customer_asset_no", {
      label: "Customer Asset No",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("missing_in_action", {
      label: "Missing In Action",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Boolean,
      default: () => {
        return false;
      },
    }),
    Field("disposed", {
      label: "Disposed",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Boolean,
      default: () => {
        return false;
      },
    }),
    Field("out_of_service", {
      label: "Out Of Service",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Boolean,
      default: () => {
        return null;
      },
    }),
    Field("customer_gone", {
      label: "Customer Gone",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Boolean,
      default: () => {
        return null;
      },
    }),
    Field("last_test_date", {
      label: "Last Test Date",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("status", {
      label: "Status",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodeAssetStatus",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field("original_id", {
      label: "Original ID",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      },
    }),
    Field("supplied_date", {
      label: "Supplied",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("test", {
      label: "Tests",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.ManyToMany,
      ref_field_type: "AssetTest",
      default: () => {
        return [];
      },
    }),
    Field("name", {
      label: "Name",
      primary_key: false,
      readonly: true,
      hide: false,
      required: false,
      virtual: true,
      field_type: FIELD_TYPES.String,
      getter: (item) => {
        var data = [
          item.code,
          item.make,
          item.model,
          item.description,
          item.serial_number,
        ].filter(function (el) {
          return el != null && el !== "";
        });

        return data.join(" - ");
      },
    }),
  ],
});
