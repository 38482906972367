import * as CONFIG from "../../config.js";

export default {
    computed: {
        isGoBackend() {
            // Return true if this has a Go Backend so we can do different code for Go.
            return CONFIG.GoBackend === true;
        },
    },
};
