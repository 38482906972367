<template>
    <o-form-group
        :form-state="formState"
        :label="label"
        :message="errorMessages"
        :nolabel="nolabel"
        :type="{ 'is-danger': hasError }"
    >
        <BFormTextarea
            :class="input_class"
            :disabled="_is_readonly"
            v-bind="$attrs"
            v-bind:value="value"
            v-on="inputListeners"
        >
            <slot />
        </BFormTextarea>
    </o-form-group>
</template>

<script>
import { BFormTextarea } from "bootstrap-vue";
import FormComponentMixin from "../../../mixins/form_component_mixin.js";
import OFormGroup from "../OFormGroup.vue";

export default {
    name: "OTextarea",
    components: {
        BFormTextarea,
        OFormGroup,
    },
    mixins: [FormComponentMixin],
};
</script>

<style scoped></style>
