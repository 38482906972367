<template>
    <o-form-group
        :form-state="formState"
        :message="errorMessages"
        :type="{ 'is-danger': hasError }"
        :label="labelName"
    >
        <BButtonGroup v-if="type == 'boolean'">
            <BButton
                :class="input_class"
                :disabled="_is_readonly"
                :variant="calcVariant(btn)"
                v-for="btn in buttons"
                :key="btn.id"
                v-bind="$attrs"
                v-on="inputListeners"
                @click="click(btn.value)"
                >{{ btn.text }}</BButton
            >
        </BButtonGroup>

        <BButtonGroup v-if="type == 'integer'">
            <BButton
                :disabled="disabled"
                :value="true"
                :variant="yesColor"
                @click="button_pressed(1)"
            >
                {{ yesLabel }}
            </BButton>
            <BButton
                :disabled="disabled"
                :value="false"
                :variant="noColor"
                @click="button_pressed(0)"
            >
                {{ noLabel }}
            </BButton>

            <BButton
                :disabled="disabled"
                :value="-1"
                :variant="naColor"
                @click="button_pressed(-1)"
            >
                {{ naLabel }}
            </BButton>
        </BButtonGroup>

        <BButtonGroup v-if="type == 'user-profile'">
            <BButton
                :disabled="disabled"
                :value="true"
                :variant="yesColor"
                @click="button_pressed(1)"
            >
                {{ activatedLabel }}
            </BButton>
            <BButton
                :disabled="disabled"
                :value="false"
                :variant="noColor"
                @click="button_pressed(0)"
            >
                {{ deactivatedLabel }}
            </BButton>

            <BButton
                :disabled="disabled"
                :value="-1"
                :variant="naColor"
                @click="button_pressed(-1)"
            >
                {{ naLabel }}
            </BButton>
        </BButtonGroup>
    </o-form-group>
</template>

<script>
import { BButton, BButtonGroup } from "bootstrap-vue";
import FormComponentMixin from "../../../mixins/form_component_mixin.js";
import OFormGroup from "../OFormGroup.vue";

export default {
    name: "OBooleanButton",
    components: {
        BButtonGroup,
        BButton,
        OFormGroup,
    },
    mixins: [FormComponentMixin],
    props: {
        labelName: { type: String },
        type: { type: String },
    },
    data: () => ({
        buttons: [
            { value: true, text: "Yes" },
            { value: false, text: "No" },
        ],
        integerButtons: [
            { value: 1, text: "Yes" },
            { value: 0, text: "No" },
            { value: -1, text: "N/A" },
        ],
    }),
    methods: {
        click(value) {
            if (this.validator) {
                this.validator.$touch();
            }
            if (this.formState) this.formState.dirty = true;
            console.warn("Value: ", value);
            this.$emit("input", value);
        },
        button_pressed(value) {
            var item = this.item || {};
            if (this.validator) {
                this.validator.$touch();
            }
            if (this.formState) this.formState.dirty = true;
            console.warn("Form state: ", this.formState.dirty);
            this.$emit("input", value);
        },
        calcVariant(btn) {
            return {
                false: "outline-dark",
                true: btn.value ? "success" : "danger",
            }[btn.value === this.value];
        },
    },
    computed: {
        yesLabel: function () {
            return "Yes";
        },

        yesColor: function () {
            if (this.value === 1) return "success";

            return "";
        },
        noLabel: function () {
            return "No";
        },
        noColor: function () {
            if (this.value === 0) return "danger";

            return "";
        },
        activatedLabel: function () {
            return "Activated";
        },

        activatedColor: function () {
            if (this.value === 1) return "success";

            return "";
        },
        deactivatedLabel: function () {
            return "Deactivated";
        },
        deactivatedColor: function () {
            if (this.value === 0) return "danger";

            return "";
        },
        naLabel: function () {
            return "N/A";
        },
        naColor: function () {
            if (this.value == -1) return "warning";

            return "";
        },
        isNullValue: function () {
            return this.value === null;
        },
    },
};
</script>

<style scoped></style>
