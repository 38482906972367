/* eslint-disable no-unused-vars */

import axios from "axios";
import * as CONFIG from "../../config.js";
import jwt_decode from "jwt-decode";

const endpoints = {
    obtainJWT: "/api-token-auth/",
    verifyJWT: "/api-token-verify/",
    refreshJWT: "/api-token-refresh/",
    expireJWT: "/api-token-expire/",

    getPermissions: "/api/ocom/my_group_permissions/",
    forgot_password: "/auth/forgot_password/",
    verify_passcode: "/auth/verify_passcode/",
    change_password: "/auth/change_password/",
    sudo: "/auth/sudo/",

    baseUrl: CONFIG.root_url || "",
};

// send login data and retrive a new token
export const postLogin = ({ username, password }) => {
    return new Promise((resolve, reject) => {
        axios
            .post(endpoints.baseUrl + endpoints.obtainJWT, {
                username: username,
                password: password,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getPermissions = (token) => {
    return new Promise((resolve, reject) => {
        var headers = { Authorization: "bearer " + token };

        axios
            .post(
                endpoints.baseUrl + endpoints.getPermissions,
                {},
                { headers: headers },
            )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const forgotPassword = (username) => {
    return new Promise((resolve, reject) => {
        axios
            .post(endpoints.baseUrl + endpoints.forgot_password, {
                username: username,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const verifyPasscode = (username, key) => {
    return new Promise((resolve, reject) => {
        axios
            .post(endpoints.baseUrl + endpoints.verify_passcode, {
                username: username,
                key: key,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const changePassword = (password, token) => {
    return new Promise((resolve, reject) => {
        var headers = { Authorization: "bearer " + token };

        axios
            .post(
                endpoints.baseUrl + endpoints.change_password,
                { password: password },
                { headers: headers },
            )
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const refreshToken = (token) => {
    return new Promise((resolve, reject) => {
        var data = {
            token: token,
        };

        axios
            .post(endpoints.baseUrl + endpoints.refreshJWT, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

// revoke current token
export const revokeToken = (token) => {
    var headers = { Authorization: "bearer " + token };

    return axios.post(
        endpoints.baseUrl + endpoints.expireJWT,
        {},
        { headers: headers },
    );
};

export const loadUserData = () => {
    return new Promise((resolve, reject) => {
        resolve();
    });
};

export const isAuthDisabled = () => {
    if ("authDisabled" in CONFIG) {
        return !!CONFIG.authDisabled;
    }

    return false; // not disabled
};

export const decodeJWT = (token) => {
    var decoded = jwt_decode(token);
    if (Date.now() >= decoded.exp * 1000) {
        console.log("Expired JWT Token");
        throw "Token Expired";
    }
    return decoded;
};

export const sudo = (user_id, token) => {
    return new Promise((resolve, reject) => {
        var headers = { Authorization: "bearer " + token };

        var data = {
            id: user_id,
        };

        axios
            .post(endpoints.baseUrl + endpoints.sudo, data, {
                headers: headers,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
