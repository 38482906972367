import { Field, FIELD_TYPES, Model } from "../../vue-ocom/schema";

export default Model("maintenance_record", {
  verbose_name: "Maintenance Record",
  verbose_name_plural: "Maintenance Records",
  fields: [
    Field("id", {
      label: "ID",
      primary_key: true,
      readonly: true,
      hide: false,
      field_type: FIELD_TYPES.AutoNumber,
    }),

    Field("modified_date", {
      label: "Modified Date",
      primary_key: false,
      readonly: true,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("created_date", {
      label: "Created Date",
      primary_key: false,
      readonly: true,
      hide: false,
      required: true,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("maintenance_date", {
      label: "Maintenance Date",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return new Date(); // now!
      },
    }),
    Field("description", {
      label: "Description",
      primary_key: false,
      readonly: false,
      hide: false,
      required: true,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("labour_minutes", {
      label: "Labour Minutes",
      primary_key: false,
      readonly: false,
      hide: false,
      required: true,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return 0;
      },
    }),
    Field("labour_costs", {
      label: "Labour Costs",
      primary_key: false,
      readonly: false,
      hide: false,
      required: true,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return 0;
      },
    }),
    Field("parts_replaced", {
      label: "Parts Replaced",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("actual_cost", {
      label: "Actual Cost",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      },
    }),
    Field("parts_cost", {
      label: "Parts Cost",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      },
    }),
    Field("date_completed", {
      label: "Date Completed",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("comments", {
      label: "Comments",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("employee", {
      label: "Employee",
      primary_key: false,
      readonly: false,
      required: true,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "Employee",
      ref_display_field: "full_name",
      default: () => {
        return null;
      },
    }),
    Field("asset", {
      label: "Asset",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "Asset",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
  ],
});
