<template>
    <span>
        <div
            :key="index"
            class="card"
            v-for="(item, index) in list"
            v-if="is_item_active(item)"
        >
            <div class="card-content">
                <div class="content">
                    <slot :index="index" :item="item"></slot>
                </div>
            </div>
            <div class="card-footer" v-if="!disableActions && !is_locked">
                <slot :index="index" :item="item" name="item_actions"></slot>

                <span class="float-right">
                    <o-list-add
                        :default-value="defaultNewValue"
                        :item-name="itemName"
                        :list="list"
                        @change="onChanged"
                        type="primary"
                        v-if="!is_locked && is_last(item)"
                    ></o-list-add>
                    <o-list-actions
                        :display-order="displayOrder"
                        :index="index"
                        :list="list"
                        :disable-move="disableMove"
                        :disable-delete="disableDelete"
                        @change="onChanged"
                    />
                </span>
            </div>
        </div>
        <div
            class="card-footer has-background-grey-lighter d-flex flex-row-reverse border"
            v-if="!is_locked && is_empty_list"
        >
            <o-list-add
                :default-value="defaultNewValue"
                :item-name="itemName"
                :list="list"
                @change="onChanged"
                type="primary"
                v-if="!is_locked && !disableAdd"
            >
            </o-list-add>
        </div>
    </span>
</template>

<script>
import { isEmpty, forEach, last } from "lodash";

import OListActions from "./OListActions.vue";
import OListAdd from "./OListAdd.vue";
import { filter_active_list, is_item_active } from "../../utils/active.js";
import moment from "moment";

export default {
    name: "ORepeatingCards",
    components: {
        OListActions: OListActions,
        OListAdd: OListAdd,
    },
    props: {
        list: { type: Array, default: () => [] },
        disabled: { type: Boolean, default: false },
        disableMove: { type: Boolean, default: false },
        disableAdd: { type: Boolean, default: false },
        disableDelete: { type: Boolean, default: false },
        disableActions: { type: Boolean, default: false },
        displayOrder: { type: String, default: null },
        displayOrderMin: { type: Number, default: 0 },
        defaultNewValue: {
            type: Object,
            default: () => {},
        },
        itemName: { type: String, default: "Item" },

        formState: {
            type: Object,
            default: () => {},
        },
    },
    model: {
        prop: "list",
        event: "change",
    },
    methods: {
        is_last(item) {
            if ("id" in item && !!item.id) {
                if (
                    this.last_active_item &&
                    "id" in this.last_active_item &&
                    !!this.last_active_item.id
                ) {
                    // They both have an ID value then compare them - more "accurate"
                    return item.id === this.last_active_item.id;
                }
            }
            // compare on object's
            return this.last_active_item == item;
        },
        onChanged() {
            const vm = this;
            if (vm.displayOrder) {
                //Update the display order
                forEach(this.list, function (o, index) {
                    o[vm.displayOrder] = index + vm.displayOrderMin;
                });
            }

            this.$emit("change", this.list);
        },
        is_item_active(item) {
            var now = moment();

            return is_item_active(item, now);
        },
    },
    computed: {
        last_active_item() {
            if (!this.is_empty_list) {
                return last(this.filtered_list);
            }
            return null; // No Last Item
        },
        is_empty_list() {
            if (isEmpty(this.filtered_list)) {
                return true;
            }
            return false;
        },
        filtered_list() {
            var result = this.list;

            if (result == null) {
                result = [];
            }

            result = filter_active_list(result); // Filter for Active only

            return result;
        },
        is_locked() {
            if (this.disabled) return true;

            if (this.formState) {
                if ("locked" in this.formState) {
                    return this.formState.locked;
                }
            }

            return false;
        },
    },
    data: () => ({}),
};
</script>

<style scoped></style>
