<template>
  <div v-if="hasCustomer">
    <slot />
  </div>
  <div v-else>
    <div class="alert alert-warning" role="alert">
      Please select a Customer to view the {{ name }}
    </div>
    <div>
      <b-form-select
        :options="customer_list"
        @input="selectCustomer"
        id="selectCustomerSelect"
        ref="selectCustomerSelect"
        size="sm"
        text-field="name"
        value="data.customer_id"
        value-field="id"
      ></b-form-select>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { CreateCodeTableFetchMixin } from "../vue-ocom/mixins";
import { sort_and_filter_active_list } from "../vue-ocom/utils/active";

export default {
  name: "GlobalCustomerGuard",
  mixins: [CreateCodeTableFetchMixin(["Customer"])],
  props: {
    name: { type: String, default: "Item(s)" },
  },
  methods: {
    ...mapActions("GlobalStateModule", ["setGlobalValue"]),
    ...mapMutations("AssetModule", { asset_preloaded: "preloaded" }),
    ...mapMutations("AssetTestModule", { asset_test_preloaded: "preloaded" }),
    ...mapMutations("MaintenanceRecordModule", {
      maintenance_record_preloaded: "preloaded",
    }),

    selectCustomer(customerID) {
      this.setGlobalValue({ key: "customer_id", value: customerID });

      this.asset_preloaded(false); // cause to pre-load again!
      this.asset_test_preloaded(false); // cause to pre-load again!
      this.maintenance_record_preloaded(false); // cause to pre-load again!
    },
  },
  computed: {
    ...mapGetters("GlobalStateModule", ["data"]),
    hasCustomer() {
      return this.data && !!this.data.customer_id;
    },
    customer_list() {
      return sort_and_filter_active_list(
        this.optionCustomerItems,
        null,
        false,
        "name",
        "(Disabled)",
        false,
        "name"
      );
    },
  },
};
</script>

<style scoped></style>
