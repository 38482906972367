<template>
    <span>
        <BPopover :show.sync="editing" :target="thisRef" triggers="blur focus">
            <template v-slot:title>
                <BButton @click="toggle" aria-label="Close" class="close">
                    <span aria-hidden="true" class="d-inline-block"
                        >&times;</span
                    >
                </BButton>
                Edit Value
            </template>

            <slot name="edit"></slot>
        </BPopover>
        <div :id="thisRef" @click="toggle" class="editable">
            <slot name="display"></slot>
        </div>
    </span>
</template>

<script>
import { BButton, BPopover } from "bootstrap-vue";

export default {
    components: {
        BPopover,
        BButton,
    },
    name: "OInlineWidget",
    data() {
        return {
            editing: false,
            thisRef: "dummy",
        };
    },
    methods: {
        toggle() {
            this.$root.$emit("bv::hide::popover");

            this.editing = !this.editing;
        },
    },
    mounted() {
        this.thisRef = "popup" + this._uid;
    },
};
</script>

<style scoped>
.editable {
    text-decoration: underline dotted;
}

.popup {
    position: relative;
    display: inline-block;
    z-index: 777;
    top: 0;
    left: 0;
    min-width: 100px;
    min-height: 20px;
    background-color: silver;
}
</style>
