import PermissionList from "./pages/ListPage.vue";
import PermissionDetail from "./pages/FormPage.vue";
import PermissionIndex from "./pages/IndexPage.vue";

export default [
    {
        path: "/permission",
        component: PermissionIndex,
        redirect: "/permission/list",
        name: "permission",
        children: [
            {
                path: "list",
                component: PermissionList,
                name: "permission.list",
            },
            {
                path: "create",
                component: PermissionDetail,
                name: "permission.create",
                props: { create: true },
            },
            {
                path: "edit/:id",
                component: PermissionDetail,
                name: "permission.edit",
                props: { create: false },
            },
        ],
    },
];
