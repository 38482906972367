import AssetLocationList from "./pages/ListPage.vue";
import AssetLocationDetail from "./pages/FormPage.vue";
import AssetLocationIndex from "./pages/IndexPage.vue";

export default [
  {
    path: "/asset_location",
    component: AssetLocationIndex,
    redirect: "/asset_location/list",
    name: "asset_location",
    children: [
      {
        path: "list",
        component: AssetLocationList,
        name: "asset_location.list",
      },
      {
        path: "create",
        component: AssetLocationDetail,
        name: "asset_location.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: AssetLocationDetail,
        name: "asset_location.edit",
        props: { create: false },
      },
    ],
  },
];
