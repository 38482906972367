import _ from "lodash";
import { update_calculated_fields } from "./schema.js";

export function calculateFieldsInlist(schema, list) {
    _.forEach(list, function (item) {
        calculateFields(schema, item);
    });
}

export function calculateFields(schema, item) {
    update_calculated_fields(schema);

    var calculatedFields = schema._calculatedFields || [];
    if (item) {
        _.forEach(calculatedFields, function (f) {
            if (!(f.name in item)) {
                Object.defineProperty(item, f.name, {
                    enumerable: true,
                    get: function () {
                        return f.getter(item);
                    },
                });
            }
        });
    }
}

export function removeVirtualFields(schema, item) {
    return item; // Does nothing right now but maybe added later
}
