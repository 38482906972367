<template>
  <o-form-wrapper
    :form-state="formState"
    :schema="schema"
    :server-errors="_se"
    :v="$v.item"
    v-model="item"
    v-if="item"
  >
    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="company_code"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="company_code"
        v-bind="schema.fields.company_code"
        v-model="item.company_code"
        @blur="check_company_code"
        :state="!duplicate_customer_code"
      ></o-text-field>
      <b-alert :show="show_failed_alert" variant="warning"
        >This Customer Code "{{ failed_code }}" is already used by another
        customer. To avoid issues we recommend you pick another code.</b-alert
      >
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="company_name"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="company_name"
        v-bind="schema.fields.company_name"
        v-model="item.company_name"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="division"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="division"
        v-bind="schema.fields.division"
        v-model="item.division"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="root_location"
    >
      <o-tree-path-select
        v-model="item.root_location"
        v-bind="schema.fields.root_location"
        :form-state="formState"
        :items="optionAssetLocationItems"
        :self-id="item.id"
        :server-errors="_se"
        :validators="$v.item"
        name="root_location"
        value-type="integer"
      ></o-tree-path-select>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="billing_address"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="billing_address"
        v-bind="schema.fields.billing_address"
        v-model="item.billing_address"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="address"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="address"
        v-bind="schema.fields.address"
        v-model="item.address"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="city"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="city"
        v-bind="schema.fields.city"
        v-model="item.city"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="state"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="state"
        v-bind="schema.fields.state"
        v-model="item.state"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="postal_code"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="postal_code"
        v-bind="schema.fields.postal_code"
        v-model="item.postal_code"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="phone_number"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="phone_number"
        v-bind="schema.fields.phone_number"
        v-model="item.phone_number"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="fax_number"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="fax_number"
        v-bind="schema.fields.fax_number"
        v-model="item.fax_number"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="web_address"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="web_address"
        v-bind="schema.fields.web_address"
        v-model="item.web_address"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="contact_first_name"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="contact_first_name"
        v-bind="schema.fields.contact_first_name"
        v-model="item.contact_first_name"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="contact_last_name"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="contact_last_name"
        v-bind="schema.fields.contact_last_name"
        v-model="item.contact_last_name"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="contact_phone_no"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="contact_phone_no"
        v-bind="schema.fields.contact_phone_no"
        v-model="item.contact_phone_no"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="contact_mobile_phone_no"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="contact_mobile_phone_no"
        v-bind="schema.fields.contact_mobile_phone_no"
        v-model="item.contact_mobile_phone_no"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="contact_fax_no"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="contact_fax_no"
        v-bind="schema.fields.contact_fax_no"
        v-model="item.contact_fax_no"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="contact_title"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="contact_title"
        v-bind="schema.fields.contact_title"
        v-model="item.contact_title"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="other_contacts"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="other_contacts"
        v-bind="schema.fields.other_contacts"
        v-model="item.other_contacts"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="email_address"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="email_address"
        v-bind="schema.fields.email_address"
        v-model="item.email_address"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="notes"
    >
      <o-textarea
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="notes"
        v-bind="schema.fields.notes"
        v-model="item.notes"
      ></o-textarea>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="notes_to_show"
    >
      <o-textarea
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="notes_to_show"
        v-bind="schema.fields.notes_to_show"
        v-model="item.notes_to_show"
      ></o-textarea>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="logo"
    >
      <o-form-group :form-state="formState" :label="schema.fields.logo.label">
        <o-file-upload
          :form-state="formState"
          :other-id="item.id"
          other-name="training"
          v-model="item.logo"
        >
        </o-file-upload>

        <o-file-preview :disable-download="true" :file="item.attachment" />
      </o-form-group>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="last_code"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="last_code"
        v-bind="schema.fields.last_code"
        v-model="item.last_code"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="status"
    >
      <o-select
        :form-state="formState"
        :items="optionCodeCustomerStatusItems"
        :loading="optionCodeCustomerStatusWorking"
        :server-errors="_se"
        :validators="$v.item"
        @change="codeStatusItemsByID"
        add-title="Create New"
        allow-add
        name="status"
        sort-field="description"
        store-name="CodeCustomerStatus"
        text-field="description"
        v-bind="schema.fields.status"
        v-model="item.status"
        value-field="id"
      >
        <template slot="form" slot-scope="{ item, formState, serverErrors }">
          <code-customer-status-form
            :form-state="formState"
            :item="item"
            :server-errors="serverErrors"
            in-modal
          ></code-customer-status-form>
        </template>
      </o-select>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="active_start_date"
    >
      <o-date-time
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="active_start_date"
        v-bind="schema.fields.active_start_date"
        v-model="item.active_start_date"
      ></o-date-time>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="active_end_date"
    >
      <o-date-time
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="active_end_date"
        v-bind="schema.fields.active_end_date"
        v-model="item.active_end_date"
      ></o-date-time>
    </o-widget-wrapper>
  </o-form-wrapper>
</template>

<script>
import schema from "../../schema/index";
import { cleanUpModel } from "../../../vue-ocom/schema/schema_vuetify";
import {
  CreateFormStateMixin,
  CreateGroupPermissionMixin,
  VuelidateMixin,
} from "../../../vue-ocom/mixins";
import { validationMixin } from "vuelidate";
import OWidgetWrapper from "../../../vue-ocom/components/forms/OWidgetWrapper";
import OTextField from "../../../vue-ocom/components/forms/fields/OTextField";
import OSelect from "../../../vue-ocom/components/forms/fields/OSelect";
import OCheckbox from "../../../vue-ocom/components/forms/fields/OCheckbox";
import OFormWrapper from "../../../vue-ocom/components/forms/OFormWrapper";
import ODateTime from "../../../vue-ocom/components/forms/fields/ODateTime";
import OFileUpload from "../../../vue-ocom/filesystem/OFileUpload";
import OFilePreview from "../../../vue-ocom/filesystem/OFilePreview";

import { default as CodeCustomerStatusForm } from "../../code_customer_status/components/ItemForm";
import OTreePathSelect from "@/vue-ocom/components/tree/OTreePathSelect";
import { fileDownload } from "@/vue-ocom/filesystem/fileDownload.js";

import UseCodeModulesMixin from "@/vue-ocom/mixins/UseCodeModulesMixin";

export default {
  mixins: [
    VuelidateMixin,
    validationMixin,
    CreateFormStateMixin("$v"),
    CreateGroupPermissionMixin("Customer"),
    UseCodeModulesMixin(["CodeCustomerStatus", "AssetLocation"]),
  ],
  components: {
    OFormWrapper,
    OWidgetWrapper,
    ODateTime,

    OCheckbox,
    OSelect,
    OTextField,

    OFileUpload,
    OFilePreview,
    OTreePathSelect,

    CodeCustomerStatusForm,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    level: { type: Number, default: 0 },
    inModal: { type: Boolean, default: false },
  },
  data: () => ({
    schemaModel: cleanUpModel(schema.Customer, {}),
    duplicate_customer_code: false,
    failed_code: "",
  }),
  methods: {
    check_company_code() {
      var vm = this;

      var root_url = process.env.VUE_APP_ROOT_API;

      if (this.item) {
        var data = {
          id: null,
          company_code: this.item.company_code,
        };

        if (this.item.id) {
          //if we have an id then make sure we don't detect this as the existing entry
          data.id = this.item.id;
        }

        var request = {
          method: "POST",
          url: root_url + "/api/check_customer_code/",
          data: data,
        };

        this.axios(request).then(
          function (response, status, headers) {
            vm.failed_code = vm.item.company_code;
            vm.duplicate_customer_code = !response.data.OK;
          },
          function (error) {
            if (vm.errorMessage) {
              vm.showMessage({
                message: vm.errorMessage,
                type: "danger",
                show: true,
              });
            }

            vm.$emit("error", error);
          }
        );
      }
    },
    GetAssetLocationCodeList() {
      const list_options = { limit: 50000, offset: 0, _code: true, _all: true };

      this.getAllAssetLocation({ ...list_options });
    },
    codeStatusItemsByID() {
      console.log("changed status");
    },
  },
  computed: {
    show_failed_alert() {
      if (this.item) {
        return (
          this.duplicate_customer_code &&
          this.failed_code === this.item.company_code
        );
      } else {
        return false;
      }
    },
    _se() {
      // Return an empty Object if there are no Server Errors to avoid Binding Errors
      return this.serverErrors || {};
    },
  },
  watch: {},
  created() {},
};
</script>
