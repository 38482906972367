import localforage from "localforage";

import { userTokenStorageKey } from "../../../config.js";
import { isEmpty } from "lodash";
import * as TYPES from "./mutations-types.js";
import * as services from "../services.js";
import { decodeJWT } from "../services.js";
import * as moment from "moment";

export const doLogin = ({ dispatch, commit }, payload) =>
    services.postLogin(payload).then(({ token, user }) => {
        dispatch("successfullLogin", { user: user, token: token });

        return user; // keep promise chain
    });

export const successfullLogin = ({ dispatch, commit }, payload) => {
    dispatch("setUser", payload.user);
    dispatch("setToken", payload.token);

    services
        .getPermissions(payload.token)
        .then((permissions) => {
            commit(TYPES.SET_PERMISSION, permissions);
        })
        .catch((err) => {
            // there are no permissions
            commit(TYPES.SET_PERMISSION, null);
        });
};

export const forgotPassword = ({ dispatch, commit }, payload) => {
    services.forgotPassword(payload.username);
};

export const verifyPasscode = ({ dispatch, commit }, payload) => {
    return services.verifyPasscode(payload.username, payload.key);
};

export const changePassword = ({ dispatch, commit }, payload) => {
    services
        .changePassword(payload.password, payload.token)
        .then(function (result) {
            if (result.status) {
                delete payload.password;
                delete payload.status;
                dispatch("successfullLogin", payload);
            }
        });
};

export const logout = ({ commit, state }) => {
    services.revokeToken(state.token);
    // call actions
    commit(TYPES.TOKEN_EXPIRED);
};

export const setUser = ({ commit }, user) => {
    // Commit the mutations
    commit(TYPES.SET_USER, user);

    Promise.resolve(user); // keep promise chain
};

export const setToken = ({ commit }, payload) => {
    // prevent if payload is a object
    const token = isEmpty(payload) ? null : payload.token || payload;

    // Commit the mutations
    commit(TYPES.SET_TOKEN, token);

    return Promise.resolve(token); // keep promise chain
};

export const checkUserToken = ({ dispatch, state }) => {
    // If the token exists then all validation has already been done
    if (!isEmpty(state.token)) {
        return Promise.resolve(state.token);
    }

    /**
     * Token does not exist yet
     * - Recover it from localstorage
     * - Recover also the user, validating the token also
     */
    return (
        localforage
            .getItem(userTokenStorageKey)
            .then((token) => {
                if (isEmpty(token)) {
                    // Token is not saved in localstorage
                    return Promise.reject("NO_TOKEN"); // Reject promise
                }
                // Put the token in the vuex store
                return dispatch("setToken", token); // keep promise chain
            })
            // With the token in hand, retrieves the user's data, validating the token
            .then(() => dispatch("loadUser"))
    );
};

/**
 * Retrieves updated user information
 * If something goes wrong, the user's token is probably invalid
 */
export const loadUser = ({ dispatch }) =>
    services
        .loadUserData()
        // store user's data
        .then((user) => dispatch("setUser", user.data))
        .catch(() => {
            // Process failure, delete the token
            dispatch("setToken", "");
            return Promise.reject("FAIL_IN_LOAD_USER"); // keep promise chain
        });

export const refreshToken = ({ state, dispatch, commit }) => {
    if (!isEmpty(state.token) && !state.refreshing_token) {
        var invalid = false;
        var about_to_expire = false;

        try {
            var decodedToken = decodeJWT(state.token);

            var expires = moment(decodedToken.exp * 1000);

            // does it expire in less than 30 minutes?
            if (expires.isBefore(moment().add(30, "minutes"))) {
                about_to_expire = true;
            }
        } catch (err) {
            invalid = true;
        }
        if (!invalid && about_to_expire) {
            commit(TYPES.REFRESH_TOKEN); // Only refresh once

            services
                .refreshToken(state.token)
                .then(({ token, user }) => {
                    dispatch("setUser", user);
                    dispatch("setToken", token);

                    commit(TYPES.DONE_REFRESH_TOKEN);
                    return user; // keep promise chain
                })
                .catch(function (err) {
                    commit(TYPES.DONE_REFRESH_TOKEN);
                    return err;
                });
        }
    }
};

export const undo_sudo = ({ state, dispatch, commit }, payload) => {
    commit("POP_RECORD_PREVIOUS");

    dispatch("successfullLogin", { user: state.user, token: state.token });
};

export const sudo = ({ state, dispatch, commit }, payload) => {
    if (!isEmpty(state.token) && !state.refreshing_token) {
        commit("RECORD_PREVIOUS");

        services.sudo(payload, state.token).then(({ token, user }) => {
            dispatch("setUser", user);
            dispatch("setToken", token);

            dispatch("successfullLogin", { user: user, token: token });

            return user; // keep promise chain
        });
    }
};
