import { Field, FIELD_TYPES, Model } from "../../vue-ocom/schema";

export default Model("asset_test", {
  verbose_name: "Asset Test",
  verbose_name_plural: "Asset Tests",
  fields: [
    Field("id", {
      label: "ID",
      primary_key: true,
      readonly: true,
      hide: false,
      field_type: FIELD_TYPES.AutoNumber,
    }),

    Field("modified_date", {
      label: "Modified Date",
      primary_key: false,
      readonly: true,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("created_date", {
      label: "Created Date",
      primary_key: false,
      readonly: true,
      hide: false,
      required: true,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("asset", {
      label: "Asset",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "Asset",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field("test_date", {
      label: "Test Date",
      primary_key: false,
      readonly: false,
      hide: false,
      required: true,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("tester", {
      label: "Tester",
      primary_key: false,
      readonly: false,
      required: true,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "Employee",
      ref_display_field: "full_name",
      default: () => {
        return null;
      },
    }),
    Field("status", {
      label: "Status",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodeAssetTestStatus",
      ref_display_field: "description",
      default: () => {
        return 1; // Default to Pass
      },
    }),
    Field("notes", {
      label: "Notes",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("supplied", {
      label: "Supplied",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Boolean,
      default: () => {
        return null;
      },
    }),
    Field("repaired", {
      label: "Repaired",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Boolean,
      default: () => {
        return null;
      },
    }),
    Field("test_cycle", {
      label: "Test Cycle",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodeTestCycle",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field("retrospective", {
      label: "Retrospective",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Boolean,
      default: () => {
        return false;
      },
    }),
    Field("supplied_product", {
      label: "Supplied Product",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "Product",
      ref_display_field: "model",
      default: () => {
        return "";
      },
    }),
    Field("failed_tag", {
      label: "Fail Tag",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Boolean,
      default: () => {
        return false;
      },
    }),
    Field("repaired_by", {
      label: "Repaired By",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "Employee",
      ref_display_field: "full_name",
      default: () => {
        return null;
      },
    }),
    Field("files", {
      label: "Files",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.ManyToMany,
      ref_field_type: "AssetTestFile",
      default: () => {
        return [];
      },
    }),
    Field('repair_type', {
      label: "Repair Type",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodeRepairType", ref_display_field: "description",
      default: () => { return null; }
    }),
    Field('repair_type__description', {
      label: "Repair Type",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      default: () => { return null; }
    }),
    Field('asset__full_name', {
      label: "Asset",
      primary_key: false,
      readonly: true,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.String,
      default: () => { return null; }
    }),
  ],
});
