import _ from "lodash";
import { IndexedDBModule } from "./IndexedDBModule.js";
import { DEFAULT_FILTER } from "./filters";
import { RestCacheModule } from "./RestCacheModule.js";
import RestModule from "./RestModule.js";
import { RestIndexedDBModule } from "./RestIndexedDBModule.js";
import { RestInMemoryModule } from "./RestInMemoryModule.js";

/*
    When used with VuexPersist as the reducer function will look in the module for a field 'persist_reducer'
    If the module has this it will be used to reduce the values stored for the module.

    if persist_reducer is an Array then use lodash's pick to get just the state values
    if persist_reducer is a Function then use the function to return a new Object from the state
 */
export function CreateReducer(allModules) {
    console.error(
        "Please remove the code from vue/src/store.js to use VuexPersist. The modules handle their own storage now. That plugin is too inefficient",
    );

    const blackListModules = { AuthModule: true, messages: true };

    return function (state) {
        var result = {};

        return result;
    };
}

function is_included(key, included, excluded) {
    if (key in included) {
        return true;
    }
    if (key in excluded) {
        return false;
    }

    return true;
}

export function CreateIndexDBCacheModules(
    schema,
    database,
    root_url,
    included,
    excluded,
) {
    var result = {};

    included = included || {};
    excluded = excluded || {};

    console.error(
        "Please use CreateRestIndexedDBModules instead of CreateIndexDBCacheModules",
    );

    _.forEach(schema, function (model, key) {
        if (is_included(key, included, excluded)) {
            console.log(
                "Creating IndexedDBModule and RestCacheModule for:" + key,
            );

            const IDBModule = IndexedDBModule(database, model, DEFAULT_FILTER);
            const Module = RestCacheModule(
                root_url + "/api/" + model.name + "/",
                model,
                key + "IDBModule",
            );

            result[key + "IDBModule"] = IDBModule;
            result[key + "Module"] = Module;
        }
    });

    return result;
}

export function CreateRestModules(schema, root_url, included, excluded) {
    var result = {};

    included = included || {};
    excluded = excluded || {};

    _.forEach(schema, function (model, key) {
        if (is_included(key, included, excluded)) {
            console.log("Creating RestModule for:" + key);

            result[key + "Module"] = RestModule(
                root_url + "/api/" + model.name + "/",
                model,
            );
        }
    });

    return result;
}

export function CreateRestIndexedDBModules(
    schema,
    idb_database,
    root_url,
    filters,
    included,
    excluded,
) {
    var result = {};

    included = included || {};
    excluded = excluded || {};

    _.forEach(schema, function (model, key) {
        if (is_included(key, included, excluded)) {
            console.log("Creating RestIndexedDBModule for:" + key);

            result[key + "Module"] = RestIndexedDBModule(
                root_url + "/api/" + model.name + "/",
                model,
                idb_database,
                filters[key],
            );
        }
    });

    return result;
}

export function CreateRestInMemoryModules(
    schema,
    root_url,
    filters,
    included,
    excluded,
) {
    var result = {};

    included = included || {};
    excluded = excluded || {};

    _.forEach(schema, function (model, key) {
        if (is_included(key, included, excluded)) {
            console.log("Creating RestInMemoryModule for:" + key);

            result[key + "Module"] = RestInMemoryModule(
                root_url + "/api/" + model.name + "/",
                model,
                { ...filters["*"], ...filters[key] },
            );
        }
    });

    return result;
}

export function CreateDataEngineModules(
    schema,
    dataServices,
    root_url,
    included,
    excluded,
) {
    var result = {};

    included = included || {};
    excluded = excluded || {};

    _.forEach(schema, function (model, key) {
        if (is_included(key, included, excluded)) {
            console.log("Creating DataEngine for:" + key);

            result[key + "Module"] = DataEngineModule(dataServices[key], model);
        }
    });

    return result;
}
