<template>
    <o-form-group
        :form-state="formState"
        :label="label"
        :message="errorMessages"
        :nolabel="nolabel"
        :type="{ 'is-danger': hasError }"
    >
        <BFormTextarea
            :class="input_class"
            :disabled="_is_readonly"
            :rows="$attrs.rows || 20"
            @update="changed_value"
            v-bind="$attrs"
            v-bind:value="current_value"
        >
            <slot />
        </BFormTextarea>
    </o-form-group>
</template>

<script>
import { BFormTextarea } from "bootstrap-vue";
import FormComponentMixin from "../../../mixins/form_component_mixin.js";
import OFormGroup from "../OFormGroup.vue";

/* Edit a JSON value in a text area  - Parses + stringifies the value */
export default {
    name: "OJsonTextArea",
    components: {
        BFormTextarea,
        OFormGroup,
    },
    mixins: [FormComponentMixin],
    methods: {
        changed_value(new_value) {
            try {
                this.on_change(JSON.parse(new_value));
            } catch (e) {
                this.serverErrors = { [this.name]: ["Invalid JSON"] };
            }
        },
    },
    computed: {
        current_value() {
            if (typeof this.value === "string" || this.value instanceof String)
                return this.value; // if a string return as is-- just incase..

            return JSON.stringify(this.value);
        },
    },
};
</script>

<style scoped></style>
