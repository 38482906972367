<template>
  <div>
    <h3 @click="enableDebug($event)" class="display-4">
      Manage Customer Statuses
    </h3>

    <o-list-toolbar
      :filters-list="filter_list"
      :list-options="list_options"
      :new-to="{ name: 'code_customer_status.create', params: { id: 0 } }"
      :query-select-options="options"
      :schema="schema"
      :search-fields-list="search_field_list"
      :selected-filter="selected_filter"
      @filter_changed="update_filter($event)"
      @options_changed="update_table($event)"
      @reset_table="reset_table"
      new-button-text="New Customer Status"
    >
      <template slot="middle">
        <o-list-multi-actions
          :schema="schema"
          :selection="selected"
          @delete="doDeleteItems"
          @merge="doMergeItems"
          enable-delete
          enable-merge
        />
      </template>
    </o-list-toolbar>

    <b-table
      :fields="fields"
      :items="items"
      :sort-by="list_options.sort"
      :sort-desc="list_options.order === 'desc'"
      @row-selected="onRowSelected"
      @sort-changed="change_sort"
      bordered
      class="o-list"
      head-variant="light"
      no-local-sorting
      selectable
      striped
      thead-class="o-gradient-header"
    >
      <template v-slot:cell(_selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
      <template
        slot="top-row"
        slot-scope="{ columns, fields }"
        v-if="filterRow"
      >
        <td :key="field.key" class="p-0" v-for="field in fields">
          <span v-if="field.key === 'active_end_date'">
            <o-flatpickr-wrapper
              @change="filter_changed"
              class="rounded-0 px-0"
              no-buttons
              v-model="list_options.qbe.active_end_date"
            ></o-flatpickr-wrapper>
          </span>
          <span v-if="field.key === 'active_start_date'">
            <o-flatpickr-wrapper
              @change="filter_changed"
              class="rounded-0 px-0"
              no-buttons
              v-model="list_options.qbe.active_start_date"
            ></o-flatpickr-wrapper>
          </span>
          <span v-if="field.key === 'code'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.code"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'color'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.color"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'created_date'">
            <o-flatpickr-wrapper
              @change="filter_changed"
              class="rounded-0 px-0"
              no-buttons
              v-model="list_options.qbe.created_date"
            ></o-flatpickr-wrapper>
          </span>
          <span v-if="field.key === 'description'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.description"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'is_end'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.is_end"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'modified_date'">
            <o-flatpickr-wrapper
              @change="filter_changed"
              class="rounded-0 px-0"
              no-buttons
              v-model="list_options.qbe.modified_date"
            ></o-flatpickr-wrapper>
          </span>
          <span v-if="field.key === 'original_id'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.original_id"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'quick_display_order'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.quick_display_order"
            ></b-form-input>
          </span>
        </td>
      </template>
      <template slot="cell(active_end_date)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'code_customer_status.edit', params: { id: item.id } }"
          :when="first_column_key === 'active_end_date'"
        >
          <date-display
            :value="item.active_end_date"
            format="DD/MM/YYYY hh:mm"
          ></date-display>
        </o-link-cell>
      </template>
      <template slot="cell(active_start_date)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'code_customer_status.edit', params: { id: item.id } }"
          :when="first_column_key === 'active_start_date'"
        >
          <date-display
            :value="item.active_start_date"
            format="DD/MM/YYYY hh:mm"
          ></date-display>
        </o-link-cell>
      </template>
      <template slot="cell(code)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'code_customer_status.edit', params: { id: item.id } }"
          :when="first_column_key === 'code'"
        >
          <default-display :value="item.code"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(color)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'code_customer_status.edit', params: { id: item.id } }"
          :when="first_column_key === 'color'"
        >
          <display-color :value="item.color"></display-color>
        </o-link-cell>
      </template>
      <template slot="cell(created_date)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'code_customer_status.edit', params: { id: item.id } }"
          :when="first_column_key === 'created_date'"
        >
          <date-display
            :value="item.created_date"
            format="DD/MM/YYYY hh:mm"
          ></date-display>
        </o-link-cell>
      </template>
      <template slot="cell(description)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'code_customer_status.edit', params: { id: item.id } }"
          :when="first_column_key === 'description'"
        >
          <default-display :value="item.description"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(is_end)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'code_customer_status.edit', params: { id: item.id } }"
          :when="first_column_key === 'is_end'"
        >
          <boolean-display :value="item.is_end"></boolean-display>
        </o-link-cell>
      </template>
      <template slot="cell(modified_date)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'code_customer_status.edit', params: { id: item.id } }"
          :when="first_column_key === 'modified_date'"
        >
          <date-display
            :value="item.modified_date"
            format="DD/MM/YYYY hh:mm"
          ></date-display>
        </o-link-cell>
      </template>
      <template slot="cell(original_id)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'code_customer_status.edit', params: { id: item.id } }"
          :when="first_column_key === 'original_id'"
        >
          <number-display :decimals="0" :value="item.original_id" />
        </o-link-cell>
      </template>
      <template slot="cell(quick_display_order)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'code_customer_status.edit', params: { id: item.id } }"
          :when="first_column_key === 'quick_display_order'"
        >
          <number-display :decimals="0" :value="item.quick_display_order" />
        </o-link-cell>
      </template>

      <template slot="empty">
        <p>
          <emoticon-sad-icon large></emoticon-sad-icon>
        </p>
        No Data Found. Please
        <router-link :to="{ name: 'code_customer_status.create' }"
          >create a new item</router-link
        >
      </template>
    </b-table>

    <o-list-paging
      :limit="list_options.limit"
      :offset="list_options.offset"
      :page-options="rows_per_page_items"
      :total-items="total_items"
      @change-limit="change_limit"
      @change-page="change_page"
    />
  </div>
</template>

<style></style>

<script>
import {
  CreateGroupPermissionMixin,
  CreateListPageMixin,
  MultiActionsMixin,
} from "../../../vue-ocom/mixins";
import FetchAndUseCodeModMixin from "@/vue-ocom/mixins/FetchAndUseCodeModMixin";
import {
  fieldToTableColumn,
  GetSearchFields,
} from "../../../vue-ocom/schema/schema_utils";
import { cleanUpModel } from "../../../vue-ocom/schema/schema_vuetify";
import schema from "../../schema/index";

import OListToolbar from "../../../vue-ocom/components/list/OListToolbar";
import OListPaging from "../../../vue-ocom/components/list/OListPaging";
import DefaultDisplay from "../../../vue-ocom/components/display/DefaultDisplay";
import DateDisplay from "../../../vue-ocom/components/display/DateDisplay";
import NumberDisplay from "../../../vue-ocom/components/display/NumberDisplay";
import BooleanDisplay from "../../../vue-ocom/components/display/BooleanDisplay";
import LookupDisplay from "../../../vue-ocom/components/display/LookupDisplay";
import EmoticonSadIcon from "vue-material-design-icons/EmoticonSad";
import EmailIcon from "vue-material-design-icons/Email";
import LinkIcon from "vue-material-design-icons/Link";
import DisplayColor from "../../../components/DisplayColor";

export default {
  components: {
    DisplayColor,
    EmoticonSadIcon,
    EmailIcon,
    LinkIcon,
    OListPaging,
    OListToolbar,
    DateDisplay,
    DefaultDisplay,
    BooleanDisplay,
    NumberDisplay,
    LookupDisplay,
  },
  mixins: [
    CreateListPageMixin("CodeCustomerStatus", "code_customer_status"),
    MultiActionsMixin,
    CreateGroupPermissionMixin("CodeCustomerStatus"),
    FetchAndUseCodeModMixin([]),
  ],
  data: function () {
    return {
      schemaModel: cleanUpModel(
        schema.CodeCustomerStatus,
        {}
      ) /*Use this.schema instead */,

      search_field_list: GetSearchFields(schema.CodeCustomerStatus),

      filterRow: true /* Set to False to disable filter row */,
      filter_list: [
        { name: "Active", code: "active", options: { offset: 0, sort: "id" } },
        {
          name: "Non Active",
          code: "inactive",
          options: { offset: 0, sort: "id" },
        },
        { name: "All", code: "all", options: { offset: 0, sort: "id" } },
      ],

      user_column_selection: null /* If user can customise the columns set them here */,
      column_override_params: {
        /* Put any column overrides here key by fieldname */
      },
    };
  },
  computed: {
    options() {
      return {};
    },
    fields() {
      const vm = this;
      const selectedColumns = this.user_column_selection || [
        "description",
        "code",
        "color",
        "quick_display_order",
        "active_start_date",
        "active_end_date",
      ];
      const fields = selectedColumns.map(function (item) {
        return fieldToTableColumn(
          vm.schema,
          item,
          vm.column_override_params[item]
        );
      });

      return this.addSelectionColums(
        this.filterTableColumns(this.schema, fields)
      );
    },
    first_column_key() {
      if (this.fields) {
        return this.fields[0].key;
      } else {
        return null;
      }
    },
  },
};
</script>
