<template>
    <div class="row" v-if="!isLoggedIn">
        <div class="col-sm-12 col-lg-6 offset-sm-0 offset-lg-3">
            <form
                @submit="onSubmit"
                action=""
                class="form-horizontal"
                method="post"
                name="form"
                novalidate=""
                ref="loginForm"
            >
                <div class="modal-dialog" v-if="mode === 0">
                    <div class="modal-content">
                        <div class="modal-header card-header">
                            <h5 class="modal-title">Login</h5>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <ocom-qa-warning />
                            </div>
                            <div class="row">
                                <div class="col-sm-3 text-center">
                                    <card-account-details-outline-icon
                                        class="largeIcon"
                                    ></card-account-details-outline-icon>
                                </div>
                                <div class="col-sm-9">
                                    <div class="form-group">
                                        <label for="username">User name</label>
                                        <input
                                            autocomplete="username"
                                            class="form-control"
                                            id="username"
                                            maxlength="147"
                                            placeholder="Enter Username"
                                            required
                                            type="text"
                                            v-model="user.username"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label for="password">Password</label>
                                        <input
                                            autocomplete="current-password"
                                            class="form-control"
                                            id="password"
                                            maxlength="147"
                                            placeholder="Password"
                                            required
                                            type="password"
                                            v-model="user.password"
                                        />
                                        <a
                                            @click.prevent="forgottenMode"
                                            href=""
                                            style="
                                                float: right;
                                                cursor: pointer;
                                            "
                                            >Forgot Password
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer card-header">
                            <button
                                @click.prevent="onSubmit"
                                class="btn btn-primary"
                                type="submit"
                            >
                                Login
                            </button>
                        </div>
                    </div>
                </div>

                <div class="modal-dialog" v-if="mode === 1">
                    <div class="modal-content">
                        <div class="modal-header card-header">
                            <h5 class="modal-title">Forgot Password</h5>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-sm-3 text-center">
                                    <card-account-details-outline-icon
                                        class="largeIcon"
                                    ></card-account-details-outline-icon>
                                </div>
                                <div class="col-sm-9">
                                    <div class="form-group">
                                        <label for="username"
                                            >User name/Email</label
                                        >
                                        <input
                                            autocomplete="username"
                                            class="form-control"
                                            id="username"
                                            maxlength="147"
                                            placeholder="Enter Username"
                                            required
                                            type="text"
                                            v-model="user.username"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer card-header">
                            <a
                                @click.prevent="onSendEmail"
                                class="btn btn-primary"
                            >
                                Send Reset Email
                            </a>
                        </div>
                    </div>
                </div>

                <div class="modal-dialog" v-if="mode === 2">
                    <div class="modal-content">
                        <div class="modal-header card-header">
                            <h5 class="modal-title">Verify Passcode</h5>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-sm-3 text-center">
                                    <card-account-details-outline-icon
                                        class="largeIcon"
                                    ></card-account-details-outline-icon>
                                </div>
                                <div class="col-sm-9">
                                    <div class="form-group">
                                        <label for="username"
                                            >User name/Email</label
                                        >
                                        <input
                                            autocomplete="username"
                                            class="form-control"
                                            id="username"
                                            maxlength="147"
                                            placeholder="Enter Username"
                                            required
                                            type="text"
                                            v-model="user.username"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label for="passcode">Passcode</label>
                                        <input
                                            class="form-control"
                                            id="passcode"
                                            maxlength="147"
                                            placeholder="Passcode from Email"
                                            required
                                            type="text"
                                            v-model="key"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer card-header">
                            <a
                                @click.prevent="onSendEmail"
                                class="btn btn-primary"
                            >
                                Resend Email
                            </a>
                            <a
                                @click.prevent="onVerify"
                                class="btn btn-success"
                            >
                                Verify Passcode
                            </a>
                        </div>
                    </div>
                </div>

                <div class="modal-dialog" v-if="mode === 3">
                    <div class="modal-content">
                        <div class="modal-header card-header">
                            <h5 class="modal-title">Change Password</h5>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-sm-3 text-center">
                                    <card-account-details-outline-icon
                                        class="largeIcon"
                                    ></card-account-details-outline-icon>
                                </div>
                                <div class="col-sm-9">
                                    <div class="form-group">
                                        <label for="passcode"
                                            >New Password</label
                                        >
                                        <password
                                            v-model="newPassword1"
                                            :toggle="true"
                                            @score="showScore"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label for="passcode">
                                            Verify New Password</label
                                        >
                                        <password
                                            v-model="newPassword2"
                                            :toggle="true"
                                            @score="showScore"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer card-header">
                            <a @click.prevent="onCancel" class="btn btn-danger">
                                Cancel
                            </a>
                            <button
                                @click.prevent="onChangePassword"
                                class="btn btn-success"
                            >
                                Change Password
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CardAccountDetailsOutlineIcon from "vue-material-design-icons/CardAccountDetailsOutline.vue";
import * as CONFIG from "../../../config.js";
import axios from "axios";
import Password from "vue-password-strength-meter";
import {
    changePassword,
    verifyPasscode,
} from "@/vue-ocom/auth/store/actions.js";
import Ocom from "../../components/ocom/Ocom.vue";
import OcomQaWarning from "../../components/ocom/QAWarning.vue";

export default {
    components: {
        OcomQaWarning,
        Ocom,
        CardAccountDetailsOutlineIcon,
        Password,
    },
    props: {},
    data() {
        return {
            // the current data entered into the form
            user: {
                username: "",
                password: "",
            },
            key: "",
            dialog: {},
            mode: 0,
            newPassword1: "",
            newPassword2: "",
            login: null,
            passwordScore: "",
        };
    },
    computed: {
        ...mapGetters("AuthModule", ["isLoggedIn"]),
        _se() {
            // Return an empty Object if there are no Server Errors to avoid Binding Errors
            return this.serverErrors || {};
        },
        isDisabled() {
            if (this.newPassword1 <= 8 && this.newPassword2 <= 8) {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        ...mapActions("AuthModule", [
            "forgotPassword",
            "verifyPasscode",
            "changePassword",
            "doLogin",
        ]),
        forgottenMode() {
            this.mode = 1;
            this.key = ""; // clear it
        },

        onChangePassword() {
            const vm = this;
            console.log(vm.passwordScore);
            if (vm.passwordScore === 4) {
                if (vm.newPassword1 == vm.newPassword2) {
                    vm.changePassword({
                        password: vm.newPassword1,
                        ...vm.login,
                    }).then(function () {
                        // we're already logged in no need to call onSubmit again
                        vm.$router.push({ name: "home" });
                    });
                } else {
                    alert("Password don't match - Please check and try again");
                }
            } else {
                vm.warningMessage(
                    "warning",
                    "Password strength should be color green",
                );
            }
        },
        showScore(score) {
            const vm = this;
            vm.passwordScore = score;
        },
        onVerify() {
            const vm = this;

            this.verifyPasscode({
                username: this.user.username,
                key: this.key,
            }).then(function (login) {
                if (login.status === true) {
                    vm.login = login;
                    vm.mode = 3; // back to start
                } else if (login === 400) {
                    vm.warningMessage("danger", "Wrong passcode");
                }
            });
        },
        onSendEmail() {
            if (this.user.username) {
                this.forgotPassword({ username: this.user.username })
                    .then(() => {
                        this.mode = 2;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                this.warningMessage(
                    "warning",
                    "Please put in a username or email",
                );
            }
        },
        onSubmit() {
            const vm = this;
            if (vm.mode == 0) {
                this.$emit("submitted", this.user);
            }
        },

        onCancel() {
            this.$emit("cancelled");
        },

        warningMessage(variant = null, message) {
            this.$bvToast.toast(message, {
                title: `Warning`,
                variant: variant,
                solid: true,
            });
        },
    },
};
</script>
<style>
.largeIcon {
    width: 100px !important;
    height: 100px !important;
}

.largeIcon > .material-design-icon__svg {
    width: 100px !important;
    height: 100px !important;
}
</style>
