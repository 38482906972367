<template>
    <o-form-wrapper
        :form-state="formState"
        :schema="schema"
        :server-errors="_se"
        :v="$v.item"
        v-model="item"
    >
        <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="app_label"
        >
            <o-text-field
                :form-state="formState"
                :server-errors="_se"
                :validators="$v.item"
                name="app_label"
                v-bind="schema.fields.app_label"
                v-model="item.app_label"
            ></o-text-field>
        </o-widget-wrapper>

        <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="model"
        >
            <o-text-field
                :form-state="formState"
                :server-errors="_se"
                :validators="$v.item"
                name="model"
                v-bind="schema.fields.model"
                v-model="item.model"
            ></o-text-field>
        </o-widget-wrapper>
    </o-form-wrapper>
</template>

<script>
import schema from "../../schema/index.js";
import { cleanUpModel } from "../../../schema/schema_vuetify.js";
import {
    CreateCodeTableFetchMixin,
    CreateFormStateMixin,
    CreateGroupPermissionMixin,
    VuelidateMixin,
} from "../../../mixins";
import { validationMixin } from "vuelidate";
import OWidgetWrapper from "../../../components/forms/OWidgetWrapper.vue";
import OTextField from "../../../components/forms/fields/OTextField.vue";
import OSelect from "../../../components/forms/fields/OSelect.vue";
import OCheckbox from "../../../components/forms/fields/OCheckbox.vue";
import OFormWrapper from "../../../components/forms/OFormWrapper.vue";
import ODateTime from "../../../components/forms/fields/ODateTime.vue";

export default {
    mixins: [
        VuelidateMixin,
        validationMixin,
        CreateFormStateMixin("$v"),
        CreateGroupPermissionMixin("ContentType"),
        CreateCodeTableFetchMixin([]),
    ],
    components: {
        OFormWrapper,
        OWidgetWrapper,
        ODateTime,

        OCheckbox,
        OSelect,
        OTextField,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        level: { type: Number, default: 0 },
        inModal: { type: Boolean, default: false },
    },
    data: () => ({
        schemaModel: cleanUpModel(schema.ContentType, {}),
    }),
    methods: {},
    computed: {
        _se() {
            // Return an empty Object if there are no Server Errors to avoid Binding Errors
            return this.serverErrors || {};
        },
    },
    watch: {},
    created() {},
};
</script>
