<template>
  <div>
    <speed-select
      :items="optionCodeProductTypeItems"
      @input="filter_now"
      text-field="code"
      v-model="field_filter.product_type"
    ></speed-select>

    <b-table
      :fields="fields"
      :items="items"
      :sort-by="list_options.sort"
      :sort-desc="list_options.order === 'desc'"
      @row-selected="onRowSelected"
      @sort-changed="change_sort"
      bordered
      class="o-list"
      head-variant="light"
      no-local-sorting
      select-mode="single"
      selectable
      striped
      style="width: 888px"
      thead-class="o-gradient-header"
    >
      <template slot="top-row" slot-scope="{ columns, fields }">
        <td :key="field.key" class="p-0" v-for="field in fields">
          <span v-if="field.key === 'description'">
            <b-form-input
              @change="filter_now"
              class="rounded-0 px-0"
              type="search"
              v-model="field_filter.description"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'product_type'">
            <o-vue-select
              :options="active_product_types"
              :reduce="(o) => o.id"
              @input="filter_now"
              class="product-select-style"
              label="description"
              v-model="field_filter.product_type"
            ></o-vue-select>
          </span>

          <span v-if="field.key === 'model'">
            <b-form-input
              @change="filter_now"
              class="rounded-0 px-0"
              type="search"
              v-model="field_filter.model"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'make'">
            <b-form-input
              @change="filter_now"
              class="rounded-0 px-0"
              type="search"
              v-model="field_filter.make"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'asset_classification'">
            <o-vue-select
              :options="optionCodeAssetClassificationItems"
              :reduce="(o) => o.id"
              @input="filter_now"
              class="product-select-style"
              label="description"
              v-model="field_filter.asset_classification"
            ></o-vue-select>
          </span>

          <span v-if="field.key === 'test_frequency'">
            <o-vue-select
              v-model="field_filter.test_frequency"
              :options="optionCodeTestFrequencyItems"
              :reduce="(o) => o.id"
              class="product-select-style"
              label="description"
              @input="filter_now"
            ></o-vue-select>
          </span>
        </td>
      </template>

      <template slot="cell(description)" slot-scope="{ index, item }">
        <router-link :to="{ name: 'product.edit', params: { id: item.id } }">
          <default-display :value="item.description"></default-display>
        </router-link>
      </template>
      <template slot="cell(product_type)" slot-scope="{ index, item }">
        <lookup-display
          :list="optionCodeProductTypeItemsById"
          :value="item.product_type"
          default-value="-"
          display-field="description"
        ></lookup-display>
      </template>
      <template slot="cell(make)" slot-scope="{ index, item }">
        <default-display :value="item.make"></default-display>
      </template>
      <template slot="cell(model)" slot-scope="{ index, item }">
        <default-display :value="item.model"></default-display>
      </template>
      <template slot="cell(electrical_class)" slot-scope="{ index, item }">
        <lookup-display
          :list="optionCodeElectricalClassItemsById"
          :value="item.electrical_class"
          default-value="-"
          display-field="description"
        ></lookup-display>
      </template>
      <template slot="cell(color)" slot-scope="{ index, item }">
        <lookup-display
          :list="optionCodeColorItemsById"
          :value="item.color"
          default-value="-"
          display-field="description"
        ></lookup-display>
      </template>
      <template slot="cell(quick_display_order)" slot-scope="{ index, item }">
        <number-display :decimals="0" :value="item.quick_display_order" />
      </template>
      <template slot="cell(asset_classification)" slot-scope="{ index, item }">
        <lookup-display
          :list="optionCodeAssetClassificationItemsById"
          :value="item.asset_classification"
          default-value="-"
          display-field="description"
        ></lookup-display>
      </template>
      <template slot="cell(phase)" slot-scope="{ index, item }">
        <lookup-display
          :list="optionCodePhaseItemsById"
          :value="item.phase"
          default-value="-"
          display-field="description"
        ></lookup-display>
      </template>
      <template slot="cell(active_start_date)" slot-scope="{ index, item }">
        <date-display
          :value="item.active_start_date"
          format="DD/MM/YYYY hh:mm"
        ></date-display>
      </template>
      <template slot="cell(active_end_date)" slot-scope="{ index, item }">
        <date-display
          :value="item.active_end_date"
          format="DD/MM/YYYY hh:mm"
        ></date-display>
      </template>

      <template slot="cell(test_frequency)" slot-scope="{ index, item }">
        <lookup-display
          :list="optionCodeTestFrequencyItemsById"
          :value="item.test_frequency"
          default-value="-"
          display-field="description"
        ></lookup-display>
      </template>

      <template slot="empty">
        <p>
          <emoticon-sad-icon large></emoticon-sad-icon>
        </p>
        No Data Found. Please
      </template>
    </b-table>

    <b-button
      v-if="items.length <= 0"
      @click="createNewProduct"
      class="mr-2"
      variant="primary"
    >
      Create New Product
    </b-button>
    <template>
      <div>
        <!-- @ok="updateValue"
          updateValue -->
        <b-modal
          size="lg"
          ref="addNewProductmodal"
          title="Create New Product"
          :hide-footer="true"
        >
          <b-form @submit.stop.prevent="submitNewProduct">
            <div class="d-block text-center">
              <item-form
                :form-state="formState"
                :item="item"
                :server-errors="serverErrors"
              ></item-form>
            </div>
            <br />
            <div style="display: flex; justify-content: flex-end">
              <b-button
                :disabled="creatingNewProduct"
                type="submit"
                variant="primary"
              >
                <b-spinner
                  v-if="creatingNewProduct"
                  small
                  label="Spinning"
                ></b-spinner>
                Save
              </b-button>
            </div>
            <!-- <b-button type="reset" variant="default">Reset</b-button> -->
          </b-form>
        </b-modal>
      </div>
    </template>
    <template>
      <div>
        <!-- @ok="updateValue"
          updateValue -->
        <b-modal
          size="lg"
          ref="updateNewProductmodal"
          title="Update Product"
          :hide-footer="true"
        >
          <b-form @submit.stop.prevent="updateItem">
            <div class="d-block text-center">
              <div class="d-block text-center">
                <b-form-group
                  label="Test Frequency"
                  label-align="right"
                  label-cols="3"
                  label-cols-lg="2"
                >
                  <speed-select
                    :form-state="formState"
                    :items="optionCodeTestFrequencyItems"
                    v-model="test_frequency"
                  ></speed-select>
                </b-form-group>
                <b-form-group
                  label="Class"
                  label-align="right"
                  label-cols="3"
                  label-cols-lg="2"
                >
                  <speed-select
                    :form-state="formState"
                    :items="optionCodeElectricalClassItems"
                    text-field="code"
                    v-model="electrical_class"
                  ></speed-select>
                </b-form-group>
              </div>
            </div>
            <br />
            <div style="display: flex; justify-content: flex-end">
              <b-button
                :disabled="creatingNewProduct"
                type="submit"
                variant="primary"
              >
                <b-spinner
                  v-if="creatingNewProduct"
                  small
                  label="Spinning"
                ></b-spinner>
                Save
              </b-button>
            </div>

            <!-- <b-button type="reset" variant="default">Reset</b-button> -->
          </b-form>
        </b-modal>
      </div>
    </template>
    <o-list-paging
      :limit="list_options.limit"
      :offset="list_options.offset"
      :page-options="rows_per_page_items"
      :total-items="total_items"
      @change-limit="change_limit"
      @change-page="change_page"
    />
  </div>
</template>

<script>
import {
  CreateFormPageMixin,
  CreateGroupPermissionMixin,
  CreateListPageMixin,
} from "../../../vue-ocom/mixins";
import {
  fieldToTableColumn,
  GetSearchFields,
} from "../../../vue-ocom/schema/schema_utils";
import { cleanUpModel } from "../../../vue-ocom/schema/schema_vuetify";
import schema from "../../schema/index";

import "vue-select/dist/vue-select.css";

import OListToolbar from "../../../vue-ocom/components/list/OListToolbar";
import OListPaging from "../../../vue-ocom/components/list/OListPaging";
import DefaultDisplay from "../../../vue-ocom/components/display/DefaultDisplay";
import DateDisplay from "../../../vue-ocom/components/display/DateDisplay";
import NumberDisplay from "../../../vue-ocom/components/display/NumberDisplay";
import LookupDisplay from "../../../vue-ocom/components/display/LookupDisplay";
import { object_to_and_query } from "../../../vue-ocom/query/expression";
import OVueSelect from "../../../components/OVueSelect";
import SpeedSelect from "../../../components/SpeedSelect";
import { filter_active_list } from "vue-ocom/utils/active";
import { mapActions, mapMutations, mapGetters } from "vuex";
import ItemForm from "../components/ItemForm.vue";

import UseCodeModulesMixin from "@/vue-ocom/mixins/UseCodeModulesMixin";

export default {
  name: "SelectProduct",
  components: {
    SpeedSelect,
    OVueSelect,
    OListPaging,
    OListToolbar,
    DateDisplay,
    DefaultDisplay,
    NumberDisplay,
    LookupDisplay,
    ItemForm,
  },
  mixins: [
    CreateListPageMixin("Product", "product"),
    CreateFormPageMixin("Product", "product"),
    CreateGroupPermissionMixin("Product"),

    UseCodeModulesMixin([
      "CodeProductType",
      "CodeTestFrequency",
      "CodeElectricalClass",
      // 'CodeColor',
      // 'CodeAssetClassification',
      // 'CodePhase'
    ]),
  ],
  data: function () {
    return {
      electrical_class: 0,
      test_frequency: 0,
      creatingNewProduct: false,
      schemaModel: cleanUpModel(
        schema.Product,
        {}
      ) /*Use this.schema instead */,

      search_field_list: GetSearchFields(schema.Product),

      list_options: {
        //Filter
        filter: "all",
        query: null,

        // Searching
        q: "",
        searchField: "",
        qbe: {},

        //Pagination-specific
        offset: 0,
        limit: 10,
        // Sort
        sort: "quick_display_order,make",
        order: "asc",
      },

      field_filter: {},
      fieldItems: {},
    };
  },
  methods: {
    ...mapMutations(["showMessage"]),
    ...mapActions("ProductModule", ["saveItem", "createNew"]),
    ...mapGetters({
      token: "AuthModule/token",
      isLoggedIn: "AuthModule/isLoggedIn",
      currentUser: "AuthModule/currentUser",
    }),
    showMessageInfo(message, type) {
      this.showMessage({
        message: message,
        type: type,
        show: true,
      });
    },
    submitNewProduct() {
      if (!this.item.product_type) {
        this.showMessageInfo("Don't forget to select product type", "danger");
        return;
      }

      if (!this.item.electrical_class) {
        this.showMessageInfo(
          "Don't forget to select electrical class",
          "danger"
        );
        return;
      }

      if (!this.item.asset_classification) {
        this.showMessageInfo(
          "Don't forget to select asset clarification",
          "danger"
        );
        return;
      }

      if (!this.item.test_frequency) {
        this.showMessageInfo("Don't forget to select test frequency", "danger");
        return;
      }

      if (!this.item.phase) {
        this.showMessageInfo("Don't forget to select phase", "danger");
        return;
      }
      this.creatingNewProduct = true;

      this.saveItem(this.item)
        .then(async (res) => {
          this.resetItem();
          // console.log("submitNewProduct", res);
          this.items.push(res);
          // this.optionServiceExtraItems.unshift(res);
          this.showMessageInfo("Successfully created new product", "success");
          this.$refs["addNewProductmodal"].hide();

          this.$emit("changed", res);
          this.creatingNewProduct = false;
        })
        .catch((error) => {
          this.creatingNewProduct = false;
          this.showMessageInfo("Something went wrong", "danger");
          this.$refs["addNewProductmodal"].hide();

          console.log(error);
        });
    },
    resetItem() {
      this.item.product_type = null;
      this.item.make = "";
      this.item.model = null;
      this.item.electrical_class = null;
      this.item.color = null;
      this.item.asset_classification = null;
      this.item.phase = null;
      this.item.description = "";
      this.item.test_frequency = null;
    },
    async createNewProduct() {
      const vm = this;
      this.createNew().then(function (item) {
        vm.item = item;

        // Set some defaults
        vm.item.product_type = vm.field_filter.product_type
          ? vm.field_filter.product_type
          : null;
        vm.item.make = vm.field_filter.make ? vm.field_filter.make : "";
        vm.item.description = vm.field_filter.make ? vm.field_filter.make : "";

        vm.field_filter.description = vm.field_filter.make;

        vm.$refs["addNewProductmodal"].show();
      });
    },
    updateItem() {
      if (!this.test_frequency) {
        this.showMessageInfo("Don't forget to select test frequency", "danger");
        return;
      }
      if (!this.electrical_class) {
        this.showMessageInfo("Don't forget to select class", "danger");
        return;
      }
      this.fieldItems.electrical_class = this.electrical_class;
      this.fieldItems.test_frequency = this.test_frequency;
      this.creatingNewProduct = true;

      this.saveItem(this.fieldItems)
        .then(async (res) => {
          // this.items.push(res);
          // // this.optionServiceExtraItems.unshift(res);
          // this.showMessageInfo("Successfull update product", "success");
          // this.$refs["updateNewProductmodal"].hide();
          const index = this.items.findIndex((item) => {
            return res.id === item.id;
          });

          this.$emit("changed", this.fieldItems);
          // this.items.splice(index, 1, res);
          this.creatingNewProduct = false;

          this.test_frequency = 0;
          this.electrical_class = 0;
          this.$refs["updateNewProductmodal"].hide();
          this.update_table(this.list_options);
        })
        .catch((error) => {
          this.creatingNewProduct = false;
          this.showMessageInfo("Something went wrong", "danger");
          this.$refs["updateNewProductmodal"].hide();

          console.log(error);
        });
    },
    onRowSelected(row) {
      if (row.length === 1) {
        const item = row[0];
        if (!item.test_frequency || !item.electrical_class) {
          this.fieldItems.id = item.id;
          this.fieldItems.test_frequency = item.test_frequency;
          this.fieldItems.electrical_class = item.electrical_class;
          this.fieldItems.who = this.currentUser.id;
          this.fieldItems.asset_classification = item.asset_classification;
          this.fieldItems.color = item.color;
          this.fieldItems.phase = item.phase;
          this.fieldItems.product_type = item.product_type;
          // this.$emit("checkData", item);
          // console.log("row", row);
          this.$refs["updateNewProductmodal"].show();
        } else {
          this.$emit("changed", row[0]);
        }
      }
    },
    filter_now() {
      this.list_options.query = object_to_and_query(this.field_filter);

      this.update_table(this.list_options);
    },
  },
  computed: {
    checkItemsLength() {
      return this.items;
    },
    options() {
      return {
        CodeProductType: {
          options: this.optionCodeProductTypeItems,
          textField: "description",
          valueField: "id",
        },
        CodeTestFrequency: {
          options: this.optionCodeTestFrequencyItems,
          textField: "description",
          valueField: "id",
        },
        // 'CodeElectricalClass': {
        //   'options': this.optionCodeElectricalClassItems,
        //   'textField': 'description',
        //   'valueField': 'id',
        // },
        // 'CodeColor': {'options': this.optionCodeColorItems, 'textField': 'description', 'valueField': 'id'},
        // 'CodeAssetClassification': {
        //   'options': this.optionCodeAssetClassificationItems,
        //   'textField': 'description',
        //   'valueField': 'id',
        // },
        // 'CodePhase': {'options': this.optionCodePhaseItems, 'textField': 'description', 'valueField': 'id'},
      };
    },
    fields() {
      return this.filterTableColumns(this.schema, [
        fieldToTableColumn(this.schema, "product_type"),
        fieldToTableColumn(this.schema, "make"),
        // fieldToTableColumn(this.schema, 'model'),
        fieldToTableColumn(this.schema, "test_frequency"),
      ]);
    },
    active_product_types() {
      return filter_active_list(this.optionCodeProductTypeItems);
    },
  },
  watch: {
    checkItemsLength() {
      if (this.items.length <= 0) {
        this.item.product_type = this.field_filter.product_type
          ? this.field_filter.product_type
          : null;
        this.item.make = this.field_filter.make ? this.field_filter.make : "";
        this.item.description = this.field_filter.make
          ? this.field_filter.make
          : "";
      }
      // alert(this.items.length)
    },
  },
  created() {},
};
</script>

<style scoped>
.o-loader {
  position: absolute;
  margin-top: 0;
  margin-bottom: 0;
}

.action-buttons {
  background-color: silver;
  padding: 7px;
  bottom: 18px;
  /*position: fixed;*/
  /*bottom: 0px;*/
  /*left: 0px;*/
  width: 100%;
  z-index: 4;
}
.product-select-style {
  min-height: 33.1875px;
}

.product-select-style .vs__selected {
  height: 24px;
  white-space: nowrap;
  overflow: hidden;
}

.product-select-style .vs__search::placeholder,
.product-select-style .vs__dropdown-toggle,
.product-select-style .vs__dropdown-menu {
  background: #ffffff;
  color: #404040;
}

.product-select-style .vs__dropdown-toggle {
  height: 33px;
}

.product-select-style .vs__clear,
.product-select-style .vs__open-indicator {
  color: #404040;
}
</style>
