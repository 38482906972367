import Vue from "vue";

import * as TYPES from "./mutations-types.js";

import NProgress from "nprogress";
import { isAuthDisabled } from "../services.js";

var currentInterceptor = null;
var tokenwas = null;

export default (store) => {
    // called when the store is initialized
    store.watch((state) => {
        var token = state.AuthModule.token;

        if (tokenwas != token) {
            // Token changeds/set
            tokenwas = token;

            if (!isAuthDisabled()) {
                Vue.axios.defaults.headers = {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${token}`,
                };
            }
        }
    });

    /* Used to check for Authentication errors. Will update state if the authentication fails */

    if (currentInterceptor != null) {
        Vue.axios.interceptors.response.eject(currentInterceptor);
        currentInterceptor = null;
    }

    currentInterceptor = Vue.axios.interceptors.response.use(
        function (response) {
            NProgress.done();
            return response; // pass through!
        },
        function (error) {
            NProgress.done();
            if (error.response && error.response.status === 403) {
                // authentication errror
                if (
                    error.response.data &&
                    error.response.data.detail === "Signature has expired."
                ) {
                    store.commit("AuthModule/" + TYPES.TOKEN_EXPIRED);
                }
            }
            return Promise.reject(error);
        },
    );
};
