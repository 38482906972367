export const MUTATION_TYPES = {
    SET_LIST: "SET_LIST",
    CLEAR_LIST: "CLEAR_LIST",
    SET_ITEM: "SET_ITEM",
    START_WORKING: "START_WORKING",
    FINISH_WORKING: "FINISH_WORKING",
    TEMPLATE: "TEMPLATE",
    PATCH_ITEM: "PATCH_ITEM",
    STORE_LIST_OPTIONS: "STORE_LIST_OPTIONS",
};

export const ACTION_TYPES = {
    CREATE_NEW: "createNew",
    GET_ALL: "getAll",
    GET_BY_ID: "getById",
    SAVE_ITEM: "saveItem",
    PATCH_ITEM: "patchItem",
    DELETE_ITEM: "deleteItem",
    MERGE_ITEMS: "mergeItems",
    REFRESH: "refresh",
};

export const GETTER_TYPES = {
    ITEM: "item",
    WORKING: "working",
    ITEMS: "items",
    ITEMS_BY_ID: "itemsByID",
    CODE_ITEMS: "code_items",
    CODE_ITEMS_BY_ID: "code_itemsByID",
    TOTAL_ITEMS: "total_items",
};
