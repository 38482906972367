import { Field, FIELD_TYPES, Model } from "../../vue-ocom/schema";

export default Model("asset_file", {
  verbose_name: "Asset File",
  verbose_name_plural: "Asset Files",
  fields: [
    Field("id", {
      label: "ID",
      primary_key: true,
      readonly: true,
      hide: false,
      field_type: FIELD_TYPES.AutoNumber,
    }),

    Field("file_type", {
      label: "File Type",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodeFileType",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field("file", {
      label: "File",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "filesystem.FileMetaData",
      ref_display_field: "original_filename",
      default: () => {
        return null;
      },
    }),
    Field("created_user", {
      label: "Created User",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "auth.User",
      ref_display_field: "username",
      default: () => {
        return null;
      },
    }),
    Field("other_name", {
      label: "Other Name",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return "";
      },
    }),
    Field("other_id", {
      label: "Other ID",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      },
    }),
    Field("created_date", {
      label: "Created Date",
      primary_key: false,
      readonly: true,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("modified_date", {
      label: "Modified Date",
      primary_key: false,
      readonly: true,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
  ],
});
