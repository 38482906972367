/* eslint-disable no-unused-vars */
import _ from "lodash";
import { FIELD_TYPES } from "./index.js";
import {
    email,
    maxLength,
    minLength,
    required,
} from "vuelidate/lib/validators";
import { cleanUpModel } from "./schema_vuetify.js";
import schema from "../../app/schema/index";

export function CreateDefaultItem(model) {
    "use strict";

    var result = {};

    if (schema) {
        _.each(model.fields, function (field) {
            var defaultValue = field.default;

            if (_.isFunction(defaultValue)) {
                defaultValue = defaultValue(); //Call the function and get the result.
            }

            result[field.name] = defaultValue;
        });
    }

    return _.clone(result); // clone just incase the default where arrays or objects
}

export function SetDefaults(model, item) {
    if (schema) {
        _.each(model.fields, function (field) {
            var defaultValue = field.default;

            if (_.isFunction(defaultValue)) {
                defaultValue = defaultValue(); //Call the function and get the result.
            }

            if (!(field.name in item)) {
                item[field.name] = defaultValue;
            }
        });
    }

    return item;
}

function _getBasicValidation(field) {
    // Generate validations object for schema - and make sure to recurse through its children (M2M / Has FK)
    let result = {};

    if (field.required) {
        result["required"] = required;
    }
    if (field.maxlength) {
        result["maxlength"] = maxLength(field.maxlength);
    }
    if (field.minlength) {
        result["minlength"] = minLength(field.minlength);
    }
    if (field.type === "email") {
        result["email"] = email;
    }
    return result;
}

export function VuelidateValidation(model) {
    var result = {};

    if (schema && model && "fields" in model) {
        _.each(model.fields, function (field) {
            if (field.readonly || field.hide || field.primary_key) {
                return;
            }
            result[field.name] = _getBasicValidation(field);

            if (field.field_type === FIELD_TYPES.ManyToMany) {
                if (field.keys_only === true) {
                    return;
                }

                let childSchema = cleanUpModel(
                    schema[field.ref_field_type],
                    {},
                );

                // Add validations in for Other Schema as a sub collection
                // see https://monterail.github.io/vuelidate/#sub-collections-validation
                result[field.name]["$each"] = VuelidateValidation(childSchema);
            }
        });
    }

    return result;
}

export function GetSearchFields(model, schema) {
    var result = [{ text: "-- All --", value: "" }];

    function collect_fields(label_prefix, code_prefix, fields, result) {
        _.each(fields, function (field) {
            if (field.field_type === FIELD_TYPES.String) {
                result.push({
                    text: label_prefix + field.label,
                    value: code_prefix + field.name,
                });
            }

            if (field.field_type === FIELD_TYPES.ForeignKey) {
                if (schema) {
                    var model_schema = schema[field.ref_field_type];

                    if (model_schema) {
                        collect_fields(
                            field.label + " : ",
                            field.name + "__",
                            model_schema.fields,
                            result,
                        );
                    }
                }
            }
        });
    }

    collect_fields("", "", model.fields, result);

    return result;
}

export function fieldToTableColumn(model, field_name, override_params) {
    var field = model.fields[field_name];

    // Use params to override the values in the field
    override_params = override_params || {};

    if (field) {
        return _.extend(
            { key: field_name, label: field.label, sortable: true },
            override_params,
        );
    } else {
        return null;
    }
}

export function cloneItem(item, modelName) {
    var newItem = _.cloneDeep(item);

    function cleanUpPKs(item, schemaModel) {
        if (item && _.isObject(item)) {
            if ("pk" in item) {
                delete item.pk; // remove ok if it exists
            }

            _.forEach(schemaModel.fields, function (field) {
                if (field.primary_key) {
                    delete item[field.name];
                }

                if (field.field_type === FIELD_TYPES.ManyToMany) {
                    var manySchema = schema[field.ref_field_type];

                    if (manySchema) {
                        _.forEach(item[field.name], function (i) {
                            cleanUpPKs(i, manySchema); // Do each Many to Many Item
                        });
                    }
                }
            });
        }
    }

    var modelSchema = schema[modelName];

    cleanUpPKs(newItem, modelSchema);

    return newItem;
}
