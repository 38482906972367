/* eslint-disable no-unused-vars */
import Vue from "vue";
import { CreateDefaultItem } from "../schema/schema_utils.js";
import _ from "lodash";
import { TOKEN_EXPIRED } from "../auth/store/mutations-types.js";
import { ACTION_TYPES, GETTER_TYPES, MUTATION_TYPES } from "./store_keys.js";
import {
    calculateFields,
    calculateFieldsInlist,
    removeVirtualFields,
} from "../schema/store_utils.js";
import { filter_list, prepareExpression } from "../query/expression.js";
import { normaliseOptions } from "./store_utils.js";
import { refresh } from "./CommonActions";
import { store_list_options } from "./CommonMutations";
import moment from "moment";

function handleError(err, commit) {
    if (
        err &&
        err.response &&
        "status" in err.response &&
        err.response.status === 401
    ) {
        // We are no longer logged in!

        commit("AuthModule/" + TOKEN_EXPIRED, null, { root: true });
    }
}

// ie RestIndexedDBModule("http://localhost:8000/api/code_example/", schema.CodeModel, indexedDB_database, filters);

export function RestInMemoryModule(api, schema, filters) {
    if (!filters) {
        filters = {};
    }

    /* Ensure it's all functions now  - Parse the Query Expressions*/
    _.forEach(filters, function (value, key) {
        if (!_.isFunction(value)) {
            filters[key] = prepareExpression(value).evaluate;
        }
    });

    function logMe(event, value) {
        // if (schema.name=='asset_location') {
        //     console.log(schema.name + ":" + event, value);
        // }
    }

    return {
        namespaced: true,
        state: {
            current_id: null,
            item: null,

            table: {},
            max_last_modified: null,
            items_list_options: { filter: "all" },
            items: [],
            itemsByID: {},

            code_items: [],
            code_itemsByID: {},

            total_items: 0,
            working: false,

            load_list_options: { filter: "all" },
            preloaded: false,
            is_preloading: false,
            last_list_options: null,
            last_load: null,
        },
        mutations: {
            [MUTATION_TYPES.STORE_LIST_OPTIONS]: store_list_options,
            [MUTATION_TYPES.SET_LIST](state, items) {
                logMe("Mutation:" + MUTATION_TYPES.SET_LIST, items);

                state.total_items = items.total_items;
                if ("items" in items) {
                    calculateFieldsInlist(schema, items.items);
                    state.items = items.items;

                    let defaultItem = { [null]: "" };
                    let keyByItems = _.keyBy(items.items, "id");
                    state.itemsByID = { ...keyByItems, ...defaultItem };
                }
                if ("code_items" in items) {
                    calculateFieldsInlist(schema, items.code_items);
                    state.code_items = items.code_items;

                    let defaultItem = { [null]: "" };
                    let keyByItems = _.keyBy(items.code_items, "id");
                    state.code_itemsByID = { ...keyByItems, ...defaultItem };
                }
            },

            [MUTATION_TYPES.CLEAR_LIST](state) {
                logMe("Mutation:" + MUTATION_TYPES.CLEAR_LIST);
                state.current_id = null;
                state.items = [];
                state.itemsByID = {};

                state.items_list_options = { filter: "all" };
                state.code_items = [];
                state.code_itemsByID = {};

                state.total_items = 0;
            },

            set_max_last_modified(state, max_last_modified) {
                logMe("Mutation:set_max_last_modified", max_last_modified);

                state.max_last_modified = max_last_modified;
            },
            SetListOptions(state, list_options) {
                logMe("Mutation:SetListOptions", list_options);

                state.items_list_options = normaliseOptions(list_options);
            },
            [MUTATION_TYPES.SET_ITEM](state, item) {
                logMe("Mutation:" + MUTATION_TYPES.SET_ITEM, item);
                calculateFields(schema, item);
                state.item = item;
                if (item && item.id) {
                    state.table[item.id] = item; // store for later!
                }
            },
            [MUTATION_TYPES.START_WORKING](state) {
                logMe("Mutation:" + MUTATION_TYPES.START_WORKING);
                state.working = true;
            },
            [MUTATION_TYPES.FINISH_WORKING](state) {
                logMe("Mutation:" + MUTATION_TYPES.FINISH_WORKING);
                state.working = false;
            },
            [MUTATION_TYPES.TEMPLATE](state, template) {
                // set a template to use later..
                logMe("Mutation:" + MUTATION_TYPES.TEMPLATE, template);
                state.template = template;
            },
            set_preload_options(state, value) {
                logMe("Mutation:set_preload_options", value);
                state.load_list_options = value;
            },
            preloaded(state, value) {
                logMe("Mutation:preloaded", value);
                state.preloaded = value;
            },
            is_preloading(state, value) {
                logMe("Mutation:is_preloading", value);
                state.is_preloading = value;
            },
            update_table(state, item) {
                if (!item.id) {
                    var maxID = _.max(_.map(_.keysIn(state.table), Number));

                    item.id = (maxID || 0) + 1;
                }
                state.table[item.id] = item; // update the table
            },
        },
        actions: {
            [ACTION_TYPES.CREATE_NEW]({ commit, state }) {
                logMe("Action:" + ACTION_TYPES.CREATE_NEW);
                return new Promise((resolve, reject) => {
                    let newItem = { _new: true };

                    // If there is a template (added using TEMPLATE action) then return that as the Default to use..
                    if (state.template) {
                        newItem = state.template;
                        state.template = null;
                    } else {
                        if (schema) {
                            newItem = CreateDefaultItem(schema);
                            newItem._new = true;
                        }
                    }

                    commit(MUTATION_TYPES.SET_ITEM, newItem);

                    resolve(newItem);
                });
            },
            refresh,
            [ACTION_TYPES.GET_ALL](
                { state, rootGetters, dispatch, commit },
                list_options = {},
            ) {
                logMe("Action:" + ACTION_TYPES.GET_ALL, list_options);
                return new Promise((resolve, reject) => {
                    if (rootGetters["AuthModule/isLoggedIn"]) {
                        dispatch("AuthModule/refreshToken", null, {
                            root: true,
                        });

                        if (list_options._code !== true) {
                            // only store list options for the Non-code options
                            commit("SetListOptions", list_options);
                        }

                        // const duration = 5
                        // var now = moment()
                        //
                        // if (list_options._code === true) {
                        //     if(!!state.last_get_all_date_code && state.last_get_all_date_code.diff(moment(),'minutes') < duration) {
                        //         resolve(state.code_items)
                        //
                        //         return
                        //     }
                        // }

                        dispatch("ReloadLists");
                    }
                });
            },
            [ACTION_TYPES.GET_BY_ID](
                { rootGetters, commit, dispatch, state },
                id,
            ) {
                logMe("Action:" + ACTION_TYPES.GET_BY_ID, id);
                return new Promise((resolve, reject) => {
                    if (rootGetters["AuthModule/isLoggedIn"]) {
                        dispatch("AuthModule/refreshToken", null, {
                            root: true,
                        });

                        commit(MUTATION_TYPES.START_WORKING);

                        var response = state.table[id];

                        commit(MUTATION_TYPES.FINISH_WORKING);

                        commit(MUTATION_TYPES.SET_ITEM, response);

                        resolve(response);
                        // }).catch(function(err) {
                        //     commit(MUTATION_TYPES.FINISH_WORKING);
                        //
                        //     handleError(err, commit);
                        //
                        //     reject(err);
                        // });
                    } else {
                        reject("NOT LOGGED IN");
                    }
                });
            },
            [ACTION_TYPES.SAVE_ITEM](
                { rootGetters, commit, dispatch, state },
                item,
            ) {
                logMe("Action:" + ACTION_TYPES.GET_BY_ID);

                return new Promise((resolve, reject) => {
                    if (rootGetters["AuthModule/isLoggedIn"]) {
                        dispatch("AuthModule/refreshToken", null, {
                            root: true,
                        });

                        commit(MUTATION_TYPES.START_WORKING);
                        var method = "post";
                        var url = api;

                        if (item.id) {
                            // Update the Item
                            method = "put";
                            url = api + item.id + "/";
                        }

                        item = removeVirtualFields(schema, item);
                        Vue.axios
                            .request({ url: url, method: method, data: item })
                            .then((response) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                commit(MUTATION_TYPES.SET_ITEM, response.data);

                                dispatch("ReloadLists");

                                resolve(response.data);
                            })
                            .catch((err) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                handleError(err, commit);

                                reject(err);
                            });
                    } else {
                        reject("NOT LOGGED IN");
                    }
                });
            },
            [ACTION_TYPES.PATCH_ITEM](
                { state, rootGetters, commit, dispatch },
                item,
            ) {
                return new Promise((resolve, reject) => {
                    if (rootGetters["AuthModule/isLoggedIn"]) {
                        dispatch("AuthModule/refreshToken", null, {
                            root: true,
                        });

                        commit(MUTATION_TYPES.START_WORKING);
                        var method = "patch";
                        var url = api;

                        if (item.id) {
                            // Update the Item
                            url = api + item.id + "/";
                        }

                        Vue.axios
                            .request({ url: url, method: method, data: item })
                            .then((response) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                commit(MUTATION_TYPES.SET_ITEM, response.data);

                                dispatch("ReloadLists");

                                resolve(response.data);
                            })
                            .catch((err) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                handleError(err, commit);

                                reject(err);
                            });
                    } else {
                        reject("NOT LOGGED IN");
                    }
                });
            },
            [ACTION_TYPES.DELETE_ITEM](
                { state, rootGetters, commit, dispatch },
                item,
            ) {
                return new Promise((resolve, reject) => {
                    if (rootGetters["AuthModule/isLoggedIn"]) {
                        dispatch("AuthModule/refreshToken", null, {
                            root: true,
                        });

                        commit(MUTATION_TYPES.START_WORKING);
                        var method = "delete";
                        var url = api;

                        url = api + item.id + "/";

                        Vue.axios
                            .request({ url: url, method: method, data: item })
                            .then((response) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                commit(MUTATION_TYPES.SET_ITEM, response.data);

                                dispatch("ReloadLists");

                                resolve(response.data);
                            })
                            .catch((err) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                handleError(err, commit);

                                reject(err);
                            });
                    } else {
                        reject("NOT LOGGED IN");
                    }
                });
            },

            preload({ rootGetters, dispatch, commit, state }, optional_params) {
                logMe("Action: preload", optional_params);
                if (state.preloaded !== true) {
                    console.log("Preloading " + schema.name + "!");
                    commit("set_preload_options", optional_params);

                    dispatch("ReloadLists");
                }
            },

            ReloadLists({ rootGetters, commit, state }) {
                logMe("Action: ReloadLists");
                commit(MUTATION_TYPES.START_WORKING);

                const FETCH_SIZE = 10000;

                function UpdateIndexedDBItems(items) {
                    var max_last_modified = state.max_last_modified;

                    return _.forEach(items, function (item) {
                        item = removeVirtualFields(schema, item);

                        if ("modified_date" in item) {
                            const row_modified_date = moment(
                                item.modified_date,
                            );
                            if (row_modified_date.isAfter(max_last_modified)) {
                                max_last_modified = row_modified_date;

                                commit(
                                    "set_max_last_modified",
                                    max_last_modified.toISOString(true),
                                );
                            }
                        }

                        commit("update_table", item);
                        return item.id;
                    });
                }

                function UpdateListStates() {
                    var data = _.valuesIn(state.table);

                    if (data) {
                        const newState = {
                            code_items: [...data],
                        };

                        const params = state.items_list_options || {
                            filter: "all",
                        };

                        // Run Filters
                        if (params.filter) {
                            const filter_found = filters[params.filter];

                            // Appy filter if found
                            if (filter_found) {
                                data = _.filter(data, filter_found);
                            }
                        }
                        // Run Queries
                        if (params.query) {
                            data = filter_list(data, params.query);
                        }

                        // Run Q Filters..
                        if (params.q) {
                            const q = (params.q || "").toLowerCase();
                            const searchField = params.searchField;

                            data = _.filter(data, function (o) {
                                if (searchField) {
                                    const value = o[searchField] || "";
                                    return (value || "")
                                        .toLowerCase()
                                        .includes(q);
                                } else {
                                    const found = _.find(
                                        o,
                                        function (value, key) {
                                            if (value && _.isString(value)) {
                                                // Is A string value so check it..
                                                return (value || "")
                                                    .toLowerCase()
                                                    .includes(q);
                                            }
                                            return false;
                                        },
                                    );

                                    return found != null;
                                }
                            });
                        }

                        newState.total_items = data.length || 0; // Total filtersed items

                        // Apply Sort params.sort=id params.order=asc
                        if (params.sort) {
                            data = _.sortBy(data, params.sort);
                            if (params.order === "desc") {
                                data = _.reverse(data);
                            }
                        }

                        // Apply params.offset, params.limit
                        data = data.splice(params.offset, params.limit);
                        newState.items = data || [];

                        commit(MUTATION_TYPES.FINISH_WORKING);

                        commit(MUTATION_TYPES.SET_LIST, newState);
                    } else {
                        commit(MUTATION_TYPES.FINISH_WORKING);

                        handleError({ message: "Cannot get data" }, commit);
                    }
                }

                function LoadAll(offset, limit, lastModifiedDate) {
                    const params = {
                        ...state.load_list_options,
                        offset: offset,
                        limit: limit,
                    };

                    if (lastModifiedDate) {
                        params["modified_date"] = lastModifiedDate;
                    }

                    commit(MUTATION_TYPES.STORE_LIST_OPTIONS, params);

                    Vue.axios
                        .get(api, { params: params })
                        .then((response) => {
                            const total_items = response.data.count || 0;

                            UpdateIndexedDBItems(response.data.results);

                            if (total_items > offset + limit) {
                                LoadAll(
                                    offset + limit,
                                    limit,
                                    lastModifiedDate,
                                ); // go again!
                            } else {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                // ALl loaded
                                commit("is_preloading", false);
                                commit("preloaded", true);

                                UpdateListStates();
                            }
                        })
                        .catch((err) => {
                            commit(MUTATION_TYPES.FINISH_WORKING);

                            commit("is_preloading", false);
                            commit("preloaded", false);
                        });
                }

                function GetMaxLastModified() {
                    var max_last_modified = moment("1900-01-01"); // Min Date

                    if (state.max_last_modified) {
                        LoadAll(0, FETCH_SIZE, state.max_last_modified);
                    } else {
                        var data = _.valuesIn(state.table);
                        if (data) {
                            _.forEach(data, function (row) {
                                if ("modified_date" in row) {
                                    const row_modified_date = moment(
                                        row.modified_date,
                                    );
                                    if (
                                        row_modified_date.isAfter(
                                            max_last_modified,
                                        )
                                    ) {
                                        max_last_modified = row_modified_date;
                                    }
                                }
                            });

                            commit(
                                "set_max_last_modified",
                                max_last_modified.toISOString(true),
                            );

                            LoadAll(
                                0,
                                FETCH_SIZE,
                                max_last_modified.toISOString(true),
                            );
                        } else {
                            LoadAll(0, FETCH_SIZE, null); // can't get Max Modified_date so get all
                        }
                    }
                }

                if (state.is_preloading === false) {
                    commit("is_preloading", true);
                    if ("modified_date" in schema.fields) {
                        GetMaxLastModified();
                    } else {
                        console.log("No modified date....Doing all");
                        LoadAll(0, FETCH_SIZE, null);
                    }
                }
            },
        },
        getters: {
            [GETTER_TYPES.ITEM](state) {
                return state.item;
            },
            [GETTER_TYPES.WORKING](state) {
                return state.working;
            },

            [GETTER_TYPES.ITEMS](state) {
                return state.items;
            },
            [GETTER_TYPES.ITEMS_BY_ID](state) {
                return state.itemsByID;
            },

            [GETTER_TYPES.CODE_ITEMS](state) {
                return state.code_items;
            },
            [GETTER_TYPES.CODE_ITEMS_BY_ID](state) {
                return state.code_itemsByID;
            },

            [GETTER_TYPES.TOTAL_ITEMS](state) {
                return state.total_items;
            },
        },
    };
}
