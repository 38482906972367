<template>
    <div>
        <BAlert show variant="info" v-if="!authenticated">
            To view or update this sensitive information, please enter your
            password again and click Authenticate.</BAlert
        >
        <BAlert show variant="danger" v-if="error"
            ><div
                class="d-flex flex-grow-1 align-items-baseline"
                slot="toast-title"
            >
                <strong class="mr-auto">Authentication failed</strong>
            </div>
            Invalid Password. Please try again.</BAlert
        >
        <BFormGroup
            :label="labelNumber"
            label-for="nested-info"
            label-cols-sm="3"
            label-align-sm="right"
            v-if="!authenticated"
        >
            <BFormInput id="nested-info" v-model="encryptedNumber"></BFormInput>
        </BFormGroup>

        <BFormGroup
            :label="labelNumber"
            label-for="nested-info"
            label-cols-sm="3"
            label-align-sm="right"
            v-if="authenticated"
        >
            <BFormInput
                id="nested-info"
                v-bind="$attrs"
                :value="value"
                v-on="inputListeners"
            ></BFormInput>
        </BFormGroup>
        <div v-if="!authenticated">
            <BFormGroup
                label="Password"
                label-for="nested-info"
                label-cols-sm="3"
                label-align-sm="right"
            >
                <BFormInput
                    label="Password"
                    id="nested-info"
                    v-model="password"
                    type="password"
                    autocomplete="off"
                ></BFormInput>
            </BFormGroup>
            <BButton class="float-right" variant="primary" @click="authenticate"
                >Authenticate</BButton
            >
        </div>
    </div>
</template>

<script>
import { BAlert, BButton, BFormGroup, BFormInput } from "bootstrap-vue";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import FormComponentMixin from "../../../mixins/form_component_mixin.js";
import OFormGroup from "../OFormGroup.vue";

export default {
    name: "OEncrypt",
    components: {
        BAlert,
        BFormGroup,
        BFormInput,
        BButton,
        OFormGroup,
    },
    mixins: [FormComponentMixin],
    props: {
        number: { type: String },
        expiryDate: { type: String },
        labelNumber: { type: String },
        labelExpiry: { type: String },
        groupName: { type: String },
    },
    data: () => ({
        dataNumber: "",
        dataExpiryDate: "",
        // username: "",
        password: "",
        authenticated: false,
        error: false,
    }),
    methods: {
        ...mapActions("AuthModule", ["doLogin"]),
        authenticate() {
            this.username = this.currentUser.username;
            const user = {
                username: this.username,
                password: this.password,
            };

            this.working = true;
            this.apiError = null;

            this.doLogin(user).then(
                () => {
                    this.working = false;
                    console.log("Authenticated");
                    this.authenticated = true;
                    this.error = false;
                    this.password = "";
                },
                (error) => {
                    if (!error.response) {
                        this.$bvToast.show("toast-server-down");
                    } else {
                        this.$bvToast.show("toast-invalid");
                        this.serverErrors = _.clone(error.response.data);
                        this.apiError = error.response.data;
                        this.working = false;
                        this.error = true;
                        this.authenticated = false;
                    }
                },
            );
        },
    },
    computed: {
        ...mapGetters("AuthModule", ["currentUser"]),
        encryptedNumber() {
            const vm = this;
            const encryptedText = this.$CryptoJS.AES.encrypt(
                vm.value,
                "Secret Passphrase",
            ).toString();

            return encryptedText;
        },
        decryptedNumber() {
            const vm = this;
            const decryptedText = this.$CryptoJS.AES.decrypt(
                vm.encryptedNumber,
                "Secret Passphrase",
            ).toString(this.$CryptoJS.enc.Utf8);

            return decryptedText;
        },
    },
    mounted() {},
};
</script>
