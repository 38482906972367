<template>
  <div v-if="isLoggedIn">
    <div class="pt-5 pt-xl-2 pt-lg-2 pt-md-2 pt-sm-2 pt-xs-5">
      <b-form-select
        id="selectCustomer"
        ref="selectCustomer"
        size="sm"
        class="w-100"
        v-model="temp_customer_ID"
        @input="onOk"
      >
        <template v-for="(option, index) in customer_list">
          <b-form-select-option :key="index" :value="option.id"
            >{{ option.name }} - {{ option.company_code }}</b-form-select-option
          >
        </template>
        <b-form-select-option :value="null"
          >-- All Customers --</b-form-select-option
        >
      </b-form-select>
    </div>
  </div>
</template>

<script>
import { CreateCodeTableFetchMixin } from "../vue-ocom/mixins";
import { sort_and_filter_active_list } from "../vue-ocom/utils/active";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "GlobalSelectCustomer",
  components: {},
  mixins: [CreateCodeTableFetchMixin(["Customer"])],
  data() {
    return {
      temp_customer_ID: null,
    };
  },
  methods: {
    ...mapActions("GlobalStateModule", ["setGlobalValue"]),
    onOk() {
      this.setGlobalValue({ key: "customer_id", value: this.temp_customer_ID });
      this.setGlobalValue({
        key: "test_date",
        value: new Date().toISOString(),
      });

      this.$emit("updateCustomer", this.temp_customer_ID);
    },
  },
  computed: {
    ...mapGetters("GlobalStateModule", ["data"]),
    ...mapGetters("AuthModule", ["isLoggedIn"]),
    current_customer_name() {
      if (this.current_customer) {
        return this.current_customer.name;
      }
      return "-- All Customers --";
    },
    current_customer() {
      if (this.data.customer_id) {
        return this.optionCustomerItemsById[this.data.customer_id];
      }
      return null;
    },
    customer_list() {
      return sort_and_filter_active_list(
        this.optionCustomerItems,
        null,
        false,
        "name",
        "(Disabled)",
        false,
        "name"
      );
    },
  },
  created() {
    if (this.data.customer_id) {
      this.temp_customer_ID = this.data.customer_id;
      this.$emit("updateCustomer", this.temp_customer_ID);
    }
  },
};
</script>
