<template>
    <o-form-group
        :form-state="formState"
        :label="label"
        :message="errorMessages"
        :nolabel="nolabel"
        :type="{ 'is-danger': hasError }"
    >
        <o-file-upload
            :class="input_class"
            :file="value"
            :form-state="formState"
            @change="on_change"
            v-bind="$attrs"
            v-on="inputListeners"
        >
        </o-file-upload>
        <o-file-preview
            :disable-download="disableDownload"
            :file="value"
            :token="token"
            v-if="showPreview"
        />
    </o-form-group>
</template>

<script>
import FormComponentMixin from "../../../mixins/form_component_mixin.js";
import OFormGroup from "../OFormGroup.vue";
import OFilePreview from "../../../filesystem/OFilePreview.vue";
import OFileUpload from "../../../filesystem/OFileUpload.vue";

export default {
    name: "OFileInput",
    components: { OFormGroup, OFilePreview, OFileUpload },
    mixins: [FormComponentMixin],
    props: {
        showPreview: { type: Boolean, default: false },
        token: { type: String, default: null },
        disableDownload: { type: Boolean, default: true },
    },
};
</script>

<style scoped></style>
