import { Field, FIELD_TYPES, Model } from "../../schema";
import { IsGoBackEnd } from "../../utils/config.js";

export default function () {
    const DjangoFields = [
        Field("user_permissions", {
            label: "User Permissions",
            primary_key: false,
            readonly: false,
            hide: false,
            required: false,
            field_type: FIELD_TYPES.ManyToMany,
            ref_field_type: "Permission",
            default: () => {
                return [];
            },
        }),
        Field("is_staff", {
            label: "Staff Status",
            primary_key: false,
            readonly: false,
            hide: false,
            required: false,
            field_type: FIELD_TYPES.Boolean,
            default: () => {
                return false;
            },
        }),
        Field("is_active", {
            label: "Active",
            primary_key: false,
            readonly: false,
            hide: false,
            required: false,
            field_type: FIELD_TYPES.Boolean,
            default: () => {
                return true;
            },
        }),
    ];
    const GoFields = [
        Field("active_start_date", {
            label: "Active Start Date",
            primary_key: false,
            readonly: false,
            hide: false,
            required: false,
            field_type: FIELD_TYPES.Date,
            default: () => {
                return new Date();
            },
        }),
        Field("active_end_date", {
            label: "Active End Date",
            primary_key: false,
            readonly: false,
            hide: false,
            required: false,
            field_type: FIELD_TYPES.Date,
            default: () => {
                return null;
            },
        }),
    ];

    var ExtraFields = DjangoFields;
    if (IsGoBackEnd()) {
        ExtraFields = GoFields;
    }
    return Model("user", {
        verbose_name: "User",
        verbose_name_plural: "Users",
        fields: [
            Field("id", {
                label: "ID",
                primary_key: true,
                readonly: true,
                hide: false,
                field_type: FIELD_TYPES.AutoNumber,
            }),

            Field("new_password", {
                label: "Password",
                primary_key: false,
                readonly: false,
                hide: false,
                required: false,
                field_type: FIELD_TYPES.Password,
                default: () => {
                    return null;
                },
            }),
            Field("password", {
                label: "Password",
                primary_key: false,
                readonly: false,
                hide: false,
                required: false,
                field_type: FIELD_TYPES.String,
                default: () => {
                    return null;
                },
            }),
            Field("is_superuser", {
                label: "Superuser Status",
                primary_key: false,
                readonly: false,
                hide: false,
                required: false,
                field_type: FIELD_TYPES.Boolean,
                default: () => {
                    return false;
                },
            }),
            Field("last_login", {
                label: "Last Login",
                primary_key: false,
                readonly: true,
                hide: false,
                required: false,
                field_type: FIELD_TYPES.Date,
                default: () => {
                    return null;
                },
            }),

            Field("username", {
                label: "Username",
                primary_key: false,
                readonly: false,
                hide: false,
                required: true,
                field_type: FIELD_TYPES.String,
                default: () => {
                    return null;
                },
            }),
            Field("first_name", {
                label: "First Name",
                primary_key: false,
                readonly: false,
                hide: false,
                required: true,
                field_type: FIELD_TYPES.String,
                default: () => {
                    return null;
                },
            }),
            Field("last_name", {
                label: "Last Name",
                primary_key: false,
                readonly: false,
                hide: false,
                required: true,
                field_type: FIELD_TYPES.String,
                default: () => {
                    return null;
                },
            }),
            Field("email", {
                label: "Email Address",
                primary_key: false,
                readonly: false,
                hide: false,
                required: true,
                field_type: FIELD_TYPES.String,
                default: () => {
                    return null;
                },
            }),
            Field("date_joined", {
                label: "Date Joined",
                primary_key: false,
                readonly: true,
                hide: false,
                required: false,
                field_type: FIELD_TYPES.Date,
                default: () => {
                    return null;
                },
            }),
            Field("groups", {
                label: "Groups",
                primary_key: false,
                readonly: false,
                hide: false,
                required: false,
                keys_only: true,
                field_type: FIELD_TYPES.ManyToMany,
                ref_field_type: "Group",
                default: () => {
                    return [];
                },
            }),
            ...ExtraFields,
        ],
    });
}
