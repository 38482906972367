import CodeModelName from "./code_model_name";
import Asset from "./asset";
import Testing from "./testing";
import AssetLocation from "./asset_location";
import AssetTest from "./asset_test";
import CodeAssetStatus from "./code_asset_status";
import CodeColor from "./code_color";
import CodeCustomerStatus from "./code_customer_status";
import CodeElectricalClass from "./code_electrical_class";
import CodeLocationType from "./code_location_type";
import CodeMake from "./code_make";
import CodePhase from "./code_phase";
import CodeAssetClassification from "./code_asset_classification";
import CodeProductType from "./code_product_type";
import CodeTestFrequency from "./code_test_frequency";
import Customer from "./customer";
import Employee from "./employee";
import MaintenanceRecord from "./maintenance_record";
import Product from "./product";
import CodeTestCycle from "./code_test_cycle";
import CodeAssetTestStatus from "./code_asset_test_status";
import CodeReplacementPart from "./code_replacement_part";
import CodeTestCycleColor from "./code_test_cycle_color";
import CodeFileType from "./code_file_type";
import AssetFile from "./asset_file";
import AssetTestFile from "./asset_test_file";
import CodeRepairType from './code_repair_type';


import { default as PermissionSchema } from "../../vue-ocom/grouppermissions/schema/index.js";

export default {
  CodeModelName,
  Asset,
  AssetLocation,
  AssetTest,
  CodeAssetStatus,
  CodeColor,
  CodeCustomerStatus,
  CodeElectricalClass,
  CodeLocationType,
  CodeMake,
  CodePhase,
  CodeAssetClassification,
  CodeProductType,
  CodeTestFrequency,
  Customer,
  Employee,
  MaintenanceRecord,
  Product,
  CodeTestCycle,
  CodeAssetTestStatus,
  CodeReplacementPart,
  CodeTestCycleColor,
  CodeFileType,
  AssetFile,
  AssetTestFile,
  Testing,
  CodeRepairType,

  ...PermissionSchema /* For Schema */,
};
