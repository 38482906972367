<template>
    <div class="alert alert-danger" role="alert" v-if="isQA">
        This is the <ocom short /> Testing Server. Please don't use this server
        for real data. Only use for Acceptance Testing as instructed by
        <ocom />
    </div>
</template>
<script>
import Ocom from "./Ocom.vue";

export default {
    name: "OcomQaWarning",
    components: { Ocom },

    computed: {
        isQA() {
            return location.host.includes(".qa");
        },
    },
};
</script>
