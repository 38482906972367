import { mapActions, mapGetters } from "vuex";

export default function (moduleName, prefix, is_component) {
    var actionList = {};
    var getterList = {};

    if (!is_component) {
        actionList[prefix + "_execute"] = moduleName + "Module/execute";
    }
    actionList[prefix + "_recall"] = moduleName + "Module/recall";

    getterList[prefix + "_result"] = moduleName + "Module/result";
    getterList[prefix + "_working"] = moduleName + "Module/working";

    return {
        methods: {
            ...mapActions({ ...actionList }),
        },
        computed: {
            ...mapGetters(getterList),
        },
    };
}
