import { Field, Model, CodeModel, FIELD_TYPES } from '../../vue-ocom/schema';

export default Model('code_repair_type', {
        verbose_name: "Code Repair Type",
        verbose_name_plural: "Code Repair Types",
        fields: [
                Field('id', { label: 'ID', primary_key: true, readonly: true, hide: false, field_type: FIELD_TYPES.AutoNumber }),

                Field('modified_date', {
                        label: "Modified Date",
                        primary_key: false,
                        readonly: true,
                        hide: false,
                        required: false,
                        field_type: FIELD_TYPES.Date,
                        default: () => { return null; }
                }),
                Field('created_date', {
                        label: "Created Date",
                        primary_key: false,
                        readonly: true,
                        hide: false,
                        required: true,
                        field_type: FIELD_TYPES.Date,
                        default: () => { return null; }
                }),
                Field('active_start_date', {
                        label: "Active Start Date",
                        primary_key: false,
                        readonly: false,
                        hide: false,
                        required: false,
                        field_type: FIELD_TYPES.Date,
                        default: () => { return new Date(); }
                }),
                Field('active_end_date', {
                        label: "Active End Date",
                        primary_key: false,
                        readonly: false,
                        hide: false,
                        required: false,
                        field_type: FIELD_TYPES.Date,
                        default: () => { return null; }
                }),
                Field('code', {
                        label: "Code",
                        primary_key: false,
                        readonly: false,
                        hide: false,
                        required: false,
                        field_type: FIELD_TYPES.String,
                        default: () => { return null; }
                }),
                Field('description', {
                        label: "Description/Name",
                        primary_key: false,
                        readonly: false,
                        hide: false,
                        required: true,
                        field_type: FIELD_TYPES.String,
                        default: () => { return null; }
                }),
        ]
});