import CodeReplacementPartList from "./pages/ListPage.vue";
import CodeReplacementPartDetail from "./pages/FormPage.vue";
import CodeReplacementPartIndex from "./pages/IndexPage.vue";

export default [
  {
    path: "/code_replacement_part",
    component: CodeReplacementPartIndex,
    redirect: "/code_replacement_part/list",
    name: "code_replacement_part",
    children: [
      {
        path: "list",
        component: CodeReplacementPartList,
        name: "code_replacement_part.list",
      },
      {
        path: "create",
        component: CodeReplacementPartDetail,
        name: "code_replacement_part.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: CodeReplacementPartDetail,
        name: "code_replacement_part.edit",
        props: { create: false },
      },
    ],
  },
];
