<template>
    <o-flatpickr-wrapper
        :config="config"
        :hide-date="hideDate"
        :hide-time="hideTime"
        :inline="inline"
        :no-buttons="noButtons"
        :state="input_state"
        :value="value"
        v-bind="$attrs"
        v-on="inputListeners"
    >
    </o-flatpickr-wrapper>
</template>

<script>
import VuexInputFieldMixin from "./VuexInputFieldMixin.js";
import OFlatpickrWrapper from "../private/OFlatpickrWrapper.vue";

// TODO Update the State of this!

export default {
    name: "ODateTimeInput",
    mixins: [VuexInputFieldMixin(true)],
    components: { OFlatpickrWrapper },
    props: {
        hideTime: { type: Boolean, default: false },
        hideDate: { type: Boolean, default: false },
        config: {
            type: Object,
            default: () => {},
        },
        noButtons: { type: Boolean, default: false },
        inline: { type: Boolean, default: false },
    },
};
</script>

<style scoped></style>
