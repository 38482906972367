<script>
export default {
    name: "OFormFieldLayout",
    props: { label: { type: String, default: "" } },
};
</script>

<template>
    <span
        ><fieldset class="form-group is-valid">
            <div class="form-row">
                <legend
                    tabindex="-1"
                    class="col-lg-2 col-3 bv-no-focus-ring col-form-label text-right"
                >
                    {{ label }}
                </legend>
                <div class="col">
                    <div role="group" class="input-group">
                        <slot />
                    </div>
                </div>
            </div>
        </fieldset>
    </span>
</template>

<style scoped></style>
