<template>
    <BFormSelect
        :options="the_list"
        :state="input_state"
        :text-field="textField"
        :value-field="valueField"
        v-bind="$attrs"
        v-bind:value="value"
        v-on="inputListeners"
    >
        <template v-if="!$attrs.required" v-slot:first>
            <option :value="null">{{ blankOptionText }}</option>
        </template>
    </BFormSelect>
</template>

<script>
import { BFormSelect } from "bootstrap-vue";
import VuexInputFieldMixin from "./VuexInputFieldMixin.js";
import SelectInputMixin from "../private/SelectInputMixin.js";

export default {
    components: {
        BFormSelect,
    },
    name: "OSelectInput",
    mixins: [VuexInputFieldMixin(true), SelectInputMixin],
};
</script>

<style scoped></style>
