<template>
    <o-flatpickr-wrapper
        class="rounded-0 px-0"
        hide-time
        @input="on_changed"
        @keydown.enter="refilter"
        v-bind="$attrs"
        v-on="inputListeners"
    >
        <template v-slot:prepend>
            <BInputGroupPrepend>
                <BButton
                    variant="outline-secondary"
                    small
                    @click="changeExpression"
                    >{{ expr_symbol }}</BButton
                >
            </BInputGroupPrepend>
        </template>
    </o-flatpickr-wrapper>
</template>

<script>
import { BButton, BInputGroupPrepend } from "bootstrap-vue";
import FilterInputMixin from "./FilterInputMixin.js";

export default {
    components: {
        BInputGroupPrepend,
        BButton,
    },
    name: "OFilterDateField",
    mixins: [FilterInputMixin],
    data() {
        return {
            expression: "gt",
            expressions: [
                { code: "eq", text: "=" },
                { code: "gt", text: ">" },
                { code: "lt", text: "<" },
                { code: "ge", text: ">=" },
                { code: "le", text: "<=" },
            ],
        };
    },
    methods: {},
};
</script>
