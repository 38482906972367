<template>
    <o-do-server-action-button
        :request="email"
        :uri="uri"
        @success="success"
        success-message=""
        v-bind="$attrs"
    >
        <slot />
    </o-do-server-action-button>
</template>

<script>
import { mapMutations } from "vuex";

import ODoServerActionButton from "./ODoServerActionButton.vue";

export default {
    name: "OSendEmailButton",
    components: { ODoServerActionButton },
    props: {
        uri: { type: String, required: true }, //eg '/ocom_project/generate_doc/'
        email: {}, // what is sent to the URL ie the Post data...
    },
    methods: {
        ...mapMutations(["showMessage"]),

        success() {
            var vm = this;

            var allAddress = (vm.email.to || [])
                .concat(vm.email.cc || [])
                .concat(vm.email.bcc || []);
            var emails = allAddress.join(", ");

            vm.showMessage({
                message: "Email Sent to " + emails,
                type: "success",
                show: true,
            });
        },
    },
};
</script>

<style scoped></style>
