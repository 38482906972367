<template>
  <div class="container o-form-page">
    <o-working-overlay :working="working"></o-working-overlay>

    <o-section :level="0" :title="title" @click="enableDebug($event)">
      <item-form
        :form-state="formState"
        :item="item"
        :server-errors="serverErrors"
      ></item-form>
    </o-section>

    <o-action-buttons
      :create="create"
      :form-state="formState"
      :item="item"
      :show-create-and-new="true"
      @delete="doDeleteItem"
      @save="doSave"
      @saveAndClose="doSaveAndClose"
      @saveAndNew="doSaveAndNew"
    ></o-action-buttons>
  </div>
</template>

<script>
import {
  CreateFormPageMixin,
  CreateGroupPermissionMixin,
  UnsavedMixin,
} from "../../../vue-ocom/mixins";
import ItemForm from "../components/ItemForm.vue";
import OActionButtons from "../../../vue-ocom/components/forms/OActionButtons";
import OSection from "../../../vue-ocom/components/layout/OSection";
import OWorkingOverlay from "../../../vue-ocom/components/OWorkingOverlay";
import FetchCodeMixin from "@/vue-ocom/mixins/FetchCodeMixin";
import FetchCustomerOnly from "@/app/asset_location/mixins/FetchCustomerOnly";
import { mapGetters } from "vuex";

export default {
  mixins: [
    UnsavedMixin,
    CreateFormPageMixin("AssetLocationMan", "asset_location"),
    CreateGroupPermissionMixin("AssetLocation"),
    FetchCodeMixin(["CodeLocationType", "Customer", "AssetLocationMan"]),
  ],
  components: {
    OSection,
    OWorkingOverlay,
    OActionButtons,
    ItemForm,
  },
  methods: {
    OnCreateNew(item) {
      item.customer = this.global_data.customer_id;
    },
  },
  computed: {
    ...mapGetters("GlobalStateModule", { global_data: "data" }),

    title() {
      if (this.create) {
        return "Create Asset Location";
      }

      return "Edit Asset Location";
    },
  },
};
</script>
