<template>
    <div>
        <slot
            :form-state="formState"
            :item="item"
            :server-errors="serverErrors"
        ></slot>
        <footer class="modal-footer">
            <button @click="close()" class="btn btn-secondary" type="button">
                Cancel
            </button>
            <button @click="save()" class="btn btn-primary" type="button">
                Create
            </button>
        </footer>
    </div>
</template>

<script>
import AddNewMixin from "../AddNewMixin.js";

export default {
    name: "OCrudDialog",
    mixins: [AddNewMixin],
    props: {
        title: { type: String, default: "" },
        value: { type: Boolean, default: false },
        newItem: { type: Object, default: () => {} },
    },
    data() {
        return {
            item: {},

            serverErrors: {},

            clean: true, // add here so we can watch it

            formState: {
                dirty: false,
                invalid: false,
                error: false,
                init: true,
                debug: false,
                //Remove
                valid: true,
            },
        };
    },
    methods: {
        createItem() {
            if (typeof this.the_store.createNew !== "undefined") {
                this.the_store.createNew().then((data) => {
                    // we set value here so if there's a new Item you want to set
                    // in the parent component use newItem prop and also set value to true
                    if (!this.value) {
                        this.item = data;
                    } else {
                        this.item = this.newItem;
                    }
                });
            } else {
                this.item = {};
            }
        },
        save() {
            var vm = this;
            vm.create_new_item(this.item, function (response) {
                vm.$emit("change", response.id);
                vm.$emit("select", response);
            });
        },
        close() {
            this.$emit("close", null);
        },
    },
    created() {
        this.createItem();
    },
};
</script>

<style scoped></style>
