export default function ($v_name) {
    if (!$v_name) $v_name = "$v"; // Assume Default

    return {
        data() {
            return {
                clean: true,
            };
        },
        props: {
            formState: {
                type: Object,
                default: () => {},
            },
            serverErrors: {
                type: [Object, Array],
                default: () => {},
            },
        },
        methods: {
            makeDirty() {
                if (this.formState) this.formState.dirty = true;
            },
            unlockForm() {
                if (this.formState) {
                    this.$set(this.formState, "locked", false);
                }
            },
        },
        computed: {
            my_validator() {
                var results = this[$v_name];

                if (!results) {
                    var name = this.$options.name || "Unknown component";

                    console.warn("No validators for " + name, this);
                }

                return results;
            },
            dirty() {
                return this.my_validator.$anyDirty;
            },
            invalid() {
                return this.my_validator.$invalid;
            },
            error() {
                return this.my_validator.$error;
            },
            _se() {
                // Return an empty Object if there are no Server Errors to avoid Binding Errors
                return this.serverErrors || {};
            },
            _isLocked() {
                if (this.formState) {
                    if (this.formState.locked) {
                        return true;
                    }
                }

                // Use a prop/data/computed "locked" value to lock the formState
                if ("locked" in this) {
                    return this.locked;
                }

                return false; // not locked
            },
        },
        watch: {
            clean: function () {
                //TODO Find a way to reset this form if needed (from parent component)
                //e.g. use :key or forceUpdate
                if (this.clean) {
                    this.my_validator.$reset();
                }
            },
            dirty: function () {
                this.$emit("dirty", this.my_validator.$anyDirty);
            },
            invalid: function () {
                this.$emit("invalid", this.my_validator.$invalid);
            },
            error: function () {
                this.$emit("error", this.my_validator.$error);
            },
            _isLocked: function () {
                // _isLocked changed so update formState to locked.
                this.$set(this.formState, "locked", this._isLocked);
            },
        },
    };
}
