import CodeLocationTypeList from "./pages/ListPage.vue";
import CodeLocationTypeDetail from "./pages/FormPage.vue";
import CodeLocationTypeIndex from "./pages/IndexPage.vue";

export default [
  {
    path: "/code_location_type",
    component: CodeLocationTypeIndex,
    redirect: "/code_location_type/list",
    name: "code_location_type",
    children: [
      {
        path: "list",
        component: CodeLocationTypeList,
        name: "code_location_type.list",
      },
      {
        path: "create",
        component: CodeLocationTypeDetail,
        name: "code_location_type.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: CodeLocationTypeDetail,
        name: "code_location_type.edit",
        props: { create: false },
      },
    ],
  },
];
