<template>
    <div :class="{ denied: item.deny }">
        <o-form-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :v="$v.item"
            v-model="item"
        >
            <o-widget-wrapper
                :form-state="formState"
                :schema="schema"
                :server-errors="_se"
                :validators="$v.item"
                name="state_name"
            >
                <o-select
                    :form-state="formState"
                    :items="routesList"
                    :server-errors="_se"
                    :validators="$v.item"
                    name="state_name"
                    sort-field="id"
                    v-bind="schema.fields.state_name"
                    v-model="item.state_name"
                ></o-select>
            </o-widget-wrapper>

            <o-widget-wrapper
                :form-state="formState"
                :schema="schema"
                :server-errors="_se"
                :validators="$v.item"
                name="deny"
            >
                <o-checkbox
                    :form-state="formState"
                    :server-errors="_se"
                    :validators="$v.item"
                    name="deny"
                    v-bind="schema.fields.deny"
                    v-model="item.deny"
                ></o-checkbox>
            </o-widget-wrapper>

            <o-widget-wrapper
                :form-state="formState"
                :schema="schema"
                :server-errors="_se"
                :validators="$v.item"
                name="models"
                v-if="!item.deny"
            >
                <group-state-model-form-table
                    :form-state="formState"
                    :label="schema.fields.models.label"
                    :level="level + 1"
                    :server-errors="_se.models"
                    :validators="$v.item.models"
                    v-model="item.models"
                >
                </group-state-model-form-table>
            </o-widget-wrapper>
        </o-form-wrapper>
    </div>
</template>

<script>
import schema from "../../schema/index.js";
import { cleanUpModel } from "../../../schema/schema_vuetify.js";
import {
    CreateCodeTableFetchMixin,
    CreateFormStateMixin,
    CreateGroupPermissionMixin,
    VuelidateMixin,
} from "../../../mixins";
import { validationMixin } from "vuelidate";
import OWidgetWrapper from "../../../components/forms/OWidgetWrapper.vue";
import OTextField from "../../../components/forms/fields/OTextField.vue";
import OSelect from "../../../components/forms/fields/OSelect.vue";
import OCheckbox from "../../../components/forms/fields/OCheckbox.vue";
import OFormWrapper from "../../../components/forms/OFormWrapper.vue";
import ODateTime from "../../../components/forms/fields/ODateTime.vue";

import { default as GroupStateModelFormTable } from "../../group_state_model/components/ItemFormTable.vue";
// import { default as GroupStateModelFormCards } from '../../group_state_model/components/ItemFormCards';

export default {
    mixins: [
        VuelidateMixin,
        validationMixin,
        CreateFormStateMixin("$v"),
        CreateGroupPermissionMixin("GroupState"),
        CreateCodeTableFetchMixin([]),
    ],
    components: {
        OFormWrapper,
        OWidgetWrapper,
        ODateTime,

        OCheckbox,
        OSelect,
        OTextField,

        GroupStateModelFormTable,
        //GroupStateModelFormCards,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        level: { type: Number, default: 0 },
        routesList: { type: Array, default: () => [] },
    },
    data: () => ({
        schemaModel: cleanUpModel(schema.GroupState, {}),
    }),
    methods: {},
    computed: {
        _se() {
            // Return an empty Object if there are no Server Errors to avoid Binding Errors
            return this.serverErrors || {};
        },
    },
    watch: {},
    created() {},
};
</script>

<style>
div.denied {
    background: #f5bdbd;
}
</style>
