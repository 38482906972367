import TestingList from "./pages/ListPage.vue";
import TestingIndex from "./pages/IndexPage.vue";
import TestingForm from "./pages/FormPage.vue";

export default [
  {
    path: "/testing",
    component: TestingIndex,
    redirect: "/testing/list",
    name: "testing",
    children: [
      {
        path: "list",
        component: TestingList,
        name: "testing.list",
      },
      {
        path: "create",
        component: TestingForm,
        name: "testing.create",
        props: { create: true },
      },
    ],
  },
];
