import AssetList from "./pages/ListPage.vue";
import AssetDetail from "./pages/FormPage.vue";
import AssetIndex from "./pages/IndexPage.vue";

export default [
  {
    path: "/asset",
    component: AssetIndex,
    redirect: "/asset/list",
    name: "asset",
    children: [
      {
        path: "list",
        component: AssetList,
        name: "asset.list",
      },
      {
        path: "create",
        component: AssetDetail,
        name: "asset.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: AssetDetail,
        name: "asset.edit",
        props: { create: false },
      },
    ],
  },
];
