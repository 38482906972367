import { ACTION_TYPES } from "../store_keys.js";
import _ from "lodash";

export default function (store) {
    store.subscribeAction({
        before: (action, state) => {
            if (store.getters["AuthModule/isLoggedIn"]) {
                if ((action.type || "").endsWith(ACTION_TYPES.GET_ALL)) {
                    _.forEach(state, function (module, moduleName) {
                        if (
                            "preloaded" in module &&
                            module.preloaded === false
                        ) {
                            if (!module.is_preloading) {
                                store.dispatch(moduleName + "/preload", {
                                    query: null,
                                });
                            }
                        }
                    });
                }
            }
        },
    });
}
