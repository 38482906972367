import ProductList from "./pages/ListPage.vue";
import ProductDetail from "./pages/FormPage.vue";
import ProductIndex from "./pages/IndexPage.vue";

export default [
  {
    path: "/product",
    component: ProductIndex,
    redirect: "/product/list",
    name: "product",
    children: [
      {
        path: "list",
        component: ProductList,
        name: "product.list",
      },
      {
        path: "create",
        component: ProductDetail,
        name: "product.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: ProductDetail,
        name: "product.edit",
        props: { create: false },
      },
    ],
  },
];
