<template>
    <BFormTextarea
        :state="input_state"
        :value="value"
        v-bind="$attrs"
        v-on="inputListeners"
    ></BFormTextarea>
</template>

<script>
import { BFormTextarea } from "bootstrap-vue";
import VuexInputFieldMixin from "./VuexInputFieldMixin.js";

export default {
    components: {
        BFormTextarea,
    },
    name: "OTextareaInput",
    mixins: [VuexInputFieldMixin(false)],
};
</script>

<style scoped></style>
