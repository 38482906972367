import { Field, FIELD_TYPES, Model } from "../../schema";
import { IsGoBackEnd } from "../../utils/config.js";

export default function () {
    const DjangoFields = [
        Field("permissions", {
            label: "Permissions",
            primary_key: false,
            readonly: false,
            hide: false,
            required: false,
            field_type: FIELD_TYPES.ManyToMany,
            ref_field_type: "Permission",
            default: () => {
                return [];
            },
        }),
    ];
    const GoFields = [
        Field("active_start_date", {
            label: "Active Start Date",
            primary_key: false,
            readonly: false,
            hide: false,
            required: false,
            field_type: FIELD_TYPES.Date,
            default: () => {
                return new Date();
            },
        }),
        Field("active_end_date", {
            label: "Active End Date",
            primary_key: false,
            readonly: false,
            hide: false,
            required: false,
            field_type: FIELD_TYPES.Date,
            default: () => {
                return null;
            },
        }),
    ];

    var ExtraFields = DjangoFields;
    if (IsGoBackEnd()) {
        ExtraFields = GoFields;
    }

    return Model("group", {
        verbose_name: "Group",
        verbose_name_plural: "Groups",
        fields: [
            Field("id", {
                label: "ID",
                primary_key: true,
                readonly: true,
                hide: false,
                field_type: FIELD_TYPES.AutoNumber,
            }),

            Field("name", {
                label: "Name",
                primary_key: false,
                readonly: false,
                hide: false,
                required: true,
                field_type: FIELD_TYPES.String,
                default: () => {
                    return null;
                },
            }),
            ...ExtraFields,
        ],
    });
}
