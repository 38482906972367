import moment from "moment";

import _ from "lodash";

export function is_item_active_now(item) {
    return is_item_active(item, moment());
}

export function is_item_active(item, now) {
    return is_item_active_now_utc(item, now.utc());
}
export function is_item_active_now_utc(item, now) {
    // Assumes Now is already UTC

    //Figure out if the item is disabled..
    if ("active_start_date" in item) {
        if (item.active_start_date) {
            var activeStartDate = moment(item.active_start_date).utc();

            if (activeStartDate.isAfter(now)) {
                // NOTE: Don't check for `isSameOrAfter` as any NEW items will be hidden for up to 1 minute!
                return false;
            }

            if (item.active_end_date) {
                var activeEndDate = moment(item.active_end_date).utc();

                if (activeEndDate.isSameOrBefore(now, "minute")) {
                    // NOTE: Use 'isSameOrBefore' because we want it to be disabled immediately.
                    return false;
                }
            }
        } else {
            // has active_start_date field but no value THEN it's not active.
            return false;
        }
    }

    return true;
}

export function filter_active_list(items) {
    var now = moment().utc();

    return _.filter(items, function (o) {
        if (!o) {
            return false;
        } else {
            return is_item_active_now_utc(o, now);
        }
    });
}

export function filter_inactive_list(items) {
    var now = moment();

    return _.filter(items, function (o) {
        if (!o) {
            return false;
        } else {
            return !is_item_active(o, now);
        }
    });
}

/* Take the items and filter on active + current Item and then sort them  for a SELECT's options */
export function sort_and_filter_active_list(
    result,
    current_id,
    showAll,
    textField,
    disabledSuffix,
    dontSort,
    sortField
) {
    if (!showAll) {
        var now = moment().utc();

        result = _.filter(result, function (o) {
            if (!o) {
                return false;
            } else {
                if (current_id) {
                    if (o.id === current_id) {
                        // Add (disabled) suffix if it was disabled but in the list..
                        if (!is_item_active_now_utc(o, now)) {
                            if (
                                !(o[textField] || "").endsWith(disabledSuffix)
                            ) {
                                o[textField] =
                                    o[textField] + " " + disabledSuffix;
                            }
                        }

                        return true;
                    }
                }

                return is_item_active_now_utc(o, now);
            }
        });
    }
    if (!dontSort) {
        result = _.sortBy(result, sortField || textField); // Sort by sortField or default to textField
    }

    return result;
}
