<template>
    <div v-if="file">
        <span class="file-info" v-if="file && !hideFileInfo"
            >{{ file.original_filename }} ({{ file_size }})</span
        >
        <a
            :href="downloadURL"
            target="_downloadWindow"
            v-if="!disableDownload"
            class="px-2"
            >Download <cloud-download-icon />
        </a>
    </div>
</template>

<script>
import CloudDownloadIcon from "vue-material-design-icons/CloudDownload.vue";
import GoMixin from "../mixins/GoMixin.js";
import { CreateDownloadURL } from "./fs_utils.js";

export default {
    name: "OFileDownloadLink",
    mixins: [GoMixin],
    components: { CloudDownloadIcon },
    props: {
        file: {
            type: Object,
            default: () => {},
        },
        disableDownload: { type: Boolean, default: false },
        hideFileInfo: { type: Boolean, default: false },
        width: { type: String, default: "800px" },
        token: { type: String, default: "" },
    },
    computed: {
        downloadURL: function () {
            if (this.file) {
                return CreateDownloadURL(this.file.id, this.token, true);
            } else {
                return "";
            }
        },
        file_size() {
            if (this.file) {
                var bytes = +this.file.file_size;

                if (!bytes) return "0 Bytes";

                const k = 1024;
                const sizes = [
                    "Bytes",
                    "KiB",
                    "MiB",
                    "GiB",
                    "TiB",
                    "PiB",
                    "EiB",
                    "ZiB",
                    "YiB",
                ];

                const i = Math.floor(Math.log(bytes) / Math.log(k));
                const dm = bytes < 10 && i > 0 ? 1 : 0;

                return (
                    parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +
                    " " +
                    sizes[i]
                );
            } else {
                return "";
            }
        },
    },
};
</script>

<style scoped></style>
