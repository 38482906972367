<template>
  <div class="positionMe">
    <table class="table table-sm table-striped">
      <thead class="thead-dark">
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Entries</th>
          <th scope="col">Loading</th>
        </tr>
      </thead>

      <tr :key="mod.name" v-for="mod in filteredModules">
        <td>{{ mod.label }}</td>
        <td class="text-right">{{ module_stats[mod.name].total_count }}</td>
        <td>
          <span
            class="badge badge-light"
            v-if="!module_stats[mod.name].working"
          ></span>
          <span
            class="badge badge-success"
            v-if="module_stats[mod.name].working"
            >Working</span
          >
        </td>
      </tr>

      <tr>
        <td colspan="3">
          <button @click="reload" class="btn btn-danger btn-block">
            Reload
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import _ from "lodash";
import cache_modules_list from "../app/store";
import { GETTER_TYPES } from "../vue-ocom/store/store_keys";
import { mapGetters } from "vuex";

const all_getters = {};

// SW: There has to be a better way..
_.forEach(cache_modules_list, function (val, name) {
  if (name !== "GlobalStateModule" && name !== "FileSystemModule") {
    all_getters[name + "_total_count"] = name + "/" + GETTER_TYPES.TOTAL_ITEMS;
    all_getters[name + "_working"] = name + "/" + GETTER_TYPES.WORKING;
  }
});

export default {
  name: "DownloadCodeData",
  data() {
    return {
      modules: _.keys(cache_modules_list),
    };
  },
  methods: {
    reload() {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to clear data? - You will have to login again!",
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) {
            // Yes!!
            localStorage.clear();
            sessionStorage.clear();

            var DBDeleteRequest = indexedDB.deleteDatabase(name);
            DBDeleteRequest.onerror = function (event) {
              console.log("Error deleting database.", event);
            };

            DBDeleteRequest.onsuccess = function (event) {
              console.log("Database deleted successfully");

              console.log(event.result); // should be undefined

              window.location.reload(); // reload page and restart app.
            };
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  computed: {
    ...mapGetters(all_getters),
    filteredModules() {
      const camelToSnakeCase = (str) =>
        str.replace(/[A-Z]/g, (letter) => ` ${letter}`);
      return _.filter(
        _.map(this.modules, function (module) {
          if (
            module !== "GlobalStateModule" &&
            module !== "FileSystemModule" &&
            !module.startsWith("Group")
          ) {
            var label = camelToSnakeCase(
              module.replace("Code", "").replace("Module", "")
            );

            return { name: module, label: label };
          }
          return null;
        })
      );
    },
    module_stats() {
      const vm = this;

      const result = {};
      _.forEach(this.modules, function (name) {
        if (name !== "GlobalStateModule" && name !== "FileSystemModule") {
          result[name] = {
            total_count: vm[name + "_total_count"],
            working: vm[name + "_working"],
          };
        }
      });

      return result;
    },
  },
};
</script>

<style scoped>
.positionMe {
  top: 100px;
  position: absolute;
  right: 0;
  width: 285px;
  border: 2px #343a40 solid;
  background-color: ghostwhite;
  z-index: 100;
}
</style>
