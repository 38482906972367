import { render, staticRenderFns } from "./OActionButtons.vue?vue&type=template&id=74a95a56&scoped=true&"
import script from "./OActionButtons.vue?vue&type=script&lang=js&"
export * from "./OActionButtons.vue?vue&type=script&lang=js&"
import style0 from "./OActionButtons.vue?vue&type=style&index=0&id=74a95a56&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74a95a56",
  null
  
)

export default component.exports