<template>
  <b-button-group>
    <b-button variant="outline-dark" :style="{ color: color }">{{
      text
    }}</b-button>
    <b-button variant="dark" :style="{ color: color }">{{ text }}</b-button>
  </b-button-group>
</template>

<script>
export default {
  name: "ColorTestButtons",
  props: {
    color: { type: String, default: "black" },
    text: { type: String, default: "" },
  },
};
</script>

<style scoped></style>
