<template>
    <o-form-group
        :form-state="formState"
        :label="label"
        :message="errorMessages"
        :nolabel="nolabel"
        :type="{ 'is-danger': hasError }"
    >
        <BButton
            :variant="state_value.variant"
            @click="changeState"
            :disabled="_is_readonly"
        >
            <check-icon v-if="current_state === 1"></check-icon>
            {{ state_value.label }}</BButton
        >
    </o-form-group>
</template>

<script>
import { BButton } from "bootstrap-vue";
import { is_item_active } from "../../../utils/active.js";
import CheckIcon from "vue-material-design-icons/Check.vue";
import FormComponentMixin from "vue-ocom/mixins/form_component_mixin";

export default {
    name: "OActiveButton",
    components: {
        BButton,
        CheckIcon,
    },
    mixins: [FormComponentMixin],
    props: {
        item: {
            /* The item with the fields to set */ type: Object,
            default: null,
        },
        neverActiveLabel: {
            /* Label when Never been active */ type: String,
            default: "Inactive",
        },
        neverActiveVariant: {
            /* Variant when Never been active */ type: String,
            default: "outline-secondary",
        },
        inactiveLabel: {
            /* Label when Inactive */ type: String,
            default: "Inactive",
        },
        inactiveVariant: {
            /* Variant when Inactive */ type: String,
            default: "outline-secondary",
        },
        activeLabel: {
            /* Label when Active */ type: String,
            default: "Active",
        },
        activeVariant: {
            /*Variant when Active */ type: String,
            default: "success",
        },
        activeStartDateFieldName: {
            /* Field name of the active_start_date field */ type: String,
            default: "active_start_date",
        },
        activeEndDateFieldName: {
            /* Field name of the active_end_date field */ type: String,
            default: "active_end_date",
        },
    },
    methods: {
        changeState() {
            if (this.item) {
                if (this.item[this.activeStartDateFieldName] == null) {
                    this.$set(
                        this.item,
                        this.activeStartDateFieldName,
                        new Date(),
                    ); // Active from Now
                    this.on_change(null);
                } else {
                    const now = new Date();
                    if (is_item_active(this.item, now)) {
                        this.$set(
                            this.item,
                            this.activeEndDateFieldName,
                            new Date(),
                        ); // Inactive from Now
                        this.on_change(null);
                    } else {
                        this.$set(this.item, this.activeEndDateFieldName, null); // Re-activate from now
                        this.on_change(null);
                    }
                }
            }
        },
    },
    computed: {
        current_state() {
            if (this.item) {
                if (this.item[this.activeStartDateFieldName] == null) {
                    return 0; // Never Activated
                }

                const now = new Date();
                if (is_item_active(this.item, now)) {
                    return 1;
                } else {
                    return 2;
                }
            }
            return 0;
        },
        state_value() {
            var stateOptions = {
                0: {
                    label: this.neverActiveLabel,
                    variant: this.neverActiveVariant,
                },
                1: { label: this.activeLabel, variant: this.activeVariant },
                2: { label: this.inactiveLabel, variant: this.inactiveVariant },
            };

            return stateOptions[this.current_state];
        },
    },
};
</script>

<style scoped></style>
