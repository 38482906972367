<template>
  <LoginGuard>
    <div class="container">
      <global-select-customer></global-select-customer>
    </div>
    <b-alert :show="!rules.hasCustomer" variant="danger"
      >Please select a customer to start working.</b-alert
    >
    <o-section-dashboard-menu :items="items" :rules="rules" />
  </LoginGuard>
</template>

<script>
import LoginGuard from "../vue-ocom/auth/components/LoginGuard";
import MENU_ITEMS from "./menu_items";
import OSectionDashboardMenu from "@/vue-ocom/components/layout/app/OSectionDashboardMenu";
import { mapGetters } from "vuex";
import GlobalSelectCustomer from "@/components/GlobalSelectCustomer.vue";

export default {
  components: {
    GlobalSelectCustomer,
    OSectionDashboardMenu,
    LoginGuard,
  },
  data: () => ({
    items: MENU_ITEMS,
  }),
  computed: {
    ...mapGetters("GlobalStateModule", ["data"]),

    rules() {
      return {
        hasCustomer: !!this.data.customer_id,
      };
    },
  },
};
</script>
