<template>
  <o-form-group
    :form-state="formState"
    :label="label"
    :message="errorMessages"
    :nolabel="nolabel"
    :type="{ 'is-danger': hasError }"
  >
    <o-select-typeahead
      :disabled="_is_readonly"
      :items="options"
      :name="name"
      :value="selected_id"
      nolabel
      text-field="text"
      value-field="value"
      @input="change"
    ></o-select-typeahead>
  </o-form-group>
</template>

<script>
import { toSelectOptions } from "../vue-ocom/components/tree/utils";
import _ from "lodash";
import FormComponentMixin from "../vue-ocom/mixins/form_component_mixin";
import OSelectTypeahead from "@/components/OSelectTypeahead";

export default {
  name: "OTreeSelect",
  mixins: [FormComponentMixin],
  components: { OSelectTypeahead },
  model: {
    value: "value",
    event: "input",
  },
  props: {
    items: { type: Array, default: () => [] },
    idField: { type: String, default: "id" },
    textField: { type: String, default: "description" },
    parentsArrayField: { type: String, default: "parents" },
    selfId: { type: Number, default: null },
    value: { type: [Array, Number], default: null },
    valueType: {
      type: String,
      default: "array",
    } /* array = array to this node, id= Id of the selected node only*/,
    required: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  methods: {
    change(node) {
      if (this.valueType === "array") {
        var found = _.find(this.items, { [this.idField]: node });

        var result = null;

        if (found) {
          result = found[this.parentsArrayField];
        }

        result = _.clone(result);
        result.push(node); // add selected node to array

        this.on_change(result);
      } else {
        this.on_change(node); // the ID
      }
      if (this.formState) {
        this.formState.dirty = true;
      }
    },
  },
  computed: {
    options() {
      return toSelectOptions(
        this.items,
        this.idField,
        this.textField,
        this.parentsArrayField,
        this.selfId,
        this.required
      );
    },
    selected_id() {
      if (this.value || this.value === 0) {
        if (this.valueType === "array") {
          return this.value[this.value.length - 1];
        } else {
          return this.value; // it's the id already
        }
      } else {
        return null;
      }
    },
  },
};
</script>
