import * as CONFIG from "@/config.js";

export function CreateDownloadURL(file_id, token, download) {
    if (file_id) {
        const ExampleURL = "http://example.ocom.com.au";
        var noHost = false;
        var baseURL = CONFIG.root_url || "";

        if (baseURL === "") {
            noHost = true;
            baseURL = ExampleURL; // SO URL will work
        }

        var uri = "/filesystem/download/";
        if (CONFIG.GoBackend === true) {
            uri = "/fs/download/";
        }

        var result = new URL(baseURL + uri + file_id + "/");

        if (token) {
            result.searchParams.set("jwt", token);
        }
        if (download === true) {
            result.searchParams.set("download", "true");
        } else {
            if (download === false) {
                result.searchParams.set("download", "false");
            }
        }

        var result_url = result.toString();

        if (noHost) {
            result_url = result_url.replace(ExampleURL, "");
        }

        return result_url;
    }

    return null;
}
