import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default {
    props: {
        storeName: { type: String, default: null },
    },
    data() {
        return {
            item: {},
        };
    },
    methods: {
        create_new_item(new_item, success_callback) {
            console.log("Create new!", new_item);
            let item = _.clone(new_item);

            var vm = this;

            if (typeof this.the_store.saveItem !== "undefined") {
                this.the_store
                    .saveItem(item)
                    .then((response) => {
                        // reload all..
                        this.the_store.getAll({
                            limit: 2000,
                            offset: 0,
                            _code: true,
                        }); // Update Code List

                        if (success_callback) {
                            success_callback(response);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        alert("An error occurred!");
                    });
            } else {
                alert("Store functions undefined!");
            }
        },
    },
    computed: {
        the_store() {
            if (this.storeName) {
                var vm = this;

                //Dynamically hook up the Actions and Getters - these will be accessible from this.store and just this.
                var storeName = this.storeName + "Module";

                vm = Object.assign(
                    vm,
                    mapActions(storeName, [
                        "getAll",
                        "createNew",
                        "saveItem",
                        "deleteItem",
                    ]),
                );
                vm = Object.assign(vm, mapGetters(storeName, ["working"]));

                return vm;
            }

            return new Vue({}); // empty store
        },
    },
};
