<template>
    <div>
        <h3 @click="enableDebug($event)" class="display-4">Manage Users</h3>

        <o-list-toolbar
            :filters-list="filter_list"
            :list-options="list_options"
            :new-to="{ name: 'user.create', params: { id: 0 } }"
            :query-select-options="options"
            :schema="schema"
            :search-fields-list="search_field_list"
            :selected-filter="selected_filter"
            @filter_changed="update_filter($event)"
            @options_changed="update_table($event)"
            @reset_table="reset_table"
            new-button-text="New User"
        >
        </o-list-toolbar>

        <BTable
            responsive
            stacked="sm"
            :fields="fields"
            :items="items"
            :sort-by="list_options.sort"
            :sort-desc="list_options.order == 'desc'"
            @sort-changed="change_sort"
            bordered
            class="o-list"
            head-variant="light"
            no-local-sorting
            striped
            thead-class="o-gradient-header"
        >
            <template #cell(email)="{ index, item }">
                {{ item.email }}
                <a
                    v-if="item.email"
                    :href="'mailto:' + item.email"
                    target="email"
                >
                    <email-icon />
                </a>
            </template>
            <template
                slot="top-row"
                slot-scope="{ columns, fields }"
                v-if="filterRow"
            >
                <td :key="field.key" class="p-0" v-for="field in fields">
                    <span v-if="field.key === 'username'">
                        <BFormInput
                            @change="filter_changed"
                            class="rounded-0 px-0 hide-filters"
                            type="search"
                            v-model="list_options.qbe.username"
                        ></BFormInput>
                    </span>
                    <span v-if="field.key === 'first_name'">
                        <BFormInput
                            @change="filter_changed"
                            class="rounded-0 px-0 hide-filters"
                            type="search"
                            v-model="list_options.qbe.first_name"
                        ></BFormInput>
                    </span>
                    <span v-if="field.key === 'last_name'">
                        <BFormInput
                            @change="filter_changed"
                            class="rounded-0 px-0 hide-filters"
                            type="search"
                            v-model="list_options.qbe.last_name"
                        ></BFormInput>
                    </span>
                    <span v-if="field.key === 'email'">
                        <BFormInput
                            @change="filter_changed"
                            class="rounded-0 px-0 hide-filters"
                            type="search"
                            v-model="list_options.qbe.email"
                        ></BFormInput>
                    </span>
                </td>
            </template>
            <template #cell(username)="{ index, item }">
                <router-link
                    :to="{ name: 'user.edit', params: { id: item.id } }"
                >
                    {{ item.username }}
                </router-link>
            </template>
            <template #cell(first_name)="{ index, item }">
                <default-display :value="item.first_name"></default-display>
            </template>
            <template #cell(last_name)="{ index, item }">
                <default-display :value="item.last_name"></default-display>
            </template>
            <template #cell(is_staff)="{ index, item }">
                <boolean-display :value="item.is_staff"></boolean-display>
            </template>
            <template #cell(is_active)="{ index, item }">
                <span class="badge badge-secondary" v-if="!item.is_active">
                    Inactive
                </span>
                <span class="badge badge-success" v-if="item.is_active">
                    Active
                </span>
            </template>

            <template #cell(sudo)="{ item }">
                <BButton variant="danger" size="sm" @click="sudo(item.id)"
                    >SUDO</BButton
                >
            </template>
            <template slot="empty">
                <p>
                    <emoticon-sad-icon large></emoticon-sad-icon>
                </p>
                No Data Found. Please
                <router-link :to="{ name: 'user.create' }"
                    >create a new item</router-link
                >
            </template>
        </BTable>

        <o-list-paging
            :limit="list_options.limit"
            :offset="list_options.offset"
            :page-options="rows_per_page_items"
            :total-items="total_items"
            @change-limit="change_limit"
            @change-page="change_page"
        />
    </div>
</template>

<style></style>

<script>
import { BButton, BFormInput, BTable } from "bootstrap-vue";
import {
    CreateCodeTableFetchMixin,
    CreateGroupPermissionMixin,
    CreateListPageMixin,
} from "../../../mixins";
import {
    fieldToTableColumn,
    GetSearchFields,
} from "../../../schema/schema_utils.js";
import schema from "../../schema/index.js";

import OListToolbar from "../../../components/list/OListToolbar.vue";
import OListPaging from "../../../components/list/OListPaging.vue";
import DefaultDisplay from "../../../components/display/DefaultDisplay.vue";
import DateDisplay from "../../../components/display/DateDisplay.vue";
import NumberDisplay from "../../../components/display/NumberDisplay.vue";
import BooleanDisplay from "../../../components/display/BooleanDisplay.vue";
import LookupDisplay from "../../../components/display/LookupDisplay.vue";
import EmoticonSadIcon from "vue-material-design-icons/EmoticonSad.vue";
import EmailIcon from "vue-material-design-icons/Email.vue";
import LinkIcon from "vue-material-design-icons/Link.vue";
import { mapActions, mapGetters } from "vuex";
import GoMixin from "../../../mixins/GoMixin.js";

export default {
    components: {
        BTable,
        BFormInput,
        BButton,
        EmoticonSadIcon,
        EmailIcon,
        LinkIcon,
        OListPaging,
        OListToolbar,
        DateDisplay,
        DefaultDisplay,
        BooleanDisplay,
        NumberDisplay,
        LookupDisplay,
    },
    mixins: [
        CreateListPageMixin("User", "user"),
        CreateGroupPermissionMixin("User"),
        CreateCodeTableFetchMixin([]),
        GoMixin,
    ],

    data: function () {
        return {
            schemaModel: schema.User,

            search_field_list: GetSearchFields(schema.User),

            filter_list: [
                {
                    name: "Active",
                    code: "active",
                    options: { offset: 0, sort: "email" },
                },
                {
                    name: "Non Active",
                    code: "inactive",
                    options: { offset: 0, sort: "email" },
                },
                {
                    name: "All",
                    code: "all",
                    options: { offset: 0, sort: "email" },
                },
            ],
            filterRow: true,
        };
    },
    methods: {
        ...mapActions("AuthModule", ["sudo"]),
    },
    computed: {
        ...mapGetters("AuthModule", ["currentUser"]),

        options() {
            return {};
        },
        fields() {
            var results = [
                fieldToTableColumn(this.schema, "username"),
                fieldToTableColumn(this.schema, "first_name"),
                fieldToTableColumn(this.schema, "last_name"),
                fieldToTableColumn(this.schema, "email"),
                fieldToTableColumn(this.schema, "is_staff"),
                fieldToTableColumn(this.schema, "is_active"),
            ];

            var can_sudo = false;
            if (this.isGoBackend) {
                // For go user is_superuser
                can_sudo = this.currentUser && this.currentUser.is_superuser;
            } else {
                // For Django user is_staff
                can_sudo = this.currentUser && this.currentUser.is_staff;
            }
            if (can_sudo) {
                results.push({ key: "sudo", label: "Sudo", sortable: false });
            }
            return results;
        },
    },
};
</script>
<style scoped>
@media only screen and (max-width: 600px) {
    .hide-filters {
        display: none;
    }
}
</style>
