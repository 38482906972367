<template>
  <o-form-wrapper
    :form-state="formState"
    :schema="schema"
    :server-errors="_se"
    :v="$v.item"
    v-model="item"
  >
    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="description"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="description"
        v-bind="schema.fields.description"
        v-model="item.description"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="customer"
    >
      <o-select
        :form-state="formState"
        :items="current_customer"
        :loading="optionCustomerWorking"
        :server-errors="_se"
        :validators="$v.item"
        add-title="Create New"
        allow-add
        name="customer"
        sort-field="name"
        store-name="Customer"
        text-field="name"
        v-bind="schema.fields.customer"
        v-model="item.customer"
        value-field="id"
      >
        <template slot="form" slot-scope="{ item, formState, serverErrors }">
          <customer-form
            :form-state="formState"
            :item="item"
            :server-errors="serverErrors"
            in-modal
          ></customer-form>
        </template>
      </o-select>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="location_name_sub_1"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="location_name_sub_1"
        v-bind="schema.fields.location_name_sub_1"
        v-model="item.location_name_sub_1"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="location_name_sub_2"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="location_name_sub_2"
        v-bind="schema.fields.location_name_sub_2"
        v-model="item.location_name_sub_2"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="parents"
    >
      <o-tree-path-select
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="parents"
        v-bind="schema.fields.parents"
        :items="asset_locations"
        v-model="item.parents"
        :self-id="item.id"
      ></o-tree-path-select>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="location_type"
    >
      <o-select
        :form-state="formState"
        :items="optionCodeLocationTypeItems"
        :loading="optionCodeLocationTypeWorking"
        :server-errors="_se"
        :validators="$v.item"
        add-title="Create New"
        allow-add
        name="location_type"
        sort-field="description"
        store-name="CodeLocationType"
        text-field="description"
        v-bind="schema.fields.location_type"
        v-model="item.location_type"
        value-field="id"
      >
        <template slot="form" slot-scope="{ item, formState, serverErrors }">
          <code-location-type-form
            :form-state="formState"
            :item="item"
            :server-errors="serverErrors"
            in-modal
          ></code-location-type-form>
        </template>
      </o-select>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="active_start_date"
    >
      <o-date-time
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="active_start_date"
        v-bind="schema.fields.active_start_date"
        v-model="item.active_start_date"
      ></o-date-time>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="active_end_date"
    >
      <o-date-time
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="active_end_date"
        v-bind="schema.fields.active_end_date"
        v-model="item.active_end_date"
      ></o-date-time>
    </o-widget-wrapper>
  </o-form-wrapper>
</template>

<script>
import schema from "../../schema/index";
import { cleanUpModel } from "../../../vue-ocom/schema/schema_vuetify";
import {
  CreateFormStateMixin,
  CreateGroupPermissionMixin,
  VuelidateMixin,
} from "../../../vue-ocom/mixins";
import { validationMixin } from "vuelidate";
import OWidgetWrapper from "../../../vue-ocom/components/forms/OWidgetWrapper";
import OTextField from "../../../vue-ocom/components/forms/fields/OTextField";
import OSelect from "../../../vue-ocom/components/forms/fields/OSelect";
import OCheckbox from "../../../vue-ocom/components/forms/fields/OCheckbox";
import OFormWrapper from "../../../vue-ocom/components/forms/OFormWrapper";
import ODateTime from "../../../vue-ocom/components/forms/fields/ODateTime";
import OTreePathSelect from "../../../vue-ocom/components/tree/OTreePathSelect";
import { default as CodeLocationTypeForm } from "../../code_location_type/components/ItemForm";
import { default as CustomerForm } from "../../customer/components/ItemForm";

import UseCodeModulesMixin from "@/vue-ocom/mixins/UseCodeModulesMixin";

import { filter } from "lodash";
import { mapGetters } from "vuex";

export default {
  mixins: [
    VuelidateMixin,
    validationMixin,
    CreateFormStateMixin("$v"),
    CreateGroupPermissionMixin("AssetLocation"),
    UseCodeModulesMixin(["CodeLocationType", "Customer", "AssetLocationMan"]),
  ],
  components: {
    OFormWrapper,
    OWidgetWrapper,
    ODateTime,
    OTreePathSelect,
    OCheckbox,
    OSelect,
    OTextField,

    CodeLocationTypeForm,
    CustomerForm,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    level: { type: Number, default: 0 },
    inModal: { type: Boolean, default: false },
  },
  data: () => ({
    schemaModel: cleanUpModel(schema.AssetLocation, {}),
  }),
  methods: {},
  computed: {
    ...mapGetters("GlobalStateModule", { global_data: "data" }),
    current_customer() {
      return filter(this.optionCustomerItems, {
        id: this.global_data.customer_id,
      });
    },
    asset_locations() {
      if (this.item.customer_id) {
        const customer_id = this.item.customer_id;

        var filteredList = filter(this.optionAssetLocationManItems, {
          customer: customer_id,
        });

        if (filteredList.length > 0) return filteredList;
      }
      return this.optionAssetLocationManItems;
    },
    _se() {
      // Return an empty Object if there are no Server Errors to avoid Binding Errors
      return this.serverErrors || {};
    },
  },
  watch: {},
  created() {},
};
</script>
