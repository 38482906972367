import { Field, FIELD_TYPES, Model } from "../../schema";

export default Model("content_type", {
    verbose_name: "Content Type",
    verbose_name_plural: "Content Types",
    fields: [
        Field("id", {
            label: "ID",
            primary_key: true,
            readonly: true,
            hide: false,
            field_type: FIELD_TYPES.AutoNumber,
        }),

        Field("app_label", {
            label: "App Label",
            primary_key: false,
            readonly: false,
            hide: false,
            required: true,
            field_type: FIELD_TYPES.String,
            default: () => {
                return "";
            },
        }),
        Field("model", {
            label: "Python Model Class Name",
            primary_key: false,
            readonly: false,
            hide: false,
            required: true,
            field_type: FIELD_TYPES.String,
            default: () => {
                return "";
            },
        }),
    ],
});
