<template>
    <div>
        <BButton variant="primary" @click="onGenerate">
            Generate password
        </BButton>
    </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

export default {
    components: {
        BButton,
    },
    data: () => ({
        characters: [
            {
                name: "Lowercase",
                value: "abcdefghijklmnopqrstuvwxyz",
                checked: true,
            },
            {
                name: "Uppercase",
                value: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
                checked: true,
            },
            {
                name: "Numbers",
                value: "0123456789",
                checked: true,
            },
            {
                name: "Special Characters",
                value: "_-+=)(*&^%$#@!`~",
                checked: true,
            },
        ],
        passwordLength: 9,
        genPassword: "",
        passwordScore: "",
    }),
    methods: {
        onGenerate() {
            const vm = this;
            let result = "";
            let charactersVal = "";
            for (var j = 0; j < this.characters.length; j++) {
                if (this.characters[j].checked) {
                    charactersVal += this.characters[j].value;
                }
            }
            for (var i = 0; i < this.passwordLength; i++) {
                result += charactersVal.charAt(
                    Math.floor(Math.random() * charactersVal.length),
                );
            }
            vm.genPassword = result;
            vm.$emit("on-generate", vm.genPassword);
        },
    },
};
</script>

<style scoped></style>
