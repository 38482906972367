<template>
    <div class="col-sm-12 form-inline">
        <select class="col-sm-2 custom-select" v-model="value[0]">
            <option :value="null">-- Select Field --</option>
            <option
                :key="field.name"
                v-bind:value="field.name"
                v-for="field in field_list"
            >
                {{ field.label }}
            </option>
        </select>

        <select
            @change="selectOperation(value)"
            class="col-sm-1 custom-select"
            v-model="value[1]"
        >
            <option
                :key="op.code"
                v-bind:value="op.code"
                v-for="op in operation_options"
            >
                {{ op.name }}
            </option>
        </select>

        <query-value-input
            :key="param_index"
            :param-index="param_index"
            :field-def="fieldDef"
            :query-select-options="querySelectOptions"
            :special-options="specialOptions"
            :value="value"
            :value-type="valueType"
            v-for="(valueType, param_index) in operation_values"
        />

        <div class="col-sm-1 self-end">
            <BButton @click="removeField" variant="outline-danger">
                <close-circle-outline-icon></close-circle-outline-icon>
            </BButton>
        </div>
    </div>
</template>
<script>
import { BButton } from "bootstrap-vue";
import _ from "lodash";
import { FIELD_TYPES } from "../../../schema";
import CloseCircleOutlineIcon from "vue-material-design-icons/CloseCircleOutline.vue";
import QueryValueInput from "./QueryValueInput.vue";

export default {
    name: "QueryField",
    components: {
        BButton,
        QueryValueInput,
        CloseCircleOutlineIcon,
    },

    data() {
        return {};
    },
    props: {
        filterOption: {
            type: Object,
            default: () => {},
        },
        value: {},
        metaData: {
            type: Object,
            default: () => {},
        },
        specialOptions: {
            type: Object,
            default: () => {},
        },
        querySelectOptions: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        operation_options() {
            if (this.fieldDef) {
                return this.filterOption[this.fieldDef.field_type];
            } else {
                return [];
            }
        },
        operation_values() {
            if (this.operation != null) {
                return this.operation.values;
            } else {
                return [];
            }
        },
        fieldDef() {
            var fieldName = this.value[0];

            if (!fieldName) return null;

            return _.find(this.metaData.fields, function (item) {
                return item.name === fieldName;
            });
        },
        operation() {
            var result = null;

            if (this.value) {
                if (!this.value[0] || !this.value[1]) return null;

                if (this.fieldDef) {
                    var options = this.filterOption[this.fieldDef.field_type];

                    var fieldRow = this.value;
                    result = _.find(options, function (item) {
                        return item.code === fieldRow[1];
                    });
                }
            }

            return result;
        },
        field_list() {
            var results = [];

            var allowed_types = {
                [FIELD_TYPES.Boolean]: true,
                [FIELD_TYPES.String]: true,
                [FIELD_TYPES.Integer]: true,
                [FIELD_TYPES.Decimal]: true,
                [FIELD_TYPES.Number]: true,
                [FIELD_TYPES.Money]: true,
                [FIELD_TYPES.Date]: true,
                [FIELD_TYPES.DateTime]: true,
                [FIELD_TYPES.ForeignKey]: true,
            };

            _.each(this.metaData.fields, function (field) {
                if (
                    ("hide" in field && field.hide === true) ||
                    field.virtual === true
                ) {
                    return;
                }

                if (allowed_types[field.field_type] === true) {
                    results.push(field);
                }
            });

            return results;
        },
    },
    methods: {
        selectOperation(value) {
            var operations = this.operation;

            if (operations) {
                // Trim the array if it's too long
                while (value.length > operations.values.length + 2) {
                    this.$delete(value, value.length - 1);
                }

                // set defaults
                _.forEach(operations.values, function (val, index) {
                    value[index + 2] = null;
                });
            }
        },
        getSpecialName(specialOptions, specialCode) {
            var result = _.find(specialOptions, function (item) {
                return item.code === specialCode;
            });

            if (result) {
                return result.name;
            } else {
                return "";
            }
        },
        removeField() {
            this.$emit("remove", this.field);
        },
    },
};
</script>

<style scoped>
input[type="checkbox"] {
    margin-left: 1em;
}
</style>
