<template>
    <span :class="get_class" v-html="display_value"></span>
</template>

<script>
import DisplayMixin from "../../mixins/display_mixin.js";

export default {
    name: "MultiLineDisplay",
    props: {
        value: { default: null },
        defaultValue: { default: "" },
    },
    mixins: [DisplayMixin("display_value")],
    computed: {
        display_value() {
            var result = this.defaultValue + "";
            if (this.value) result = this.value + "";

            result = result.replace(/(?:\r\n|\r|\n)/g, "<br>");

            return result;
        },
    },
};
</script>

<style scoped></style>
