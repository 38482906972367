<template>
    <o-form-group
        :form-state="formState"
        :message="errorMessages"
        :type="{ 'is-danger': hasError }"
    >
        <BFormCheckbox
            :class="input_class"
            :disabled="_is_readonly"
            v-bind="$attrs"
            v-bind:checked="value"
            v-on="inputListeners"
        >
            <span v-if="!nolabel">{{ label }}</span>
        </BFormCheckbox>
    </o-form-group>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
import FormComponentMixin from "../../../mixins/form_component_mixin.js";
import OFormGroup from "../OFormGroup.vue";

export default {
    name: "OCheckbox",
    components: {
        BFormCheckbox,
        OFormGroup,
    },
    mixins: [FormComponentMixin],
    methods: {
        on_input(event) {
            // Noop as the User didn't change it
        },
        on_change(event) {
            if (event == null) if (this.value !== false) event = false; // convert Null to False for checkboxes

            console.log("event change");
            this.makeDirty();
            if (this.validator) {
                this.validator.$touch();
            }

            this.changed = true;
            this.$emit("reset");
            this.$emit("input", event); // Emit input so v-model props works!
        },
    },
};
</script>

<style scoped></style>
