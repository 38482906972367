import { Field, FIELD_TYPES, Model } from "../../vue-ocom/schema";

export default Model("product", {
  verbose_name: "Product",
  verbose_name_plural: "Products",
  fields: [
    Field("id", {
      label: "ID",
      primary_key: true,
      readonly: true,
      hide: false,
      field_type: FIELD_TYPES.AutoNumber,
    }),

    Field("modified_date", {
      label: "Modified Date",
      primary_key: false,
      readonly: true,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("created_date", {
      label: "Created Date",
      primary_key: false,
      readonly: true,
      hide: false,
      required: true,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("active_start_date", {
      label: "Active Start Date",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return new Date();
      },
    }),
    Field("active_end_date", {
      label: "Active End Date",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("product_type", {
      label: "Product Type",
      primary_key: false,
      readonly: false,
      required: true,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodeProductType",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field("make", {
      label: "Make",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return "";
      },
    }),
    Field("model", {
      label: "Model",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return "";
      },
    }),
    Field("electrical_class", {
      label: "Electrical Class",
      primary_key: false,
      readonly: false,
      required: true,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodeElectricalClass",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field("color", {
      label: "Color",
      primary_key: false,
      readonly: false,
      required: true,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodeColor",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field("asset_classification", {
      label: "Asset Classification",
      primary_key: false,
      readonly: false,
      required: true,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodeAssetClassification",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field("phase", {
      label: "Phase",
      primary_key: false,
      readonly: false,
      required: true,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodePhase",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field("recall", {
      label: "Recall",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Boolean,
      default: () => {
        return null;
      },
    }),
    Field("replacement_part", {
      label: "Replacement Part",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      },
    }),
    Field("description", {
      label: "Description",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return "";
      },
    }),
    Field("original_id", {
      label: "Original ID",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      },
    }),
    Field("quick_display_order", {
      label: "Quick Display Order",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      },
    }),
    Field("test_frequency", {
      label: "Test Frequency",
      primary_key: false,
      readonly: false,
      required: true,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodeTestFrequency",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
    Field('supplied', {
      label: "Supplied",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Boolean,
      default: () => { return false; }
    }),
  ],
});
