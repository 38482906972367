/* eslint-disable no-unused-vars */
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default function (moduleName, modelName, default_filter) {
    default_filter = default_filter || "active";

    return {
        components: {},
        props: {
            tableInline: {
                type: Boolean,
                default: false,
            } /* When true doesn't update route */,
        },
        data: function () {
            var query = this.$route.query.query;

            if (query) {
                query = JSON.parse(query); // convert if it is there!
            }

            var qbe = this.$route.query.qbe;
            if (qbe) {
                qbe = JSON.parse(qbe);
            } else {
                qbe = {};
            }

            return {
                loading: false,

                list_options: {
                    //Filter
                    filter: this.$route.query.filter || default_filter,
                    query: query,

                    // Searching
                    q: this.$route.query.q || "",
                    searchField: this.$route.query.searchField || "",
                    qbe: qbe,

                    //Pagination-specific
                    offset: parseInt(this.$route.query.offset || 0),
                    limit: parseInt(this.$route.query.limit || 10),
                    // Sort
                    sort: this.$route.query.sort || "id",
                    order: this.$route.query.order || "asc",
                },

                selected_filter: this.$route.query.filter || default_filter,

                filter_list_by_code: null,

                rows_per_page_items: [2, 5, 10, 25, 50, 100],

                /* for QBE inputs */
                formState: {
                    debug: false,
                },

                _se: {},
            };
        },
        methods: {
            reset_table() {
                this.list_options["filter"] = default_filter;
                this.list_options["q"] = "";
                this.list_options["searchField"] = "";

                this.update_table(this.list_options);
            },
            before_get_all(list_options) {
                /* Overwrite this to update the list_options before the data is changed
                 * NOT: use _.cloneDeep(list_options) so the url is not changed
                 * */
                return _.cloneDeep(list_options);
            },
            update_table(list_options) {
                if (!list_options) {
                    list_options = this.list_options;
                }

                // Tidy up the list_options to make sure they have a smart default
                this.$set(
                    this.list_options,
                    "searchField",
                    list_options.searchField,
                );

                this.$set(this.list_options, "sort", list_options.sort || "id");
                this.$set(
                    this.list_options,
                    "order",
                    list_options.order || "asc",
                );

                this.$set(
                    this.list_options,
                    "offset",
                    list_options.offset || 0,
                );
                this.$set(this.list_options, "limit", list_options.limit || 10);

                this.getAll(this.before_get_all(this.list_options));

                /* Update the URL to match the state */
                // Make the query a JSON STRING
                var router_query = _.cloneDeep(this.list_options);
                if (router_query.query) {
                    router_query.query = JSON.stringify(router_query.query);
                }

                if (router_query.qbe) {
                    router_query.qbe = JSON.stringify(router_query.qbe);
                } else {
                    router_query.qbe = ""; // nothing
                }

                if (!this.tableInline) {
                    router_query["_t"] = new Date().getTime();

                    this.$router.replace({
                        name: this.$route.name,
                        query: router_query,
                    });
                }
            },

            update_filter(selected_filter) {
                if (this.filter_list_by_code === null) {
                    // turn into Map for later..
                    this.filter_list_by_code = _.keyBy(
                        this.filter_list,
                        "code",
                    ); //
                }
                this.list_options.filter = selected_filter;

                var filter = this.filter_list_by_code[selected_filter];

                var list_options = this.list_options;

                // If found a filter and has default options then set them
                if (filter && "options" in filter) {
                    _.each(filter.options, function (val, key) {
                        list_options[key] = val;
                    });
                }

                this.update_table(list_options);
            },
            enableDebug(e) {
                if (e.shiftKey) {
                    this.formState.debug = !this.formState.debug;
                }
            },
            canSee(schema, field_name) {
                /* Here we can check if they have permission for the field */
                return true;
            },
            ...mapActions(moduleName + "Module", [
                "getAll",
                "deleteItem",
                "mergeItems",
                "saveItem",
            ]),

            change_limit(limit) {
                var list_options = this.list_options;

                list_options.limit = limit;
                list_options.offset = 0; // return back to 0

                this.update_table(list_options);
            },
            change_page(page) {
                var list_options = this.list_options;

                list_options["offset"] =
                    (page - 1) * (list_options.limit || 10);

                this.update_table(list_options);
            },
            change_sort(ctx) {
                var list_options = this.list_options;

                list_options.sort = ctx.sortBy;
                list_options.order = ctx.sortDesc ? "desc" : "asc";

                this.update_table(list_options);
            },
            filter_changed() {
                this.update_table(this.list_options);
            },
        },
        computed: {
            ...mapGetters(moduleName + "Module", [
                "items",
                "total_items",
                "working",
            ]),
            showClear() {
                return (
                    this.list_options.q ||
                    this.list_options.searchField ||
                    this.list_options.filter !== default_filter
                );
            },
        },
        created() {
            this.update_table(this.list_options);
        },
        mounted() {
            window.lockLeave = false; // Allow them to navigate off this page!
        },
    };
}
