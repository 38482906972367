<template>
    <LoginGuard>
        <router-view></router-view>
    </LoginGuard>
</template>

<script>
// this is the Home/index of Brokers
import LoginGuard from "../../../auth/components/LoginGuard.vue";

export default {
    components: {
        LoginGuard,
    },
    computed: {},
};
</script>
