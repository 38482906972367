<template>
    <div class="row">
        <div class="col-sm">
            <BCard no-body>
                <BTabs card :value="current_tab" @input="setCurrentTab">
                    <slot name="before-tabs"></slot>
                    <BTab
                        v-for="group in filtered_items"
                        :key="group.name"
                        :title="group.name"
                    >
                        <div class="row">
                            
                            <slot v-if="group.append" name="list_content"></slot>
                            <div
                                v-for="section in group.children"
                                :key="section.name"
                                class="dashboard-menu pb-4 col-sm-12 col-lg-6"
                            >
                                <div v-if="!group.append" class="list-group">
                                    <label class="list-group-item active">{{
                                        section.name
                                    }}</label>

                                    <router-link
                                        v-for="child in section.children"
                                        :key="child.title"
                                        :to="child.to"
                                        class="list-group-item list-group-item-action d-flex"
                                    >
                                        <div class="align-self-start">
                                            <component
                                                :is="child.icon + '-icon'"
                                            ></component>
                                        </div>

                                        <div class="align-self-start flex-fill">
                                            &nbsp; {{ child.title }}
                                        </div>

                                        <div class="align-self-end">
                                            <menu-right-outline-icon />
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </BTab>
                    <slot name="after-tabs"></slot>
                </BTabs>
            </BCard>
        </div>
    </div>
</template>
<script>
import { BCard, BTab, BTabs } from "bootstrap-vue";
import MenuRightOutlineIcon from "vue-material-design-icons/MenuRightOutline.vue";
import MenuMixin from "./MenuMixin.js";

export default {
    name: "OSectionDashboardMenu",
    mixins: [MenuMixin],
    components: {
        BCard,
        BTabs,
        BTab,
        MenuRightOutlineIcon,
    },
    data() {
        let current_tab = +(sessionStorage.getItem("current_tab") || "0");
        return {
            current_tab: current_tab,
        };
    },
    props: {
        items: {},
    },
    computed: {},
    methods: {
        setCurrentTab(value) {
            window.sessionStorage.setItem("current_tab", value);
            this.current_tab = value;
        },
    },
};
</script>
