/* eslint-disable no-unused-vars */
import Vue from "vue";
import _ from "lodash";
import { GETTER_TYPES } from "./store_keys.js";

const MUTATION_TYPES = {
    SET_LIST: "SET_LIST",
    CLEAR_LIST: "CLEAR_LIST",
    SET_ITEM: "SET_ITEM",
    START_WORKING: "START_WORKING",
    FINISH_WORKING: "FINISH_WORKING",
};

// ie UserModule("http://localhost:8000");
function CreateTheUserModule(api_base, uri) {
    var api = api_base + uri;

    function _getStoredState() {
        // NOTE this is in a function so we don't keep multiple copies of all this data in the local variables
        var result = {
            item: null,
            items: [],
            itemsById: {},
            code_items: [],
            code_itemsByID: {},
            total_items: 0,
            working: false,
        };

        var stored_state = localStorage.getItem("user_list");
        if (stored_state) {
            stored_state = JSON.parse(stored_state);

            result = { ...result, ...stored_state }; // overwrite the defaults with the stored State
            result.itemsByID = _.keyBy(result.items || [], "id");
            result.code_itemsByID = _.keyBy(result.code_items || [], "id");
        }

        return result;
    }

    function _storeState(state) {
        const value = _.pick(state, [
            "item",
            "items",
            "code_items",
            "total_items",
        ]);

        localStorage.setItem("user_list", JSON.stringify(value));
    }

    return {
        namespaced: true,
        state: _getStoredState(),
        mutations: {
            [MUTATION_TYPES.SET_LIST](state, items) {
                state.total_items = items.total_items;

                if ("items" in items) {
                    state.items = items.items;

                    let defaultItem = { [null]: "" };
                    let keyByItems = _.keyBy(items.items, "id");
                    state.itemsByID = { ...keyByItems, ...defaultItem };
                }
                if ("code_items" in items) {
                    state.code_items = items.code_items;

                    let defaultItem = { [null]: "" };
                    let keyByItems = _.keyBy(items.code_items, "id");
                    state.code_itemsByID = { ...keyByItems, ...defaultItem };
                }
                _storeState(state);
            },

            [MUTATION_TYPES.CLEAR_LIST](state) {
                state.items = [];
                state.itemsByID = {};

                state.code_items = [];
                state.code_itemsByID = {};

                state.total_items = 0;
                _storeState(state);
            },

            [MUTATION_TYPES.START_WORKING](state) {
                state.working = true;
            },
            [MUTATION_TYPES.FINISH_WORKING](state) {
                state.working = false;
            },
        },
        actions: {
            getAll({ rootGetters, commit }, list_options = {}) {
                return new Promise((resolve, reject) => {
                    if (rootGetters["AuthModule/isLoggedIn"]) {
                        console.log("Get All");

                        //TODO Support if code table is called
                        list_options.page = list_options.page || 1;
                        list_options.rowsPerPage =
                            list_options.rowsPerPage || 100;

                        list_options.offset =
                            (list_options.page - 1) *
                                list_options.rowsPerPage || 0;
                        list_options.limit = list_options.rowsPerPage;

                        var code_list = list_options["_code"] || false;

                        commit(MUTATION_TYPES.START_WORKING);

                        Vue.axios
                            .get(api, { params: list_options })
                            .then((response) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                // Ensure they have a descriptive_name of some sort!
                                _.forEach(
                                    response.data.results,
                                    function (item) {
                                        if (!("descriptive_name" in item)) {
                                            var full_name =
                                                item.first_name +
                                                " " +
                                                item.last_name;

                                            if (full_name.trim() !== "") {
                                                item.descriptive_name =
                                                    full_name;
                                            } else {
                                                item.descriptive_name =
                                                    item.email || item.username;
                                            }
                                        }
                                    },
                                );

                                const data = {
                                    total_items: response.data.count || 0,
                                };
                                if (code_list) {
                                    data.code_items =
                                        response.data.results || [];
                                } else {
                                    data.items = response.data.results || [];
                                }

                                commit(MUTATION_TYPES.SET_LIST, data);

                                resolve(data);
                            });
                    }
                });
            },
            getById({ rootGetters, commit }, id) {
                return new Promise((resolve, reject) => {
                    if (rootGetters["AuthModule/isLoggedIn"]) {
                        commit(MUTATION_TYPES.START_WORKING);

                        console.log("Get By ID", id);

                        Vue.axios
                            .get(api + id + "/", {})
                            .then((response) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                commit(MUTATION_TYPES.SET_ITEM, response.data);
                                resolve(response.data);
                            })
                            .catch((err) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                reject(err);
                            });
                    } else {
                        reject("NOT LOGGED IN");
                    }
                });
            },
        },
        getters: {
            item(state) {
                return state.item;
            },
            working(state) {
                return state.working;
            },
            items(state) {
                return state.items;
            },
            itemsByID(state) {
                return state.itemsById;
            },
            [GETTER_TYPES.CODE_ITEMS](state) {
                return state.code_items;
            },
            [GETTER_TYPES.CODE_ITEMS_BY_ID](state) {
                return state.code_itemsByID;
            },
            total_items(state) {
                return state.total_items;
            },
        },
    };
}

export function GoAuthUserModule(api_base) {
    return CreateTheUserModule(api_base, "/auth/auth_user");
}

export default function (api_base) {
    return CreateTheUserModule(api_base, "/api/user_list");
}
