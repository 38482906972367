<template>
  <div class="container o-form-page">
    <o-working-overlay :working="working"></o-working-overlay>
    <o-section :level="0" title="Asset" @click="enableDebug($event)">
      <asset-item-form
        :form-state="formState"
        :item="asset_item"
        :server-errors="serverErrors"
      ></asset-item-form>
    </o-section>
    <o-section :level="0" title="Test Results" @click="enableDebug($event)">
      <asset-test-item-form
        :form-state="formState"
        :item="asset_test_item"
        :server-errors="serverErrors"
        :with-test="true"
        :is-autofocus="false"
      ></asset-test-item-form>
    </o-section>
    <o-action-buttons
      :create="create"
      :form-state="formState"
      :item="asset_item"
      hide-save-only
      :show-create-and-new="false"
      @save="saveAssetAndTest"
      @saveAndClose="saveAssetAndTestAndClose"
    >
      <b-modal
        no-close-on-backdrop
        centered
        ref="list-asset-codes"
        id="list-asset-codes"
        title="List of Asset Codes"
        header-bg-variant="primary"
      >
        <template v-for="(code, index) in assetCodes">
          <CodeItem :key="index" :code="code" />
        </template>
      </b-modal>
      <template v-slot:left>
        <b-button
          v-b-modal.create-multiple
          :disabled="!isSaveAllowed"
          class="mr-2"
          variant="primary"
        >
          Create Multiples
        </b-button>

        <b-modal
          centered
          ref="create-multiple"
          id="create-multiple"
          title="Create Multiples"
          header-bg-variant="warning"
        >
          <p class="my-2">
            Enter total number of copies:
            <span class="font-weight-bold">{{ copies }}</span>
          </p>
          <b-form-input
            v-model="copies"
            type="number"
            @focus.native="$event.target.select()"
            placeholder="Copies to Create"
          ></b-form-input>
          <template v-slot:modal-footer="{ ok, cancel }">
            <b-button variant="secondary" @click="cancel()"> Cancel </b-button>
            <b-button variant="primary" @click="create_multiple()">
              Create {{ copies }} Assets and Tests
            </b-button>
          </template>
        </b-modal>
      </template>
    </o-action-buttons>
  </div>
</template>

<script>
import {
  CreateFormPageMixin,
  CreateGroupPermissionMixin,
  UnsavedMixin,
} from "../../../vue-ocom/mixins";
import AssetItemForm from "../../asset/components/ItemForm";
import AssetTestItemForm from "../../asset_test/components/ItemForm";
import OActionButtons from "../../../vue-ocom/components/forms/OActionButtons";
import OSection from "../../../vue-ocom/components/layout/OSection";
import OWorkingOverlay from "../../../vue-ocom/components/OWorkingOverlay";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import UseRestActionModuleMixin from "@/vue-ocom/mixins/UseRestActionModuleMixin";
import CodeItem from "../../asset/components/CodeItem.vue";

export default {
  mixins: [
    UnsavedMixin,
    CreateFormPageMixin("Asset", "asset"),
    CreateGroupPermissionMixin("Asset"),
    CreateFormPageMixin("AssetTest", "asset_test"),
    CreateGroupPermissionMixin("AssetTest"),
    UseRestActionModuleMixin("APIAction", "api_action"),
  ],
  components: {
    CodeItem,
    OSection,
    OWorkingOverlay,
    OActionButtons,
    AssetItemForm,
    AssetTestItemForm,
  },
  methods: {
    ...mapActions("AssetModule", {
      saveAssetItem: "saveItem",
      createNewAsset: "createNew",
    }),
    ...mapActions("AssetTestModule", {
      saveAssetTestItem: "saveItem",
      createNewAssetTest: "createNew",
    }),

    doCreateNew() {
      const vm = this;
      this.createNewAsset().then((result) => {
        vm.asset_item = _.clone(result); // Copy out of vuex

        vm.asset_item.customer = this.global_data.customer_id;
        vm.asset_item.location = this.global_data.last_location_id;

        vm.clean = true;
      });

      this.createNewAssetTest().then((result) => {
        vm.asset_test_item = _.clone(result); // Copy out of vuex
        /* console.log(this.global_data) */
        if (vm.global_data.test_date)
          vm.asset_test_item.test_date = vm.global_data.test_date;
        if (vm.global_data.tester)
          vm.asset_test_item.tester = vm.global_data.tester;
        if (vm.global_data.test_cycle)
          vm.asset_test_item.test_cycle = vm.global_data.test_cycle;

        vm.asset_test_item.customer = vm.global_data.customer_id;
        vm.clean = true;
      });
    },

    create_multiple() {
      this.create_asset_and_test(this.copies, function () {
        //nop
      });
    },
    checkNotes() {
      if (
        this.asset_test_item.status < 0 &&
        _.isEmpty(this.asset_test_item.notes)
      ) {
        return true;
      }

      return false;
    },
    create_asset_and_test(copies, completeHandler) {
      const vm = this;
      let error = {};
      if (vm.checkNotes()) {
        error.notes = ["This field may not be Empty when the Status is Fail."];
      } else {
        error.description = null;
      }
      if (!this.asset_item.description) {
        error.description = ["This field may not be empty."];
      } else {
        error.description = null;
      }

      if (!this.asset_item.location) {
        error.location = ["This field may not be empty."];
      } else {
        error.location = null;
      }

      if (!this.asset_item.code) {
        error.code = ["This field may not be empty."];
      } else {
        error.code = null;
      }

      if (!this.asset_test_item.test_date) {
        error.test_date = ["This field may not be empty."];
      } else {
        error.test_date = null;
      }
      if (
        error.test_date == null &&
        error.code == null &&
        error.description == null &&
        error.location == null
      ) {
        this.serverErrors = null;
      } else {
        this.serverErrors = JSON.parse(JSON.stringify(error));
      }

      if (this.serverErrors) {
        vm.showMessage({
          message: "Dont forget the required fields",
          type: "danger",
          show: true,
        });

        return;
      }
      this.api_action_execute({
        action: "create_asset_and_test",
        method: "post",
        data: {
          asset: vm.asset_item,
          asset_test: vm.asset_test_item,
          copies: copies || 1,
        },
      }).then((result) => {
        vm.asset_item = result.asset[result.asset.length - 1];
        vm.asset_test_item = result.asset_test[result.asset_test.length - 1];

        if (result.status != true) {
          this.serverErrors =
            result.errors.asset_test.length > 0
              ? result.errors.asset_test[0]
              : {};
          this.serverErrors =
            result.errors.asset.length > 0 ? result.errors.asset[0] : {};

          vm.showMessage({ message: result.msg, type: "danger", show: true });
        } else {
          this.serverErrors = {};
          if (result.copies == 1) {
            vm.showMessage({
              message: "Asset and Test Created Successfully",
              type: "success",
              show: true,
            });
          } else {
            this.doShowListAssetCodesModal(result.asset);
            vm.showMessage({
              message:
                "Created " +
                result.copies +
                " Asset(s) and Test(s) Created Successfully",
              type: "success",
              show: true,
            });
          }
          completeHandler();
        }
      });
    },
    doShowListAssetCodesModal(assets) {
      this.$refs["create-multiple"].hide();
      this.$refs["list-asset-codes"].show();
      _.forEach(assets, (code) => {
        this.assetCodes.push(code.code);
      });
    },
    do_save(completeHandler) {
      return this.create_asset_and_test(1, completeHandler);
    },

    // Create new Asset First then Asset Test based on the created Asset
    saveAssetAndTest() {
      this.do_save(function () {
        //NOthing
      });
    },

    saveAssetAndTestAndClose() {
      this.do_save(this.doClose);
    },
  },

  computed: {
    ...mapGetters("GlobalStateModule", { global_data: "data" }),
    isSaveAllowed() {
      return !this.formState.invalid && this.formState.dirty;
    },
    // Set the request for getting multiple codes
    get_next_request() {
      var result = { count: this.copies };

      if (this.asset_item) {
        if (this.asset_item.customer) {
          result.id = this.asset_item.customer;
        }
      }

      return result;
    },
  },
  data() {
    return {
      serverErrorsAssetTest: {},
      serverErrorsAsset: {},
      copies: 1,
      asset_item: {},
      asset_test_item: {},
      assetCodes: [],
    };
  },
};
</script>
