import _ from "lodash";
import * as CONFIG from "../../config.js";

function getStateNames(statename) {
    var result = ["*", statename];

    if (statename.includes(".")) {
        result.push(statename.split(".")[0]); // check root ie modelname.create also checks 'modelname'
    }

    return result;
}

export function canAccessState(group_permissions, statename) {
    var result = true;
    var missingState = true;

    if (checkIsAdmin(group_permissions)) {
        return true;
    }

    var stateList = getStateNames(statename);

    _.forEach(stateList, function (astate_name) {
        var hasPermissionEntry = group_permissions[astate_name];

        if (hasPermissionEntry) {
            missingState = false;
            if (hasPermissionEntry.deny) {
                result = false; // deny
            }
        }
    });

    if (missingState && group_permissions["#deny_by_default"] === true) {
        // Cannot find the state listed so Deny
        return false;
    }

    return result;
}

function checkIsAdmin(group_permissions) {
    if (CONFIG.PermAdminRole && "#groups" in group_permissions) {
        if (CONFIG.PermAdminRole in group_permissions["#groups"]) {
            //They are in the Admin group so show all
            return true;
        }
    }

    return false;
}

function canOperation(
    group_permissions,
    statename,
    modelName,
    fieldName,
    operation
) {
    var result = true; //allow
    var missingState = true;

    var stateList = getStateNames(statename);

    if (checkIsAdmin(group_permissions)) {
        return true;
    }

    group_permissions = group_permissions || {
        "#deny_by_default": false,
        "#groups": {},
    }; // handle No permissions

    _.forEach(stateList, function (astate_name) {
        var state = group_permissions[astate_name];

        if (state) {
            missingState = false; // we found a matching State Rule
            if (state.deny) {
                return false; // Whole state denied!
            }

            var model = state.models[modelName];

            if (model) {
                var field = model[fieldName];

                if (field) {
                    if (field[operation] === true) {
                        result = false; // disallowed
                    }
                }
            }
        }
    });

    if (missingState && group_permissions["#deny_by_default"] === true) {
        // State Not listed in Roles so deny by default.
        return false;
    }

    return result;
}

export function canReadField(
    group_permissions,
    statename,
    modelName,
    fieldName
) {
    return canOperation(
        group_permissions,
        statename,
        modelName,
        fieldName,
        "deny_read"
    );
}

export function canUpdateField(
    group_permissions,
    statename,
    modelName,
    fieldName
) {
    return canOperation(
        group_permissions,
        statename,
        modelName,
        fieldName,
        "deny_update"
    );
}

export function updateSchemaModelWithPermissions(
    group_permissions,
    schemaModel,
    routeName
) {
    var result = _.cloneDeep(schemaModel);

    if (group_permissions && schemaModel) {
        _.forEach(result.fields, function (field) {
            var canRead = canReadField(
                group_permissions,
                routeName,
                result.name,
                field.name
            );
            var canUpdate = canUpdateField(
                group_permissions,
                routeName,
                result.name,
                field.name
            );

            field.permissions = { canRead: canRead, canUpdate: canUpdate };

            field.hide = !canRead;
            if (!("defined_readonly" in field)) {
                field.defined_readonly = field.readonly === true;
            }

            // if it was defined Readonly then leave readonly.
            if (field.defined_readonly !== true) {
                // Not already readonly then change/check it.
                field.readonly = !canUpdate;
            }
        });
    }

    return result;
}
