<template>
  <b-button-group>
    <b-button
      :variant="{ false: 'outline-dark', true: 'dark' }[btn.id == value]"
      v-for="btn in buttons"
      :key="btn.id"
      @click="click(btn.id)"
      :style="{ color: btn.color }"
      >{{ btn[textField] }}
    </b-button>
    <b-button
      v-if="other_buttons.length <= 0 && !disableMore && allowUnselect"
      variant="outline-secondary"
      @click="unselect()"
      :style="{ color: 'black' }"
      >Unselect
    </b-button>

    <b-dropdown
      v-if="other_buttons.length > 0 && !disableMore"
      right
      text=""
      variant="outline-dark"
    >
      <b-dropdown-form
        v-if="all_other_buttons.length > maxMoreItems"
        class="p-0"
      >
        <b-form-input v-model="search" autofocus type="search"></b-form-input>
      </b-dropdown-form>
      <b-dropdown-item-button
        v-for="o in other_buttons"
        :key="o.id"
        :active="o.id == value"
        @click="click(o.id)"
        ><span v-if="o.id == value">&#10003;</span>{{ o[textField] }}
      </b-dropdown-item-button>
      <b-dropdown-divider v-if="allowUnselect"></b-dropdown-divider>
      <b-dropdown-item-button @click="unselect()" v-if="allowUnselect"
        >Unselect
      </b-dropdown-item-button>
    </b-dropdown>
  </b-button-group>
</template>

<script>
import _ from "lodash";
import { filter_active_list } from "@/vue-ocom/utils/active";

export default {
  name: "SpeedSelect",
  model: {
    prop: "value",
    event: "input",
  },
  data() {
    return {
      search: "",
    };
  },
  props: {
    validator: { type: Object, default: null },
    formState: { type: Object, default: null },
    items: { type: Array, default: null },
    value: { type: Number },
    textField: { type: String, default: "description" },
    disableMore: { type: Boolean, default: false },
    maxMoreItems: { type: Number, default: 10 },
    allowUnselect: { type: Boolean, default: false },
  },
  methods: {
    unselect() {
      this.$emit("input", null);
    },
    click(btnID) {
      if (this.validator) {
        this.validator.$touch();
      }
      if (this.formState) this.formState.dirty = true;

      this.$emit("input", btnID);
    },
  },
  computed: {
    buttons() {
      return _.sortBy(
        _.filter(this.items, function (o) {
          return !!o.quick_display_order;
        }),
        "quick_display_order"
      );
    },
    all_other_buttons() {
      return _.sortBy(
        _.filter(filter_active_list(this.items), function (o) {
          return !o.quick_display_order;
        }),
        this.textField
      );
    },
    other_buttons() {
      var items = this.all_other_buttons;
      const vm = this;

      // Filter by search
      items = _.filter(items, function (o) {
        return o[vm.textField].includes(vm.search);
      });

      return _.slice(items, 0, vm.maxMoreItems);
    },
  },
};
</script>

<style scoped></style>
