import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import VueFlatPickr from "vue-flatpickr-component";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "flatpickr/dist/flatpickr.css";
import "vue-material-design-icons/styles.css";

Vue.use(BootstrapVue);
Vue.use(VueFlatPickr);
