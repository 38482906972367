import _ from "lodash";

export default {
    data() {
        return {
            selected: {
                selectable: false,
                items: [],
            },
        };
    },
    methods: {
        onRowSelected(items) {
            this.selected.items = items || [];
        },
        addSelectionColums(columns) {
            if (this.selected.selectable) {
                return [{ key: "_selected", label: "" }, ...columns];
            } else {
                return columns; // no selection column
            }
        },
        doMergeItems() {
            var ids = _.map(this.selected.items, (o) => o.id);

            const primary_id = ids.shift(); // first one

            return this.mergeItems({ id: primary_id, other_ids: ids });
        },
        doDeleteItems() {
            const vm = this;
            _.forEach(this.selected.items, function (item) {
                vm.deleteItem(item);
            });
        },
    },
    computed: {
        hasSelection() {
            return this.selected.items.length > 0;
        },
    },
};
