export default function (displayValueFieldName) {
    return {
        props: {
            defaultClass: { type: String, default: "" }, // The Class to apply if it's a default value
        },
        computed: {
            get_class() {
                var _display_value = this[displayValueFieldName];

                // Get defaultValue or default if defaultValue doesn't exist
                let value = this.defaultValue
                    ? this.defaultValue
                    : this.default;

                if (_display_value == value) return this.defaultClass;

                return "";
            },
        },
    };
}
