<template>
  <o-form-wrapper
    :form-state="formState"
    :schema="schema"
    :server-errors="_se"
    :v="$v.item"
    v-model="item"
  >
    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="code"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="code"
        v-bind="schema.fields.code"
        v-model="item.code"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="description"
    >
      <o-text-field
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="description"
        v-bind="schema.fields.description"
        v-model="item.description"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="active_start_date"
    >
      <o-date-time
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="active_start_date"
        v-bind="schema.fields.active_start_date"
        v-model="item.active_start_date"
      ></o-date-time>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="active_end_date"
    >
      <o-date-time
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="active_end_date"
        v-bind="schema.fields.active_end_date"
        v-model="item.active_end_date"
      ></o-date-time>
    </o-widget-wrapper>
  </o-form-wrapper>
</template>

<script>
import schema from "../../schema/index";
import { cleanUpModel } from "../../../vue-ocom/schema/schema_vuetify";
import {
  CreateFormStateMixin,
  CreateGroupPermissionMixin,
  VuelidateMixin,
} from "../../../vue-ocom/mixins";
import { validationMixin } from "vuelidate";
import OWidgetWrapper from "../../../vue-ocom/components/forms/OWidgetWrapper";
import OTextField from "../../../vue-ocom/components/forms/fields/OTextField";
import OSelect from "../../../vue-ocom/components/forms/fields/OSelect";
import OCheckbox from "../../../vue-ocom/components/forms/fields/OCheckbox";
import OFormWrapper from "../../../vue-ocom/components/forms/OFormWrapper";
import ODateTime from "../../../vue-ocom/components/forms/fields/ODateTime";

import UseCodeModulesMixin from "@/vue-ocom/mixins/UseCodeModulesMixin";

export default {
  mixins: [
    VuelidateMixin,
    validationMixin,
    CreateFormStateMixin("$v"),
    CreateGroupPermissionMixin("CodePhase"),
    UseCodeModulesMixin([]),
  ],
  components: {
    OFormWrapper,
    OWidgetWrapper,
    ODateTime,

    OCheckbox,
    OSelect,
    OTextField,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    level: { type: Number, default: 0 },
    inModal: { type: Boolean, default: false },
  },
  data: () => ({
    schemaModel: cleanUpModel(schema.CodePhase, {}),
  }),
  methods: {},
  computed: {
    _se() {
      // Return an empty Object if there are no Server Errors to avoid Binding Errors
      return this.serverErrors || {};
    },
  },
  watch: {},
  created() {},
};
</script>
