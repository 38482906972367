import { forEach } from "lodash";
import * as CONFIG from "@/config.js";

function GetModulesUsed() {
    if (!window.ModulesUsed) {
        window.ModulesUsed = {};
    }
    return window.ModulesUsed;
}
export function AddModuleUsed(name) {
    var ModulesUsed = GetModulesUsed();
    var found = ModulesUsed[name];
    if (!found) {
        found = { loaded: true, used: false };
        ModulesUsed[name] = found;
    } else {
        if (found.loaded) {
            console.error("ERROR: Module already Loaded:", name);
        } else {
            found.loaded = true; // was in there but not loaded
        }
    }
}

export function ClearModulesUsed() {
    window.ModulesUsed = {};
}

export function RequireModule(name) {
    var ModulesUsed = GetModulesUsed();
    var found = ModulesUsed[name];
    if (!found) {
        found = { loaded: false, used: true };
        ModulesUsed[name] = found;
    } else {
        found.used = true; // Mark as used if it wasn't already
    }
}

export function CheckLoadedAndUsedModules() {
    var ModulesUsed = GetModulesUsed();
    forEach(ModulesUsed, function (entry, moduleName) {
        if (entry.used && !entry.loaded) {
            console.error(moduleName + " Is USED but NOT loaded");
        }
        if (!entry.used && entry.loaded) {
            console.error(moduleName + " Is LOADED but NOT used");
        }
    });
}

// to use update src/router.js

// export default AddModuleLoadChecksToRouter(new VueRouter({
export function AddModuleLoadChecksToRouter(router) {
    if (CONFIG.Debug) {
        router.beforeEach((to, from, next) => {
            ClearModulesUsed();

            next();
        });

        router.afterEach((to, from) => {
            CheckLoadedAndUsedModules();
        });
    }

    return router;
}
