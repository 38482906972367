import { Field, FIELD_TYPES, Model } from "../../vue-ocom/schema";

export default Model("code_test_cycle_color", {
  verbose_name: "Test Cycle Color",
  verbose_name_plural: "Test Cycle Colors",
  fields: [
    Field("id", {
      label: "ID",
      primary_key: true,
      readonly: true,
      hide: false,
      field_type: FIELD_TYPES.AutoNumber,
    }),

    Field("color", {
      label: "Color",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return "";
      },
    }),
    Field("test_frequency", {
      label: "Test Frequency",
      primary_key: false,
      readonly: false,
      required: false,
      hide: false,
      field_type: FIELD_TYPES.ForeignKey,
      ref_field_type: "CodeTestFrequency",
      ref_display_field: "description",
      default: () => {
        return null;
      },
    }),
  ],
});
