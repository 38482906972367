<template>
    <o-form-group
        :form-state="formState"
        :label="label"
        :message="errorMessages"
        :nolabel="nolabel"
        :type="{ 'is-danger': hasError }"
    >
        <div class="input-group">
            <div :class="input_class" class="align-self-center">
                <BBadge
                    :key="tag"
                    @click="remove_tag(index)"
                    href=""
                    pill
                    v-for="(tag, index) in value"
                >
                    <lookup-display
                        :display-field="textField"
                        :list="the_key_list"
                        :value="tag"
                    />
                    <close-circle-icon />
                </BBadge>
            </div>
            <BFormSelect
                :class="input_class"
                :disabled="_is_readonly"
                :options="the_list"
                :text-field="textField"
                :value-field="valueField"
                @change="select_tag"
                size="sm"
                v-model="current_select_value"
            >
            </BFormSelect>
        </div>
    </o-form-group>
</template>

<script>
import { BBadge, BFormSelect } from "bootstrap-vue";
import FormComponentMixin from "../../../mixins/form_component_mixin.js";
import _ from "lodash";
import moment from "moment";
import { is_item_active } from "../../../utils/active.js";
import OFormGroup from "../OFormGroup.vue";
import LookupDisplay from "../../display/LookupDisplay.vue";
import CloseCircleIcon from "vue-material-design-icons/CloseCircle.vue";

export default {
    name: "OTags",
    components: {
        BBadge,
        BFormSelect,
        LookupDisplay,
        OFormGroup,
        CloseCircleIcon,
    },
    mixins: [FormComponentMixin],
    data() {
        return {
            current_select_value: null,
        };
    },
    props: {
        items: { type: Array, default: null }, // the Tags

        sortField: { type: String, default: null }, // field to Sort By

        textField: {
            // The Field to show on the tags
            type: String,
            default: "description",
        },
        valueField: {
            // The value to store
            type: String,
            default: "id",
        },
    },
    methods: {
        handle_input($event) {
            console.log($event);
        },
        remove_tag(tag_index) {
            console.log(tag_index);

            var current_values = this.value || [];
            current_values.splice(tag_index, 1);

            this.on_change(current_values);
        },
        select_tag(selected_tag) {
            console.log(selected_tag);

            var current_values = this.value || [];

            current_values.push(selected_tag);
            current_values = _.uniq(current_values);

            this.current_select_value = null; // reset!

            this.on_change(current_values);
        },
    },
    computed: {
        the_value() {
            // Do any pre-processing before handing to v-select
            return this.value;
        },
        the_list() {
            var vm = this;
            var result = this.items;

            if (!this.showAll) {
                var now = moment();
                var current_id = this.value;

                result = _.filter(result, function (o) {
                    if (!o) {
                        return false;
                    } else {
                        if (current_id) {
                            if (o.id === current_id) {
                                // Add (disabled) suffix if it was disabled but in the list..
                                if (!is_item_active(o, now)) {
                                    if (
                                        !o[vm.textField].endsWith(
                                            vm.disabledSuffix,
                                        )
                                    ) {
                                        o[vm.textField] =
                                            o[vm.textField] +
                                            " " +
                                            vm.disabledSuffix;
                                    }
                                }

                                return true;
                            }
                        }

                        return is_item_active(o, now);
                    }
                });
            }
            if (!this.dontSort) {
                result = _.sortBy(result, this.sortField || this.textField); // Sort by sortField or default to textField
            }

            return result;
        },
        the_key_list() {
            return _.keyBy(this.the_list, this.valueField);
        },
    },
};
</script>

<style scoped>
select {
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
}
</style>
