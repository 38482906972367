var MessageHandlers = {
    null: function (messageObj) {
        return "Value Required";
    },
    max: function (messageObj) {
        return "Maximum value " + messageObj.params[0];
    },
    min: function (messageObj) {
        return "Minimum value " + messageObj.params[0];
    },
    "min-len": function (messageObj) {
        return "Minimum length:" + messageObj.params[0];
    },
    "max-len": function (messageObj) {
        return "Maximum length:" + messageObj.params[0];
    },
};

function ProcessMessageObject(results, message) {
    if ("code" in message && "message" in message) {
        // Is an Error from Go backend!
        var msgFunc = MessageHandlers[message.code];
        if (msgFunc == null) {
            results.push(message.message);
        } else {
            results.push(msgFunc(message));
        }
    } else {
        // Handle object the way it was..
        for (let key in message) {
            results.push(key);
        }
    }
}

export function CalculateFeedback(messages) {
    if (!messages) {
        return "";
    }

    if (typeof messages === "string") {
        return messages;
    } else {
        let results = [];

        if (Array.isArray(messages)) {
            messages.forEach((a_msg) => {
                if (typeof a_msg === "string") {
                    results.push(a_msg);
                } else {
                    ProcessMessageObject(results, a_msg);
                }
            });
        } else {
            ProcessMessageObject(results, messages);
        }
        return results.join(", ");
    }
}
