<template>
  <LoginGuard>
    <router-view></router-view>
  </LoginGuard>
</template>

<script>
// this is the Home/index of Brokers
import LoginGuard from "../../../vue-ocom/auth/components/LoginGuard";

export default {
  components: {
    LoginGuard,
  },
  computed: {},
};
</script>
