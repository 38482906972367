export const QUERY_FIELD_OPTIONS = {
    String: [
        { code: "eq", name: "Equals", values: ["Text"] },
        {
            code: "icontains",
            name: "Contains (Ignore Case)",
            values: ["Text"],
        },
        { code: "contains", name: "Contains", values: ["Text"] },
        { code: "is_null", name: "Is Not Set", values: [] },
        { code: "is_not_null", name: "Is Set", values: [] },
    ],
    Boolean: [{ code: "eq", name: "Equals", values: ["Boolean"] }],
    NullBoolean: [
        { code: "eq", name: "Equals", values: ["Boolean"] },
        { code: "is_null", name: "Is Not Set", values: [] },
        { code: "is_not_null", name: "Is Set", values: [] },
    ],
    Money: [
        { code: "eq", name: "Equals", values: ["Decimal"] },
        { code: "is_null", name: "Is Not Set", values: [] },
        { code: "is_not_null", name: "Is Set", values: [] },
        { code: "gt", name: ">", values: ["Decimal"] },
        { code: "lt", name: "<", values: ["Decimal"] },
        { code: "ge", name: ">=", values: ["Decimal"] },
        { code: "le", name: "<=", values: ["Decimal"] },
        {
            code: "between",
            name: "Between",
            values: ["Decimal", "Decimal"],
        },
    ],
    Number: [
        { code: "eq", name: "Equals", values: ["Decimal"] },
        { code: "is_null", name: "Is Not Set", values: [] },
        { code: "is_not_null", name: "Is Set", values: [] },
        { code: "gt", name: ">", values: ["Decimal"] },
        { code: "lt", name: "<", values: ["Decimal"] },
        { code: "ge", name: ">=", values: ["Decimal"] },
        { code: "le", name: "<=", values: ["Decimal"] },
        {
            code: "between",
            name: "Between",
            values: ["Decimal", "Decimal"],
        },
    ],
    DateTime: [
        { code: "eq", name: "Equals", values: ["DateTime"] },
        { code: "is_null", name: "Is Not Set", values: [] },
        { code: "is_not_null", name: "Is Set", values: [] },
        { code: "gt", name: "After", values: ["DateTime"] },
        { code: "lt", name: "Before", values: ["DateTime"] },
        { code: "ge", name: "After/On", values: ["DateTime"] },
        { code: "le", name: "Before/On", values: ["DateTime"] },
        {
            code: "between",
            name: "Between",
            values: ["DateTime", "DateTime"],
        },
    ],
    Date: [
        { code: "eq", name: "Equals", values: ["Date"] },
        { code: "is_null", name: "Is Not Set", values: [] },
        { code: "is_not_null", name: "Is Set", values: [] },
        { code: "gt", name: "After", values: ["Date"] },
        { code: "lt", name: "Before", values: ["Date"] },
        { code: "ge", name: "After/On", values: ["Date"] },
        { code: "le", name: "Before/On", values: ["Date"] },
        { code: "between", name: "Between", values: ["Date", "Date"] },
    ],
    ForeignKey: [
        { code: "eq", name: "Equals", values: ["ForeignKey"] },
        { code: "is_null", name: "Is Not Set", values: [] },
        { code: "is_not_null", name: "Is Set", values: [] },
    ],
    AutoNumber: [
        { code: "eq", name: "Equals", values: ["Integer"] },
        { code: "is_null", name: "Is Not Set", values: [] },
        { code: "is_not_null", name: "Is Set", values: [] },
        { code: "gt", name: ">", values: ["Integer"] },
        { code: "lt", name: "<", values: ["Integer"] },
        { code: "ge", name: ">=", values: ["Integer"] },
        { code: "le", name: "<=", values: ["Integer"] },
        {
            code: "between",
            name: "Between",
            values: ["Integer", "Integer"],
        },
    ],
};
