/* eslint-disable no-unused-vars */
import Vue from "vue";
import { TOKEN_EXPIRED } from "../auth/store/mutations-types.js";
import { ACTION_TYPES } from "./store_keys";

const MUTATION_TYPES = {
    SET_RESULT: "SET_RESULT",
    START_WORKING: "START_WORKING",
    FINISH_WORKING: "FINISH_WORKING",
};

// ie RestActionModule("http://localhost:8000/api/");

/* Then in methods have
    ...mapActions('ActionModule', ['execute']),

    .... in a method

    execute({action:'do_action', data:data});

 */

function handleError(err, commit) {
    if (err.response) {
        if ("status" in err.response) {
            if (err.response.status === 401) {
                // We are no longer logged in!

                commit("AuthModule/" + TOKEN_EXPIRED, null, { root: true });
            }
        }
    }
}

export default function (base_api) {
    return {
        namespaced: true,
        state: {
            result: null,
            working: false,
            last_request: null,
            last_request_when: null,
        },
        mutations: {
            STORE_LAST_REQUEST(state, last_request) {
                state.last_request = last_request;
                state.last_request_when = new Date();
            },
            [MUTATION_TYPES.SET_RESULT](state, result) {
                state.result = result;
            },
            [MUTATION_TYPES.START_WORKING](state) {
                state.working = true;
            },
            [MUTATION_TYPES.FINISH_WORKING](state) {
                state.working = false;
            },
        },
        actions: {
            recall({ state, dispatch }) {
                if (state.working) {
                    console.error("Action already loading!");
                    return;
                }
                if (!state.last_request || !state.last_request_when) {
                    console.error(
                        "Action has not been called!, Cannot Refresh",
                    );
                    return;
                }
                var lastLoad = new Date(state.last_request_when);
                let current_date = new Date();
                let difference = current_date.getTime() - lastLoad.getTime();
                let delaySeconds = 5; // 5 seconds
                if (Math.abs(difference) < delaySeconds * 1000) {
                    console.error("Module loaded recently. Not refreshed!");
                    return;
                }

                dispatch(ACTION_TYPES.GET_ALL, state.last_request);
            },
            execute({ commit, rootGetters }, params) {
                return new Promise((resolve, reject) => {
                    if (rootGetters["AuthModule/isLoggedIn"]) {
                        commit(MUTATION_TYPES.START_WORKING);

                        var url = base_api;

                        if (params.action) {
                            url = url + params.action + "/";
                        }

                        var method = params.method || "post";

                        commit("STORE_LAST_REQUEST", params);

                        Vue.axios
                            .request({
                                url: url,
                                method: method,
                                data: params.data,
                            })
                            .then((response) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                commit(
                                    MUTATION_TYPES.SET_RESULT,
                                    response.data,
                                );
                                resolve(response.data);
                            })
                            .catch((err) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                handleError(err, commit);

                                reject(err);
                            });
                    } else {
                        reject("NOT LOGGED IN");
                    }
                });
            },
        },
        getters: {
            result(state) {
                return state.result;
            },
            working(state) {
                return state.working;
            },
        },
    };
}
