<template>
    <div>
        <div v-if="!file">
            <BFormFile
                :accept="accept"
                :disabled="disabled"
                :loading="working"
                @input="fileChanged"
                v-model="uploadingFile"
            >
                <a class="button is-primary">
                    <upload-icon />
                    <span>{{ title }} {{ uploadingFileName }}</span>
                </a>
            </BFormFile>
        </div>

        <div class="row" v-if="file">
            <div class="col-11" style="padding-top: 7px">
                <a
                    :href="downloadURL"
                    target="_downloadWindow"
                    v-if="!disableDownload"
                    >{{ file.original_filename }}</a
                >
                <span v-if="disableDownload">{{ file.original_filename }} </span
                >({{ file.file_size }})
            </div>
            <div class="col-1" v-if="!hideDelete">
                <BButton @click="removeFile" variant="danger">
                    <close-icon />
                </BButton>
            </div>
        </div>
    </div>
</template>

<script>
import { BButton, BFormFile } from "bootstrap-vue";
// some code from https://github.com/DoritoBandito/vuetify-upload-button/blob/master/src/UploadButton.vue
import { mapActions, mapGetters, mapMutations } from "vuex";

import UploadIcon from "vue-material-design-icons/Upload.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";
import GoMixin from "../mixins/GoMixin.js";
import { CreateDownloadURL } from "./fs_utils.js";

export default {
    name: "OFileUpload",
    mixins: [GoMixin],
    components: {
        BFormFile,
        BButton,
        UploadIcon,
        CloseIcon,
    },
    model: {
        prop: "file",
        event: "change",
    },
    props: {
        file: {
            type: [Object, Array],
            default: () => {},
        },
        otherId: { type: [String, Number], default: 0 },
        otherName: { type: String, default: "default" },
        userId: { type: Number, default: null },
        dontIncludeToken: { type: Boolean, default: false },

        disableDownload: { type: Boolean, default: false },
        hideDelete: { type: Boolean, default: false },
        accept: {
            default: "",
            type: String,
        },
        disabled: {
            default: false,
            type: Boolean,
        },
        title: {
            default: "Upload",
            type: String,
        },
        uniqueId: {
            default: false,
            type: Boolean,
        },
        formState: { type: Object },
    },
    data() {
        return {
            uploadingFileName: "",
            uploadingFile: null,
        };
    },
    methods: {
        ...mapActions("FileSystemModule", ["getById", "upload", "cancel"]),
        ...mapMutations(["showMessage"]),

        makeDirty() {
            if (this.formState) this.formState.dirty = true;
        },

        removeFile: function () {
            // this.file = null;

            this.makeDirty();

            this.$emit("change", null);
        },
        uploadFile: function (file) {
            this.uploadingFileName = file.original_filename;

            var data = {
                file: file,
                userId: this.userId,
                other_id: this.otherId,
                other_name: this.otherName,
            };

            var vm = this;
            this.upload(data)
                .then(function (result) {
                    vm.makeDirty();
                    vm.$emit("change", result);
                })
                .catch(function (msg) {
                    var errorMsg = msg;

                    if ("response" in msg) {
                        if ("data" in msg.response) {
                            errorMsg = msg.response.data;
                        }
                    }

                    vm.showMessage({
                        message: "Cannot upload file:" + errorMsg,
                        type: "danger",
                        show: true,
                    });
                });
        },
        fileChanged(file) {
            if (file && !this.working) {
                this.uploadFile(file);
            } else {
                this.makeDirty();
                this.$emit("change", null);
            }
        },
    },
    computed: {
        ...mapGetters("AuthModule", ["token"]),
        ...mapGetters("FileSystemModule", [
            "working",
            "item",
            "progress_percentage",
        ]),

        inlineURL: function () {
            if (this.file) {
                return CreateDownloadURL(this.file.id, this.token, true);
            } else {
                return "";
            }
        },
        downloadURL: function () {
            var token = null;
            if (!this.dontIncludeToken) {
                token = this.token;
            }

            if (this.file) {
                return CreateDownloadURL(this.file.id, token, false);
            } else {
                return "";
            }
        },
    },
};
</script>

<style scoped></style>
