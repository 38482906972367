<template>
  <div class="container o-form-page">
    <o-working-overlay :working="working"></o-working-overlay>
    <o-section :level="0" :title="title" @click="enableDebug($event)">
      <item-form
        :form-state="formState"
        :item="item"
        :server-errors="serverErrors"
      ></item-form>
    </o-section>

    <o-action-buttons
      :create="create"
      :form-state="formState"
      :item="item"
      :show-create-and-new="true"
      @delete="doDeleteItem"
      @save="doSave"
      @saveAndClose="doSaveAndClose"
      @saveAndNew="doSaveAndNew"
    >
      <b-modal
        no-close-on-backdrop
        centered
        ref="list-asset-codes"
        id="list-asset-codes"
        title="List of Asset Codes"
        header-bg-variant="primary"
      >
        <template v-for="(code, index) in assetCodes">
          <CodeItem :key="index" :code="code" />
        </template>
      </b-modal>
      <template v-slot:left>
        <b-button
          v-b-modal.create-multiple
          :disabled="!isSaveAllowed"
          class="mr-2"
          variant="primary"
        >
          Create Multiples
        </b-button>
        <b-modal
          centered
          ref="create-multiple"
          id="create-multiple"
          title="Create Multiples"
          header-bg-variant="warning"
        >
          <p class="my-2">
            Enter total number of copies:
            <span class="font-weight-bold">{{ copies }}</span>
          </p>
          <b-form-input
            v-model="copies"
            type="number"
            placeholder="Enter your name"
          ></b-form-input>
          <template v-slot:modal-footer="{ ok, cancel }">
            <b-button variant="secondary" @click="cancel()"> Cancel </b-button>
            <o-do-server-action-button
              :request="get_next_request"
              @success="saveMultiple"
              uri="/api/get_next_code/"
              variant="primary"
              >Create
            </o-do-server-action-button>
          </template>
        </b-modal>
      </template>
    </o-action-buttons>
  </div>
</template>

<script>
import {
  CreateFormPageMixin,
  CreateGroupPermissionMixin,
  UnsavedMixin,
} from "../../../vue-ocom/mixins";
import FetchCodeMixin from "@/vue-ocom/mixins/FetchCodeMixin";
import ItemForm from "../components/ItemForm.vue";
import OActionButtons from "../../../vue-ocom/components/forms/OActionButtons";
import OSection from "../../../vue-ocom/components/layout/OSection";
import OWorkingOverlay from "../../../vue-ocom/components/OWorkingOverlay";
import { mapGetters } from "vuex";
import _ from "lodash";
import CodeItem from "../components/CodeItem.vue";

export default {
  mixins: [
    UnsavedMixin,
    CreateFormPageMixin("Asset", "asset"),
    CreateGroupPermissionMixin("Asset"),
    FetchCodeMixin([
      "Customer",
      "AssetLocation",
      "Product",
      "CodeColor",
      "CodePhase",
      "CodeTestFrequency",
      "CodeElectricalClass",
      "CodeAssetClassification",
      "CodeAssetStatus",
    ]),
  ],
  components: {
    OSection,
    OWorkingOverlay,
    OActionButtons,
    ItemForm,
    CodeItem,
  },
  methods: {
    OnCreateNew(item) {
      item.customer = this.global_data.customer_id;
      item.location = this.global_data.last_location_id;

      this.clean = true;
    },
    // Create multiple copies of assets with same values but different Codes
    saveMultiple(data) {
      this.$refs["create-multiple"].hide();
      this.$refs["list-asset-codes"].show();
      /* console.log(data) */
      const vm = this;
      var promises = [];
      var msg = "";

      _.forEach(data.code, (code) => {
        var newitem = _.clone(vm.item); // Clone the original from ItemForm
        vm.$set(newitem, "code", code); // Set the new Code
        //newItem.phase = '5002a'; // break it! - remove this line
        this.assetCodes.push(code);
        promises.push(vm.saveItem(newitem)); // Save it
      });

      Promise.all(promises)
        .then(() => {
          msg = `Created ${vm.copies} Assets`;
          this.showMessage({ message: msg, type: "success", show: true });
          this.serverErrors = {};
          this.clean = true;
          this.formState.dirty = false;
        })
        .catch((response) => {
          msg = `Could not create ${vm.copies} Assets`;
          if (response.status === 400) {
            this.clean = true;
            this.serverErrors = _.clone(response.data);
          }
          this.showMessage({ message: msg, type: "danger", show: true });
        });
    },
  },
  computed: {
    ...mapGetters("GlobalStateModule", { global_data: "data" }),
    title() {
      if (this.create) {
        return "Create Asset";
      }

      return "Edit Asset";
    },
    isSaveAllowed() {
      return !this.formState.invalid && this.formState.dirty;
    },
    // Set the request for getting multiple codes
    get_next_request() {
      var result = { count: this.copies, id: null };

      if (this.item) {
        if (this.item.customer) {
          result.id = this.item.customer;
          return result;
        }
      }

      return result;
    },
  },
  data() {
    return {
      copies: 1,
      assetCodes: [],
    };
  },
};
</script>
