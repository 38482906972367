<template>
    <o-form-select
        blank-option-text=""
        :name="fieldName"
        @input="on_changed"
        @keydown.enter="refilter"
        :value="currentValue"
        v-bind="$attrs"
        v-on="inputListeners"
    >
        <template v-slot:first>
            <option :value="null"></option>
        </template>
    </o-form-select>
</template>

<script>
import FilterInputMixin from "./FilterInputMixin.js";

export default {
    name: "OFilterSelect",
    mixins: [FilterInputMixin],
    data() {
        return {
            expression: "eq",
        };
    },
};
</script>
