import CodeMakeList from "./pages/ListPage.vue";
import CodeMakeDetail from "./pages/FormPage.vue";
import CodeMakeIndex from "./pages/IndexPage.vue";

export default [
  {
    path: "/code_make",
    component: CodeMakeIndex,
    redirect: "/code_make/list",
    name: "code_make",
    children: [
      {
        path: "list",
        component: CodeMakeList,
        name: "code_make.list",
      },
      {
        path: "create",
        component: CodeMakeDetail,
        name: "code_make.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: CodeMakeDetail,
        name: "code_make.edit",
        props: { create: false },
      },
    ],
  },
];
