<template>
    <BButton
        @click="executeServerAction"
        :variant="variantColor"
        :disabled="disabled"
        v-bind="$attrs"
    >
        <slot />
    </BButton>
</template>

<script>
import { BButton } from "bootstrap-vue";
import { mapMutations } from "vuex";
import { fileDownload } from "../../filesystem/fileDownload.js";
import * as CONFIG from "../../../config.js";

export default {
    components: {
        BButton,
    },
    name: "ODoServerActionButton",
    props: {
        variantColor: { type: String, default: "" }, //eg '/ocom_project/generate_doc/'
        uri: { type: String, required: true }, //eg '/ocom_project/generate_doc/'
        method: { type: String, default: "post" },
        request: {}, // what is sent to the URL ie json data
        form: { type: Object, default: null }, // Form Data to Post - If this is NOT null it will send FormData.

        filename: { type: String, default: null }, // the Download filename

        doSave: { type: Function, default: null },
        beforeSubmit: { type: Function, default: null },
        disabled: { type: Boolean, default: false },

        successMessage: { type: String, default: null },
        errorMessage: {
            type: String,
            default:
                "Error talking to server. Please try again later. Contact support@ocom.com.au if it still fails",
        },
    },
    methods: {
        ...mapMutations(["showMessage"]),
        executeServerAction() {
            var vm = this;

            var root_url = CONFIG.root_url;

            var options = {};

            var expectDownload = false;

            if (this.disabled) {
                return; // Don't do anything
            }

            if (this.filename) {
                expectDownload = true;
                options.responseType = "blob"; // expect a file back 'arraybuffer'
            }
            var request = {
                method: this.method.toUpperCase(),
                url: root_url + vm.uri,
                data: this.request,
                ...options,
            };

            if (this.form) {
                // We have form data to send it as form NOT a json body
                request.headers = { "Content-Type": "multipart/form-data" };
                var bodyFormData = new FormData();

                for (var key in this.form) {
                    bodyFormData.append(key, this.form[key]);
                }

                request.data = bodyFormData;
            }

            var runServerAction = function () {
                vm.axios(request).then(
                    function (response, status, headers) {
                        if (expectDownload || response.data instanceof Blob) {
                            fileDownload(
                                response.data,
                                vm.filename || "file",
                                response.headers["content-type"],
                            );
                        }

                        if (vm.successMessage) {
                            vm.showMessage({
                                message: vm.successMessage,
                                type: "success",
                                show: true,
                            });
                        }

                        vm.$emit("success", response.data);
                    },
                    function (error) {
                        if (vm.errorMessage) {
                            vm.showMessage({
                                message: vm.errorMessage,
                                type: "danger",
                                show: true,
                            });
                        }

                        vm.$emit("error", error);
                    },
                );
            };

            var cancel = false;
            if (vm.beforeSubmit != null) {
                cancel = vm.beforeSubmit();
            }

            if (!cancel) {
                if (vm.doSave != null) {
                    vm.doSave(runServerAction);
                } else {
                    runServerAction();
                }
            }
        },
    },
};
</script>

<style scoped></style>
