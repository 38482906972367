<template>
    <div class="bd-sidebar col-md-3 col-xl-2 col-12 card">
        <BListGroup :key="item.name" v-for="item in first_section_menu_options">
            <BListGroupItem variant="primary">{{ item.name }}</BListGroupItem>
            <BListGroupItem
                :key="submenu.title"
                :to="submenu.to"
                v-for="submenu in item.children"
                @click="hideSideBar()"
            >
                {{ submenu.title }}
                <chevron-right-icon />
            </BListGroupItem>
        </BListGroup>
    </div>
</template>

<script>
import { BListGroup, BListGroupItem } from "bootstrap-vue";
import ChevronRightIcon from "vue-material-design-icons/ChevronRight.vue";
import MenuMixin from "@/vue-ocom/components/layout/app/MenuMixin.js";

export default {
    name: "OSideBar",
    mixins: [MenuMixin],
    components: {
        BListGroup,
        BListGroupItem,
        ChevronRightIcon,
    },
    props: {
        items: { type: Array, default: () => [] },
    },
    methods: {
        hideSideBar() {
            this.$emit("hide_sidebar");
        },
    },
};
</script>

<style scoped>
.bd-sidebar {
    height: 100%; /* 100% Full-height */
    position: fixed; /* Stay in place */
    z-index: 100; /* Stay on top */
    top: 0;
    left: 0;
    background-color: whitesmoke;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}
</style>
