export { default as CreateFormPageMixin } from "./form_page_mixin.js";
export { default as CreateCodeTableFetchMixin } from "./code_table_fetch_mixin.js";
export { default as CreateListPageMixin } from "./list_page_mixin.js";
export { default as DisplayMixin } from "./display_mixin.js";
export { default as VuelidateMixin } from "./vuelidate_mixin.js";
export { default as CreateFormStateMixin } from "./form_state_mixin.js";
export { default as CreateGroupPermissionMixin } from "./group_permission_mixin.js";
export { default as UseRestModuleMixin } from "./UseRestModuleMixin.js";
export { default as MultiActionsMixin } from "./MultiActionsMixin.js";
export { default as UseRestActionModuleMixin } from "./UseRestActionModuleMixin.js";

export { default as FetchCodeMixin } from "./FetchCodeMixin.js";
export { default as UseCodeModulesMixin } from "./UseCodeModulesMixin.js";

export const UnsavedMixin = {
    beforeRouteLeave(to, from, next) {
        if (this.formState.dirty) {
            const answer = window.confirm(
                "Do you really want to leave? you have unsaved changes!",
            );
            if (answer) {
                next();
            } else {
                next(false);
            }
        } else {
            next();
        }
    },
};
