<template>
    <div class="container">
        <o-working-overlay :working="working"></o-working-overlay>

        <o-section :level="0" :title="title" @click="enableDebug($event)">
            <item-form
                :form-state="formState"
                :item="item"
                :server-errors="serverErrors"
            ></item-form>
        </o-section>

        <o-action-buttons
            :create="create"
            :form-state="formState"
            :item="item"
            :show-create-and-new="true"
            @delete="doDeleteItem"
            @save="doSave"
            @saveAndClose="doSaveAndClose"
            @saveAndNew="doSaveAndNew"
        ></o-action-buttons>
    </div>
</template>

<script>
import {
    CreateFormPageMixin,
    CreateGroupPermissionMixin,
    UnsavedMixin,
} from "../../../mixins";
import ItemForm from "../components/ItemForm.vue";
import OActionButtons from "../../../components/forms/OActionButtons.vue";
import OSection from "../../../components/layout/OSection.vue";
import OWorkingOverlay from "../../../components/OWorkingOverlay.vue";

export default {
    mixins: [
        UnsavedMixin,
        CreateFormPageMixin("Group", "group"),
        CreateGroupPermissionMixin("Group"),
    ],
    components: {
        OSection,
        OWorkingOverlay,
        OActionButtons,
        ItemForm,
    },
    computed: {
        title() {
            if (this.create) {
                return "Create Group";
            }

            return "Edit Group";
        },
    },
};
</script>
