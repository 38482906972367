import Vue from "vue";
import Vuex from "vuex";
import AuthModule from "./vue-ocom/auth/store";
import AxiosAuthPlugin from "./vue-ocom/auth/store/plugin";
import AppModules from "./app/store";
import GroupPermissionsModules from "./vue-ocom/grouppermissions/store";
import HooksPlugin from "./vue-ocom/store/plugins/HooksPlugin";
import { hooks } from "./app/app_hooks";
import RestPreloadPlugin from "./vue-ocom/store/plugins/RestPreloadPlugin";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const allModules = Object.assign(
  {
    AuthModule,
  },
  AppModules,
  GroupPermissionsModules
);

export default new Vuex.Store({
  plugins: [AxiosAuthPlugin, HooksPlugin(hooks), RestPreloadPlugin],
  modules: allModules,
  strict: debug,
  state: {
    messages: {
      show: false,
    },
  },
  mutations: {
    showMessage(state, messages) {
      console.log(messages);

      state.messages = messages;
    },
  },
  actions: {},
  getters: {
    messages(state) {
      return state.messages || { show: false };
    },
  },
});
