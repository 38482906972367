import CodeTestFrequencyList from "./pages/ListPage.vue";
import CodeTestFrequencyDetail from "./pages/FormPage.vue";
import CodeTestFrequencyIndex from "./pages/IndexPage.vue";

export default [
  {
    path: "/code_test_frequency",
    component: CodeTestFrequencyIndex,
    redirect: "/code_test_frequency/list",
    name: "code_test_frequency",
    children: [
      {
        path: "list",
        component: CodeTestFrequencyList,
        name: "code_test_frequency.list",
      },
      {
        path: "create",
        component: CodeTestFrequencyDetail,
        name: "code_test_frequency.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: CodeTestFrequencyDetail,
        name: "code_test_frequency.edit",
        props: { create: false },
      },
    ],
  },
];
