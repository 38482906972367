<template>
    <span>
        <BButton
            v-if="!selection.selectable"
            class="ml-1 my-2"
            variant="primary"
            @click="multiple_select_on"
            ><check-all-icon /> Select</BButton
        >
        <BDropdown
            v-if="selection.selectable"
            class="pl-1 ml-0 my-2"
            variant="primary"
        >
            <template v-slot:button-content>
                With {{ selection.items.length }}
                {{ schema.verbose_name_plural }}
            </template>
            <BDropdownItem
                @click="mergeSelected"
                v-if="enableMerge && multiple_selected"
                >Merge {{ selection.items.length }}
                {{ schema.verbose_name_plural }}
            </BDropdownItem>
            <BDropdownItem
                @click="deleteSelected"
                v-if="enableDelete && has_a_selection"
                >Delete {{ selection.items.length }}
                {{ schema.verbose_name_plural }}
            </BDropdownItem>
            <BDropdownDivider
                v-if="
                    (enableDelete && has_a_selection) ||
                    (enableMerge && multiple_selected)
                "
            />
            <BDropdownItem @click="multiple_select_off">
                Turn off Multiple Select</BDropdownItem
            >
        </BDropdown>
    </span>
</template>

<script>
import {
    BButton,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
} from "bootstrap-vue";
import CheckAllIcon from "vue-material-design-icons/CheckAll.vue";

export default {
    name: "OListMultiActions",
    components: {
        BButton,
        BDropdown,
        BDropdownItem,
        BDropdownDivider,
        CheckAllIcon,
    },
    props: {
        selection: { type: Object, default: () => {} },
        schema: { type: Object, default: null },

        enableDelete: { type: Boolean, default: false },
        deleteMessage: {
            type: String,
            default: "Are you sure you want to delete these items?",
        },
        deleteTitle: { type: String, default: "Deleting Items" },
        deleteConfirmText: { type: String, default: "Delete Items" },

        enableMerge: { type: Boolean, default: false },
        mergeTitle: { type: String, default: "Merging Items" },
        mergeMessage: {
            type: String,
            default: "Are you sure you want to merge these items?",
        },
        mergeConfirmText: { type: String, default: "Merge" },
    },
    methods: {
        multiple_select_on() {
            this.selection.selectable = true;
        },
        multiple_select_off() {
            this.selection.selectable = false;
            this.selection.items = []; // clear the list
        },
        deleteSelected() {
            var options = {
                title: this.deleteTitle,
                headerBgVariant: "danger",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: this.deleteConfirmText,
                cancelVariant: "success",
                cancelTitle: "Cancel",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true,
            };
            this.$bvModal
                .msgBoxConfirm(this.deleteMessage + "", options)
                .then((value) => {
                    if (value) {
                        this.$emit("delete", this.selection.items);
                    }
                });
        },
        mergeSelected() {
            var options = {
                title: this.mergeTitle,
                headerBgVariant: "warning",
                size: "sm",
                buttonSize: "sm",
                okVariant: "warning",
                okTitle: this.mergeConfirmText,
                cancelVariant: "secondary",
                cancelTitle: "Cancel",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true,
            };
            this.$bvModal
                .msgBoxConfirm(this.mergeMessage + "", options)
                .then((value) => {
                    if (value) {
                        this.$emit("merge", this.selection.items);
                    }
                });
        },
    },
    computed: {
        has_a_selection() {
            return (
                this.selection.items != null && this.selection.items.length > 0
            );
        },
        multiple_selected() {
            return (
                this.selection.items != null && this.selection.items.length > 1
            );
        },
    },
};
</script>
