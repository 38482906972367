<template>
    <footer class="footer fixed-bottom">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-4">
                    &copy;
                    <ocom />
                    {{ year }}
                </div>

                <div class="col-4 text-right">Version {{ version }}</div>
            </div>
        </div>
    </footer>
</template>

<style scoped>
footer {
    background-color: #f5f5f5;
}
</style>
<script>
import Ocom from "../../ocom/Ocom.vue";

export default {
    components: { Ocom },
    props: {
        year: { type: String, default: "2021" },
        version: { type: String, default: "0.0.1(beta)" },
    },
};
</script>
