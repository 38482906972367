import { mapActions, mapGetters } from "vuex";

export default {
  methods: {
    ...mapActions("AssetTestCountModule", { UpdateAssetTestCount: "execute" }),
    updateAssetTestCount(customerID) {
      this.UpdateAssetTestCount({
        data: { customer: customerID },
      });
    },
  },
  computed: {
    ...mapGetters("AssetTestCountModule", { AssetTestCount: "result" }),
  },
};
