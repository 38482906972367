<template>
    <o-form-wrapper
        :form-state="formState"
        :schema="schema"
        :server-errors="_se"
        :v="$v.item"
        v-model="item"
    >
        <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="name"
        >
            <o-text-field
                :form-state="formState"
                :server-errors="_se"
                :validators="$v.item"
                name="name"
                v-bind="schema.fields.name"
                v-model="item.name"
            ></o-text-field>
        </o-widget-wrapper>

        <div v-if="isGoBackend">
            <o-widget-wrapper
                :schema="schema"
                name="active_start_date"
                :validators="$v.item"
                :server-errors="_se"
                :form-state="formState"
            >
                <o-date-time
                    v-model="item.active_start_date"
                    name="active_start_date"
                    v-bind="schema.fields.active_start_date"
                    :form-state="formState"
                    :validators="$v.item"
                    :server-errors="_se"
                ></o-date-time>
            </o-widget-wrapper>

            <o-widget-wrapper
                :schema="schema"
                name="active_end_date"
                :validators="$v.item"
                :server-errors="_se"
                :form-state="formState"
            >
                <o-date-time
                    v-model="item.active_end_date"
                    name="active_end_date"
                    v-bind="schema.fields.active_end_date"
                    :form-state="formState"
                    :validators="$v.item"
                    :server-errors="_se"
                ></o-date-time>
            </o-widget-wrapper>
        </div>

        <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="permissions"
            v-if="!isGoBackend"
        >
            <div style="overflow-y: scroll; overflow-x: hidden; height: 200px">
                <o-multi-checkbox
                    :form-state="formState"
                    :items="optionPermissionItems"
                    :loading="optionPermissionWorking"
                    :server-errors="_se"
                    :validators="$v.item"
                    multiple
                    name="permissions"
                    sort-field="name"
                    text-field="name"
                    v-bind="schema.fields.permissions"
                    v-model="item.permissions"
                    value-field="id"
                >
                </o-multi-checkbox>
            </div>
            <!--<permission-form-table v-model="item.permissions"
                                   :validators="$v.item.permissions"
                                   :form-state="formState"
                                   :label="schema.fields.permissions.label"
                                   :level="level+1"
                                   :server-errors="_se.permissions">
            </permission-form-table>-->
            <!-- UNCOMMENT TO USE Cards
            <permission-form-cards v-model="item.permissions"
                :validators="$v.item.permissions"
                :form-state="formState"
                :label="schema.fields.permissions.label"
                :level="level+1"
                :server-errors="_se.permissions">
            </permission-form-cards>
            -->
        </o-widget-wrapper>
    </o-form-wrapper>
</template>

<script>
import schema from "../../schema/index.js";
import { cleanUpModel } from "../../../schema/schema_vuetify.js";
import {
    CreateCodeTableFetchMixin,
    CreateFormStateMixin,
    CreateGroupPermissionMixin,
    VuelidateMixin,
} from "../../../mixins";
import { validationMixin } from "vuelidate";
import OWidgetWrapper from "../../../components/forms/OWidgetWrapper.vue";
import OTextField from "../../../components/forms/fields/OTextField.vue";
import OSelect from "../../../components/forms/fields/OSelect.vue";
import OCheckbox from "../../../components/forms/fields/OCheckbox.vue";
import OFormWrapper from "../../../components/forms/OFormWrapper.vue";
import ODateTime from "../../../components/forms/fields/ODateTime.vue";
import { default as PermissionForm } from "../../permission/components/ItemForm.vue";
import OMultiCheckbox from "../../../components/forms/fields/OMultiCheckbox.vue";
// import { default as PermissionFormCards } from "../../permission/components/ItemFormCards.vue";
import { IsGoBackEnd } from "../../../utils/config.js";

export default {
    mixins: [
        VuelidateMixin,
        validationMixin,
        CreateFormStateMixin("$v"),
        CreateGroupPermissionMixin("Group"),
        CreateCodeTableFetchMixin(["Permission"]),
    ],
    components: {
        OMultiCheckbox,
        OFormWrapper,
        OWidgetWrapper,
        ODateTime,

        OCheckbox,
        OSelect,
        OTextField,

        PermissionForm,
        //PermissionFormCards,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        level: { type: Number, default: 0 },
    },
    data: () => ({
        schemaModel: cleanUpModel(schema.Group, {}),
    }),
    methods: {},
    computed: {
        _se() {
            // Return an empty Object if there are no Server Errors to avoid Binding Errors
            return this.serverErrors || {};
        },
        isGoBackend() {
            return IsGoBackEnd();
        },
    },
    watch: {},
    created() {},
};
</script>
