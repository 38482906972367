import Vue from "vue";
import VueRouter from "vue-router";
import {
  AddModuleLoadChecksToRouter,
} from "@/vue-ocom/mixins/globals";


Vue.use(VueRouter);

const requireAppRoutes = require.context(".", true, /routes.js$/);

function mergeAppRoutes() {
  //Merges all routes.js files in the project!
  let routes = [];
  requireAppRoutes.keys().forEach((fileName) => {
    const foundRoutes = requireAppRoutes(fileName);
    routes = routes.concat(foundRoutes.default);
  });
  return routes;
}

const routes = mergeAppRoutes();

export default AddModuleLoadChecksToRouter(
  new VueRouter({
    base: process.env.BaseURL,
    routes,
  })
);

export { routes };
