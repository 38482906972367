<script>
export default {
    name: "OLinkCell",
    props: {
        when: { type: Boolean, default: true },
    },
    render(_h) {
        var _vm = this;
        var _c = _vm._self._c || _h;
        return _vm.when
            ? _c(
                  "router-link",
                  _vm._g(
                      _vm._b({}, "router-link", _vm.$attrs, false),
                      _vm.$listeners,
                  ),
                  [_vm._t("default")],
                  2,
              )
            : _c("span", [_vm._t("default")], 2);
    },
};
</script>

<style scoped></style>
