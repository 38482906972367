import CodeTestCycleList from "./pages/ListPage.vue";
import CodeTestCycleDetail from "./pages/FormPage.vue";
import CodeTestCycleIndex from "./pages/IndexPage.vue";

export default [
  {
    path: "/code_test_cycle",
    component: CodeTestCycleIndex,
    redirect: "/code_test_cycle/list",
    name: "code_test_cycle",
    children: [
      {
        path: "list",
        component: CodeTestCycleList,
        name: "code_test_cycle.list",
      },
      {
        path: "create",
        component: CodeTestCycleDetail,
        name: "code_test_cycle.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: CodeTestCycleDetail,
        name: "code_test_cycle.edit",
        props: { create: false },
      },
    ],
  },
];
