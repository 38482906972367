import _ from "lodash";
import { maxLength, minLength, required } from "vuelidate/lib/validators";

/*
    And Enum of Types..
    We could make the elements Objects that has other "values" ie fields etc..
 */
export const FIELD_TYPES = {
    AutoNumber: "AutoNumber",
    Boolean: "Boolean",
    String: "String",
    Password: "Password",
    UUID: "String" /* Alias */,
    Text: "String" /* Alias */,
    Integer: "Number" /* Alias */,
    Number: "Number",
    Decimal: "Number" /* Alias */,
    DateTime: "DateTime",
    Date: "Date",
    Money: "Money",

    Json: "Json",
    Array: "Array",

    ForeignKey: "ForeignKey",
    ManyToMany: "ManyToMany",
};

/* Create a new Field
    Adds the field into the model as name

    @param {Model} model - the Model this field belongs too.
    @param {String} name - The field name of this field
    @param {Object} options - The options

    Also Set smart defaults for fields
    And Create rules based on properties so we don't have to declare the rules each time
 */
export function Field(name, options) {
    /*
     * TODO REMOVE - No need for adding the rules in schema now since this will be handled by vuelidate from now on:
     * Please see vuelidate_mixin.js - it adds the validations needed including serverValid validation
     * */
    options.rules = options.rules || [];
    /* Add Validation Rules if these values are set on the field */
    if (options.required) {
        if (!options.label.endsWith("*")) {
            options.label = options.label + " *";
        }
        var found_required = _.find(options.rules, function (rule) {
            return rule === required;
        });
        if (!found_required) {
            options.rules.push(required);
        }
    }
    if (options.maxlength) {
        var found_maxLength = _.find(options.rules, function (rule) {
            return rule === maxLength;
        });
        if (!found_maxLength) {
            options.rules.push(maxLength(options.maxlength));
        }
    }
    if (options.minlength) {
        var found_minLength = _.find(options.rules, function (rule) {
            return rule === minLength;
        });
        if (!found_minLength) {
            options.rules.push(minLength(options.minlength));
        }
    }
    options.readonly = options.readonly || false;
    options.hide = options.hide || false;

    if (FIELD_TYPES[options.field_type] == null) {
        console.error(name + ": Could not find Type " + options.field_type);
    }

    options.name = name;

    return options;
}

export function update_calculated_fields(schema) {
    if (!("_calculatedFields" in schema)) {
        schema._calculatedFields = _.filter(schema.fields, function (f) {
            return f.virtual === true;
        });
    }
}

/*
    Create a new Model

    @param {Schema} schema - the Schema this will be added to
    @param {String} name = The name of the Model
    @param {Object} options - the Options of this Model

    Allows us to add defaults etc..
 */
export function Model(name, options) {
    //TODO Any "tidy up" of the model

    options.name = name;
    if (_.isArray(options.fields)) {
        options.fields = _.keyBy(options.fields, "name"); // Convert to Object
    }

    update_calculated_fields(options);

    return options;
}

/* Create a Default Model for a Code Table
    Takes options that has verbose_name and verbose_name_plural and extra "fields"

    usage:

        CodeModel('code_example', {verbose_name:"Code Example",  verbose_name_plural:"Code Examples", fields:[]});
 */
export function CodeModel(name, options) {
    var fields = [
        Field("id", {
            label: "ID",
            primary_key: true,
            readonly: true,
            hide: false,
            field_type: FIELD_TYPES.AutoNumber,
        }),
        Field("code", {
            label: "code",
            field_type: FIELD_TYPES.String,
            maxlength: 50,
            required: false,
            rules: [],
        }),
        Field("description", {
            label: "Description",
            field_type: FIELD_TYPES.String,
            maxlength: 255,
            required: true,
            rules: [],
        }),
        Field("active_start_date", {
            label: "Active Start Date and Time",
            field_type: FIELD_TYPES.DateTime,
            default: () => {
                return new Date();
            },
        }),
        Field("active_end_date", {
            label: "Active End Date and Time",
            field_type: FIELD_TYPES.DateTime,
            default: null,
        }),
    ];

    if (fields in options) {
        fields = _.concat(fields, options.fields); // Add extra fields in here
    }

    return Model(name, {
        verbose_name: options.verbose_name,
        verbose_name_plural: options.verbose_name_plural,
        fields: fields,
    });
}
