import _ from "lodash";
import FetchCodeMixin from "./FetchCodeMixin";
import UseCodeModulesMixin from "./UseCodeModulesMixin";

export default function (moduleList, list_options) {
    var fetchValues = FetchCodeMixin(moduleList, list_options);
    var useCodeMods = UseCodeModulesMixin(moduleList);

    var getCodeTablesMethods = {};

    // Replace GetAllModule to call GetModuleCodeList as there is no Event to Raise etc.
    _.each(moduleList, function (moduleName) {
        var function_name = "GetAll" + moduleName;

        getCodeTablesMethods[function_name] =
            fetchValues.methods["Get" + moduleName + "CodeList"];
    });

    return {
        methods: {
            ...fetchValues.methods,
            ...useCodeMods.methods,
            ...getCodeTablesMethods /* Override GetAllModule */,
        },
        computed: {
            ...fetchValues.computed,
            ...useCodeMods.computed,
        },
        created: fetchValues.created,
    };
}
