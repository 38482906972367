/* eslint-disable no-unused-vars */
import Vue from "vue";
import _ from "lodash";
import * as moment from "moment";
import { TOKEN_EXPIRED } from "../auth/store/mutations-types.js";
import { GETTER_TYPES } from "vue-ocom/store/store_keys.js";
import * as CONFIG from "../../config.js";

const MUTATION_TYPES = {
    SET_RESULT: "SET_RESULT",
    SET_ITEM: "SET_ITEM",
    START_WORKING: "START_WORKING",
    FINISH_WORKING: "FINISH_WORKING",
};

// ie RestActionModule("http://localhost:8000/api/");

/* Then in methods have
    ...mapActions('ActionModule', ['execute']),

    .... in a method

    execute({action:'do_action', data:data});

 */

function handleError(err, commit) {
    if (err.response.status == 401) {
        // We are no longer logged in!

        commit("AuthModule/" + TOKEN_EXPIRED, null, { root: true });
    }
}

export default function (base_api) {
    return {
        namespaced: true,
        state: {
            items: null,
            item: null,
            working: false,
        },
        mutations: {
            [MUTATION_TYPES.SET_RESULT](state, items) {
                state.items = _.sortBy(items, "title");
            },
            [MUTATION_TYPES.SET_ITEM](state, item) {
                state.item = item;
            },
            [MUTATION_TYPES.START_WORKING](state) {
                state.working = true;
            },
            [MUTATION_TYPES.FINISH_WORKING](state) {
                state.working = false;
            },
        },
        actions: {
            getAll({ rootGetters, commit }) {
                return new Promise((resolve, reject) => {
                    if (rootGetters["AuthModule/isLoggedIn"]) {
                        console.log("Get All");

                        commit(MUTATION_TYPES.START_WORKING);

                        var api = base_api + "/reports/reports/";

                        Vue.axios
                            .get(api, { params: {} })
                            .then((response) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                commit(
                                    MUTATION_TYPES.SET_RESULT,
                                    response.data || [],
                                );

                                resolve(response.data);
                            })
                            .catch((err) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                handleError(err, commit);

                                reject(err);
                            });
                    }
                });
            },
            getReportParams({ rootGetters, commit }, reportName) {
                return new Promise((resolve, reject) => {
                    if (rootGetters["AuthModule/isLoggedIn"]) {
                        commit(MUTATION_TYPES.START_WORKING);

                        var api =
                            base_api +
                            "/reports/reportsParams/" +
                            reportName +
                            "/";

                        Vue.axios
                            .get(api, {})
                            .then((response) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                response.data.name = reportName;
                                commit(MUTATION_TYPES.SET_ITEM, response.data);
                                resolve(response.data);
                            })
                            .catch((err) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                handleError(err, commit);

                                reject(err);
                            });
                    } else {
                        reject("NOT LOGGED IN");
                    }
                });
            },
            run_report({ rootGetters, commit, state }, params) {
                return new Promise((resolve, reject) => {
                    if (rootGetters["AuthModule/isLoggedIn"]) {
                        commit(MUTATION_TYPES.START_WORKING);

                        var api = base_api + "/reports/download";

                        var current_report = _.find(
                            state.items,
                            function (item) {
                                if (item.name === state.item.name) return item;
                                else return null;
                            },
                        );

                        var filename =
                            (current_report.title || current_report.name) +
                            "_" +
                            moment().format("YYYY-MM-DD") +
                            "." +
                            params._format;

                        var formdata = new FormData();
                        formdata.set("__timezone", "Australia/Melbourne");
                        formdata.set("_filename", filename);
                        formdata.set("_reportName", state.item.name);

                        for (var key in params) {
                            formdata.set(key, params[key]);
                        }

                        Vue.axios({
                            method: "post",
                            url: api,
                            data: formdata,
                            responseType: "blob",
                            config: {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                            },
                        })
                            .then(function (response) {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                //handle success
                                console.log(response);

                                // commit(MUTATION_TYPES.SET_ITEM, response.data);

                                resolve(response.data);
                            })
                            .catch(function (err) {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                handleError(err, commit);

                                reject(err);
                            });
                    } else {
                        reject("NOT LOGGED IN");
                    }
                });
            },
        },
        getters: {
            item(state) {
                return state.item;
            },
            items(state) {
                return state.items;
            },
            working(state) {
                return state.working;
            },
            downloadURL(state, getters, rootState, rootGetters) {
                var suffix = "";

                if (CONFIG.GoBackend === true) {
                    suffix = "?jwt=" + rootGetters["AuthModule/token"];
                }

                return base_api + "/reports/download" + suffix;
            },
            [GETTER_TYPES.TOTAL_ITEMS](state) {
                return (state.items || []).length;
            },
        },
    };
}
