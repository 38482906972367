import Vue from "vue";

var data = localStorage.getItem("global_state");

if (!data) {
    data = {};
} else {
    data = JSON.parse(data);
}

export default {
    namespaced: true,

    state: {
        data: data,
    },
    getters: {
        data(state) {
            return state.data;
        },
    },
    mutations: {
        update_global_state(state, { key, value }) {
            Vue.set(state.data, key, value);

            localStorage.setItem("global_state", JSON.stringify(state.data));
        },
    },
    actions: {
        setGlobalValue({ commit }, keySet) {
            commit("update_global_state", keySet);
        },
    },
};
