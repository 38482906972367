export const apiUrl = process.env.API_URL;
export const root_url = process.env.VUE_APP_ROOT_API;
export const version = process.env.VUE_APP_VERSION;
export const userTokenStorageKey = "APP_USER_TOKEN";
export const authDisabled = false; /* Set to True to disable auth */
export const bridgeAngularJS = false;
export const permanent = true;
export const PermAdminRole = "Administrator";
export const DefaultPermissions = { home: { deny: false } };
export const Debug = process.env.NODE_ENV !== "production";
export const BaseURL = process.env.BASE_URL;

export const TurnOnComplexityChecking = false;
export const OldValidationLogic = true;

export const GoBackend = false;

export const CheckPageAccess = function (route, currentUser) {
  return null; // No problem!
};
export const SentryURL = process.env.VUE_APP_SENTRY_DSN;
