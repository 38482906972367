import Vue from "vue";

import ViewListIcon from "vue-material-design-icons/ViewList";
import PencilIcon from "vue-material-design-icons/Pencil";
import ClipboardCheckOutlineIcon from "vue-material-design-icons/ClipboardCheckOutline";
import FileChartIcon from "vue-material-design-icons/FileChart";

// Register the Icons so Webpack includes them and we can use them
Vue.component("ViewListIcon", ViewListIcon);
Vue.component("PencilIcon", PencilIcon);
Vue.component("ClipboardCheckOutlineIcon", ClipboardCheckOutlineIcon);
Vue.component("FileChartIcon", FileChartIcon);

export default [
  {
    name: "Main",
    children: [
      {
        name: "Testing",
        children: [
          {
            icon: "clipboard-check-outline",
            title: "Do Testing",
            rule: "hasCustomer",
            to: {
              name: "testing.list",
              query: { limit: 25, filter: "untested" },
            },
            loggedIn: true,
          },

          {
            icon: "view-list",
            title: "List All Asset Tests",
            rule: "hasCustomer",
            to: {
              name: "asset_test.list",
              query: { limit: 10, filter: "all" },
            },
            loggedIn: true,
          },
          {
            icon: "pencil",
            title: "New Asset Test",
            rule: "hasCustomer",
            to: { name: "asset_test.create" },
            loggedIn: true,
          },
        ],
        loggedIn: true,
      },
      {
        name: "Assets",
        children: [
          {
            icon: "view-list",
            title: "List Assets",
            rule: "hasCustomer",
            to: { name: "asset.list", query: { limit: 10, filter: "active" } },
            loggedIn: true,
          },
          {
            icon: "pencil",
            title: "New Asset",
            rule: "hasCustomer",
            to: { name: "asset.create" },
            loggedIn: true,
          },
        ],
        loggedIn: true,
      },

      {
        name: "Maintenance Record",
        children: [
          {
            icon: "view-list",
            title: "List Maintenance Record",
            rule: "hasCustomer",
            to: {
              name: "maintenance_record.list",
              query: { limit: 10, filter: "all" },
            },
            loggedIn: true,
          },
          {
            icon: "pencil",
            title: "New Maintenance Record",
            rule: "hasCustomer",
            to: { name: "maintenance_record.create" },
            loggedIn: true,
          },
        ],
        loggedIn: true,
      },

      {
        name: "Test Cycle",
        children: [
          {
            icon: "view-list",
            title: "List Test Cycle",
            rule: "hasCustomer",
            to: {
              name: "code_test_cycle.list",
              query: {
                limit: 25,
                filter: "active",
                sort: "description",
              },
            },
            loggedIn: true,
          },
          {
            icon: "pencil",
            title: "New Test Cycle",
            rule: "hasCustomer",
            to: { name: "code_test_cycle.create" },
            loggedIn: true,
          },
        ],
        loggedIn: true,
      },

      {
        name: "Reports",
        children: [
          {
            icon: "file-chart",
            title: "Reports",
            to: { name: "reports", params: { reportName: "NONE" } },
            loggedIn: true,
          },
        ],
      },
    ],
  },
  {
    name: "Administration",
    children: [
      {
        name: "Manage Customer",
        children: [
          {
            icon: "view-list",
            title: "List Customer",
            to: {
              name: "customer.list",
              query: {
                limit: 10,
                filter: "all",
                sort: "company_name",
                order: "asc",
              },
            },
            loggedIn: true,
          },
          {
            icon: "view-list",
            title: "List Asset Location",
            to: {
              name: "asset_location.list",
              query: {
                limit: 25,
                filter: "active",
                sort: "location_name_sub_1",
              },
            },
            loggedIn: true,
          },
        ],
        loggedIn: true,
      },
      {
        name: "Manage TTS",
        children: [
          {
            icon: "view-list",
            title: "List Employee",
            to: {
              name: "employee.list",
              query: {
                limit: 10,
                filter: "all",
                sort: "full_name",
                order: "asc",
              },
            },
            loggedIn: true,
          },
          {
            icon: "view-list",
            title: "List Users",
            to: { name: "user.list", query: { limit: 10, filter: "all" } },
            loggedIn: true,
          },
        ],
        loggedIn: true,
      },
      {
        name: "Manage Asset Code Tables",
        children: [
          {
            icon: "view-list",
            title: "List Product Type",
            to: {
              name: "code_product_type.list",
              query: { limit: 25, filter: "active", sort: "description" },
            },
            loggedIn: true,
          },
          {
            icon: "view-list",
            title: "List Product",
            to: {
              name: "product.list",
              query: { limit: 10, filter: "active", sort: "description" },
            },
            loggedIn: true,
          },
          {
            icon: "view-list",
            title: "List Make",
            to: {
              name: "code_make.list",
              query: { limit: 25, filter: "active", sort: "description" },
            },
            loggedIn: true,
          },
          {
            icon: "view-list",
            title: "List Model",
            to: {
              name: "code_model_name.list",
              query: { limit: 25, filter: "active", sort: "description" },
            },
            loggedIn: true,
          },
          {
            icon: "view-list",
            title: "List Color",
            to: {
              name: "code_color.list",
              query: {
                limit: 25,
                filter: "active",
                sort: "quick_display_order",
              },
            },
            loggedIn: true,
          },

          {
            icon: "view-list",
            title: "List Phase",
            to: {
              name: "code_phase.list",
              query: { limit: 25, filter: "active", sort: "description" },
            },
            loggedIn: true,
          },
          {
            icon: "view-list",
            title: "List Electrical Class",
            to: {
              name: "code_electrical_class.list",
              query: { limit: 25, filter: "active", sort: "description" },
            },
            loggedIn: true,
          },
          {
            icon: "view-list",
            title: "List Asset Status",
            to: {
              name: "code_asset_status.list",
              query: { limit: 25, filter: "active", sort: "description" },
            },
            loggedIn: true,
          },

          {
            icon: "view-list",
            title: "List Asset Classification",
            to: {
              name: "code_asset_classification.list",
              query: { limit: 25, filter: "active", sort: "description" },
            },
            loggedIn: true,
          },
          {
            icon: "view-list",
            title: "List File Type",
            to: {
              name: "code_file_type.list",
              query: { limit: 25, filter: "active", sort: "description" },
            },
            loggedIn: true,
          },
        ],
        loggedIn: true,
      },
      {
        name: "Manage Other Code Tables",
        children: [
          {
            icon: "view-list",
            title: "List Repair Type",
            to: {
              name: "code_repair_type.list",
              query: { limit: 25, filter: "active", sort: "description" },
            },
            loggedIn: true,
          },
          {
            icon: "view-list",
            title: "List Customer Status",
            to: {
              name: "code_customer_status.list",
              query: { limit: 25, filter: "active", sort: "description" },
            },
            loggedIn: true,
          },
          {
            icon: "view-list",
            title: "List Location Type",
            to: {
              name: "code_location_type.list",
              query: {
                limit: 25,
                filter: "active",
                sort: "description",
              },
            },
            loggedIn: true,
          },
          {
            icon: "view-list",
            title: "List Asset Test Status",
            to: {
              name: "code_asset_test_status.list",
              query: { limit: 25, filter: "active", sort: "description" },
            },
            loggedIn: true,
          },
          {
            icon: "view-list",
            title: "List Test Frequency",
            to: {
              name: "code_test_frequency.list",
              query: { limit: 25, filter: "active", sort: "description" },
            },
            loggedIn: true,
          },
          {
            icon: "view-list",
            title: "List Replacement Part",
            to: {
              name: "code_replacement_part.list",
              query: { limit: 25, filter: "active", sort: "description" },
            },
            loggedIn: true,
          },
        ],
        loggedIn: true,
      },
    ],
  },
];
