<template>
    <o-do-server-action-button
        :filename="filename"
        :request="report_request"
        :variant-color="variant"
        uri="/reports/download/"
        :doSave="doSave"
        v-bind="$attrs"
    >
        <slot />
    </o-do-server-action-button>
</template>

<script>
export default {
    name: "OReportButton",
    props: {
        reportName: { type: String, required: true },
        params: {
            type: Object,
            default: () => {},
        },
        format: { type: String, default: "pdf" },
        filename: { type: String, default: "report.pdf" },
        doSave: { type: Function, default: null },
        variant: { type: String, default: "secondary" },
    },
    computed: {
        report_request() {
            const vm = this;
            var result = {
                _format: vm.format,
                _reportName: vm.reportName,

                ...(vm.params || {}),
            };

            return result;
        },
    },
};
</script>

<style scoped></style>
