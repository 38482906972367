<template>
    <BAlert
        :show="isRefresh"
        class="position-fixed fixed-bottom m-0 rounded-0"
        style="z-index: 2000"
        variant="info"
        dismissible
    >
        A new version is available.<button
            @click="update"
            class="ml-2 btn btn-primary"
        >
            Update
        </button>
    </BAlert>
</template>
<script>
import { BAlert } from "bootstrap-vue";

export default {
    components: {
        BAlert,
    },
    data: () => ({
        registration: null,
        isRefresh: false,
        refreshing: false,
        updatePressed: false, // Not pressed yet!
    }),
    methods: {
        reloadPageNow() {
            if (this.isRefresh === false && this.updatePressed) {
                this.updatePressed = false;
                window.location.href =
                    window.location.origin + "/?t=" + Date.now().toString();
            }
        },
        appUpdateUI: function (e) {
            this.registration = e.detail;
            if (this.isRefresh === false && this.updatePressed) {
                // User pressed the button
                this.reloadPageNow();
            }
            this.isRefresh = true;
        },
        update() {
            this.isRefresh = false;
            this.updatePressed = true;
            if (this.registration || this.registration.waiting) {
                this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
            }
        },
    },
    created() {
        document.addEventListener(
            "serviceWorkerUpdateEvent",
            this.appUpdateUI,
            {
                once: true,
            },
        );
    },
};
</script>
