import _ from "lodash";

export default {
    props: {
        items: { type: Array, default: () => [] },
        itemsById: {
            type: Object,
            default: () => {},
        },
        idField: { type: String, default: "id" },
        textField: { type: String, default: "description" },
        parentsArrayField: {
            type: String,
            default: "parents",
        } /* Need this if value is a number */,
        value: { type: [Array, Number], default: () => [] },
        valueType: {
            type: String,
            default: "array",
        } /* array = array to this node, id= Id of the selected node only*/,
    },
    computed: {
        value_is_array() {
            // return true if this.value is an array and not just a number!
            return _.isArray(this.value || []);
        },
        _itemsByID() {
            // return the prop or calculate ourself.
            return this.itemsById || _.keyBy(this.items, this.idField);
        },
        value_ids() {
            // Get the value as an Array if it's was JUST a number find it's parents
            // and return the full ID array so it works as an array

            if (this.value == null) {
                return []; // null!
            }

            const keys = this._itemsByID;
            var values = this.value || [];

            // If just a number find it in itemByID and get parents..
            if (!this.value_is_array) {
                var item = keys[this.value];
                if (!item) {
                    item = { [this.parentsArrayField]: [] }; // empty so it still works even if not 100% properly.
                }

                if (_.isArray(item[this.parentsArrayField])) {
                    values = [...item[this.parentsArrayField], this.value];
                } else {
                    values = [this.value];
                }
            }

            return values;
        },
        treeData() {
            const vm = this;
            const keys = this._itemsByID;

            var values = this.value_ids;

            var result = _.map(values, function (i) {
                var result = keys[i];
                if (result) {
                    return {
                        id: keys[i][vm.idField],
                        label: result[vm.textField],
                    };
                } else {
                    return { id: null, label: "--" };
                }
            });

            return result;
        },
        selected_id() {
            if (this.value || this.value === 0) {
                if (this.value_is_array) {
                    return this.value[this.value.length - 1];
                } else {
                    return this.value; // integer
                }
            } else {
                return null;
            }
        },
    },
};
