import _ from "lodash";
import { FIELD_TYPES } from "./index.js";

export function cleanUpField(field, options) {
    if (field.field_type === FIELD_TYPES.Number) {
        field.type = "number";
    }

    if (field.field_type === FIELD_TYPES.Money) {
        field.step = "0.01";
        field.type = "number";
    }

    if (field.field_type === FIELD_TYPES.AutoNumber) {
        field.disabled = true;
    }

    _.merge(field, options);
}

/* Take a model and clean it up for Vuetify
    This allows us to change the model for Vuetify if we
    need to..

    Might use the options to specify changes/config etc.

    For now just passes thought.
 */
export function cleanUpModel(model, options) {
    "use strict";

    if (model && "fields" in model) {
        _.each(model.fields, function (field) {
            var option = {};
            if ("fields" in options) {
                if (field.name in options.fields) {
                    option = options.fields[field.name];
                }
            }

            cleanUpField(field, option);
        });
    }

    return model;
}
