<template>
    <div>
        <div class="card text-center">
            <div class="card-header">
                <ul class="nav nav-tabs card-header-tabs">
                    <li
                        class="nav-item"
                        v-for="grp in section_with_id"
                        :key="grp.id"
                    >
                        <router-link
                            class="nav-link"
                            :class="{ active: current_group.id === grp.id }"
                            aria-current="page"
                            :to="{
                                name: 'homepage',
                                params: { group: grp.id },
                            }"
                            >{{ grp.name }}</router-link
                        >
                    </li>
                </ul>
            </div>

            <div v-if="!current_group" class="alert alert-info">
                There are no menu items for you. Try a different login.
            </div>

            <div class="card-body">
                <div class="row" v-if="current_group && current_group.children">
                    <div
                        v-for="section in current_group.children"
                        :key="section.name"
                        class="dashboard-menu pb-4 col-sm-12 col-lg-6"
                    >
                        <div class="list-group">
                            <label class="list-group-item active">{{
                                section.name
                            }}</label>
                            <template v-if="section && section.children">
                                <router-link
                                    v-for="child in section.children"
                                    :key="child.title"
                                    :to="child.to"
                                    class="list-group-item list-group-item-action d-flex"
                                >
                                    <div class="align-self-start">
                                        <component
                                            :is="child.icon + '-icon'"
                                        ></component>
                                    </div>

                                    <div
                                        class="align-self-start flex-fill text-left"
                                    >
                                        &nbsp; {{ child.title }}
                                    </div>

                                    <div class="align-self-end">
                                        <menu-right-outline-icon />
                                    </div>
                                </router-link>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { keyBy, snakeCase, map } from "lodash";

import MenuMixin from "@/vue-ocom/components/layout/app/MenuMixin.js";
import MenuRightOutlineIcon from "vue-material-design-icons/MenuRightOutline.vue";

export default {
    name: "ONavDashboardMenu",
    mixins: [MenuMixin],
    components: { MenuRightOutlineIcon },
    props: {
        items: {},
    },
    computed: {
        section_with_id() {
            return map(this.filtered_items, function (o) {
                if (!("id" in o)) {
                    o.id = snakeCase(o.name);
                }

                return o;
            });
        },
        current_group() {
            var result = this.filtered_items[0];

            if (this.$route.params && this.$route.params.group) {
                var name = this.$route.params.group;

                result = keyBy(this.section_with_id, "id")[name];
            }
            return result;
        },
    },
};
</script>

<style scoped></style>
