<template>
    <div class="card" v-if="useFrame">
        <div :class="headClass" @click="click">
            {{ title }}
            <BButton @click="close" v-if="closable">
                <close-icon />
            </BButton>
        </div>
        <div :class="bodyClass" v-if="value">
            <slot :level="level" />
        </div>
    </div>
    <div v-else>
        <div v-if="value">
            <slot :level="level" />
        </div>
    </div>
</template>

<style>
.card-header {
    /* background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #ffffff), color-stop(1, #eaeaea));*/
}
</style>
<script>
import { BButton } from "bootstrap-vue";
import CloseIcon from "vue-material-design-icons/Close.vue";

export default {
    name: "OSection",
    components: {
        BButton,
        CloseIcon,
    },
    model: {
        prop: "value",
        event: "close",
    },
    props: {
        title: { type: String, default: "" },
        value: { type: Boolean, default: true },
        level: { type: Number, default: 1 }, // Might not use this but allows us have "different" levels..
        // background: { type: String, default: 'primary' },
        closable: { type: Boolean, default: false },

        bodyClasses: { type: String, default: "" },
        headClasses: { type: String, default: "" },
    },
    data() {
        return {};
    },
    computed: {
        headClass() {
            return "card-header o-bg-gradient-light" + (this.headClasses || "");
        },
        bodyClass() {
            return "card-body " + (this.bodyClasses || "");
        },
        useFrame() {
            return (
                (this.title != null && this.title != "") ||
                this.closable == true
            );
        },
    },
    methods: {
        close: function () {
            this.$emit("close", false);
        },
        click: function ($event) {
            this.$emit("click", $event);
        },
    },
};
</script>
