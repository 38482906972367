import _ from "lodash";

/*
    Merge the 'class' prop value in classValue withe the Dict in newClasses

    Preserves the type ie Object, Array or String value
*/
export function mergeCss(classValue, newClasses) {
    var result = classValue;

    if (!classValue) {
        result = {};
    }

    // Use the same 'type' as the prop passed in.
    if (_.isArray(result)) {
        _.forEach(newClasses, function (v, k) {
            if (v) {
                result.push(k);
            }
        });
    }
    if (_.isObject(result)) {
        result = { ...result, ...newClasses };
    }
    if (_.isString(result)) {
        _.forEach(newClasses, function (v, k) {
            if (v) {
                result = result + " " + k;
            }
        });
    }

    return result;
}
