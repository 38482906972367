<template>
  <div>
    <h3 @click="enableDebug($event)" class="display-4">Manage Asset Tests</h3>

    <o-list-toolbar
      :filters-list="filter_list"
      :list-options="list_options"
      :new-to="{ name: 'asset_test.create', params: { id: 0 } }"
      :query-select-options="options"
      :schema="schema"
      :search-fields-list="search_field_list"
      :selected-filter="selected_filter"
      @filter_changed="update_filter($event)"
      @options_changed="update_table($event)"
      @reset_table="reset_table"
      new-button-text="New Asset Test"
    >
      <template slot="middle">
        <o-list-multi-actions
          :schema="schema"
          :selection="selected"
          @delete="doDeleteItems"
          @merge="doMergeItems"
          enable-delete
          enable-merge
        />
      </template>
    </o-list-toolbar>

    <b-table
      :fields="fields"
      :items="items"
      :sort-by="list_options.sort"
      :sort-desc="list_options.order === 'desc'"
      @row-selected="onRowSelected"
      @sort-changed="change_sort"
      bordered
      class="o-list"
      head-variant="light"
      no-local-sorting
      selectable
      striped
      thead-class="o-gradient-header"
    >
      <template v-slot:cell(_selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
      <template
        slot="top-row"
        slot-scope="{ columns, fields }"
        v-if="filterRow"
      >
        <td :key="field.key" class="p-0" v-for="field in fields">
          <span v-if="field.key === 'asset__full_name'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.asset__full_name"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'created_date'">
            <o-flatpickr-wrapper
              @change="filter_changed"
              class="rounded-0 px-0"
              no-buttons
              v-model="list_options.qbe.created_date"
            ></o-flatpickr-wrapper>
          </span>
          <span v-if="field.key === 'modified_date'">
            <o-flatpickr-wrapper
              @change="filter_changed"
              class="rounded-0 px-0"
              no-buttons
              v-model="list_options.qbe.modified_date"
            ></o-flatpickr-wrapper>
          </span>
          <span v-if="field.key === 'notes'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.notes"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'original_id'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.original_id"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'repaired'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.repaired"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'retrospective'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.retrospective"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'status'">
            <o-form-select
              :options="optionCodeAssetTestStatusItems"
              :loading="optionCodeAssetTestStatusWorking"
              @change="filter_changed"
              name="status"
              sort-field="description"
              text-field="description"
              v-model="list_options.qbe.status"
              value-field="id"
            >
              <template v-slot:first>
                <option :value="null"></option>
              </template>
            </o-form-select>
          </span>
          <span v-if="field.key === 'supplied'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.supplied"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'supplied_product'">
            <o-form-select
              :options="optionProductItems"
              :loading="optionProductWorking"
              @change="filter_changed"
              name="supplied_product"
              sort-field="model"
              text-field="model"
              v-model="list_options.qbe.supplied_product"
              value-field="id"
            >
              <template v-slot:first>
                <option :value="null"></option>
              </template>
            </o-form-select>
          </span>
          <span v-if="field.key === 'test_cycle'">
            <o-form-select
              :options="optionCodeTestCycleItems"
              :loading="optionCodeTestCycleWorking"
              @change="filter_changed"
              name="test_cycle"
              sort-field="description"
              text-field="description"
              v-model="list_options.qbe.test_cycle"
              value-field="id"
              ><template v-slot:first>
                <option :value="null"></option>
              </template>
            </o-form-select>
          </span>
          <span v-if="field.key === 'test_date'">
            <o-flatpickr-wrapper
              @change="filter_changed"
              class="rounded-0 px-0"
              no-buttons
              v-model="list_options.qbe.test_date"
            ></o-flatpickr-wrapper>
          </span>
          <span v-if="field.key === 'tester'">
            <o-form-select
              :options="optionEmployeeItems"
              :loading="optionEmployeeWorking"
              @change="filter_changed"
              name="tester"
              sort-field="full_name"
              text-field="full_name"
              v-model="list_options.qbe.tester"
              value-field="id"
            >
              <template v-slot:first>
                <option :value="null"></option>
              </template>
            </o-form-select>
          </span>
        </td>
      </template>
      <template slot="cell(asset__full_name)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset_test.edit', params: { id: item.id } }"
          :when="first_column_key === 'asset'"
        >
          <default-display :value="item.asset__full_name"></default-display>
          <!-- <lookup-display
            :list="optionAssetItemsById"
            :value="item.asset"
            default-value="-"
            display-field="name"
          ></lookup-display> -->
        </o-link-cell>
      </template>
      <template slot="cell(created_date)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset_test.edit', params: { id: item.id } }"
          :when="first_column_key === 'created_date'"
        >
          <date-display
            :value="item.created_date"
            format="DD/MM/YYYY hh:mm"
          ></date-display>
        </o-link-cell>
      </template>
      <template slot="cell(modified_date)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset_test.edit', params: { id: item.id } }"
          :when="first_column_key === 'modified_date'"
        >
          <date-display
            :value="item.modified_date"
            format="DD/MM/YYYY hh:mm"
          ></date-display>
        </o-link-cell>
      </template>
      <template slot="cell(notes)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset_test.edit', params: { id: item.id } }"
          :when="first_column_key === 'notes'"
        >
          <default-display :value="item.notes"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(original_id)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset_test.edit', params: { id: item.id } }"
          :when="first_column_key === 'original_id'"
        >
          <number-display :decimals="0" :value="item.original_id" />
        </o-link-cell>
      </template>
      <template slot="cell(repaired)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset_test.edit', params: { id: item.id } }"
          :when="first_column_key === 'repaired'"
        >
          <boolean-display :value="item.repaired"></boolean-display>
        </o-link-cell>
      </template>
      <template slot="cell(retrospective)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset_test.edit', params: { id: item.id } }"
          :when="first_column_key === 'retrospective'"
        >
          <boolean-display :value="item.retrospective"></boolean-display>
        </o-link-cell>
      </template>
      <template slot="cell(status)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset_test.edit', params: { id: item.id } }"
          :when="first_column_key === 'status'"
        >
          <lookup-display
            :list="optionCodeAssetTestStatusItemsById"
            :value="item.status"
            default-value="-"
            display-field="description"
          ></lookup-display>
        </o-link-cell>
      </template>
      <template slot="cell(supplied)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset_test.edit', params: { id: item.id } }"
          :when="first_column_key === 'supplied'"
        >
          <boolean-display :value="item.supplied"></boolean-display>
        </o-link-cell>
      </template>
      <template slot="cell(supplied_product)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset_test.edit', params: { id: item.id } }"
          :when="first_column_key === 'supplied_product'"
        >
          <lookup-display
            :list="optionProductItemsById"
            :value="item.supplied_product"
            default-value="-"
            display-field="model"
          ></lookup-display>
        </o-link-cell>
      </template>
      <template slot="cell(test_cycle)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset_test.edit', params: { id: item.id } }"
          :when="first_column_key === 'test_cycle'"
        >
          <lookup-display
            :list="optionCodeTestCycleItemsById"
            :value="item.test_cycle"
            default-value="-"
            display-field="description"
          ></lookup-display>
        </o-link-cell>
      </template>
      <template slot="cell(test_date)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset_test.edit', params: { id: item.id } }"
          :when="first_column_key === 'test_date'"
        >
          <date-display
            :value="item.test_date"
            format="DD/MM/YYYY hh:mm"
          ></date-display>
        </o-link-cell>
      </template>
      <template slot="cell(tester)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset_test.edit', params: { id: item.id } }"
          :when="first_column_key === 'tester'"
        >
          <lookup-display
            :list="optionEmployeeItemsById"
            :value="item.tester"
            default-value="-"
            display-field="full_name"
          ></lookup-display>
        </o-link-cell>
      </template>

      <template slot="empty">
        <p>
          <emoticon-sad-icon large></emoticon-sad-icon>
        </p>
        No Data Found. Please
        <router-link :to="{ name: 'asset_test.create' }"
          >create a new item</router-link
        >
      </template>
    </b-table>

    <o-list-paging
      :limit="list_options.limit"
      :offset="list_options.offset"
      :page-options="rows_per_page_items"
      :total-items="total_items"
      @change-limit="change_limit"
      @change-page="change_page"
    />
  </div>
</template>

<style></style>

<script>
import {
  CreateGroupPermissionMixin,
  CreateListPageMixin,
  MultiActionsMixin,
} from "../../../vue-ocom/mixins";
import {
  fieldToTableColumn,
  GetSearchFields,
} from "../../../vue-ocom/schema/schema_utils";
import { cleanUpModel } from "../../../vue-ocom/schema/schema_vuetify";
import schema from "../../schema/index";

import OListToolbar from "../../../vue-ocom/components/list/OListToolbar";
import OListPaging from "../../../vue-ocom/components/list/OListPaging";
import DefaultDisplay from "../../../vue-ocom/components/display/DefaultDisplay";
import DateDisplay from "../../../vue-ocom/components/display/DateDisplay";
import NumberDisplay from "../../../vue-ocom/components/display/NumberDisplay";
import BooleanDisplay from "../../../vue-ocom/components/display/BooleanDisplay";
import LookupDisplay from "../../../vue-ocom/components/display/LookupDisplay";
import EmoticonSadIcon from "vue-material-design-icons/EmoticonSad";
import EmailIcon from "vue-material-design-icons/Email";
import LinkIcon from "vue-material-design-icons/Link";

import FetchAndUseCodeModMixin from "@/vue-ocom/mixins/FetchAndUseCodeModMixin";

export default {
  components: {
    EmoticonSadIcon,
    EmailIcon,
    LinkIcon,
    OListPaging,
    OListToolbar,
    DateDisplay,
    DefaultDisplay,
    BooleanDisplay,
    NumberDisplay,
    LookupDisplay,
  },
  mixins: [
    CreateListPageMixin("AssetTest", "asset_test"),
    MultiActionsMixin,
    CreateGroupPermissionMixin("AssetTest"),
    FetchAndUseCodeModMixin([
      "Asset",
      "Employee",
      "CodeAssetTestStatus",
      "CodeTestCycle",
      "Product",
      "Employee",
      "CodeRepairType",
    ]),
  ],
  data: function () {
    return {
      schemaModel: cleanUpModel(
        schema.AssetTest,
        {}
      ) /*Use this.schema instead */,

      search_field_list: GetSearchFields(schema.AssetTest),

      filterRow: true /* Set to False to disable filter row */,
      filter_list: [
        { name: "Active", code: "active", options: { offset: 0, sort: "id" } },
        {
          name: "Non Active",
          code: "inactive",
          options: { offset: 0, sort: "id" },
        },
        { name: "All", code: "all", options: { offset: 0, sort: "id" } },
      ],

      user_column_selection:
        null /* If user can customise the columns set them here */,
      column_override_params: {
        /* Put any column overrides here key by fieldname */
      },
    };
  },
  computed: {
    options() {
      return {
        Asset: {
          options: this.optionAssetItems,
          textField: "name",
          valueField: "id",
        },
        Employee: {
          options: this.optionEmployeeItems,
          textField: "full_name",
          valueField: "id",
        },
        CodeAssetTestStatus: {
          options: this.optionCodeAssetTestStatusItems,
          textField: "description",
          valueField: "id",
        },
        CodeTestCycle: {
          options: this.optionCodeTestCycleItems,
          textField: "description",
          valueField: "id",
        },
        Product: {
          options: this.optionProductItems,
          textField: "model",
          valueField: "id",
        },
      };
    },
    fields() {
      const vm = this;
      const selectedColumns = this.user_column_selection || [
        "tester",
        "asset__full_name",
        "test_cycle",
        "supplied",
        "status",
        "repaired",
        "test_date",
      ];
      const fields = selectedColumns.map(function (item) {
        return fieldToTableColumn(
          vm.schema,
          item,
          vm.column_override_params[item]
        );
      });

      return this.addSelectionColums(
        this.filterTableColumns(this.schema, fields)
      );
    },
    first_column_key() {
      if (this.fields) {
        return this.fields[0].key;
      } else {
        return null;
      }
    },
  },
};
</script>
