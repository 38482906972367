import CodeProductTypeList from "./pages/ListPage.vue";
import CodeProductTypeDetail from "./pages/FormPage.vue";
import CodeProductTypeIndex from "./pages/IndexPage.vue";

export default [
  {
    path: "/code_product_type",
    component: CodeProductTypeIndex,
    redirect: "/code_product_type/list",
    name: "code_product_type",
    children: [
      {
        path: "list",
        component: CodeProductTypeList,
        name: "code_product_type.list",
      },
      {
        path: "create",
        component: CodeProductTypeDetail,
        name: "code_product_type.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: CodeProductTypeDetail,
        name: "code_product_type.edit",
        props: { create: false },
      },
    ],
  },
];
