<template>
    <div @change="onChanged">
        <table class="table table-hover table-bordered">
            <thead class="thead-dark">
                <!--Insert headers from form-->
                <slot name="o-headers"></slot>

                <th class="action-column">
                    <o-list-add
                        :default-value="defaultNewValue"
                        :item-name="itemName"
                        @change="onChanged"
                        v-if="!disableAdd && !is_locked"
                        v-model="list"
                        :display-order="displayOrder"
                    ></o-list-add>
                    <!--Support for more buttons beside Add New button-->
                    <slot name="right-action"></slot>
                </th>
            </thead>
            <tbody>
                <slot name="o-top-row"></slot>
                <tr
                    :key="item[itemKey]"
                    v-for="(item, index) in list"
                    v-if="is_item_active(item)"
                >
                    <slot :index="index" :item="item" name="o-body"></slot>

                    <td>
                        <div class="list-actions">
                            <o-list-actions
                                :disable-delete="is_delete_disabled(item)"
                                :disable-move="is_move_disabled(item)"
                                :display-order="displayOrder || is_locked"
                                :enable-insert="enableInsert"
                                :index="index"
                                :default-value="defaultValue"
                                @change="onChanged"
                                v-model="list"
                            ></o-list-actions>
                            <span v-if="formState && formState.debug">{{
                                item.display_order
                            }}</span>
                        </div>
                    </td>
                </tr>
                <slot name="o-bottom-row"></slot>
            </tbody>
        </table>
    </div>
</template>

<style scoped>
.table td {
    padding: 1px;
}
</style>

<script>
import { isFunction } from "lodash";

// This component would be a "Table" in a form to edit the Code Examples as a child
import OListActions from "./OListActions.vue";
import OListAdd from "./OListAdd.vue";
import { filter_active_list, is_item_active } from "../../utils/active.js";
import moment from "moment";
import _ from "lodash";

export default {
    name: "OItemEditableTable",
    components: {
        OListActions: OListActions,
        OListAdd: OListAdd,
    },
    props: {
        /* The List */
        list: { type: Array, default: () => [] },

        /*OlistActions */
        disableMove: { type: [Boolean, Function], default: false },
        disableAdd: { type: Boolean, default: false },
        disableDelete: { type: [Boolean, Function], default: false },
        displayOrder: { type: String, default: "display_order" },

        disableActiveFilter: { type: Boolean, default: false },
        useActiveEndDateNullOnly: { type: Boolean, default: false },

        enableInsert: { type: Boolean, default: false },
        defaultValue: {
            type: [Object, Function],
            default: () => {},
        },

        /* OListAdd */
        defaultNewValue: {
            type: [Object, Function],
            default: () => {},
        },
        itemName: { type: String, default: "Item" },

        /* v-for key */
        itemKey: { type: String, default: "id" },

        formState: {
            type: Object,
            default: () => {},
        },
    },
    model: {
        prop: "list",
        event: "change",
    },
    methods: {
        onChanged() {
            this.$emit("change", this.list);
        },
        is_item_active(item) {
            if (this.disableActiveFilter) {
                return true; // always Active
            }

            if (this.useActiveEndDateNullOnly) {
                return item.active_end_date == null;
            }

            var now = moment();
            return is_item_active(item, now);
        },
        is_move_disabled(item) {
            if (this.is_locked) return true;
            if (isFunction(this.disableMove)) {
                return this.disableMove(item);
            } else {
                return this.disableMove;
            }
        },
        is_delete_disabled(item) {
            if (this.is_locked) return true;
            if (isFunction(this.disableDelete)) {
                return this.disableDelete(item);
            } else {
                return this.disableDelete;
            }
        },
    },
    computed: {
        filtered_list() {
            var result = this.list;

            result = filter_active_list(result); // Filter for Active only

            return result;
        },
        is_locked() {
            if (this.formState) {
                if ("locked" in this.formState) {
                    return this.formState.locked;
                }
            }

            return false;
        },
    },
    data: () => ({}),
};
</script>
