<template>
    <div v-if="file">
        <img
            :src="inlineURL"
            style="max-width: 800px"
            v-if="file.content_type.indexOf('image/') == 0"
        />

        <iframe
            :src="inlineURL"
            height="600px"
            v-if="file.content_type.indexOf('application/pdf') == 0"
            :width="width"
        ></iframe>

        <!-- TODO Show Icon for Mime Type/Content Type -->
        <!-- TODO show Video -->

        <br />
        <o-file-download-link
            :file="file"
            :disable-download="disableDownload"
            :hide-file-info="hideFileInfo"
        ></o-file-download-link>
    </div>
</template>

<script>
import OFileDownloadLink from "@/vue-ocom/filesystem/OFileDownloadLink.vue";
import GoMixin from "../mixins/GoMixin.js";
import { CreateDownloadURL } from "./fs_utils.js";

export default {
    name: "OFilePreview",
    mixins: [GoMixin],
    components: { OFileDownloadLink },
    props: {
        file: {
            type: Object,
            default: () => {},
        },
        disableDownload: { type: Boolean, default: false },
        hideFileInfo: { type: Boolean, default: false },
        width: { type: String, default: "800px" },
        token: { type: String, default: null },
    },
    computed: {
        inlineURL: function () {
            if (this.file) {
                return CreateDownloadURL(this.file.id, this.token, false);
            } else {
                return "";
            }
        },
    },
};
</script>

<style scoped></style>
