<template>
    <o-cross-table
        :column-headers="header_data"
        :column-headers-config="header_config"
        :debug="debug"
        :row-headers="row_headers"
        :row-headers-config="row_config"
        :values="data"
    >
        <template #header-cell="{ item }">
            {{ item.data.name }}
        </template>
        <template #row-header="{ item }">
            <span v-if="item && item.data">{{ item.data.name }}</span>
        </template>
        <template
            slot="value-cell"
            slot-scope="{ items, colHeader, rowHeader }"
        >
            <span :key="index" v-for="(item, index) in items">
                [{{ item.label }}]
            </span>
        </template>
    </o-cross-table>
</template>

<script>
import OCrossTable from "../OCrossTable.vue";

export default {
    name: "OCrossTableTest",
    components: { OCrossTable },
    data: function () {
        return {
            header_config: [
                {
                    key: "id",
                    children_key: "children",
                    title: "Sites",
                    item_key: "site",
                },
                {
                    key: "id",
                    children_key: null,
                    title: "Rooms",
                    item_key: "room",
                },
            ],

            debug: false,

            header_data: [
                {
                    id: 1,
                    name: "Site 1",
                    children: [
                        { id: 1, name: "Room 1" },
                        { id: 2, name: "Room 2" },
                    ],
                },

                {
                    id: 2,
                    name: "Site 2",
                    children: [
                        { id: 1, name: "Room 1" },
                        { id: 2, name: "Room 2" },
                        { id: 3, name: "Room 3" },
                    ],
                },
                {
                    id: 3,
                    name: "Site 3",
                    children: [
                        { id: 1, name: "Room 1" },
                        { id: 2, name: "Room 2" },
                    ],
                },
            ],

            row_headers: [
                {
                    id: 1,
                    name: "Employee 1",
                    children: [
                        { id: 1, name: "Shift 1" },
                        { id: 2, name: "Shift 2" },
                    ],
                },

                {
                    id: 2,
                    name: "Employee 2",
                    children: [
                        { id: 1, name: "Shift 1" },
                        { id: 2, name: "Shift 2" },
                        { id: 3, name: "Shift 3" },
                    ],
                },
                {
                    id: 3,
                    name: "Employee 3",
                    children: [
                        { id: 1, name: "Shift 1" },
                        { id: 2, name: "Shift 2" },
                    ],
                },
            ],

            row_config: [
                {
                    key: "id",
                    children_key: "children",
                    title: "Employee",
                    item_key: "employee",
                },
                {
                    key: "id",
                    children_key: null,
                    title: "Shift",
                    item_key: "shift",
                },
            ],

            data: [
                {
                    site: 1,
                    room: 1,
                    employee: 1,
                    shift: 1,
                    label: "Employee 1, Shift 1, Site 1, Room 1",
                },
                {
                    site: 2,
                    room: 2,
                    employee: 2,
                    shift: 2,
                    label: "Employee 2, Shift 2, Site 2, Room 2",
                },
                {
                    site: 2,
                    room: 2,
                    employee: 2,
                    shift: 3,
                    label: "Employee 2, Shift 3, Site 2, Room 2",
                },
            ],
        };
    },
};
</script>

<style scoped></style>
