<template>
  <o-form-wrapper
    v-model="item"
    :form-state="formState"
    :schema="schema"
    :server-errors="_se"
    :v="$v.item"
  >
    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="description"
    >
      <o-text-field
        v-model="item.description"
        v-bind="schema.fields.description"
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="description"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="product_type"
    >
      <o-select
        v-model="item.product_type"
        v-bind="schema.fields.product_type"
        :form-state="formState"
        :items="optionCodeProductTypeItems"
        :loading="optionCodeProductTypeWorking"
        :server-errors="_se"
        :validators="$v.item"
        add-title="Create New"
        allow-add
        name="product_type"
        sort-field="description"
        store-name="CodeProductType"
        text-field="description"
        value-field="id"
      >
        <template slot="form" slot-scope="{ item, formState, serverErrors }">
          <code-product-type-form
            :form-state="formState"
            :item="item"
            :server-errors="serverErrors"
            in-modal
          ></code-product-type-form>
        </template>
      </o-select>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="make"
    >
      <o-text-field
        v-model="item.make"
        v-bind="schema.fields.make"
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="make"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="electrical_class"
    >
      <b-form-group
        label="Class"
        label-align="right"
        label-cols="3"
        label-cols-lg="2"
      >
        <speed-select
          allow-unselect
          :form-state="formState"
          :items="optionCodeElectricalClassItems"
          text-field="code"
          v-model="item.electrical_class"
        ></speed-select>
      </b-form-group>
      <!-- <o-select
        v-model="item.electrical_class"
        v-bind="schema.fields.electrical_class"
        :form-state="formState"
        :items="optionCodeElectricalClassItems"
        :loading="optionCodeElectricalClassWorking"
        :server-errors="_se"
        :validators="$v.item"
        add-title="Create New"
        allow-add
        name="electrical_class"
        sort-field="description"
        store-name="CodeElectricalClass"
        text-field="description"
        value-field="id"
      >
        <template slot="form" slot-scope="{ item, formState, serverErrors }">
          <code-electrical-class-form
            :form-state="formState"
            :item="item"
            :server-errors="serverErrors"
            in-modal
          ></code-electrical-class-form>
        </template>
      </o-select> -->
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="color"
    >
      <b-form-group
        label="Color"
        label-align="right"
        label-cols="3"
        label-cols-lg="2"
      >
        <speed-select
          allow-unselect
          :form-state="formState"
          :items="optionCodeColorItems"
          text-field="description"
          v-model="item.color"
        ></speed-select>
      </b-form-group>
      <!-- <o-select
        v-model="item.color"
        v-bind="schema.fields.color"
        :form-state="formState"
        :items="optionCodeColorItems"
        :loading="optionCodeColorWorking"
        :server-errors="_se"
        :validators="$v.item"
        add-title="Create New"
        allow-add
        name="color"
        sort-field="description"
        store-name="CodeColor"
        text-field="description"
        value-field="id"
      >
        <template slot="form" slot-scope="{ item, formState, serverErrors }">
          <code-color-form
            :form-state="formState"
            :item="item"
            :server-errors="serverErrors"
            in-modal
          ></code-color-form>
        </template>
      </o-select> -->
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="quick_display_order"
    >
      <o-text-field
        v-model="item.quick_display_order"
        v-bind="schema.fields.quick_display_order"
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="quick_display_order"
      ></o-text-field>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="asset_classification"
    >
      <o-select
        v-model="item.asset_classification"
        v-bind="schema.fields.asset_classification"
        :form-state="formState"
        :items="optionCodeAssetClassificationItems"
        :loading="optionCodeAssetClassificationWorking"
        :server-errors="_se"
        :validators="$v.item"
        add-title="Create New"
        allow-add
        name="asset_classification"
        sort-field="description"
        store-name="CodeAssetClassification"
        text-field="description"
        value-field="id"
      >
        <template slot="form" slot-scope="{ item, formState, serverErrors }">
          <code-asset-classification-form
            :form-state="formState"
            :item="item"
            :server-errors="serverErrors"
            in-modal
          ></code-asset-classification-form>
        </template>
      </o-select>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="phase"
    >
      <o-select
        v-model="item.phase"
        v-bind="schema.fields.phase"
        :form-state="formState"
        :items="optionCodePhaseItems"
        :loading="optionCodePhaseWorking"
        :server-errors="_se"
        :validators="$v.item"
        add-title="Create New"
        allow-add
        name="phase"
        sort-field="description"
        store-name="CodePhase"
        text-field="description"
        value-field="id"
      >
        <template slot="form" slot-scope="{ item, formState, serverErrors }">
          <code-phase-form
            :form-state="formState"
            :item="item"
            :server-errors="serverErrors"
            in-modal
          ></code-phase-form>
        </template>
      </o-select>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="recall"
    >
      <o-checkbox
        v-model="item.recall"
        v-bind="schema.fields.recall"
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="recall"
      ></o-checkbox>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="test_frequency"
    >
      <b-form-group
        label="Test Frequency"
        label-align="right"
        label-cols="3"
        label-cols-lg="2"
      >
        <speed-select
          allow-unselect
          :form-state="formState"
          :items="optionCodeTestFrequencyItems"
          v-model="item.test_frequency"
        ></speed-select>
      </b-form-group>

      <!-- <o-select
        v-model="item.test_frequency"
        v-bind="schema.fields.test_frequency"
        :form-state="formState"
        :items="optionCodeTestFrequencyItems"
        :loading="optionCodeTestFrequencyWorking"
        :server-errors="_se"
        :validators="$v.item"
        add-title="Create New"
        allow-add
        name="test_frequency"
        sort-field="description"
        store-name="CodeTestFrequency"
        text-field="description"
        value-field="id"
      >
        <template slot="form" slot-scope="{ item, formState, serverErrors }">
          <code-test-frequency-form
            :form-state="formState"
            :item="item"
            :server-errors="serverErrors"
            in-modal
          ></code-test-frequency-form>
        </template>
      </o-select> -->
    </o-widget-wrapper>
    <o-widget-wrapper
      :schema="schema"
      name="supplied"
      :validators="$v.item"
      :server-errors="_se"
      :form-state="formState"
    >
      <o-checkbox
        v-model="item.supplied"
        :form-state="formState"
        name="supplied"
        :validators="$v.item"
        :server-errors="_se"
        v-bind="schema.fields.supplied"
      ></o-checkbox>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="active_start_date"
    >
      <o-date-time
        v-model="item.active_start_date"
        v-bind="schema.fields.active_start_date"
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="active_start_date"
      ></o-date-time>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="active_end_date"
    >
      <o-date-time
        v-model="item.active_end_date"
        v-bind="schema.fields.active_end_date"
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="active_end_date"
      ></o-date-time>
    </o-widget-wrapper>
  </o-form-wrapper>
</template>

<script>
import schema from "../../schema/index";
import { cleanUpModel } from "../../../vue-ocom/schema/schema_vuetify";
import {
  CreateFormStateMixin,
  CreateGroupPermissionMixin,
  VuelidateMixin,
} from "../../../vue-ocom/mixins";
import { validationMixin } from "vuelidate";
import OWidgetWrapper from "../../../vue-ocom/components/forms/OWidgetWrapper";
import OTextField from "../../../vue-ocom/components/forms/fields/OTextField";
import OSelect from "../../../vue-ocom/components/forms/fields/OSelect";
import OCheckbox from "../../../vue-ocom/components/forms/fields/OCheckbox";
import OFormWrapper from "../../../vue-ocom/components/forms/OFormWrapper";
import ODateTime from "../../../vue-ocom/components/forms/fields/ODateTime";
import SpeedSelect from "../../../components/SpeedSelect";

import { default as CodeProductTypeForm } from "../../code_product_type/components/ItemForm";
import { default as CodeElectricalClassForm } from "../../code_electrical_class/components/ItemForm";
import { default as CodeColorForm } from "../../code_color/components/ItemForm";
import { default as CodeAssetClassificationForm } from "../../code_asset_classification/components/ItemForm";
import { default as CodePhaseForm } from "../../code_phase/components/ItemForm";
import { default as CodeTestFrequencyForm } from "../../code_test_frequency/components/ItemForm";

import UseCodeModulesMixin from "@/vue-ocom/mixins/UseCodeModulesMixin";

export default {
  mixins: [
    VuelidateMixin,
    validationMixin,
    CreateFormStateMixin("$v"),
    CreateGroupPermissionMixin("Product"),
    UseCodeModulesMixin([
      "CodeProductType",
      "CodeElectricalClass",
      "CodeColor",
      "CodeAssetClassification",
      "CodePhase",
      "CodeTestFrequency",
    ]),
  ],
  components: {
    OFormWrapper,
    OWidgetWrapper,
    ODateTime,

    OCheckbox,
    OSelect,
    OTextField,
    SpeedSelect,

    CodeProductTypeForm,
    CodeElectricalClassForm,
    CodeColorForm,
    CodeAssetClassificationForm,
    CodePhaseForm,
    CodeTestFrequencyForm,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    level: { type: Number, default: 0 },
    inModal: { type: Boolean, default: false },
  },
  data: () => ({
    schemaModel: cleanUpModel(schema.Product, {}),
  }),
  methods: {},
  computed: {
    checkActiveDate() {
      return this.item.active_start_date;
    },
    _se() {
      // Return an empty Object if there are no Server Errors to avoid Binding Errors
      return this.serverErrors || {};
    },
  },
  watch: {
    checkActiveDate() {
      // this.item.active_start_date = new Date();
    },
  },
  created() {},
  updated() {
    if (!this.item.active_start_date) {
      this.item.active_start_date = new Date();
    }
  },
};
</script>
