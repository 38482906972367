<template>
    <o-form-wrapper
        :form-state="formState"
        :schema="schema"
        :server-errors="_se"
        :v="$v.item"
        v-model="item"
    >
        <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="username"
        >
            <o-text-field
                :form-state="formState"
                :server-errors="_se"
                :validators="$v.item"
                name="username"
                v-bind="schema.fields.username"
                v-model="item.username"
            ></o-text-field>
        </o-widget-wrapper>

        <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="new_password"
        >
            <o-text-field
                :form-state="formState"
                :server-errors="_se"
                :validators="$v.item"
                :value="newPassword"
                name="new_password"
                v-bind="schema.fields.new_password"
                v-model="item.new_password"
                v-if="!complexity_checking"
            ></o-text-field>
            <o-text-field
                :form-state="formState"
                :server-errors="_se"
                :validators="$v.item"
                :value="newPassword"
                name="new_password"
                v-bind="schema.fields.new_password"
                v-model="item.new_password"
                @input="newTypedPassword"
                v-if="complexity_checking"
            ></o-text-field>
            <password
                class="password-complexity"
                v-model="item.new_password"
                :strength-meter-only="true"
                v-if="complexity_checking"
            >
            </password>
        </o-widget-wrapper>
        <div class="password-generator">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <o-generate @on-generate="generatePassword"></o-generate>
            </div>
        </div>

        <h4>Personal Info</h4>

        <div class="row no-gutters justify-content-end">
            <div class="col-lg-11 col-12 d-lg-flex">
                <div class="col-lg-6 col-12">
                    <o-widget-wrapper
                        :form-state="formState"
                        :schema="schema"
                        :server-errors="_se"
                        :validators="$v.item"
                        name="first_name"
                    >
                        <o-text-field
                            label="Name *"
                            :form-state="formState"
                            placeholder="First Name"
                            :server-errors="_se"
                            :validators="$v.item"
                            name="first_name"
                            v-bind="schema.fields.first_name"
                            v-model="item.first_name"
                        ></o-text-field>
                    </o-widget-wrapper>
                </div>
                <div class="col-lg-6 col-12">
                    <o-widget-wrapper
                        :form-state="formState"
                        :schema="schema"
                        :server-errors="_se"
                        :validators="$v.item"
                        name="last_name"
                    >
                        <o-text-field
                            nolabel
                            :form-state="formState"
                            placeholder="Last Name"
                            :server-errors="_se"
                            :validators="$v.item"
                            name="last_name"
                            v-bind="schema.fields.last_name"
                            v-model="item.last_name"
                        ></o-text-field>
                    </o-widget-wrapper>
                </div>
            </div>
        </div>

        <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="email"
        >
            <o-text-field
                :form-state="formState"
                :server-errors="_se"
                :validators="$v.item"
                name="email"
                v-bind="schema.fields.email"
                v-model="item.email"
            ></o-text-field>
        </o-widget-wrapper>

        <h4>Permissions</h4>

        <div v-if="!isGoBackend">
            <o-widget-wrapper
                :form-state="formState"
                :schema="schema"
                :server-errors="_se"
                :validators="$v.item"
                name="is_active"
            >
                <o-checkbox
                    :form-state="formState"
                    :server-errors="_se"
                    :validators="$v.item"
                    name="is_active"
                    v-bind="schema.fields.is_active"
                    v-model="item.is_active"
                ></o-checkbox>
            </o-widget-wrapper>
        </div>

        <o-widget-wrapper
            v-if="advancedUsage"
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="is_staff"
        >
            <o-checkbox
                :form-state="formState"
                :server-errors="_se"
                :validators="$v.item"
                name="is_staff"
                v-bind="schema.fields.is_staff"
                v-model="item.is_staff"
            ></o-checkbox>
        </o-widget-wrapper>

        <!--<o-widget-wrapper :schema="schema" name="is_superuser" :validators="$v.item" :server-errors="_se"
                          :form-state="formState">
            <o-checkbox
                v-model="item.is_superuser"

                :form-state="formState"
                name="is_superuser" :validators="$v.item" :server-errors="_se" v-bind="schema.fields.is_superuser"
            ></o-checkbox>
        </o-widget-wrapper>-->

        <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="groups"
        >
            <o-multi-checkbox
                :form-state="formState"
                :items="optionGroupItems"
                :loading="optionGroupWorking"
                :server-errors="_se"
                :validators="$v.item"
                name="group"
                sort-field="name"
                text-field="name"
                v-bind="schema.fields.groups"
                v-model="item.groups"
                value-field="id"
            >
            </o-multi-checkbox>
        </o-widget-wrapper>

        <o-widget-wrapper
            v-if="advancedUsage"
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="user_permissions"
        >
            <div style="overflow-y: scroll; overflow-x: hidden; height: 200px">
                <o-multi-checkbox
                    :form-state="formState"
                    :items="optionPermissionItems"
                    :loading="optionPermissionWorking"
                    :server-errors="_se"
                    :validators="$v.item"
                    multiple
                    name="user_permissions"
                    sort-field="name"
                    text-field="name"
                    v-bind="schema.fields.user_permissions"
                    v-model="item.user_permissions"
                    value-field="id"
                >
                </o-multi-checkbox>
            </div>
        </o-widget-wrapper>
        <div v-if="isGoBackend">
            <o-widget-wrapper
                :schema="schema"
                name="active_start_date"
                :validators="$v.item"
                :server-errors="_se"
                :form-state="formState"
            >
                <o-date-time
                    v-model="item.active_start_date"
                    name="active_start_date"
                    v-bind="schema.fields.active_start_date"
                    :form-state="formState"
                    :validators="$v.item"
                    :server-errors="_se"
                ></o-date-time>
            </o-widget-wrapper>

            <o-widget-wrapper
                :schema="schema"
                name="active_end_date"
                :validators="$v.item"
                :server-errors="_se"
                :form-state="formState"
            >
                <o-date-time
                    v-model="item.active_end_date"
                    name="active_end_date"
                    v-bind="schema.fields.active_end_date"
                    :form-state="formState"
                    :validators="$v.item"
                    :server-errors="_se"
                ></o-date-time>
            </o-widget-wrapper>
        </div>
        <div v-if="item.id">
            <h4>Important Dates</h4>

            <o-widget-wrapper
                :form-state="formState"
                :schema="schema"
                :server-errors="_se"
                :validators="$v.item"
                name="last_login"
            >
                <o-date-time
                    :form-state="formState"
                    :server-errors="_se"
                    :validators="$v.item"
                    disabled
                    name="last_login"
                    v-bind="schema.fields.last_login"
                    v-model="item.last_login"
                ></o-date-time>
            </o-widget-wrapper>

            <o-widget-wrapper
                :form-state="formState"
                :schema="schema"
                :server-errors="_se"
                :validators="$v.item"
                name="date_joined"
            >
                <o-date-time
                    :form-state="formState"
                    :server-errors="_se"
                    :validators="$v.item"
                    disabled
                    name="date_joined"
                    v-bind="schema.fields.date_joined"
                    v-model="item.date_joined"
                ></o-date-time>
            </o-widget-wrapper>
        </div>
    </o-form-wrapper>
</template>

<script>
import schema from "../../schema/index.js";
import { cleanUpModel } from "../../../schema/schema_vuetify.js";
import {
    CreateCodeTableFetchMixin,
    CreateFormStateMixin,
    CreateGroupPermissionMixin,
    VuelidateMixin,
} from "../../../mixins";
import { validationMixin } from "vuelidate";
import OWidgetWrapper from "../../../components/forms/OWidgetWrapper.vue";
import OTextField from "../../../components/forms/fields/OTextField.vue";
import OSelect from "../../../components/forms/fields/OSelect.vue";
import OCheckbox from "../../../components/forms/fields/OCheckbox.vue";
import OFormWrapper from "../../../components/forms/OFormWrapper.vue";
import ODateTime from "../../../components/forms/fields/ODateTime.vue";
import { default as GroupForm } from "../../group/components/ItemForm.vue";
// import { default as GroupFormCards } from "../../group/components/ItemFormCards.vue";
import { default as PermissionForm } from "../../permission/components/ItemForm.vue";
import OMultiCheckbox from "../../../components/forms/fields/OMultiCheckbox.vue";
import { IsGoBackEnd } from "../../../utils/config.js";
// import { default as PermissionFormCards } from "../../permission/components/ItemFormCards.vue";
import Password from "vue-password-strength-meter";

import * as CONFIG from "../../../../config.js";

import OGenerate from "../../../components/forms/OGenerate.vue";
import { mapActions, mapGetters } from "vuex";
import { ACTION_TYPES } from "vue-ocom/store/store_keys";
import UseCodeModulesMixin from "@/vue-ocom/mixins/UseCodeModulesMixin";

export default {
    mixins: [
        VuelidateMixin,
        validationMixin,
        CreateFormStateMixin("$v"),
        CreateGroupPermissionMixin("User"),
        CreateCodeTableFetchMixin(["Group"]),
        UseCodeModulesMixin(["Permission"]),
    ],
    components: {
        OMultiCheckbox,
        OFormWrapper,
        OWidgetWrapper,
        ODateTime,

        OCheckbox,
        OSelect,
        OTextField,

        GroupForm,
        //GroupFormCards,
        PermissionForm,
        //PermissionFormCards,
        Password,
        OGenerate,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        level: { type: Number, default: 0 },
        inModal: { type: Boolean, default: false },
        advancedUsage: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        schemaModel: cleanUpModel(schema.User, {}),
        newPassword: "",
        complexityChecker: false,
    }),
    methods: {
        ...mapActions("PermissionModule", {
            permission_getall: ACTION_TYPES.GET_ALL,
        }),
        changed() {
            const vm = this;
        },
        showScore(score) {
            const vm = this;
            vm.passwordScore = score;
        },
        newTypedPassword() {
            const vm = this;
            if (vm.complexity_checking) {
                vm.formState.dirty = true;
                vm.newPassword = vm.item.new_password;
            }
        },
        generatePassword(password) {
            const vm = this;
            vm.item.new_password = password;
            vm.newPassword = password;
        },
    },
    computed: {
        _se() {
            // Return an empty Object if there are no Server Errors to avoid Binding Errors
            return this.serverErrors || {};
        },
        isGoBackend() {
            return IsGoBackEnd();
        },
        complexity_checking() {
            return CONFIG.TurnOnComplexityChecking === true;
        },
    },
    watch: {},
    created() {
        if (!IsGoBackEnd()) {
            this.permission_getall({
                limit: 2000,
                offset: 0,
                _code: true,
            });
        }
    },
};
</script>

<style scoped>
.password-generator {
    padding-left: 167px;
}
.type-name {
    padding-left: 10px;
}

.password-complexity {
    max-width: 705px !important;
}
.password-label {
    margin-left: 105px;
}
</style>
