<template>
  <o-section
    :level="level + 1"
    :title="label || schema.verbose_name"
    body-classes="p-0"
    not-closable
  >
    <o-item-editable-table
      :default-new-value="newChild"
      :form-state="formState"
      :item-name="schema.verbose_name"
      @change="changed"
      v-model="items"
    >
      <template slot="o-headers">
        <th v-if="canSee(schema, 'test_frequency')">
          {{ schema.fields.test_frequency.label }}
        </th>
        <th v-if="canSee(schema, 'color')">{{ schema.fields.color.label }}</th>
      </template>
      <template slot="o-body" slot-scope="{ item, index }">
        <td v-if="canSee(schema, 'test_frequency')">
          <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :server-errors-index="index"
            :validators="validators.$each[index]"
            name="test_frequency"
          >
            <o-select
              :form-state="formState"
              :items="optionCodeTestFrequencyItems"
              :loading="optionCodeTestFrequencyWorking"
              :server-errors="_se"
              :server-errors-index="index"
              :validators="validators.$each[index]"
              add-title="Create New"
              allow-add
              name="test_frequency"
              nolabel
              sort-field="display_order"
              store-name="CodeTestFrequency"
              text-field="description"
              v-bind="schema.fields.test_frequency"
              v-model="items[index].test_frequency"
              value-field="id"
            >
              <template
                slot="form"
                slot-scope="{ item, formState, serverErrors }"
              >
                <code-test-frequency-form
                  :form-state="formState"
                  :item="item"
                  :server-errors="serverErrors"
                  in-modal
                ></code-test-frequency-form>
              </template>
            </o-select>
          </o-widget-wrapper>
        </td>
        <td v-if="canSee(schema, 'color')">
          <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :server-errors-index="index"
            :validators="validators.$each[index]"
            name="color"
          >
            <o-text-field
              :form-state="formState"
              :server-errors="_se"
              :server-errors-index="index"
              :validators="validators.$each[index]"
              name="color"
              nolabel
              v-bind="schema.fields.color"
              v-model="items[index].color"
            ></o-text-field>
          </o-widget-wrapper>
        </td>
      </template>
    </o-item-editable-table>
  </o-section>
</template>
<script>
import schema from "../../schema/index";
import { cleanUpModel } from "../../../vue-ocom/schema/schema_vuetify";
import { CreateDefaultItem } from "../../../vue-ocom/schema/schema_utils";
import {
  CreateFormStateMixin,
  CreateGroupPermissionMixin,
} from "../../../vue-ocom/mixins";
import OItemEditableTable from "../../../vue-ocom/components/table/OItemEditableTable";
import OSection from "../../../vue-ocom/components/layout/OSection";

import UseCodeModulesMixin from "@/vue-ocom/mixins/UseCodeModulesMixin";

export default {
  name: "CodeTestCycleColorFormTable",
  mixins: [
    CreateFormStateMixin("validators"),
    CreateGroupPermissionMixin("CodeTestCycleColor"),
    UseCodeModulesMixin(["CodeTestFrequency"]),
  ],
  components: { OItemEditableTable, OSection },
  model: {
    prop: "items",
    event: "changed",
  },
  props: {
    items: {
      type: Array,
      default: () => {},
    },
    label: { type: String, default: null },
    validators: { type: Object, default: () => null },
    level: { type: Number, default: 0 },
  },
  data: function () {
    return {
      schemaModel: cleanUpModel(
        schema.CodeTestCycleColor,
        {}
      ) /*Use this.schema instead */,
      newChild: CreateDefaultItem(schema.CodeTestCycleColor),
    };
  },
  methods: {
    changed() {
      if (this.validator) {
        this.validator.$touch();
      }

      if (this.formState) this.formState.dirty = true;

      this.$emit("changed", this.items);
    },
  },
  computed: {},
  created() {
    // this.loadCodeTables()
  },
};
</script>
