<template>
  <div class="container o-form-page">
    <o-working-overlay :working="working"></o-working-overlay>
    <o-section :level="0" :title="title" @click="enableDebug($event)">
      <item-form
        :form-state="formState"
        :item="item"
        :server-errors="serverErrors"
        :is-autofocus="true"
      ></item-form>
    </o-section>

    <o-action-buttons
      :create="create"
      :form-state="formState"
      :item="item"
      :show-create-and-new="true"
      @delete="doDeleteItem"
      @save="doSaveCheck"
      @saveAndClose="doSaveAndCloseCheck"
      @saveAndNew="doSaveAndNewCheck"
    >
      <template v-slot:left>
        <b-button
          :disabled="!item.asset"
          variant="success"
          @click="create_maint"
          >Create Maintenance</b-button
        >
      </template>
    </o-action-buttons>
  </div>
</template>

<script>
import {
  CreateFormPageMixin,
  CreateGroupPermissionMixin,
  UnsavedMixin,
} from "../../../vue-ocom/mixins";
import ItemForm from "../components/ItemForm.vue";
import OActionButtons from "../../../vue-ocom/components/forms/OActionButtons";
import OSection from "../../../vue-ocom/components/layout/OSection";
import OWorkingOverlay from "../../../vue-ocom/components/OWorkingOverlay";
import { mapGetters } from "vuex";
import _ from "lodash";

import FetchCodeMixin from "@/vue-ocom/mixins/FetchCodeMixin";

export default {
  mixins: [
    UnsavedMixin,
    CreateFormPageMixin("AssetTest", "asset_test"),
    CreateGroupPermissionMixin("AssetTest"),
    FetchCodeMixin([
      "Asset",
      "Employee",
      "CodeAssetTestStatus",
      "CodeTestCycle",
      "Product",
      "Employee",
      "CodeRepairType",
    ]),
  ],
  components: {
    OSection,
    OWorkingOverlay,
    OActionButtons,
    ItemForm,
  },
  methods: {
    showMessageForNotes() {
      this.showMessage({
        message: "Notes may not be blank if status is Fail",
        type: "danger",
        show: true,
      });
    },
    async doSaveCheck() {
      const needNotes = await this.checkNotes();
      if (needNotes) {
        this.showMessageForNotes();
        return false;
      }
      this.doSave();
    },
    async doSaveAndCloseCheck() {
      const needNotes = await this.checkNotes();
      if (needNotes) {
        this.showMessageForNotes();
        return false;
      }
      this.doSaveAndClose();
    },
    async doSaveAndNewCheck() {
      const needNotes = await this.checkNotes();
      if (needNotes) {
        this.showMessageForNotes();
        return false;
      }
      this.doSaveAndNew();
    },
    checkNotes() {
      if (this.item.status < 0 && _.isEmpty(this.item.notes)) {
        return true;
      }

      return false;
    },
    create_maint() {
      var defaults = { _asset: this.item.asset, _employee: this.data.tester };

      this.$router.push({ name: "maintenance_record.create", query: defaults });
    },
  },
  computed: {
    ...mapGetters("GlobalStateModule", ["data"]),
    title() {
      if (this.create) {
        return "Create Asset Test";
      }

      return "Edit Asset Test";
    },
  },
};
</script>
