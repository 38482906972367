<template>
    <div>
        <h3 @click="enableDebug($event)" class="display-4">
            Manage Group Permission
        </h3>

        <o-list-toolbar
            :filters-list="filter_list"
            :list-options="list_options"
            :new-to="{ name: 'group_permissions.create', params: { id: 0 } }"
            :query-select-options="options"
            :schema="schema"
            :search-fields-list="search_field_list"
            :selected-filter="selected_filter"
            @filter_changed="update_filter($event)"
            @options_changed="update_table($event)"
            @reset_table="reset_table"
            new-button-text="New Group Permission"
        >
        </o-list-toolbar>

        <BTable
            :fields="fields"
            :items="items"
            :sort-by="list_options.sort"
            :sort-desc="list_options.order == 'desc'"
            @sort-changed="change_sort"
            bordered
            class="o-list"
            head-variant="light"
            no-local-sorting
            striped
            thead-class="o-gradient-header"
        >
            <template #cell(group)="{ index, item }">
                <router-link
                    :to="{
                        name: 'group_permissions.edit',
                        params: { id: item.id },
                    }"
                >
                    <lookup-display
                        :list="optionGroupItemsById"
                        :value="item.group"
                        default-value="-"
                        display-field="name"
                    ></lookup-display>
                </router-link>
            </template>
            <template #cell(details)="{ index, item }">
                <default-display :value="item.details"></default-display>
            </template>

            <template slot="empty">
                <p>
                    <emoticon-sad-icon large></emoticon-sad-icon>
                </p>
                No Data Found. Please
                <router-link :to="{ name: 'group_permissions.create' }"
                    >create a new item</router-link
                >
            </template>
        </BTable>

        <o-list-paging
            :limit="list_options.limit"
            :offset="list_options.offset"
            :page-options="rows_per_page_items"
            :total-items="total_items"
            @change-limit="change_limit"
            @change-page="change_page"
        />
    </div>
</template>

<style></style>

<script>
import { BTable } from "bootstrap-vue";
import {
    CreateCodeTableFetchMixin,
    CreateGroupPermissionMixin,
    CreateListPageMixin,
} from "../../../mixins";
import {
    fieldToTableColumn,
    GetSearchFields,
} from "../../../schema/schema_utils.js";
import schema from "../../schema/index.js";

import OListToolbar from "../../../components/list/OListToolbar.vue";
import OListPaging from "../../../components/list/OListPaging.vue";
import DefaultDisplay from "../../../components/display/DefaultDisplay.vue";
import DateDisplay from "../../../components/display/DateDisplay.vue";
import NumberDisplay from "../../../components/display/NumberDisplay.vue";
import BooleanDisplay from "../../../components/display/BooleanDisplay.vue";
import LookupDisplay from "../../../components/display/LookupDisplay.vue";
import EmoticonSadIcon from "vue-material-design-icons/EmoticonSad.vue";
import EmailIcon from "vue-material-design-icons/Email.vue";
import LinkIcon from "vue-material-design-icons/Link.vue";

export default {
    components: {
        BTable,
        EmoticonSadIcon,
        EmailIcon,
        LinkIcon,
        OListPaging,
        OListToolbar,
        DateDisplay,
        DefaultDisplay,
        BooleanDisplay,
        NumberDisplay,
        LookupDisplay,
    },
    mixins: [
        CreateListPageMixin("GroupPermissions", "group_permissions"),
        CreateGroupPermissionMixin("GroupPermissions"),
        CreateCodeTableFetchMixin(["Group"]),
    ],

    data: function () {
        return {
            schemaModel: schema.GroupPermissions,

            search_field_list: GetSearchFields(schema.GroupPermissions),

            filter_list: [
                {
                    name: "All",
                    code: "all",
                    options: { offset: 0, sort: "group" },
                },
            ],
        };
    },
    computed: {
        options() {
            return {
                Group: {
                    options: this.optionGroupItems,
                    textField: "name",
                    valueField: "id",
                },
            };
        },
        fields() {
            return [
                fieldToTableColumn(this.schema, "group"),
                fieldToTableColumn(this.schema, "details"),
            ];
        },
    },
};
</script>
