export default function (hooks) {
    return function (store) {
        store.subscribeAction({
            before: (action, state) => {
                if (hooks.action) {
                    if (hooks.action.before) {
                        if (hooks.action.before.log) {
                            console.log(`before action ${action.type}`);
                        }
                        if (hooks.action.before[action.type]) {
                            hooks.action.before[action.type](
                                action.payload,
                                state,
                            );
                        }
                    }
                }
            },
            after: (action, state) => {
                if (hooks.action) {
                    if (hooks.action.after) {
                        if (hooks.action.after.log) {
                            console.log(`after action ${action.type}`);
                        }
                        if (hooks.action.after[action.type]) {
                            hooks.action.after[action.type](
                                action.payload,
                                state,
                            );
                        }
                    }
                }
            },
        });

        store.subscribe(function (mutation, state) {
            if (hooks.mutation) {
                if (hooks.mutation.log) {
                    console.log(mutation, state);
                }
                if (hooks.mutation[mutation.type]) {
                    hooks.mutation[mutation.type](mutation.payload, state);
                }
            }
        });
    };
}
