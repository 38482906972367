import { prefixAnd } from "../vue-ocom/query/utils";

export const hooks = {
  action: {
    before: {
      "AssetModule/getAll": function (payload, state) {
        var customer_id = state.GlobalStateModule.data.customer_id;
        if (customer_id) {
          payload.query = prefixAnd(payload.query, [
            "customer",
            "eq",
            customer_id,
          ]);
        }
      },
      "AssetTestModule/getAll": function (payload, state) {
        var customer_id = state.GlobalStateModule.data.customer_id;
        if (customer_id) {
          payload.query = prefixAnd(payload.query, [
            "asset__customer",
            "eq",
            customer_id,
          ]);
        }
      },
      "MaintenanceRecordModule/getAll": function (payload, state) {
        var customer_id = state.GlobalStateModule.data.customer_id;
        if (customer_id) {
          payload.query = prefixAnd(payload.query, [
            "asset__customer",
            "eq",
            customer_id,
          ]);
        }
      },
      "CodeTestCycleModule/getAll": function (payload, state) {
        var customer_id = state.GlobalStateModule.data.customer_id;
        if (customer_id) {
          payload.query = prefixAnd(payload.query, [
            "customer",
            "eq",
            customer_id,
          ]);
        }
      },
      "AssetLocationModule/getAll": function (payload, state) {
        var customer_id = state.GlobalStateModule.data.customer_id;
        if (customer_id && !payload._all) {
          payload.customer_id = customer_id;
          // payload.query = prefixAnd(payload.query,
          //   {'or': [['customer', 'eq', customer_id], ['parents', 'is_null']]});
        }
      },
      "AssetLocationModule/preload": function (payload, state) {
        var customer_id = state.GlobalStateModule.data.customer_id;
        if (customer_id && !payload._all) {
          payload.customer_id = customer_id;
          // payload.query = prefixAnd(payload.query,
          //   {'or': [['customer', 'eq', customer_id], ['parents', 'is_null']]});
        }
      },
      "AssetLocationManModule/getAll": function (payload, state) {
        var customer_id = state.GlobalStateModule.data.customer_id;
        if (customer_id && !payload._all) {
          payload.customer_id = customer_id;
          // payload.query = prefixAnd(payload.query,
          //   {'or': [['customer', 'eq', customer_id], ['parents', 'is_null']]});
        }
      },
      "AssetLocationManModule/preload": function (payload, state) {
        var customer_id = state.GlobalStateModule.data.customer_id;
        if (customer_id && !payload._all) {
          payload.customer_id = customer_id;
          // payload.query = prefixAnd(payload.query,
          //   {'or': [['customer', 'eq', customer_id], ['parents', 'is_null']]});
        }
      },
      "TestingModule/getAll": function (payload, state) {
        var customer_id = state.GlobalStateModule.data.customer_id;
        if (customer_id) {
          payload.query = prefixAnd(payload.query, [
            "customer",
            "eq",
            customer_id,
          ]);
        }
      },

      "AssetModule/preload": function (payload, state) {
        var customer_id = state.GlobalStateModule.data.customer_id;
        if (customer_id) {
          payload.query = prefixAnd(payload.query, [
            "customer",
            "eq",
            customer_id,
          ]);
        }
      },
      "AssetTestModule/preload": function (payload, state) {
        var customer_id = state.GlobalStateModule.data.customer_id;
        if (customer_id) {
          payload.query = prefixAnd(payload.query, [
            "asset__customer",
            "eq",
            customer_id,
          ]);
        }
      },
      "MaintenanceRecordModule/preload": function (payload, state) {
        var customer_id = state.GlobalStateModule.data.customer_id;
        if (customer_id) {
          payload.query = prefixAnd(payload.query, [
            "asset__customer",
            "eq",
            customer_id,
          ]);
        }
      },
      "CodeTestCycleModule/preload": function (payload, state) {
        var customer_id = state.GlobalStateModule.data.customer_id;
        if (customer_id) {
          payload.query = prefixAnd(payload.query, [
            "customer",
            "eq",
            customer_id,
          ]);
        }
      },
    },
  },
};
