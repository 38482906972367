import UserList from "./pages/ListPage.vue";
import UserDetail from "./pages/FormPage.vue";
import UserIndex from "./pages/IndexPage.vue";
import UserAccount from "./pages/AccountPage.vue";

export default [
    {
        path: "/user",
        component: UserIndex,
        redirect: "/user/list",
        name: "user",
        children: [
            {
                path: "list",
                component: UserList,
                name: "user.list",
            },
            {
                path: "create",
                component: UserDetail,
                name: "user.create",
                props: { create: true },
            },
            {
                path: "edit/:id",
                component: UserDetail,
                name: "user.edit",
                props: { create: false },
            },

            {
                path: "account",
                component: UserAccount,
                name: "user.account",
            },
        ],
    },
    /* Put Login/account in another route so group permissions will not hide/disable it */
    {
        path: "/login",
        component: UserIndex,
        redirect: "/login/account",
        name: "login",
        children: [
            {
                path: "account",
                component: UserAccount,
                name: "login.account",
            },
        ],
    },
];
