import CustomerList from "./pages/ListPage.vue";
import CustomerDetail from "./pages/FormPage.vue";
import CustomerIndex from "./pages/IndexPage.vue";

export default [
  {
    path: "/customer",
    component: CustomerIndex,
    redirect: "/customer/list",
    name: "customer",
    children: [
      {
        path: "list",
        component: CustomerList,
        name: "customer.list",
      },
      {
        path: "create",
        component: CustomerDetail,
        name: "customer.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: CustomerDetail,
        name: "customer.edit",
        props: { create: false },
      },
    ],
  },
];
