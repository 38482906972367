import CodeAssetTestStatusList from "./pages/ListPage.vue";
import CodeAssetTestStatusDetail from "./pages/FormPage.vue";
import CodeAssetTestStatusIndex from "./pages/IndexPage.vue";

export default [
  {
    path: "/code_asset_test_status",
    component: CodeAssetTestStatusIndex,
    redirect: "/code_asset_test_status/list",
    name: "code_asset_test_status",
    children: [
      {
        path: "list",
        component: CodeAssetTestStatusList,
        name: "code_asset_test_status.list",
      },
      {
        path: "create",
        component: CodeAssetTestStatusDetail,
        name: "code_asset_test_status.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: CodeAssetTestStatusDetail,
        name: "code_asset_test_status.edit",
        props: { create: false },
      },
    ],
  },
];
