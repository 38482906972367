<template>
  <default-display
    :style="{ 'background-color': value, color: complementary_color }"
    :value="value"
    class="colorBox"
  >
  </default-display>
</template>

<script>
export default {
  name: "DisplayColor",
  props: {
    value: { type: String, default: "#00000" },
  },
  computed: {
    complementary_color() {
      var rgb = this.color_to_rgb(this.value);
      var complementary = { r: 255 - rgb[0], g: 255 - rgb[1], b: 255 - rgb[2] };
      var hex = this.RGBToHex(
        complementary["r"],
        complementary["g"],
        complementary["b"]
      );
      return hex;
    },
  },
  methods: {
    color_to_rgb(colorname) {
      var cvs, ctx, rgb;

      cvs = document.createElement("canvas");
      cvs.height = 1;
      cvs.width = 1;
      ctx = cvs.getContext("2d");
      ctx.fillStyle = colorname;
      ctx.fillRect(0, 0, 1, 1);
      rgb = ctx.getImageData(0, 0, 1, 1).data;
      return rgb;
    },
    RGBToHex(r, g, b) {
      r = r.toString(16);
      g = g.toString(16);
      b = b.toString(16);

      if (r.length == 1) r = "0" + r;
      if (g.length == 1) g = "0" + g;
      if (b.length == 1) b = "0" + b;

      return "#" + r + g + b;
    },
  },
};
</script>

<style>
.colorBox {
  padding: 10px;
  display: inline-block;
  max-width: 100px;
  min-width: 100px;
  text-align: center;
}
</style>
