<template>
    <o-form-group
        :form-state="formState"
        :label="label"
        :message="errorMessages"
        :nolabel="nolabel"
        :type="{ 'is-danger': hasError, 'has-addons': allowAdd }"
    >
        <BInputGroup>
            <o-form-select
                :class="input_class"
                :disabled="_is_readonly"
                :options="the_list"
                :text-field="textField"
                :value-field="valueField"
                :sort-field="sortField"
                v-bind="$attrs"
                v-bind:value="value"
                v-on="inputListeners"
            >
                <template v-if="!$attrs.required" v-slot:first>
                    <option :value="null">{{ blankOptionText }}</option>
                </template>
            </o-form-select>

            <template slot="append-outer" />

            <BInputGroupAppend v-if="allowAdd && !nolabel">
                <BButton
                    :disabled="_is_readonly"
                    @click="show_add_new"
                    variant="outline"
                >
                    <table-plus-icon />
                </BButton>

                <BModal
                    :title="addTitle"
                    hide-footer
                    ref="crud_dialog"
                    size="lg"
                    style="width: 900px"
                >
                    <o-crud-dialog
                        :store-name="storeName"
                        :title="addTitle"
                        @change="added_to_list"
                        @close="close"
                    >
                        <slot slot-scope="{ item, formState, serverErrors }">
                            <slot
                                :form-state="formState"
                                :item="item"
                                :server-errors="serverErrors"
                                name="form"
                            ></slot>
                        </slot>
                    </o-crud-dialog>
                </BModal>
            </BInputGroupAppend>
        </BInputGroup>
    </o-form-group>
</template>

<script>
import { BButton, BInputGroup, BInputGroupAppend, BModal } from "bootstrap-vue";
import FormComponentMixin from "../../../mixins/form_component_mixin.js";
import OFormGroup from "../OFormGroup.vue";
import OCrudDialog from "./private/OCrudDialog.vue";
import TablePlusIcon from "vue-material-design-icons/TablePlus.vue";
import SelectInputMixin from "./private/SelectInputMixin.js";
import OFormSelect from "./private/OFormSelect.js";

export default {
    name: "OSelect",
    mixins: [FormComponentMixin, SelectInputMixin],
    components: {
        BInputGroup,
        BInputGroupAppend,
        BButton,
        BModal,
        OFormSelect,
        TablePlusIcon,
        OCrudDialog,
        OFormGroup,
    },
    props: {
        /* For Add New Button */
        allowAdd: { type: Boolean, default: false },
        storeName: { type: String, default: null },
        addTitle: { type: String, default: "Create New Item" },
    },
    data() {
        return {};
    },
    methods: {
        show_add_new() {
            this.$refs["crud_dialog"].show();
        },
        close() {
            this.$refs["crud_dialog"].hide();
        },

        added_to_list(new_id) {
            this.on_change(new_id);

            this.$refs["crud_dialog"].hide();
        },
        on_input(event) {
            // Ignore so ONLY on_change is catured and used for o-select
        },
    },
};
</script>

<style scoped></style>
