<template>
    <o-section :level="level" :title="label" body-classes="p-0" not-closable>
        <o-item-editable-table
            :default-new-value="defaultNewValue"
            :item-name="label"
            @change="changed"
            v-model="files"
        >
            <template slot="o-headers">
                <th>{{ schema.fields[fileField].label }}</th>
                <th>{{ schema.fields[fileTypeField].label }}</th>
                <th>{{ schema.fields[whoField].label }}</th>
                <th>{{ schema.fields[whenField].label }}</th>
            </template>
            <template #o-body="{ item, index }">
                <td
                    :colspan="item._is_new ? 2 : 1"
                    v-if="canSee(schema, 'fileField')"
                >
                    <o-widget-wrapper
                        :schema="schema"
                        :server-errors="_se"
                        :server-errors-index="index"
                        :validators="validator.$each[index]"
                        :name="fileField"
                    >
                        <o-file-upload
                            :hide-delete="true"
                            :other-id="otherId"
                            :other-name="otherName"
                            v-model="files[index][fileField]"
                        >
                            <template slot="icon">
                                <plus-icon />
                            </template>
                        </o-file-upload>
                    </o-widget-wrapper>
                </td>
                <td
                    :colspan="item._is_new ? 2 : 1"
                    v-if="canSee(schema, fileTypeField)"
                >
                    <o-widget-wrapper
                        :form-state="formState"
                        :schema="schema"
                        :server-errors="_se"
                        :server-errors-index="index"
                        :validators="validator.$each[index]"
                        :name="fileTypeField"
                    >
                        <o-select
                            :form-state="formState"
                            :items="fileTypes"
                            :loading="fileTypesWorking"
                            :server-errors="_se"
                            :server-errors-index="index"
                            :validators="validator.$each[index]"
                            item-text="description"
                            item-value="id"
                            :name="fileTypeField"
                            nolabel
                            v-bind="schema.fields[fileTypeField]"
                            v-model="files[index][fileTypeField]"
                        ></o-select>
                    </o-widget-wrapper>
                </td>
                <td
                    class="py-2"
                    v-if="canSee(schema, whoField) && !item._is_new"
                >
                    <user-display
                        :list="users"
                        :value="files[index][whoField]"
                        default-value="-"
                    ></user-display>
                </td>
                <td
                    class="py-2"
                    v-if="canSee(schema, whenField) && !item._is_new"
                >
                    <date-display
                        :value="files[index][whenField]"
                        format="MM/DD/YYYY H:mm a"
                    />
                </td>
            </template>
        </o-item-editable-table>
    </o-section>
</template>

<script>
/* eslint-disable no-unused-vars */

import { cleanUpModel } from "../../../schema/schema_vuetify.js";
import schema from "../../../../app/schema/index.js";
import UserDisplay from "../../display/UserDisplay.vue";
import OFileUpload from "../../../filesystem/OFileUpload.vue";
import { CreateFormStateMixin } from "../../../mixins";
import OWidgetWrapper from "../../forms/OWidgetWrapper.vue";

import OSelect from "../../forms/fields/OSelect.vue";
import OItemEditableTable from "../OItemEditableTable.vue";
import PlusIcon from "vue-material-design-icons/Plus.vue";
import { CreateDefaultItem } from "../../../schema/schema_utils.js";
import { mapGetters } from "vuex";

export default {
    name: "OFilesTable",
    mixins: [CreateFormStateMixin("validator")],
    model: {
        prop: "files",
        event: "changed",
    },
    components: {
        PlusIcon,
        OItemEditableTable,
        OSelect,
        OWidgetWrapper,
        OFileUpload,
        UserDisplay,
    },
    props: {
        files: {
            type: Array,
            default: () => {},
        },
        users: {
            type: Object,
            default: () => {},
        },
        usersWorking: { type: Boolean, default: false },

        fileTypes: {
            type: Array,
            default: () => {},
        },
        fileTypesWorking: { type: Boolean, default: false },

        modelName: { type: String, default: null },
        label: { type: String, default: null },
        validator: { type: Object, default: () => null },
        serverErrors: { type: Array, default: () => [] },

        otherId: { type: [String, Number], default: 0 },
        otherName: { type: String, default: "default" },
        level: { type: Number, default: 0 },

        whoField: {
            type: String,
            default: "created_user",
        } /* What Field in the Model has the User ID */,
        whenField: {
            type: String,
            default: "created_date",
        } /* What Field in the Model has the Date when it was created/uploaded */,
        fileField: {
            type: String,
            default: "file",
        },
        fileTypeField: {
            type: String,
            default: "file_type",
        },
    },
    data: function () {
        return {
            schema: cleanUpModel(schema[this.modelName], {}),
        };
    },
    computed: {
        ...mapGetters("AuthModule", ["currentUser"]),
        defaultNewValue() {
            var result = { _is_new: true };
            if (this.schema) {
                result = CreateDefaultItem(this.schema);
            }

            result[this.whenField] = new Date();
            if (this.currentUser) {
                result[this.whoField] = this.currentUser.id;
            }

            result.other_id = this.otherId;
            result.other_name = this.otherName;

            return result;
        },
    },
    methods: {
        changed() {
            if (this.validator) {
                this.validator.$touch();
            }
            this.makeDirty();
            this.$emit("changed", this.files);
        },
        canSee(schema, field_name) {
            /* Here we can check if they have permission for the field */
            return true;
        },
    },
    created() {
        // this.loadCodeTables()
    },
};
</script>
