import _ from "lodash";

export default {
    data() {
        return { value_changed: false };
    },
    props: {
        fieldName: {
            type: String,
        },
        qbe: {
            type: Object,
        },
    },
    methods: {
        blurred() {
            if (this.value_changed) {
                this.refilter();
                this.value_changed = false;
            }
        },
        update_qbe(value) {
            if (this.qbe) {
                if (value == null || value === "") {
                    if (this.fieldName in this.qbe) {
                        this.$delete(this.qbe, this.fieldName);
                    }
                } else {
                    this.$set(this.qbe, this.fieldName, [
                        this.expression,
                        value,
                    ]);
                }
                this.value_changed = true;
            }
        },
        refilter() {
            this.$emit("filter", this.qbe);
        },
        changeExpression() {
            const vm = this;
            var currentIndex = _.findIndex(this.expressions, function (o) {
                return o.code === vm.expression;
            });

            if (currentIndex >= this.expressions.length - 1) {
                // Roll back around!
                currentIndex = 0;
            } else {
                currentIndex = currentIndex + 1;
            }
            this.expression = this.expressions[currentIndex].code;
            this.update_qbe(this.currentValue);
            this.refilter();
        },
        on_changed($event) {
            this.update_qbe($event);
            this.blurred();
        },
    },
    computed: {
        expr_symbol: function () {
            const vm = this;
            var expr = _.find(this.expressions, function (o) {
                return o.code === vm.expression;
            });

            if (expr) {
                return expr.text;
            }
            return "";
        },
        inputListeners: function () {
            return this.$listeners;
        },
        currentValue() {
            if (this.qbe) {
                if (this.fieldName in this.qbe) {
                    return this.qbe[this.fieldName][1];
                }
            }

            return null;
        },
    },
};
