import { Field, FIELD_TYPES, Model } from "../../schema";

export default Model("group_permissions", {
    verbose_name: "Group Permission",
    verbose_name_plural: "Group Permission",
    fields: [
        Field("id", {
            label: "ID",
            primary_key: true,
            readonly: true,
            hide: false,
            field_type: FIELD_TYPES.AutoNumber,
        }),

        Field("group", {
            label: "Group",
            primary_key: false,
            readonly: false,
            required: true,
            hide: false,
            field_type: FIELD_TYPES.ForeignKey,
            ref_field_type: "Group",
            ref_display_field: "name",
            default: () => {
                return null;
            },
        }),
        Field("details", {
            label: "Details",
            primary_key: false,
            readonly: false,
            hide: false,
            required: true,
            field_type: FIELD_TYPES.String,
            default: () => {
                return null;
            },
        }),
        Field("states", {
            label: "States",
            primary_key: false,
            readonly: false,
            hide: false,
            required: false,
            field_type: FIELD_TYPES.ManyToMany,
            ref_field_type: "GroupState",
            default: () => {
                return [];
            },
        }),
        Field("deny_by_default", {
            label: "Deny By Default",
            primary_key: false,
            readonly: false,
            hide: false,
            required: false,
            field_type: FIELD_TYPES.Boolean,
            default: () => {
                return false;
            },
        }),
    ],
});
