/* eslint-disable no-unused-vars */
import { VuelidateValidation } from "../schema/schema_utils.js";

export default {
    validations() {
        let vm = this;
        return {
            item: VuelidateValidation(vm.schema),
        };
    },
};
