<template>
    <span :class="get_class">{{ display_value }}</span>
</template>

<script>
import DisplayMixin from "../../mixins/display_mixin.js";

export default {
    name: "UserDisplay",
    props: {
        value: {}, // The value that is looked up in the "list"
        list: {
            type: Object,
            default: () => {},
        }, // the dict/Object to look up - List might not be the right name
        defaultValue: { type: String, default: "" }, // The Default value to show if there is no match in the list
    },
    mixins: [DisplayMixin("display_value")],
    computed: {
        display_value() {
            if (this.list) {
                var item = this.list[this.value];

                if (item) {
                    if ("descriptive_name" in item) {
                        return item.descriptive_name;
                    } else {
                        var full_name = item.first_name + " " + item.last_name;

                        if (full_name.trim() !== "") {
                            return full_name;
                        } else {
                            return item.email || item.username;
                        }
                    }
                }
            }

            return this.defaultValue;
        },
    },
};
</script>

<style scoped></style>
