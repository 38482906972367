import GroupList from "./pages/ListPage.vue";
import GroupDetail from "./pages/FormPage.vue";
import GroupIndex from "./pages/IndexPage.vue";

export default [
    {
        path: "/group",
        component: GroupIndex,
        redirect: "/group/list",
        name: "group",
        children: [
            {
                path: "list",
                component: GroupList,
                name: "group.list",
            },
            {
                path: "create",
                component: GroupDetail,
                name: "group.create",
                props: { create: true },
            },
            {
                path: "edit/:id",
                component: GroupDetail,
                name: "group.edit",
                props: { create: false },
            },
        ],
    },
];
