<template>
  <div class="form-inline" v-if="isLoggedIn">
    <div id="select_customer_container" class="customer-mobile">
      <b-button
        variant="outline-dark"
        v-if="current_customer"
        class="ml-md-2 overflow-hidden text-nowrap customer-mobile-button"
        @click="goHome"
      >
        <strong>{{ current_customer.company_code }}</strong> -
        {{ current_customer.name }}
      </b-button>
      <div class="d-md-inline">
        <slot name="total_tested"> </slot>
      </div>
    </div>

    <span
      class="navbar-text py-0 pl-2 customerMessage"
      v-if="showmessage != null"
    >
      <vue-markdown :source="showmessage"></vue-markdown>
    </span>
  </div>
</template>

<script>
import { CreateCodeTableFetchMixin } from "../vue-ocom/mixins";
import { sort_and_filter_active_list } from "../vue-ocom/utils/active";
import { mapActions, mapGetters } from "vuex";
import VueMarkdown from "vue-markdown";

export default {
  name: "GlobalShowCustomer",
  components: {
    VueMarkdown,
  },
  mixins: [CreateCodeTableFetchMixin(["Customer"])],
  data() {
    return {
      temp_customer_ID: null,
    };
  },
  methods: {
    ...mapActions("GlobalStateModule", ["setGlobalValue"]),

    onOk() {
      this.setGlobalValue({ key: "customer_id", value: this.temp_customer_ID });
      this.setGlobalValue({
        key: "test_date",
        value: new Date().toISOString(),
      });

      this.$emit("updateCustomer", this.temp_customer_ID);
    },
    goHome() {
      var goHomeNow = confirm(
        "You need to return the Menu to change the Customer. Do you want to go the menu now?"
      );
      if (goHomeNow) {
        this.$router.push({ name: "home" });
      }
    },
  },
  computed: {
    ...mapGetters("GlobalStateModule", ["data"]),
    ...mapGetters("AuthModule", ["isLoggedIn"]),
    current_customer_name() {
      if (this.current_customer) {
        return (
          this.current_customer.name +
          " - " +
          this.current_customer.company_code
        );
      }
      return "-- None --";
    },
    current_customer() {
      if (this.data.customer_id) {
        return this.optionCustomerItemsById[this.data.customer_id];
      }
      return null;
    },
    showmessage() {
      if (this.current_customer) return this.current_customer.notes_to_show;

      return null; /* nothing */
    },
  },
  created() {
    if (this.data.customer_id) {
      this.temp_customer_ID = this.data.customer_id;
      this.$emit("updateCustomer", this.temp_customer_ID);
    }
  },
};
</script>

<style>
@media (max-width: 640px) {
  .customer-mobile {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 85vw;
  }
  .customer-mobile-button {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
}
span.customerMessage {
  color: black;
}

span.customerMessage p {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
</style>
