import AssetTestList from "./pages/ListPage.vue";
import AssetTestDetail from "./pages/FormPage.vue";
import AssetTestIndex from "./pages/IndexPage.vue";

export default [
  {
    path: "/asset_test",
    component: AssetTestIndex,
    redirect: "/asset_test/list",
    name: "asset_test",
    children: [
      {
        path: "list",
        component: AssetTestList,
        name: "asset_test.list",
      },
      {
        path: "create",
        component: AssetTestDetail,
        name: "asset_test.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: AssetTestDetail,
        name: "asset_test.edit",
        props: { create: false },
      },
    ],
  },
];
