import { prefixAnd } from "../query/utils.js";
import { object_to_and_query } from "../query/expression.js";
import _ from "lodash";

export function normaliseOptions(list_options) {
    var params = _.cloneDeep(list_options); // sot

    //TODO Support if code table is called
    params.offset = list_options.offset || 0;
    params.limit = list_options.limit || 10;

    params["_code"] = list_options["_code"] || false;

    params.q = (list_options.q || "").trim(); // Remove Whitespace

    if (params.query) {
        if (_.isString(params.query)) {
            params.query = JSON.stringify(params.query);
        }
    } else {
        params.query = null;
    }

    if (params.qbe) {
        params.query = prefixAnd(params.query, object_to_and_query(params.qbe));
    }

    return params;
}
