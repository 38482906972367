import _ from "lodash";
import moment from "moment";
import { validate as isValidUUID } from "uuid";

function get_value(item, fieldname) {
    var path = fieldname.split(".");

    var value = item;

    for (var i = 0, len = path.length; i < len; i++) {
        value = value[path[i]];
    }

    return value;
}

var operations = {
    eq: function (fieldname, params) {
        return function (item) {
            return _.isEqual(get_value(item, fieldname), params[0]);
        };
    },
    gt: function (fieldname, params) {
        return function (item) {
            return get_value(item, fieldname) > params[0];
        };
    },
    ge: function (fieldname, params) {
        return function (item) {
            return get_value(item, fieldname) >= params[0];
        };
    },
    lt: function (fieldname, params) {
        return function (item) {
            return get_value(item, fieldname) < params[0];
        };
    },
    le: function (fieldname, params) {
        return function (item) {
            return get_value(item, fieldname) <= params[0];
        };
    },
    is_null: function (fieldname, params) {
        return function (item) {
            return get_value(item, fieldname) == null;
        };
    },
    is_not_null: function (fieldname, params) {
        return function (item) {
            return get_value(item, fieldname) != null;
        };
    },
    between: function (fieldname, params) {
        return function (item) {
            return (
                get_value(item, fieldname) >= params[0] &&
                get_value(item, fieldname) <= params[1]
            );
        };
    },
    contains: function (fieldname, params) {
        if (_.isArray(params[0])) {
            return function (item) {
                const val = get_value(item, fieldname);
                var result = true;
                _.forEach(params[0], function (param_value, index) {
                    const new_result = val[index] === param_value;

                    result = result && new_result;
                });
                // if (result) {
                //     console.log(val + '==' + params[0]);
                // }
                return result;
            };
        } else {
            params[0] = String(params[0]); // Pre-convert to String to ensure it's always a String

            return function (item) {
                return String(get_value(item, fieldname)).includes(params[0]);
            };
        }
    },
    icontains: function (fieldname, params) {
        params[0] = String(params[0]).toUpperCase(); // Pre-convert to Uppercase String to ensure it's always an Uppercase String

        return function (item) {
            return String(get_value(item, fieldname))
                .toUpperCase()
                .includes(params[0]);
        };
    },
};

// Used by GraphQL query to where clause also!
export const special_codes = {
    "date-now": function () {
        return [new Date()];
    },
    "date-today": function () {
        var result = moment(0, "HH");

        return [result.toDate()];
    },
    "date-tomorrow": function () {
        var result = moment(0, "HH");

        result = result.add(1, "days");

        return [result.toDate()];
    },
    "date-yesterday": function () {
        var result = moment(0, "HH");

        result = result.subtract(1, "days");

        return [result.toDate()];
    },
    "this-week": function () {
        var result1 = moment().startOf("week");

        var result2 = result1.add(1, "weeks");

        return [result1, result2];
    },
    "last-week": function () {
        var result1 = moment().startOf("week");

        result1 = result1.subtract(1, "weeks");

        var result2 = result1.add(1, "weeks");

        return [result1, result2];
    },
    "next-week": function () {
        var result1 = moment().startOf("week");

        result1 = result1.add(1, "weeks");

        var result2 = result1.add(1, "weeks");

        return [result1, result2];
    },
    // TODO Others..
};

function prepareCriteria(query) {
    // ie ["fieldname", "op", 'param1', .... 'paramx']
    // special is optional - if found then looks up  special_codes dict for code to generate params

    var fieldname = query[0];
    var op = query[1];
    var params = query.slice(2);

    var result = { source: query };

    result.toString = function () {
        return fieldname + " " + op + " " + params;
    };

    // Convert special {"special":"special_value"}
    params = [
        ..._.map(params, function (param) {
            if (_.isObject(param) && param.special) {
                // looks like {"special": } ??
                param = special_codes[param.special](); // Get "Special" Values
            }

            return param;
        }),
    ];

    var found = operations[op];
    if (found) result.evaluate = found(fieldname, params);
    else
        result.evaluate = function (item) {
            return true; // NOOP
        };

    return result;
}

function prepareAnd(query) {
    var expr_result = { criteria: [], source: query };

    _.each(query, function (queryItem) {
        expr_result.criteria.push(prepareExpression(queryItem));
    });

    expr_result.toString = function () {
        return "AND";
    };

    expr_result.evaluate = function (item) {
        var result = true;

        _.each(expr_result.criteria, function (queryItem) {
            if (_.isFunction(queryItem.evaluate)) {
                if (result) {
                    var filterResult = queryItem.evaluate(item);
                    result = result && filterResult;

                    // console.log(queryItem.toString() + " == " + filterResult);
                }
            }
        });

        return result;
    };

    return expr_result;
}

function prepareOr(query) {
    var expr_result = { criteria: [], source: query };

    _.each(query, function (queryItem) {
        expr_result.criteria.push(prepareExpression(queryItem));
    });

    expr_result.toString = function () {
        return "OR";
    };

    expr_result.evaluate = function (item) {
        var result = false;

        _.each(expr_result.criteria, function (queryItem) {
            if (!result) {
                if (_.isFunction(queryItem.evaluate)) {
                    var filterResult = queryItem.evaluate(item);

                    if (filterResult) {
                        result = true;
                    }

                    // console.log(queryItem.toString() + " == " + filterResult);
                }
            }
        });

        return result;
    };

    return expr_result;
}

/*
    Determine type of Query Item
 */
export function prepareExpression(query) {
    /* assumes expression is a list of expressions in this format
        {'and': [["fieldname", "operator", "param1", "param2", ..], ]}
     */

    if (!_.isArray(query)) {
        if ("or" in query) {
            return prepareOr(query.or);
        }
        if ("and" in query) {
            return prepareAnd(query.and);
        }
    } else {
        // Assume it's just a Criteria
        return prepareCriteria(query);
    }
}

export function eval_expression(context, expression) {
    var func = prepareExpression(expression);

    return func.evaluate(context);
}

/* Filter the list based on the expression */
export function filter_list(list, expression) {
    const expr = prepareExpression(expression);

    return _.filter(list, expr.evaluate);
}

/* Take an Object of Values and turn into An "And" query for thoese fields
    If the value == null then don't add a filter.
*/
export function object_to_and_query(values) {
    var query = { and: [] };

    var someCriteria = false;
    _.forEach(values, function (value, key) {
        if (value != null) {
            if (_.isString(value)) {
                if (value !== "") {
                    someCriteria = true;
                    //SW NOTE: If this errors ensure you are using uuid@8.3.2
                    if (isValidUUID(value)) {
                        query.and.push([key, "eq", value]);
                    } else {
                        query.and.push([key, "icontains", value]);
                    }
                }
            } else if (_.isArray(value)) {
                someCriteria = true;
                query.and.push([key, value[0], value[1]]);
            } else {
                someCriteria = true;
                query.and.push([key, "eq", value]);
            }
        }
    });
    if (someCriteria === true) {
        return query;
    } else {
        return null;
    }
}
