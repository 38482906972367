/* eslint-disable no-unused-vars */
import installGlobalComponents from "./components/installGlobalComponents.js";
import installBaseComponents from "./components/installBaseComponents.js";

export default {
    install(Vue, options) {
        // Components
        if (options && options.installAll === true) {
            installBaseComponents(); // to get the extras
            installGlobalComponents(); // get the rest
        } else {
            installBaseComponents();
        }
    },
};
