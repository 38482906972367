<template>
    <span>
        <div class="row-fluid">
            <div class="body">
                <div class="page-header"></div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-header">Reports</div>
                            <div
                                class="card-body p-1"
                                style="max-height: 850px; overflow-y: scroll"
                            >
                                <div class="list-group">
                                    <router-link
                                        :class="{ active: is_active(report) }"
                                        :key="report.name"
                                        :to="{
                                            name: 'reports',
                                            params: { reportName: report.name },
                                        }"
                                        class="list-group-item list-group-item-action"
                                        v-for="report in reports_list"
                                    >
                                        <div
                                            class="d-flex w-100 justify-content-between"
                                        >
                                            <h4 class="mb-1 font-weight-bold">
                                                {{ report.title }}
                                            </h4>
                                            <div>
                                                <menu-right-outline-icon />
                                            </div>
                                        </div>
                                        <p class="mb-1">
                                            {{ report.description }}
                                        </p>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-header">Parameters</div>
                            <div class="card-body">
                                <div
                                    class="alert alert-info"
                                    v-if="current_report == null"
                                >
                                    Please select a report on the Left to
                                    generate
                                </div>
                                <div class="" v-if="current_report">
                                    <div
                                        class="alert alert-warning"
                                        v-if="working"
                                    >
                                        Loading Report Details
                                    </div>
                                    <div class="lead">
                                        {{ current_report.description }}
                                    </div>
                                    <div v-if="!working">
                                        <report-params
                                            :key="current_report.name"
                                            :current_report="current_report"
                                            :download-url="downloadURL"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </span>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import _ from "lodash";

import MenuRightOutlineIcon from "vue-material-design-icons/MenuRightOutline.vue";
import ReportParams from "./ReportParams.vue";

export default {
    name: "ReportsLibPage",
    components: {
        ReportParams,
        MenuRightOutlineIcon,
    },
    data() {
        return {};
    },
    methods: {
        ...mapActions("ReportsModule", [
            "getAll",
            "getReportParams",
            "run_report",
        ]),
        download(format) {
            this.$set(this.params_values, "_format", format);

            var vm = this;

            setTimeout(() => {
                vm.postDownload();
            }, 10);
        },
        toggleDebug() {
            this.formState.debug = !this.formState.debug;
        },

        is_active(report) {
            if (!this.current_report) {
                return false;
            }

            return report.name == this.current_report.name;
        },
    },
    computed: {
        ...mapGetters("ReportsModule", {
            reports: "items",
            current_report: "item",
            downloadURL: "downloadURL",
            working: "working",
        }),

        reports_list() {
            //TODO check for superuser
            return _.filter(this.reports, function (r) {
                return !r.name.startsWith("_");
            });
        },
    },
    mounted() {
        window.lockLeave = false; // show prevent message
    },
    created() {
        this.getAll();

        if (this.$route.params.reportName) {
            var vm = this;

            if (
                this.$route.params.reportName &&
                this.$route.params.reportName != "NONE"
            ) {
                this.getReportParams(this.$route.params.reportName).then(
                    function (rpt) {
                        if (rpt.props.ext) {
                            this.form = rpt.props.ext;
                        }
                        vm.params_values = { _format: "pdf" };

                        if (rpt.props.ext) {
                            vm.params_values._format = rpt.props.ext;
                        }

                        // Set defaults and Hidden values
                        _.each(rpt.params, function (param) {
                            vm.$set(
                                vm.params_values,
                                param.name,
                                param.defaultVal
                            );
                        });
                    }
                );
            }
        }
    },
};
</script>
