import GroupPermissionsList from "./pages/ListPage.vue";
import GroupPermissionsDetail from "./pages/FormPage.vue";
import GroupPermissionsIndex from "./pages/IndexPage.vue";
import Report from "./pages/Report.vue";

export default [
    {
        path: "/group_permissions",
        component: GroupPermissionsIndex,
        redirect: "/group_permissions/list",
        name: "group_permissions",
        children: [
            {
                path: "list",
                component: GroupPermissionsList,
                name: "group_permissions.list",
            },
            {
                path: "create",
                component: GroupPermissionsDetail,
                name: "group_permissions.create",
                props: { create: true },
            },
            {
                path: "edit/:id",
                component: GroupPermissionsDetail,
                name: "group_permissions.edit",
                props: { create: false },
            },
            {
                path: "report",
                component: Report,
                name: "group_permissions.report",
            },
        ],
    },
];
