<template>
    <form
        :action="downloadUrl"
        method="post"
        ref="downloadForm"
        targetsss="_downloadReport"
    >
        <div>
            <h1 class="title is-1">
                {{ current_report.props.title || $route.params.reportName }}
            </h1>
            {{ current_report.props.description }}

            <BFormGroup
                :key="param.name"
                :label="param.promptText"
                label-cols="4"
                label-cols-lg="3"
                v-for="param in shown_params"
            >
                <span v-if="formState.debug"
                    >{{ param.controlType }}, {{ param.type }}</span
                >

                <div v-if="param.controlType == 1">
                    <div v-if="param.paramType != 'multi-value'">
                        <BFormSelect
                            :placeholder="param.name"
                            :required="!param.allowBlank"
                            v-model="params_values[param.name]"
                            :name="param.name"
                        >
                            <option
                                :key="option.value"
                                :value="option.value"
                                v-for="option in param.listEntries"
                            >
                                {{ option.label }}
                            </option>
                        </BFormSelect>
                    </div>
                    <div
                        style="overflow-x: scroll; height: 400px"
                        v-if="param.paramType == 'multi-value'"
                    >
                        <div
                            class="form-check"
                            :key="o.value"
                            v-for="o in param.listEntries"
                        >
                            <input
                                :name="param.name"
                                :value="o.value"
                                class="form-check-input"
                                type="checkbox"
                                :id="param.name + '-' + o.value"
                            />
                            <label
                                class="form-check-label"
                                :for="param.name + '-' + o.value"
                            >
                                {{ o.label }}
                            </label>
                        </div>
                    </div>
                </div>
                <div v-if="param.controlType == 3">
                    <BFormCheckbox
                        :false-value="0"
                        :label="param.promptText"
                        :placeholder="param.name"
                        :true-value="1"
                        :name="param.name"
                        v-model="params_values[param.name]"
                    ></BFormCheckbox>
                </div>
                <div v-if="param.controlType == 0">
                    <div
                        v-if="
                            param.type == 1 ||
                            param.type == 0 ||
                            param.type == -1
                        "
                    >
                        <BFormInput
                            :label="param.promptText"
                            :placeholder="param.name"
                            :name="param.name"
                            :required="!param.allowBlank"
                            v-model="params_values[param.name]"
                        />
                    </div>

                    <div v-if="param.type == 7">
                        <o-date-time
                            :form-state="formState"
                            :name="param.name"
                            :placeholder="param.name"
                            :required="!param.allowBlank"
                            :server-errors="se"
                            hide-time
                            nolabel
                            v-model="params_values[param.name]"
                        >
                        </o-date-time>
                    </div>

                    <div v-if="param.type == 4">
                        <o-date-time
                            :form-state="formState"
                            :name="param.name"
                            :placeholder="param.name"
                            :required="!param.allowBlank"
                            :server-errors="se"
                            nolabel
                            v-model="params_values[param.name]"
                        />
                    </div>

                    <div v-if="param.type == 8">
                        <input
                            :placeholder="param.name"
                            :name="param.name"
                            :required="!param.allowBlank"
                            class="form-control"
                            type="text"
                            v-model="params_values[param.name]"
                        />
                    </div>

                    <div v-if="param.type == 3">
                        <input
                            :placeholder="param.name"
                            :name="param.name"
                            :required="!param.allowBlank"
                            class="form-control"
                            step="0.01"
                            type="number"
                            v-model="params_values[param.name]"
                        />
                    </div>

                    <div v-if="param.type == 5">
                        <checkbox
                            :false-value="0"
                            :name="param.name"
                            :label="param.promptText"
                            :placeholder="param.name"
                            :true-value="1"
                            v-model="params_values[param.name]"
                        ></checkbox>
                    </div>

                    <div v-if="param.type == 6">
                        <input
                            :placeholder="param.name"
                            :name="param.name"
                            :required="!param.allowBlank"
                            class="form-control"
                            step="1"
                            type="number"
                            v-model="params_values[param.name]"
                        />
                    </div>

                    <div v-if="param.type == 2">
                        <input
                            :placeholder="param.name"
                            :required="!param.allowBlank"
                            class="form-control"
                            step="0.000001"
                            type="number"
                            :name="param.name"
                            v-model="params_values[param.name]"
                        />
                    </div>
                </div>

                <div class="help-block">
                    {{ param.helpText }}
                </div>
            </BFormGroup>

            <!-- Hidden params -->
            <input
                type="hidden"
                v-for="param in hidden_params"
                :key="param.name"
                :name="param.name"
                :value="params_valuesWithToken[param.name]"
            />

            <BFormGroup label="Format" label-cols="4" label-cols-lg="3">
                <BFormSelect
                    :options="formats"
                    placeholder="Format"
                    required
                    v-model="params_values._format"
                />
            </BFormGroup>

            <input
                name="__timezone"
                type="hidden"
                value="Australia/Melbourne"
            />
            <input
                :value="params_values._format"
                name="_format"
                type="hidden"
            />
            <input :value="_filename" name="_filename" type="hidden" />
            <input
                :value="current_report.name"
                name="_reportName"
                type="hidden"
            />

            <div class="row">
                <div class="col-lg-3 col-4 col-form-label">&nbsp;</div>
                <div class="col-8">
                    <BButton @click="postDownload" variant="primary full-width"
                        >Download Report
                    </BButton>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
import {
    BButton,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import ODateTime from "../components/forms/fields/ODateTime.vue";
import * as moment from "moment";
import _ from "lodash";

export default {
    name: "report-params",
    components: {
        BFormGroup,
        BFormCheckbox,
        BFormInput,
        BFormSelect,
        BButton,
        ODateTime,
    },
    data() {
        return {
            default_formats: [
                { value: "pdf", text: "Acrobat PDF (.pdf)", icon: "file-pdf" },
                { value: "xls", text: "Excel 2003 (.xls)", icon: "file-excel" },
                {
                    value: "xlsx",
                    text: "Excel 2007+ (.xlsx)",
                    icon: "file-excel",
                },
                { value: "doc", text: "Word (.doc)", icon: "file-word" },
            ],
            params_values: {},
            date_field_config: {
                altFormat: "d/n/Y",
            },
            formState: { debug: false }, // Empty objects so the components are happy but they wont have anything
            se: {},
        };
    },
    props: {
        current_report: {},
        downloadUrl: {},
    },
    methods: {
        postDownload() {
            this.$refs.downloadForm.submit();
        },
    },
    computed: {
        ...mapGetters("AuthModule", { token: "token" }),

        formats() {
            if ("formats" in this.current_report) {
                return this.current_report.formats;
            }
            return this.default_formats;
        },
        params_valuesWithToken() {
            return { ...this.params_values, _jwt: this.token };
        },
        hidden_params() {
            return _.filter(this.current_report.params, function (r) {
                return r.isHidden;
            });
        },
        shown_params() {
            return _.filter(this.current_report.params, function (r) {
                return !r.isHidden;
            });
        },
        _filename() {
            var title = this.$route.params.reportName;

            if (this.current_report && this.current_report.props) {
                title = this.current_report.props.title;
            }

            return (
                title +
                "_" +
                moment().format("YYYY-MM-DD") +
                "." +
                this.params_values._format
            );
        },
    },
    created() {
        const rpt = this.current_report;
        const vm = this;

        this.params_values = { _format: "pdf" };

        if (rpt.props.ext) {
            this.params_values._format = rpt.props.ext;
        }

        // Set defaults and Hidden values
        _.each(rpt.params, function (param) {
            vm.$set(vm.params_values, param.name, param.defaultVal);
        });
    },
};
</script>
