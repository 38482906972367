/* eslint-disable no-unused-vars */
import Vue from "vue";
import { CreateDefaultItem } from "../schema/schema_utils.js";
import { findIndex, keyBy } from "lodash";

import { TOKEN_EXPIRED } from "../auth/store/mutations-types.js";
import { ACTION_TYPES, GETTER_TYPES, MUTATION_TYPES } from "./store_keys.js";
import {
    calculateFields,
    calculateFieldsInlist,
    removeVirtualFields,
} from "../schema/store_utils.js";
import { normaliseOptions } from "./store_utils.js";
import { refresh } from "./CommonActions";
import { store_list_options } from "./CommonMutations";
import moment from "moment";

// ie RestModule("http://localhost:8000/api/code_example/");

function handleError(err, commit) {
    if (
        err &&
        err.response &&
        "status" in err.response &&
        err.response.status === 401
    ) {
        // We are no longer logged in!

        commit("AuthModule/" + TOKEN_EXPIRED, null, { root: true });
    } else {
        console.log("Error when calling Ajax");
        console.error(err);
    }
}

export default function (api, schema, disableLocalStorage) {
    function _get_store_name() {
        if (schema) {
            return schema.name;
        } else {
            return api || "unknown";
        }
    }

    function _getStoredState() {
        // NOTE this is in a function so we don't keep multiple copies of all this data in the local variables
        var result = {
            item: null,

            items: [],
            code_items: [],

            total_items: 0,
            working: false,
            last_list_options: null,
            last_load: null,
        };

        if (!disableLocalStorage) {
            var stored_state = localStorage.getItem(_get_store_name());
            if (stored_state) {
                stored_state = JSON.parse(stored_state);

                result = { ...result, ...stored_state }; // overwrite the defaults with the stored State
            }
        }
        return result;
    }

    function _storeState(state) {
        if (!disableLocalStorage) {
            try {
                localStorage.setItem(_get_store_name(), JSON.stringify(state));
            } catch (ex) {
                console.log("Err: Too much data for localstorage");
            }
        }
    }

    return {
        namespaced: true,
        state: _getStoredState(),
        persist_reducer(state) {
            return {}; //use our OWN storage now..
        },
        mutations: {
            [MUTATION_TYPES.STORE_LIST_OPTIONS]: store_list_options,
            [MUTATION_TYPES.SET_LIST](state, items) {
                state.total_items = items.total_items;
                if ("items" in items) {
                    calculateFieldsInlist(schema, items.items);
                    state.items = items.items;
                }
                if ("code_items" in items) {
                    calculateFieldsInlist(schema, items.code_items);
                    state.code_items = items.code_items;
                }
                _storeState(state);
            },

            [MUTATION_TYPES.CLEAR_LIST](state) {
                state.items = [];

                state.code_items = [];

                state.total_items = 0;
                _storeState(state);
            },

            [MUTATION_TYPES.SET_ITEM](state, item) {
                calculateFields(schema, item);
                state.item = item;
                _storeState(state);
            },
            [MUTATION_TYPES.START_WORKING](state) {
                state.working = true;
            },
            [MUTATION_TYPES.FINISH_WORKING](state) {
                state.working = false;
            },
            [MUTATION_TYPES.TEMPLATE](state, template) {
                // set a template to use later..
                state.template = template;
                _storeState(state);
            },
            [MUTATION_TYPES.PATCH_ITEM](state, item) {
                if (state.item && state.item.id === item.id) {
                    // Updating this item
                    state.item = item;
                }

                // Update the items also..
                var found = findIndex(state.items, { id: item.id });
                if (found > -1) {
                    state.items.splice(found, 1, item); // Replace it
                }
                _storeState(state);
            },
            preloaded(state, value) {
                //nop - here so we don't get an error if we try and call the mutation
            },
        },
        actions: {
            [ACTION_TYPES.CREATE_NEW]({ commit, state }) {
                return new Promise((resolve, reject) => {
                    let newItem = { _new: true };

                    // If there is a template (added using TEMPLATE action) then return that as the Default to use..
                    if (state.template) {
                        newItem = state.template;
                        state.template = null;
                    } else {
                        if (schema) {
                            newItem = CreateDefaultItem(schema);
                            newItem._new = true;
                        }
                    }

                    calculateFields(schema, newItem);
                    commit(MUTATION_TYPES.SET_ITEM, newItem);

                    resolve(newItem);
                });
            },
            refresh,
            [ACTION_TYPES.GET_ALL](
                { state, rootGetters, dispatch, commit },
                list_options = {},
            ) {
                return new Promise((resolve, reject) => {
                    if (rootGetters["AuthModule/isLoggedIn"]) {
                        dispatch("AuthModule/refreshToken", null, {
                            root: true,
                        });

                        var params = normaliseOptions(list_options);

                        commit(MUTATION_TYPES.STORE_LIST_OPTIONS, list_options);
                        commit(MUTATION_TYPES.START_WORKING);

                        const duration = 5;
                        var now = moment();

                        Vue.axios
                            .get(api, { params: params })
                            .then((response) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                const data = {
                                    total_items: response.data.count || 0,
                                };
                                if (params["_code"]) {
                                    data.code_items =
                                        response.data.results || [];
                                } else {
                                    data.items = response.data.results || [];
                                }

                                commit(MUTATION_TYPES.SET_LIST, data);

                                resolve(data);
                            })
                            .catch((err) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                handleError(err, commit);

                                reject(err);
                            });
                    }
                });
            },
            [ACTION_TYPES.GET_BY_ID]({ rootGetters, commit, dispatch }, id) {
                return new Promise((resolve, reject) => {
                    if (rootGetters["AuthModule/isLoggedIn"]) {
                        dispatch("AuthModule/refreshToken", null, {
                            root: true,
                        });

                        commit(MUTATION_TYPES.START_WORKING);

                        Vue.axios
                            .get(api + id + "/", {})
                            .then((response) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                commit(MUTATION_TYPES.SET_ITEM, response.data);
                                resolve(response.data);
                            })
                            .catch((err) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                handleError(err, commit);

                                reject(err);
                            });
                    } else {
                        reject("NOT LOGGED IN");
                    }
                });
            },
            [ACTION_TYPES.SAVE_ITEM](
                { state, rootGetters, commit, dispatch },
                item,
            ) {
                return new Promise((resolve, reject) => {
                    if (rootGetters["AuthModule/isLoggedIn"]) {
                        dispatch("AuthModule/refreshToken", null, {
                            root: true,
                        });

                        commit(MUTATION_TYPES.START_WORKING);
                        var method = "post";
                        var url = api;

                        if (item.id) {
                            // Update the Item
                            method = "put";
                            url = api + item.id + "/";
                        }

                        item = removeVirtualFields(schema, item);
                        Vue.axios
                            .request({ url: url, method: method, data: item })
                            .then((response) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                commit(MUTATION_TYPES.SET_ITEM, response.data);

                                resolve(response.data);
                            })
                            .catch((err) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                handleError(err, commit);

                                reject(err);
                            });
                    } else {
                        reject("NOT LOGGED IN");
                    }
                });
            },
            [ACTION_TYPES.PATCH_ITEM](
                { rootGetters, commit, dispatch, state },
                item,
            ) {
                return new Promise((resolve, reject) => {
                    if (rootGetters["AuthModule/isLoggedIn"]) {
                        dispatch("AuthModule/refreshToken", null, {
                            root: true,
                        });

                        commit(MUTATION_TYPES.START_WORKING);
                        var method = "patch";
                        var url = api;

                        if (item.id) {
                            // Update the Item
                            url = api + item.id + "/";
                        }

                        item = removeVirtualFields(schema, item);
                        Vue.axios
                            .request({ url: url, method: method, data: item })
                            .then((response) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                commit(
                                    MUTATION_TYPES.PATCH_ITEM,
                                    response.data,
                                );

                                resolve(response.data);
                            })
                            .catch((err) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                handleError(err, commit);

                                reject(err);
                            });
                    } else {
                        reject("NOT LOGGED IN");
                    }
                });
            },
            [ACTION_TYPES.MERGE_ITEMS](
                { state, rootGetters, commit, dispatch },
                data,
            ) {
                return new Promise((resolve, reject) => {
                    if (rootGetters["AuthModule/isLoggedIn"]) {
                        dispatch("AuthModule/refreshToken", null, {
                            root: true,
                        });

                        commit(MUTATION_TYPES.START_WORKING);
                        var method = "post";
                        var url = api;

                        url = api + data.id + "/merge/";

                        Vue.axios
                            .request({
                                url: url,
                                method: method,
                                data: data.other_ids,
                            })
                            .then((response) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                commit(MUTATION_TYPES.SET_ITEM, response.data);

                                resolve(response.data);
                            })
                            .catch((err) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                handleError(err, commit);

                                reject(err);
                            });
                    } else {
                        reject("NOT LOGGED IN");
                    }
                });
            },
            [ACTION_TYPES.DELETE_ITEM](
                { state, rootGetters, commit, dispatch },
                item,
            ) {
                return new Promise((resolve, reject) => {
                    if (rootGetters["AuthModule/isLoggedIn"]) {
                        dispatch("AuthModule/refreshToken", null, {
                            root: true,
                        });

                        commit(MUTATION_TYPES.START_WORKING);
                        var method = "delete";
                        var url = api;

                        url = api + item.id + "/";

                        Vue.axios
                            .request({ url: url, method: method, data: item })
                            .then((response) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                commit(MUTATION_TYPES.SET_ITEM, response.data);

                                resolve(response.data);
                            })
                            .catch((err) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                handleError(err, commit);

                                reject(err);
                            });
                    } else {
                        reject("NOT LOGGED IN");
                    }
                });
            },
        },
        getters: {
            [GETTER_TYPES.ITEM](state) {
                return state.item;
            },
            [GETTER_TYPES.WORKING](state) {
                return state.working;
            },

            [GETTER_TYPES.ITEMS](state) {
                return state.items;
            },
            [GETTER_TYPES.ITEMS_BY_ID](state) {
                return keyBy(state.items || [], "id");
            },

            [GETTER_TYPES.CODE_ITEMS](state) {
                return state.code_items;
            },
            [GETTER_TYPES.CODE_ITEMS_BY_ID](state) {
                return keyBy(state.code_items || [], "id");
            },

            [GETTER_TYPES.TOTAL_ITEMS](state) {
                return state.total_items;
            },
        },
    };
}
