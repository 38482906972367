<template>
    <td class="p-0">
        <template v-if="fieldData.options !== false">
            <span v-if="field_type === 'Date'">
                <o-filter-date-field
                    @input="filter_changed"
                    class="rounded-0 px-0"
                    :field-name="field.key"
                    :qbe="qbe"
                    @filter="filter_changed"
                    v-bind="fieldData.options"
                ></o-filter-date-field>
            </span>
            <span v-if="field_type === 'String'">
                <o-filter-text-field
                    class="rounded-0 px-0"
                    :field-name="field.key"
                    :qbe="qbe"
                    @filter="filter_changed"
                    v-bind="fieldData.options"
                ></o-filter-text-field>
            </span>
            <span v-if="field_type === 'Number'">
                <o-filter-number-field
                    class="rounded-0 px-0"
                    :field-name="field.key"
                    :qbe="qbe"
                    @filter="filter_changed"
                    v-bind="fieldData.options"
                ></o-filter-number-field>
            </span>
            <span v-if="field_type === 'ForeignKey'">
                <o-filter-select
                    blank-option-text=""
                    name="client"
                    :field-name="field.key"
                    :qbe="qbe"
                    @filter="filter_changed"
                    value-field="id"
                    v-bind="fieldData.options"
                >
                    <template v-slot:first>
                        <option :value="null"></option>
                    </template>
                </o-filter-select>
            </span>
        </template>
    </td>
</template>

<script>
import OFilterNumberField from "./OFilterNumberField.vue";
import OFilterSelect from "./OFilterSelect.vue";
import OFilterDateField from "./OFilterDateField.vue";
import OFilterTextField from "./OFilterTextField.vue";

export default {
    name: "FilterCell",
    components: {
        OFilterDateField,
        OFilterSelect,
        OFilterNumberField,
        OFilterTextField,
    },
    props: {
        field: { type: Object },
        schema: { type: Object },
        options: { type: Object },
        qbe: { type: Object },
    },
    methods: {
        filter_changed($event) {
            this.$emit("filter", $event);
        },
    },
    computed: {
        fieldData() {
            var result = {
                ...this.field,
                schema: this.schema.fields[this.field.key],
                options: {},
            };

            if (this.options && this.field.key in this.options) {
                result.options = this.options[this.field.key];
            }
            return result;
        },
        field_type() {
            return this.schema.fields[this.field.key].field_type;
        },
    },
};
</script>
