import { openDB } from "idb";

export function openDatabaseWithSchema(schema, name) {
    return openDB(name, 1, {
        // eslint-disable-next-line no-unused-vars
        upgrade(db, oldVersion, newVersion, transaction) {
            for (var key in schema) {
                try {
                    var schema_name = schema[key].name;

                    if (!db.objectStoreNames.contains(schema_name)) {
                        db.createObjectStore(schema_name, {
                            keyPath: "id",
                            autoIncrement: true,
                        });
                    }

                    //TODO Create indexes based on schema
                } catch (ex) {
                    console.log("Cannot create object store" + schema_name, ex);
                }
            }
        },
        blocked() {
            // …
        },
        blocking() {
            // …
        },
    });
}
