<template>
    <o-item-editable-table
        :default-new-value="newChild"
        :item-name="schema.verbose_name"
        @change="changed"
        v-model="items"
    >
        <template slot="o-headers">
            <th>{{ schema.fields.model_name.label }}</th>
            <th>{{ schema.fields.fields.label }}</th>
        </template>
        <template #o-body="{ item, index }">
            <td v-if="canSee(schema, 'model_name')">
                <o-widget-wrapper
                    :form-state="formState"
                    :schema="schema"
                    :server-errors="_se"
                    :server-errors-index="index"
                    :validators="validators.$each[index]"
                    name="model_name"
                >
                    <o-select
                        :form-state="formState"
                        :items="models_list"
                        :server-errors="_se"
                        :server-errors-index="index"
                        :validators="validators.$each[index]"
                        name="model_name"
                        nolabel
                        v-bind="schema.fields.model_name"
                        v-model="items[index].model_name"
                    ></o-select>

                    <BButton @click="addAll(items[index])">Add Fields</BButton>
                    <BButton @click="cleanUp(items[index])">Clean Up</BButton>
                </o-widget-wrapper>

                {{ item.base_uri }}
            </td>
            <td v-if="canSee(schema, 'fields')">
                <o-widget-wrapper
                    :form-state="formState"
                    :schema="schema"
                    :server-errors="_se"
                    :server-errors-index="index"
                    :validators="validators.$each[index]"
                    name="fields"
                >
                    <group-state-model-field-form-table
                        :form-state="formState"
                        :label="schema.fields.fields.label"
                        :level="level + 1"
                        :model-name="items[index].model_name"
                        :server-errors="_se.fields"
                        :validators="validators.$each[index].fields"
                        v-model="items[index].fields"
                    >
                    </group-state-model-field-form-table>
                </o-widget-wrapper>
            </td>
        </template>
    </o-item-editable-table>
</template>
<script>
import { BButton } from "bootstrap-vue";
import _ from "lodash";
import all_schema from "../../../../app/schema/index.js";
import schema from "../../schema/index.js";
import { cleanUpModel } from "../../../schema/schema_vuetify.js";
import { CreateDefaultItem } from "../../../schema/schema_utils.js";
import {
    CreateCodeTableFetchMixin,
    CreateFormStateMixin,
    CreateGroupPermissionMixin,
} from "../../../mixins";
import OItemEditableTable from "../../../components/table/OItemEditableTable.vue";
import OSection from "../../../components/layout/OSection.vue";

import { default as GroupStateModelFieldFormTable } from "../../group_state_model_field/components/ItemFormTable.vue";

export default {
    name: "GroupStateModelFormTable",
    mixins: [
        CreateFormStateMixin("validators"),
        CreateGroupPermissionMixin("GroupStateModel"),
        CreateCodeTableFetchMixin([]),
    ],
    components: {
        BButton,
        OItemEditableTable,
        OSection,
        GroupStateModelFieldFormTable,
    },
    model: {
        prop: "items",
        event: "changed",
    },
    props: {
        items: {
            type: Array,
            default: () => {},
        },
        label: { type: String, default: null },
        validators: { type: Object, default: () => null },
        level: { type: Number, default: 0 },
    },
    data: function () {
        return {
            all_schema: all_schema,
            schemaModel: cleanUpModel(schema.GroupStateModel, {}),
            newChild: CreateDefaultItem(schema.GroupStateModel),
        };
    },
    methods: {
        changed() {
            if (this.validators) {
                this.validators.$touch();
            }

            if (this.formState) this.formState.dirty = true;

            this.$emit("changed", this.items);
        },
        addAll(model) {
            var schema_model = this.models_by_name[model.model_name];

            if (schema_model) {
                var model_fieldsByName = _.keyBy(model.fields, "field_name");

                _.forEach(schema_model.fields, function (schema_field) {
                    var found = model_fieldsByName[schema_field.name];

                    if (!found) {
                        model.fields.push({ field_name: schema_field.name });
                    }
                });
            }
        },
        cleanUp(model) {
            var schema_model = this.models_by_name[model.model_name];

            if (schema_model) {
                var schema_fields = _.keyBy(schema_model.fields, "name");

                var result = [];
                _.forEach(model.fields, function (entry) {
                    var found = schema_fields[entry.field_name];

                    if (found) {
                        if (found.deny_read || found.deny_update) {
                            // if something is set
                            result.push(entry); // keep it
                        }
                    }
                });

                this.$set(model, "fields", result);
            }
        },
    },
    computed: {
        models_by_name() {
            return _.keyBy(_.values(this.all_schema), "name");
        },
        models_list() {
            return _.map(this.all_schema, function (model) {
                return { description: model.verbose_name, id: model.name };
            });
        },
    },
    created() {
        // this.loadCodeTables()
    },
};
</script>
