<template>
    <span :class="get_class">{{ display_value }}</span>
</template>

<script>
import DisplayMixin from "../../mixins/display_mixin.js";

export default {
    name: "LookupDisplay",
    props: {
        value: {}, // The value that is looked up in the "list"
        list: {
            type: Object,
            default: () => {},
        }, // the dict/Object to look up - List might not be the right name
        displayField: { type: String, default: "description" }, // The field to show the value of
        defaultValue: { type: String, default: "" }, // The Default value to show if there is no match in the list
    },
    mixins: [DisplayMixin("display_value")],

    computed: {
        display_value() {
            if (this.list) {
                var item = this.list[this.value];

                if (item) {
                    return item[this.displayField];
                }
            }

            return this.defaultValue;
        },
    },
};
</script>

<style scoped></style>
