<template>
    <BButton
        :disabled="disabled"
        :variant="type"
        @click="addNew"
        size="sm"
        v-bind="$attrs"
    >
        <plus-box-icon />
        Add {{ itemName }}
    </BButton>
</template>

<script>
import { BButton } from "bootstrap-vue";
import _ from "lodash";
import PlusBoxIcon from "vue-material-design-icons/PlusBox.vue";

export default {
    components: {
        BButton,
        PlusBoxIcon,
    },
    props: {
        list: { type: Array, default: () => [] },
        index: { type: Number, default: null }, // Where to insert. If Not set adds to the end
        disabled: { type: Boolean, default: false },
        defaultValue: {
            type: [Object, Function],
            default: () => {},
        },
        itemName: { type: String, default: "Row" },
        displayOrder: { type: String, default: "display_order" },
    },
    model: {
        prop: "list",
        event: "change",
    },
    computed: {
        type() {
            if ("type" in this.$attrs) {
                return this.$attrs.type;
            } else {
                return "primary";
            }
        },
    },
    methods: {
        addNew() {
            const vm = this;

            if (!this.disable) {
                var list = this.list || [];

                var newValue = {};

                if (_.isFunction(this.defaultValue)) {
                    newValue = this.defaultValue(); // call function
                } else {
                    newValue = _.cloneDeep(this.defaultValue);
                }

                if (this.index != null) {
                    list.splice(this.index, 0, newValue);
                } else {
                    list.push(newValue);
                }

                if (this.displayOrder in newValue) {
                    // Renumber the list
                    _.forEach(list, function (val, index) {
                        val[vm.displayOrder] = index;
                    });
                }
                this.onChanged(list);
            }
        },
        onChanged(list) {
            this.$emit("change", list);
        },
    },
};
</script>
