import CodePhaseList from "./pages/ListPage.vue";
import CodePhaseDetail from "./pages/FormPage.vue";
import CodePhaseIndex from "./pages/IndexPage.vue";

export default [
  {
    path: "/code_phase",
    component: CodePhaseIndex,
    redirect: "/code_phase/list",
    name: "code_phase",
    children: [
      {
        path: "list",
        component: CodePhaseList,
        name: "code_phase.list",
      },
      {
        path: "create",
        component: CodePhaseDetail,
        name: "code_phase.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: CodePhaseDetail,
        name: "code_phase.edit",
        props: { create: false },
      },
    ],
  },
];
