<template>
    <section class="hero">
        <div class="hero-body">
            <div class="container">
                <h1 class="title">Oops!</h1>
                <h2 class="subtitle">
                    <alert-icon class="alert" large />
                    The page you're looking for is not here.
                </h2>

                <p class="is-pulled-right">
                    <router-link :to="{ name: 'home' }"
                        >Back to the home page.</router-link
                    >
                </p>
            </div>
        </div>
    </section>
</template>
<script>
import AlertIcon from "vue-material-design-icons/Alert.vue";

export default {
    components: { AlertIcon },
};
</script>
