<template>
    <div>
        <h3 @click="enableDebug($event)" class="display-4">Manage Groups</h3>

        <o-list-toolbar
            :filters-list="filter_list"
            :list-options="list_options"
            :new-to="{ name: 'group.create', params: { id: 0 } }"
            :query-select-options="options"
            :schema="schema"
            :search-fields-list="search_field_list"
            :selected-filter="selected_filter"
            @filter_changed="update_filter($event)"
            @options_changed="update_table($event)"
            @reset_table="reset_table"
            new-button-text="New Group"
        >
        </o-list-toolbar>

        <BTable
            :fields="fields"
            :items="items"
            :sort-by="list_options.sort"
            :sort-desc="list_options.order == 'desc'"
            @sort-changed="change_sort"
            bordered
            head-variant="light"
            no-local-sorting
            striped
        >
            <template #cell(name)="{ index, item }">
                <router-link
                    :to="{ name: 'group.edit', params: { id: item.id } }"
                >
                    <default-display :value="item.name"></default-display>
                </router-link>
            </template>
            <template #cell(active_end_date)="{ index, item }">
                <o-link-cell
                    :to="{ name: 'agency.edit', params: { id: item.id } }"
                    :when="first_column_key === 'active_end_date'"
                >
                    <date-display
                        :value="item.active_end_date"
                        format="DD/MM/YYYY hh:mm"
                    ></date-display>
                </o-link-cell>
            </template>
            <template
                slot="cell(active_start_date)"
                slot-scope="{ index, item }"
            >
                <o-link-cell
                    :to="{ name: 'agency.edit', params: { id: item.id } }"
                    :when="first_column_key === 'active_start_date'"
                >
                    <date-display
                        :value="item.active_start_date"
                        format="DD/MM/YYYY hh:mm"
                    ></date-display>
                </o-link-cell>
            </template>
            <template slot="empty">
                <p>
                    <emoticon-sad-icon large></emoticon-sad-icon>
                </p>
                No Data Found. Please
                <router-link :to="{ name: 'group.create' }"
                    >create a new item</router-link
                >
            </template>
        </BTable>

        <o-list-paging
            :limit="list_options.limit"
            :offset="list_options.offset"
            :page-options="rows_per_page_items"
            :total-items="total_items"
            @change-limit="change_limit"
            @change-page="change_page"
        />
    </div>
</template>

<style></style>

<script>
import { BTable } from "bootstrap-vue";
import {
    CreateCodeTableFetchMixin,
    CreateGroupPermissionMixin,
    CreateListPageMixin,
} from "../../../mixins";
import {
    fieldToTableColumn,
    GetSearchFields,
} from "../../../schema/schema_utils.js";
import schema from "../../schema/index.js";
import { IsGoBackEnd } from "../../../utils/config.js";

import OListToolbar from "../../../components/list/OListToolbar.vue";
import OListPaging from "../../../components/list/OListPaging.vue";
import DefaultDisplay from "../../../components/display/DefaultDisplay.vue";
import DateDisplay from "../../../components/display/DateDisplay.vue";
import NumberDisplay from "../../../components/display/NumberDisplay.vue";
import BooleanDisplay from "../../../components/display/BooleanDisplay.vue";
import LookupDisplay from "../../../components/display/LookupDisplay.vue";
import EmoticonSadIcon from "vue-material-design-icons/EmoticonSad.vue";
import EmailIcon from "vue-material-design-icons/Email.vue";
import LinkIcon from "vue-material-design-icons/Link.vue";

export default {
    components: {
        BTable,
        EmoticonSadIcon,
        EmailIcon,
        LinkIcon,
        OListPaging,
        OListToolbar,
        DateDisplay,
        DefaultDisplay,
        BooleanDisplay,
        NumberDisplay,
        LookupDisplay,
    },
    mixins: [
        CreateListPageMixin("Group", "group"),
        CreateGroupPermissionMixin("Group"),
        CreateCodeTableFetchMixin([]),
    ],

    data: function () {
        var extraFilters = [];
        if (IsGoBackEnd()) {
            extraFilters = [
                {
                    name: "Active",
                    code: "active",
                    options: { offset: 0, sort: "id" },
                },
                {
                    name: "Non Active",
                    code: "inactive",
                    options: { offset: 0, sort: "id" },
                },
            ];
        }
        return {
            schemaModel: schema.Group,

            search_field_list: GetSearchFields(schema.Group),

            filter_list: [
                {
                    name: "All",
                    code: "all",
                    options: { offset: 0, sort: "name" },
                },
                ...extraFilters,
            ],
        };
    },
    computed: {
        options() {
            return {};
        },
        first_column_key() {
            return "name";
        },
        fields() {
            var result = [fieldToTableColumn(this.schema, "name")];

            if (IsGoBackEnd()) {
                result = [
                    ...result,
                    fieldToTableColumn(this.schema, "active_start_date"),
                    fieldToTableColumn(this.schema, "active_end_date"),
                ];
            }
            return result;
        },
    },
};
</script>
