<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab :active="!this.item.product" title="Select Product">
        <select-product
          @changed="selectProduct"
          @checkData="checkDataTestFrequencyElectricalClass"
          table-inline
        ></select-product>
      </b-tab>
      <b-tab :active="this.item.code != null" title="Asset">
        <div class="row justify-content-end">
          <div class="col-md-3 text-right">
            <h1>
              #<span class="code-highlight" v-if="item.code">{{
                item.code
              }}</span>
            </h1>
          </div>
        </div>
        <o-form-wrapper
          :form-state="formState"
          :schema="schema"
          :server-errors="_se"
          :v="$v.item"
          v-model="item"
        >
          <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="customer"
          >
            <o-select-typeahead
              :form-state="formState"
              :items="optionCustomerItems"
              :loading="optionCustomerWorking"
              :server-errors="_se"
              :text-field="customer_option_label"
              :validators="$v.item"
              add-title="Create New"
              allow-add
              name="customer"
              sort-field="name"
              store-name="Customer"
              v-bind="schema.fields.customer"
              v-model="item.customer"
              value-field="id"
            >
              <template
                slot="form"
                slot-scope="{ item, formState, serverErrors }"
              >
                <customer-form
                  :form-state="formState"
                  :item="item"
                  :server-errors="serverErrors"
                  in-modal
                ></customer-form>
              </template>
            </o-select-typeahead>
          </o-widget-wrapper>

          <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="location"
          >
            <o-tree-path-select
              :form-state="formState"
              :server-errors="_se"
              :validators="$v.item"
              :items="asset_locations"
              name="location"
              value-type="integer"
              v-bind="schema.fields.location"
              v-model="item.location"
              show-bookmark
              auto-save-bookmark
              @input="selectLocation"
            ></o-tree-path-select>
          </o-widget-wrapper>

          <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="code"
          >
            <o-text-field
              :input-class="codefont"
              :form-state="formState"
              :server-errors="_se"
              :validators="$v.item"
              name="code"
              @blur="check_asset_code"
              v-bind="schema.fields.code"
              :state="!duplicate_asset_code"
              v-model="item.code"
            >
              <template v-slot:append>
                <o-do-server-action-button
                  ref="next_code_btn"
                  :disabled="get_next_request == null"
                  :request="get_next_request"
                  @success="get_next_code"
                  disable-when-request-null
                  type="button"
                  uri="/api/get_next_code/"
                  variant="dark"
                  >Get a Code
                </o-do-server-action-button>
              </template>
            </o-text-field>

            <b-alert
              :show="duplicate_asset_code && failed_code === item.code"
              variant="danger"
              >This Asset Code "{{ failed_code }}" is already used by another
              Asset. Please change the code</b-alert
            >
          </o-widget-wrapper>

          <!--        <o-widget-wrapper :form-state="formState" :schema="schema" :server-errors="_se" :validators="$v.item"-->
          <!--                          name="product">-->
          <!--          <o-select-->
          <!--            :form-state="formState"-->
          <!--            :items="optionProductItems"-->
          <!--            :loading="optionProductWorking"-->
          <!--            :server-errors="_se"-->
          <!--            :validators="$v.item"-->

          <!--            add-title="Create New"-->
          <!--            allow-add-->
          <!--            name="product" sort-field="description" store-name="Product" text-field="description"-->

          <!--            v-bind="schema.fields.product"-->
          <!--            v-model="item.product"-->
          <!--            value-field="id"-->
          <!--          >-->
          <!--            <template slot="form" slot-scope="{item, formState, serverErrors}">-->
          <!--              <product-form :form-state="formState" :item="item" :server-errors="serverErrors" in-modal></product-form>-->
          <!--            </template>-->
          <!--          </o-select>-->
          <!--        </o-widget-wrapper>-->

          <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="description"
          >
            <o-textarea
              :form-state="formState"
              :server-errors="_se"
              :validators="$v.item"
              name="description"
              v-bind="schema.fields.description"
              v-model="item.description"
            ></o-textarea>
          </o-widget-wrapper>

          <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="make"
          >
            <o-text-field
              :form-state="formState"
              :server-errors="_se"
              :validators="$v.item"
              name="make"
              v-bind="schema.fields.make"
              v-model="item.make"
            ></o-text-field>
          </o-widget-wrapper>

          <!--        <o-widget-wrapper :form-state="formState" :schema="schema" :server-errors="_se" :validators="$v.item"-->
          <!--                          name="model">-->
          <!--          <o-text-field-->
          <!--            :form-state="formState"-->

          <!--            :server-errors="_se"-->
          <!--            :validators="$v.item" name="model" v-bind="schema.fields.model" v-model="item.model"-->
          <!--          ></o-text-field>-->
          <!--        </o-widget-wrapper>-->

          <!--        <o-widget-wrapper :form-state="formState" :schema="schema" :server-errors="_se" :validators="$v.item"-->
          <!--                          name="serial_number">-->
          <!--          <o-text-field-->
          <!--            :form-state="formState"-->

          <!--            :server-errors="_se"-->
          <!--            :validators="$v.item" name="serial_number" v-bind="schema.fields.serial_number" v-model="item.serial_number"-->
          <!--          ></o-text-field>-->
          <!--        </o-widget-wrapper>-->

          <!--        <o-widget-wrapper :form-state="formState" :schema="schema" :server-errors="_se" :validators="$v.item"-->
          <!--                          name="phase">-->
          <!--          <o-select-->
          <!--            :form-state="formState"-->
          <!--            :items="optionCodePhaseItems"-->
          <!--            :loading="optionCodePhaseWorking"-->
          <!--            :server-errors="_se"-->
          <!--            :validators="$v.item"-->

          <!--            add-title="Create New"-->
          <!--            allow-add-->
          <!--            name="phase" sort-field="description" store-name="CodePhase" text-field="description"-->

          <!--            v-bind="schema.fields.phase"-->
          <!--            v-model="item.phase"-->
          <!--            value-field="id"-->
          <!--          >-->
          <!--            <template slot="form" slot-scope="{item, formState, serverErrors}">-->
          <!--              <code-phase-form :form-state="formState" :item="item" :server-errors="serverErrors"-->
          <!--                               in-modal></code-phase-form>-->
          <!--            </template>-->
          <!--          </o-select>-->
          <!--        </o-widget-wrapper>-->

          <!--        <o-widget-wrapper :form-state="formState" :schema="schema" :server-errors="_se" :validators="$v.item"-->
          <!--                          name="test_frequency">-->
          <!--          <o-select-->
          <!--            :form-state="formState"-->
          <!--            :items="optionCodeTestFrequencyItems"-->
          <!--            :loading="optionCodeTestFrequencyWorking"-->
          <!--            :server-errors="_se"-->
          <!--            :validators="$v.item"-->

          <!--            add-title="Create New"-->
          <!--            allow-add-->
          <!--            name="test_frequency" sort-field="display_order" store-name="CodeTestFrequency" text-field="description"-->

          <!--            v-bind="schema.fields.test_frequency"-->
          <!--            v-model="item.test_frequency"-->
          <!--            value-field="id"-->
          <!--          >-->
          <!--            <template slot="form" slot-scope="{item, formState, serverErrors}">-->
          <!--              <code-test-frequency-form :form-state="formState" :item="item" :server-errors="serverErrors"-->
          <!--                                        in-modal></code-test-frequency-form>-->
          <!--            </template>-->
          <!--          </o-select>-->
          <!--        </o-widget-wrapper>-->

          <b-form-group
            label="Test Frequency"
            label-align="right"
            label-cols="3"
            label-cols-lg="2"
          >
            <speed-select
              :form-state="formState"
              :items="optionCodeTestFrequencyItems"
              v-model="item.test_frequency"
            ></speed-select>
          </b-form-group>

          <!--        <o-widget-wrapper :form-state="formState" :schema="schema" :server-errors="_se" :validators="$v.item"-->
          <!--                          name="elec_class">-->
          <!--          <o-select-->
          <!--            :form-state="formState"-->
          <!--            :items="optionCodeElectricalClassItems"-->
          <!--            :loading="optionCodeElectricalClassWorking"-->
          <!--            :server-errors="_se"-->
          <!--            :validators="$v.item"-->

          <!--            add-title="Create New"-->
          <!--            allow-add-->
          <!--            name="elec_class" sort-field="description" store-name="CodeElectricalClass" text-field="description"-->

          <!--            v-bind="schema.fields.elec_class"-->
          <!--            v-model="item.elec_class"-->
          <!--            value-field="id"-->
          <!--          >-->
          <!--            <template slot="form" slot-scope="{item, formState, serverErrors}">-->
          <!--              <code-electrical-class-form :form-state="formState" :item="item" :server-errors="serverErrors"-->
          <!--                                          in-modal></code-electrical-class-form>-->
          <!--            </template>-->
          <!--          </o-select>-->
          <!--        </o-widget-wrapper>-->

          <b-form-group
            label="Class"
            label-align="right"
            label-cols="3"
            label-cols-lg="2"
          >
            <speed-select
              :form-state="formState"
              :items="optionCodeElectricalClassItems"
              text-field="code"
              v-model="item.electrical_class"
            ></speed-select>
          </b-form-group>

          <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="comments"
          >
            <o-textarea
              :form-state="formState"
              :server-errors="_se"
              :validators="$v.item"
              name="comments"
              v-bind="schema.fields.comments"
              v-model="item.comments"
            ></o-textarea>
          </o-widget-wrapper>

          <!--        <o-widget-wrapper :form-state="formState" :schema="schema" :server-errors="_se" :validators="$v.item"-->
          <!--                          name="asset_classification">-->
          <!--          <o-select-->
          <!--            :form-state="formState"-->
          <!--            :items="optionCodeAssetClassificationItems"-->
          <!--            :loading="optionCodeAssetClassificationWorking"-->
          <!--            :server-errors="_se"-->
          <!--            :validators="$v.item"-->

          <!--            add-title="Create New"-->
          <!--            allow-add-->
          <!--            name="asset_classification" sort-field="description" store-name="CodeAssetClassification"-->
          <!--            text-field="description"-->

          <!--            v-bind="schema.fields.asset_classification"-->
          <!--            v-model="item.asset_classification"-->
          <!--            value-field="id"-->
          <!--          >-->
          <!--            <template slot="form" slot-scope="{item, formState, serverErrors}">-->
          <!--              <code-asset-classification-form :form-state="formState" :item="item" :server-errors="serverErrors"-->
          <!--                                              in-modal></code-asset-classification-form>-->
          <!--            </template>-->
          <!--          </o-select>-->
          <!--        </o-widget-wrapper>-->

          <!--        <o-widget-wrapper :form-state="formState" :schema="schema" :server-errors="_se" :validators="$v.item"-->
          <!--                          name="replacement_value">-->
          <!--          <o-text-field-->
          <!--            :form-state="formState"-->

          <!--            :server-errors="_se"-->
          <!--            :validators="$v.item" name="replacement_value" v-bind="schema.fields.replacement_value"-->
          <!--            v-model="item.replacement_value"-->
          <!--          ></o-text-field>-->
          <!--        </o-widget-wrapper>-->

          <!--        <o-widget-wrapper :form-state="formState" :schema="schema" :server-errors="_se" :validators="$v.item"-->
          <!--                          name="customer_asset_no">-->
          <!--          <o-text-field-->
          <!--            :form-state="formState"-->

          <!--            :server-errors="_se"-->
          <!--            :validators="$v.item" name="customer_asset_no" v-bind="schema.fields.customer_asset_no"-->
          <!--            v-model="item.customer_asset_no"-->
          <!--          ></o-text-field>-->
          <!--        </o-widget-wrapper>-->

          <!--        <o-widget-wrapper :form-state="formState" :schema="schema" :server-errors="_se" :validators="$v.item"-->
          <!--                          name="status">-->
          <!--          <o-select-->
          <!--            :form-state="formState"-->
          <!--            :items="optionCodeAssetStatusItems"-->
          <!--            :loading="optionCodeAssetStatusWorking"-->
          <!--            :server-errors="_se"-->
          <!--            :validators="$v.item"-->

          <!--            add-title="Create New"-->
          <!--            allow-add-->
          <!--            name="status" sort-field="description" store-name="CodeAssetStatus" text-field="description"-->

          <!--            v-bind="schema.fields.status"-->
          <!--            v-model="item.status"-->
          <!--            value-field="id"-->
          <!--          >-->
          <!--            <template slot="form" slot-scope="{item, formState, serverErrors}">-->
          <!--              <code-asset-status-form :form-state="formState" :item="item" :server-errors="serverErrors"-->
          <!--                                      in-modal></code-asset-status-form>-->
          <!--            </template>-->
          <!--          </o-select>-->
          <!--        </o-widget-wrapper>-->

          <b-form-group
            label="Status"
            label-align="right"
            label-cols="3"
            label-cols-lg="2"
          >
            <speed-select
              :form-state="formState"
              :items="optionCodeAssetStatusItems"
              v-model="item.status"
            ></speed-select>
          </b-form-group>
          <transition name="fade">
            <span v-if="showField">
              <o-widget-wrapper
                :form-state="formState"
                :schema="schema"
                :server-errors="_se"
                :validators="$v.item"
                name="asset_color"
              >
                <o-select
                  v-model="item.asset_color"
                  v-bind="schema.fields.asset_color"
                  :form-state="formState"
                  :items="optionCodeColorItems"
                  :loading="optionCodeColorWorking"
                  :server-errors="_se"
                  :validators="$v.item"
                  add-title="Create New"
                  allow-add
                  name="asset_color"
                  sort-field="description"
                  store-name="CodeColor"
                  text-field="description"
                  value-field="id"
                >
                  <template
                    slot="form"
                    slot-scope="{ item, formState, serverErrors }"
                  >
                    <code-color-form
                      :form-state="formState"
                      :item="item"
                      :server-errors="serverErrors"
                      in-modal
                    ></code-color-form>
                  </template>
                </o-select>
              </o-widget-wrapper>
              <b-form-group
                label="Colour"
                label-align="right"
                label-cols="3"
                label-cols-lg="2"
              >
                <speed-select
                  allow-unselect
                  v-model="item.asset_color"
                  :form-state="formState"
                  :items="optionCodeColorItems"
                ></speed-select>
              </b-form-group>

              <o-widget-wrapper
                :form-state="formState"
                :schema="schema"
                :server-errors="_se"
                :validators="$v.item"
                name="length"
              >
                <o-text-field
                  v-model="item.length"
                  v-bind="schema.fields.length"
                  :form-state="formState"
                  :server-errors="_se"
                  :validators="$v.item"
                  name="length"
                ></o-text-field>
              </o-widget-wrapper>
            </span>
          </transition>
          <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="active_start_date"
          >
            <o-date-time
              :form-state="formState"
              :server-errors="_se"
              :validators="$v.item"
              name="active_start_date"
              v-bind="schema.fields.active_start_date"
              v-model="item.active_start_date"
            ></o-date-time>
          </o-widget-wrapper>

          <o-widget-wrapper
            :form-state="formState"
            :schema="schema"
            :server-errors="_se"
            :validators="$v.item"
            name="active_end_date"
          >
            <o-date-time
              :form-state="formState"
              :server-errors="_se"
              :validators="$v.item"
              name="active_end_date"
              v-bind="schema.fields.active_end_date"
              v-model="item.active_end_date"
            ></o-date-time>
          </o-widget-wrapper>
        </o-form-wrapper>
      </b-tab>
      <b-tab title="Files">
        <o-widget-wrapper
          :form-state="formState"
          :schema="schema"
          :server-errors="_se"
          :validators="$v.item"
          name="files"
        >
          <o-files-table
            :file-types="optionCodeFileTypeItems"
            :file-types-working="optionCodeFileTypeWorking"
            :form-state="formState"
            :label="schema.fields.files.label"
            :level="level + 1"
            :other-id="item.id"
            :server-errors="_se[schema.fields.files.name]"
            :users="optionUserItemsById"
            :users-working="optionUserWorking"
            :validator="$v.item.files"
            model-name="AssetFile"
            other-name="asset_file"
            v-model="item.files"
            when-field="modified_date"
            who-field="created_user"
          >
          </o-files-table>
        </o-widget-wrapper>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import schema from "../../schema/index";
import { cleanUpModel } from "../../../vue-ocom/schema/schema_vuetify";
import {
  CreateFormStateMixin,
  CreateGroupPermissionMixin,
  VuelidateMixin,
} from "../../../vue-ocom/mixins";
import { validationMixin } from "vuelidate";
import OWidgetWrapper from "../../../vue-ocom/components/forms/OWidgetWrapper";
import OTextField from "../../../vue-ocom/components/forms/fields/OTextField";
import OSelect from "../../../vue-ocom/components/forms/fields/OSelect";
import OCheckbox from "../../../vue-ocom/components/forms/fields/OCheckbox";
import OFormWrapper from "../../../vue-ocom/components/forms/OFormWrapper";
import ODateTime from "../../../vue-ocom/components/forms/fields/ODateTime";

import { default as CustomerForm } from "../../customer/components/ItemForm";
import { default as AssetLocationForm } from "../../asset_location/components/ItemForm";
import { default as ProductForm } from "../../product/components/ItemForm";
import { default as CodeColorForm } from "../../code_color/components/ItemForm";
import { default as CodeTestFrequencyForm } from "../../code_test_frequency/components/ItemForm";
import { default as CodeElectricalClassForm } from "../../code_electrical_class/components/ItemForm";
import { default as CodeAssetStatusForm } from "../../code_asset_status/components/ItemForm";
import SpeedSelect from "../../../components/SpeedSelect";
import OSelectTypeahead from "../../../components/OSelectTypeahead";
import SelectProduct from "../../product/components/SelectProduct";
import { mapActions, mapGetters } from "vuex";
import OTreePathSelect from "../../../vue-ocom/components/tree/OTreePathSelect";
import { filter_active_list } from "vue-ocom/utils/active";
import AssetLocationCodeTable from "@/mixins/AssetLocationCodeTable";

import UseCodeModulesMixin from "@/vue-ocom/mixins/UseCodeModulesMixin";

export default {
  mixins: [
    VuelidateMixin,
    validationMixin,
    CreateFormStateMixin("$v"),
    CreateGroupPermissionMixin("Asset"),
    UseCodeModulesMixin([
      "Customer",
      "AssetLocation",
      "Product",
      "CodeColor",
      "CodeProductType",
      "CodeTestFrequency",
      "CodeElectricalClass",
      "CodeAssetStatus",
      "User",
      "CodeFileType",
    ]),
    AssetLocationCodeTable,
  ],
  components: {
    SelectProduct,
    OSelectTypeahead,
    SpeedSelect,
    OFormWrapper,
    OWidgetWrapper,
    ODateTime,
    OTreePathSelect,
    OCheckbox,
    OSelect,
    OTextField,

    CustomerForm,
    AssetLocationForm,
    ProductForm,
    CodeColorForm,
    CodeTestFrequencyForm,
    CodeElectricalClassForm,
    CodeAssetStatusForm,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    level: { type: Number, default: 0 },
    inModal: { type: Boolean, default: false },
  },
  data: () => ({
    codefont: "code-font",
    schemaModel: cleanUpModel(schema.Asset, {}),
    select_product: false,
    duplicate_asset_code: false,
    failed_code: "",
    stringLead: "LEAD",
    selectedItem: {},
  }),
  methods: {
    ...mapActions("GlobalStateModule", ["setGlobalValue"]),
    checkDataTestFrequencyElectricalClass(item) {
      // alert('checkDataTestFrequencyElectricalClass');
      this.$refs["editmodal"].show();
      this.selectedItem = item;
    },
    updateValue() {
      this.$refs["editmodal"].hide();
      this.selectProduct(this.selectedItem);
    },
    check_asset_code() {
      var vm = this;

      var root_url = process.env.VUE_APP_ROOT_API;

      var data = {
        id: null,
        code: this.item.code,
      };
      if (this.item.id) {
        //if we have an id then make sure we don't detect this as the existing entry
        data.id = this.item.id;
      }

      var request = {
        method: "POST",
        url: root_url + "/api/check_asset_code/",
        data: data,
      };

      this.axios(request).then(
        function (response, status, headers) {
          vm.failed_code = vm.item.code;
          vm.duplicate_asset_code = !response.data.OK;
        },
        function (error) {
          if (vm.errorMessage) {
            vm.showMessage({
              message: vm.errorMessage,
              type: "danger",
              show: true,
            });
          }

          vm.$emit("error", error);
        }
      );
    },

    selectLocation(location_id) {
      this.setGlobalValue({ key: "last_location_id", value: location_id });
    },
    selectProduct(product) {
      this.item.product = product.id;
      const product_type = this.optionCodeProductTypeItemsById[
        product.product_type
      ];
      if (product_type) this.item.description = product_type.description;

      this.item.make = product.make;
      this.item.model = product.model;
      this.item.electrical_class = product.electrical_class;
      // this.item.asset_color = product.color;
      this.item.asset_classification = product.asset_classification;
      this.item.phase = product.phase;
      this.item.test_frequency = product.test_frequency;
      this.item.location = this.global_data.last_location_id;

      if (!this.item.customer) {
        this.item.customer = this.global_data.customer_id;
      }
      if (!this.item.code) {
        // Only set the code if it is empty
        this.$nextTick(function () {
          var btn = this.$refs.next_code_btn;
          btn.executeServerAction();
        }, 0);
      }
      this.enableButton();
    },
    enableButton() {
      this.formState.dirty = true;
      this.formState.invalid = false;
    },
    location_option_label(location) {
      var result = location.description;

      if (!result) {
        return "--unknown--";
      }

      return result;
    },
    customer_option_label(customer) {
      var result = customer.company_name;
      if (customer.division) {
        result = result + " - " + customer.division;
      }

      if (customer.company_code) {
        result = result + " - " + customer.company_code;
      }

      if (!result) {
        result = "--unknown--";
      }

      return result;
    },
    get_next_code(data) {
      this.$set(this.item, "code", data.code[0]); // First One
    },
  },
  computed: {
    ...mapGetters("GlobalStateModule", { global_data: "data" }),

    showField() {
      if (this.item && this.item.description) {
        return this.item.description.toUpperCase().includes(this.stringLead);
      }
      return false; // default to false
    },
    checkTestFrequency() {
      return this.test_frequency;
    },
    get_next_request() {
      if (this.item) {
        if (this.item.code) {
          return null; // got a Code so don't ask again!
        }

        var result = { count: 1, id: null };
        if (this.item.customer) {
          result.id = this.item.customer;
          return result;
        }
        result.id = this.global_data.customer_id; // use the Global Customer
      }

      return null; // not ready Yet!
    },
    _se() {
      // Return an empty Object if there are no Server Errors to avoid Binding Errors
      return this.serverErrors || {};
    },
    asset_locations() {
      var result = this.optionAssetLocationItems;

      result = filter_active_list(result); // Only Active locations!

      // if (this.item.customer != null) {
      //   const customer_id = this.item.customer;
      //   result = _.filter(result, function(o) {
      //     return o.company_code === customer_id;
      //   })
      // }
      return result;
    },
  },
  watch: {},
  created() {},
};
</script>

<style>
.code-highlight {
  color: #ec8805;
}
.code-font {
  font-size: 1.1rem !important;
  font-weight: 700 !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
