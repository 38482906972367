<template>
    <o-section
        :level="level"
        :title="label || schema.verbose_name"
        not-closable
    >
        <div class="row">
            <div class="col-sm-3 fixedheight py-0">
                <BButtonGroup>
                    <BButton @click="denyOthers" variant="outline-danger"
                        >Deny Rest</BButton
                    >
                    <BButton @click="allowAll" variant="outline-success"
                        >Allow All</BButton
                    >
                </BButtonGroup>
                <BListGroup>
                    <BListGroupItem class="p-0">
                        <input v-model="q" type="search" class="w-100" />
                    </BListGroupItem>
                    <BListGroupItem
                        :active="
                            editing_index != null &&
                            items[editing_index].state_name == route.id
                        "
                        :key="route.id"
                        :variant="calcVariant(route.id)"
                        @click="selectState(route)"
                        v-for="(route, index) in fitleredRoutesList"
                    >
                        {{ route.title || route.description }}
                    </BListGroupItem>
                </BListGroup>
            </div>
            <div class="col-sm-9 fixedheight py-0">
                <span v-if="editing_index != null">
                    <item-form
                        :form-state="formState"
                        :item="items[editing_index]"
                        :level="level"
                        :routes-list="routesList"
                        :server-errors="_se[editing_index]"
                    ></item-form>
                </span>
            </div>
        </div>
    </o-section>
</template>

<script>
import {
    BButton,
    BButtonGroup,
    BListGroup,
    BListGroupItem,
} from "bootstrap-vue";
import _ from "lodash";

import schema from "../../schema/index.js";
import { cleanUpModel } from "../../../schema/schema_vuetify.js";
import { CreateDefaultItem } from "../../../schema/schema_utils.js";
import {
    CreateCodeTableFetchMixin,
    CreateFormStateMixin,
    CreateGroupPermissionMixin,
} from "../../../mixins";

import OSection from "../../../components/layout/OSection.vue";

import { default as GroupStateModelFormTable } from "../../group_state_model/components/ItemFormTable.vue";
import ItemForm from "./ItemForm.vue";

export default {
    name: "GroupStateFormCards",
    mixins: [
        CreateFormStateMixin("validators"),
        CreateGroupPermissionMixin("GroupState"),
        CreateCodeTableFetchMixin([]),
    ],
    components: {
        BButtonGroup,
        BButton,
        BListGroup,
        BListGroupItem,
        OSection,
        ItemForm,
        GroupStateModelFormTable,
    },
    model: {
        prop: "items",
        event: "changed",
    },
    props: {
        items: {
            type: Array,
            default: () => {},
        },
        label: { type: String, default: null },
        validators: { type: Object, default: () => null },
        level: { type: Number, default: 0 },
    },
    data: function () {
        return {
            schemaModel: cleanUpModel(schema.GroupState, {}),
            newChild: CreateDefaultItem(schema.GroupState),
            MENU_ITEMS: null,
            editing_index: null,
            q: "",
        };
    },
    methods: {
        denyOthers() {
            var vm = this;

            var blacklist = ["home", "404", "login", "logout", "*"];

            _.forEach(vm.fitleredRoutesList, function (route) {
                if (
                    vm.usedRoutesMap[route.id] == null &&
                    !route.id.includes(".")
                ) {
                    if (blacklist.indexOf(route.id) == -1) {
                        // Not "used" and NOT blacklisted to create a deny entry
                        vm.items.push({
                            state_name: route.id,
                            deny: true,
                            models: [],
                        });
                    }
                }
            });

            this.$emit("changed", this.items);
        },
        allowAll() {
            var vm = this;
            _.forEach(this.items, function (item) {
                vm.$set(item, "deny", false);
            });

            this.$emit("changed", this.items);
        },
        selectState(route) {
            var vm = this;
            var found = _.findIndex(this.items, { state_name: route.id });
            if (found > -1) {
                this.editing_index = found;
            } else {
                this.editing_index =
                    this.items.push({
                        state_name: route.id,
                        deny: true,
                        models: [],
                    }) - 1;

                if ((route.id.match(/\./g) || []).length == 0) {
                    // has no dot in it so check for "children"
                    _.forEach(this.routesList, function (route_state) {
                        if (
                            route_state.id.startsWith(route.id + ".") &&
                            vm.usedRoutesMap[route_state.id] == null
                        ) {
                            // is child route and NOT already done
                            vm.items.push({
                                state_name: route_state.id,
                                deny: true,
                                models: [],
                            }); // deny child route also..
                        }
                    });
                }

                this.$emit("changed", this.items);
            }
        },
        calcVariant(routeID) {
            var deny = this.usedRoutesMap[routeID]; // null

            if (deny != null) {
                deny = deny.deny;
            }

            return { null: "", true: "danger", false: "success" }[deny];
        },
        changed() {
            if (this.validators) {
                this.validators.$touch();
            }

            if (this.formState) this.formState.dirty = true;

            this.$emit("changed", this.items);
        },
    },
    computed: {
        fitleredRoutesList() {
            if (this.q) {
                const q = this.q;
                return _.filter(this.routesList, function (i) {
                    return i.id.includes(q);
                });
            } else {
                return this.routesList;
            }
        },
        usedRoutesMap() {
            return _.keyBy(this.items, "state_name");
        },
        routesList() {
            var result = [{ id: "*", description: "-- All Routes --" }];

            // Collect the Menu Items and the titles
            var menuItems = {};
            if (this.MENU_ITEMS) {
                _.forEach(this.MENU_ITEMS, function (section) {
                    if (section && section.children) {
                        _.forEach(section.children, function (child) {
                            if (child.to && child.to.name) {
                                menuItems[child.to.name] = child.title;
                            }
                        });
                    }
                });
            }

            function processRoutes(routes) {
                _.forEach(routes, function (route) {
                    if (route.name) {
                        var title = menuItems[route.name];

                        var description = route.name; // default to route.name
                        if (title) {
                            // use this for SELECT text
                            description = route.name + " - " + title;
                        }

                        var indent = (route.name.match(/\./g) || []).length;
                        if (indent) {
                            var prefix = "\xa0".repeat(indent * 4);

                            description = prefix + description;
                            if (title) {
                                title = prefix + title;
                            }
                        }

                        result.push({
                            id: route.name,
                            description: description,
                            title: title,
                        });
                    }

                    if (route.children) {
                        processRoutes(route.children);
                    }
                });
            }

            processRoutes(this.$router.options.routes);

            result = _.uniqBy(_.sortBy(result, "id"), "id");

            return result;
        },
    },
    created() {
        var vm = this;
        // Dynamically load it if the is a menu
        import("../../../../app/menu_items").then(function (MENU_ITEMS) {
            vm.MENU_ITEMS = MENU_ITEMS.default; // will trigger recalc
        });
    },
};
</script>
<style>
div.fixedheight {
    height: 500px;
    overflow-y: auto;
}
</style>
