/*
 * TODO make sure to define VUE_SENTRY_DSN= in your .env files
 * */
import Vue from "vue";
import * as Sentry from "@sentry/browser";
import * as CONFIG from "@/config.js";

if (CONFIG.SentryURL) {
    Sentry.init({
        dsn: CONFIG.SentryURL,
        integrations: [
            new Sentry.Integrations.Vue({
                Vue,
                attachProps: true,
            }),
        ],
    });
    console.log("Sentry installed!");
}
