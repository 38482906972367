<template>
  <o-form-wrapper
    :form-state="formState"
    :schema="schema"
    :server-errors="_se"
    :v="$v.item"
    v-model="item"
  >
    <o-widget-wrapper
      v-if="!withTest"
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="asset"
    >
      <o-select-typeahead
        :form-state="formState"
        :items="optionAssetItems"
        :loading="optionAssetWorking"
        :server-errors="_se"
        :validators="$v.item"
        add-title="Create New"
        allow-add
        name="asset"
        sort-field="name"
        store-name="Asset"
        text-field="name"
        v-bind="schema.fields.asset"
        v-model="item.asset"
        value-field="id"
      >
        <template slot="form" slot-scope="{ item, formState, serverErrors }">
          <asset-form
            :form-state="formState"
            :item="item"
            :server-errors="serverErrors"
            in-modal
          ></asset-form>
        </template>
      </o-select-typeahead>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="test_date"
    >
      <o-date-time
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="test_date"
        v-bind="schema.fields.test_date"
        v-model="item.test_date"
      ></o-date-time>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="tester"
    >
      <o-select-typeahead
        :form-state="formState"
        :items="optionEmployeeItems"
        :loading="optionEmployeeWorking"
        :server-errors="_se"
        :validators="$v.item"
        add-title="Create New"
        allow-add
        name="tester"
        sort-field="full_name"
        store-name="Employee"
        text-field="full_name"
        v-bind="schema.fields.tester"
        v-model="item.tester"
        value-field="id"
      >
        <template slot="form" slot-scope="{ item, formState, serverErrors }">
          <employee-form
            :form-state="formState"
            :item="item"
            :server-errors="serverErrors"
            in-modal
          ></employee-form>
        </template>
      </o-select-typeahead>
    </o-widget-wrapper>

    <b-form-group
      label="Quick"
      label-cols="3"
      label-cols-lg="2"
      label-align="right"
    >
      <speed-select
        v-model="item.tester"
        :items="active_employees"
        text-field="full_name"
      ></speed-select>
    </b-form-group>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="status"
    >
      <o-select
        :form-state="formState"
        :items="optionCodeAssetTestStatusItems"
        :loading="optionCodeAssetTestStatusWorking"
        :server-errors="_se"
        :validators="$v.item"
        name="status"
        sort-field="description"
        store-name="CodeAssetTestStatus"
        text-field="description"
        v-bind="schema.fields.status"
        v-model="item.status"
        value-field="id"
      >
      </o-select>
    </o-widget-wrapper>

    <b-form-group
      label="Quick"
      label-cols="3"
      label-cols-lg="2"
      label-align="right"
    >
      <speed-select
        :items="optionCodeAssetTestStatusItems"
        v-model="item.status"
        @input="checkStatus"
      ></speed-select>
    </b-form-group>
    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="notes"
    >
      <o-textarea
        :autofocus="isAutofocus"
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="notes"
        v-bind="schema.fields.notes"
        v-model="item.notes"
      ></o-textarea>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="failed_tag"
    >
      <o-checkbox
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="failed_tag"
        v-bind="schema.fields.failed_tag"
        v-model="item.failed_tag"
      ></o-checkbox>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="supplied"
    >
      <o-checkbox
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="supplied"
        v-bind="schema.fields.supplied"
        v-model="item.supplied"
      ></o-checkbox>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="repaired"
    >
      <o-checkbox
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="repaired"
        v-bind="schema.fields.repaired"
        v-model="item.repaired"
      ></o-checkbox>
    </o-widget-wrapper>
    <o-widget-wrapper
      v-if="item.repaired"
      :schema="schema"
      name="repair_type"
      :validators="$v.item"
      :server-errors="_se"
      :form-state="formState"
    >
      <o-select
        v-model="item.repair_type"
        :items="optionCodeRepairTypeItems"
        text-field="description"
        value-field="id"
        sort-field="description"
        :form-state="formState"
        :loading="optionCodeRepairTypeWorking"
        name="repair_type"
        :validators="$v.item"
        :server-errors="_se"
        v-bind="schema.fields.repair_type"
        allow-add
        store-name="CodeRepairType"
        add-title="Create New"
        @input="updateNotes"
      >
        <template slot="form" slot-scope="{ item, formState, serverErrors }">
          <code-repair-type-form
            :item="item"
            :form-state="formState"
            :server-errors="serverErrors"
            in-modal
          ></code-repair-type-form>
        </template>
      </o-select>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="repaired_by"
      v-if="item.repaired"
    >
      <o-select
        :form-state="formState"
        :items="optionEmployeeItems"
        :loading="optionEmployeeWorking"
        :server-errors="_se"
        :validators="$v.item"
        add-title="Create New"
        allow-add
        name="repaired_by"
        sort-field="full_name"
        store-name="Employee"
        text-field="full_name"
        v-bind="schema.fields.repaired_by"
        v-model="item.repaired_by"
        value-field="id"
      >
        <template slot="form" slot-scope="{ item, formState, serverErrors }">
          <employee-form
            :form-state="formState"
            :item="item"
            :server-errors="serverErrors"
            in-modal
          ></employee-form>
        </template>
      </o-select>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="test_cycle"
    >
      <o-select
        :form-state="formState"
        :items="checkoptionCodeTestCycleItems"
        :loading="optionCodeTestCycleWorking"
        :server-errors="_se"
        :validators="$v.item"
        add-title="Create New"
        allow-add
        name="test_cycle"
        sort-field="descriptionAndCode"
        store-name="CodeTestCycle"
        text-field="descriptionAndCode"
        v-bind="schema.fields.test_cycle"
        v-model="item.test_cycle"
        value-field="id"
      >
        <template slot="form" slot-scope="{ item, formState, serverErrors }">
          <code-test-cycle-form
            :form-state="formState"
            :item="item"
            :server-errors="serverErrors"
            in-modal
          ></code-test-cycle-form>
        </template>
      </o-select>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="retrospective"
    >
      <o-checkbox
        :form-state="formState"
        :server-errors="_se"
        :validators="$v.item"
        name="retrospective"
        v-bind="schema.fields.retrospective"
        v-model="item.retrospective"
      ></o-checkbox>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="supplied_product"
      v-if="item.supplied"
    >
      <o-select
        :form-state="formState"
        :items="optionProductItems"
        :loading="optionProductWorking"
        :server-errors="_se"
        :validators="$v.item"
        add-title="Create New"
        allow-add
        name="supplied_product"
        sort-field="description"
        store-name="Product"
        text-field="description"
        v-bind="schema.fields.supplied_product"
        v-model="item.supplied_product"
        value-field="id"
      >
        <template slot="form" slot-scope="{ item, formState, serverErrors }">
          <product-form
            :form-state="formState"
            :item="item"
            :server-errors="serverErrors"
            in-modal
          ></product-form>
        </template>
      </o-select>
    </o-widget-wrapper>

    <o-widget-wrapper
      :form-state="formState"
      :schema="schema"
      :server-errors="_se"
      :validators="$v.item"
      name="files"
    >
      <o-files-table
        :file-types="optionCodeFileTypeItems"
        :file-types-working="optionCodeFileTypeWorking"
        :form-state="formState"
        :label="schema.fields.files.label"
        :level="level + 1"
        :other-id="item.id"
        :server-errors="_se[schema.fields.files.name]"
        :users="optionUserItemsById"
        :users-working="optionUserWorking"
        :validator="$v.item.files"
        model-name="AssetTestFile"
        other-name="asset_test_file"
        v-model="item.files"
        when-field="modified_date"
        who-field="created_user"
      >
      </o-files-table>
    </o-widget-wrapper>
  </o-form-wrapper>
</template>

<script>
import schema from "../../schema/index";
import { cleanUpModel } from "../../../vue-ocom/schema/schema_vuetify";
import {
  CreateFormStateMixin,
  CreateGroupPermissionMixin,
  VuelidateMixin,
} from "../../../vue-ocom/mixins";
import { validationMixin } from "vuelidate";
import OWidgetWrapper from "../../../vue-ocom/components/forms/OWidgetWrapper";
import OTextField from "../../../vue-ocom/components/forms/fields/OTextField";
import OSelect from "../../../vue-ocom/components/forms/fields/OSelect";
import OCheckbox from "../../../vue-ocom/components/forms/fields/OCheckbox";
import OFormWrapper from "../../../vue-ocom/components/forms/OFormWrapper";
import ODateTime from "../../../vue-ocom/components/forms/fields/ODateTime";

import { default as AssetForm } from "../../asset/components/ItemForm";
import { default as EmployeeForm } from "../../employee/components/ItemForm";
import { default as CodeAssetTestStatusForm } from "../../code_asset_status/components/ItemForm";
import { default as CodeTestCycleForm } from "../../code_test_cycle/components/ItemForm";
import { default as ProductForm } from "../../product/components/ItemForm";
import SpeedSelect from "../../../components/SpeedSelect";
import OSelectTypeahead from "../../../components/OSelectTypeahead";
import { fieldToTableColumn } from "../../../vue-ocom/schema/schema_utils";
import { mapActions } from "vuex";
import AssetLocationCodeTable from "@/mixins/AssetLocationCodeTable";
import { filter_active_list } from "vue-ocom/utils/active";
import { default as CodeRepairTypeForm } from "../../code_repair_type/components/ItemForm";

import UseCodeModulesMixin from "@/vue-ocom/mixins/UseCodeModulesMixin";

export default {
  mixins: [
    VuelidateMixin,
    validationMixin,
    CreateFormStateMixin("$v"),
    CreateGroupPermissionMixin("AssetTest"),
    UseCodeModulesMixin([
      "Asset",
      "Employee",
      "CodeAssetTestStatus",
      "CodeTestCycle",
      "Customer",
      "AssetLocation",
      "CodeColor",
      "CodePhase",
      "CodeTestFrequency",
      "CodeElectricalClass",
      "CodeAssetClassification",
      "CodeFileType",
      "User",
      "CodeRepairType",
    ]),
    UseCodeModulesMixin(["Product", "CodeRepairType"], {
      filter: "supplied_only",
      limit: 10000,
    }),
    AssetLocationCodeTable,
  ],
  components: {
    OSelectTypeahead,
    SpeedSelect,
    OFormWrapper,
    OWidgetWrapper,
    ODateTime,

    OCheckbox,
    OSelect,
    OTextField,

    AssetForm,
    EmployeeForm,
    CodeAssetTestStatusForm,
    CodeTestCycleForm,
    ProductForm,
    CodeRepairTypeForm,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    serverErrorsAssetTest: {
      type: Object,
      default: () => {},
    },
    level: { type: Number, default: 0 },
    inModal: { type: Boolean, default: false },
    withTest: { type: Boolean, default: false },
    isAutofocus: { type: Boolean, default: true },
  },
  data: () => ({
    schemaModel: cleanUpModel(schema.AssetTest, {}),
    assetSchema: cleanUpModel(schema.Asset, {}) /*Use this.schema instead */,
  }),
  methods: {
    ...mapActions("GlobalStateModule", ["setGlobalValue"]),
    checkStatus() {
      if (this.item.status === -1) {
        this.schemaModel.fields.notes.required = true;
      } else {
        this.schemaModel.fields.notes.required = false;
      }
    },
    updateNotes() {
      const repair_type_selected = this.optionCodeRepairTypeItems.filter(
        (item) => {
          return this.item.repair_type == item.id;
        }
      );
      if (
        repair_type_selected.length > 0 &&
        repair_type_selected &&
        !this.item.notes
      ) {
        this.item.notes = repair_type_selected[0].description;
      }
    },
  },
  computed: {
    checkoptionCodeTestCycleItems() {
      if (this.optionCodeTestCycleItems.length > 0) {
        // return this.optionCodeTestCycleItems;
        this.optionCodeTestCycleItems.forEach((code) => {
          code.descriptionAndCode = code.description + " - " + code.code;
        });
        return this.optionCodeTestCycleItems;
      } else {
        return [];
      }
    },
    _se() {
      // Return an empty Object if there are no Server Errors to avoid Binding Errors
      return this.serverErrors || {};
    },
    asset_fields() {
      return [
        fieldToTableColumn(this.assetSchema, "code", { width: "100px" }),
        fieldToTableColumn(this.assetSchema, "customer", { width: "150px" }),
        fieldToTableColumn(this.assetSchema, "location", { width: "100px" }),
        fieldToTableColumn(this.assetSchema, "description", { width: "180px" }),
        fieldToTableColumn(this.assetSchema, "make", { width: "85px" }),
        fieldToTableColumn(this.assetSchema, "model", { width: "100px" }),
        fieldToTableColumn(this.assetSchema, "serial_number", {
          width: "95px",
        }),
      ];
    },
    globalTester() {
      return this.item.tester;
    },
    globalTestDate() {
      return this.item.test_date;
    },
    globalTestCycle() {
      return this.item.test_cycle;
    },
    active_employees() {
      return filter_active_list(this.optionEmployeeItems);
    },
  },
  watch: {
    serverErrorsAssetTest() {
      // this.serverErrors = this.serverErrorsAssetTest
    },

    globalTester: {
      handler() {
        // Set Global Store values
        this.setGlobalValue({ key: "tester", value: this.item.tester });
      },
      deep: true,
    },
    globalTestDate: {
      handler() {
        this.setGlobalValue({ key: "test_date", value: this.item.test_date });
      },
      deep: true,
    },
    globalTestCycle: {
      handler() {
        this.setGlobalValue({ key: "test_cycle", value: this.item.test_cycle });
      },
      deep: true,
    },
  },
  created() {},
};
</script>
