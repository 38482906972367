<template>
    <table class="xo-table scrolling scrolly scrollx" ref="table">
        <thead
            :key="'thead' + row_no"
            :style="stubScrollbarStyle"
            @dragenter="onDragEnterHeader"
            @dragover.prevent="onDragOverHeader"
            @drop="onDropHeader"
            class="ox-header-row scrollsync"
            name="thead"
            ref="thead"
            v-for="(row, row_no) in all_column_headers"
        >
            <th
                :key="rowHeaderIndex"
                :style="{ width: rowLabelWidth }"
                v-for="(rowHeader, rowHeaderIndex) in rowHeadersConfig"
            >
                {{ rowHeader.title }} / {{ columnHeadersConfig[row_no].title }}
            </th>

            <th
                :class="{
                    'xo-header-cell': true,
                    'xo-active-column': is_col_active(cell),
                }"
                :colspan="cell.span"
                :key="'th' + cell_index"
                :style="{ width: cellWidth }"
                v-for="(cell, cell_index) in row"
            >
                <div>
                    <slot :item="cell" name="header-cell"></slot>
                </div>
            </th>
            <th
                :class="{
                    'xo-total-col': true,
                    'xo-header-cell': true,
                }"
                :key="'th-total' + index"
                :style="{ width: cellWidth }"
                v-for="(totalColHeader, index) in totalCols"
            >
                <div>
                    <slot
                        :header="totalColHeader"
                        :index="index"
                        name="header-total-cell"
                        >{{ totalColHeader }}</slot
                    >
                </div>
            </th>
        </thead>

        <tbody @scroll.passive="updateSyncedScroll" name="tbody" ref="tbody">
            <tr
                :class="{ 'xo-active-row': is_row_active(last_row(row_index)) }"
                :key="'tr' + rowno"
                v-for="(row_index, rowno) in all_row_headers"
            >
                <td
                    :class="{
                        'xo-row-header': true,
                        'xo-active-row': is_row_active(row_level_header),
                    }"
                    :key="'td' + row_level"
                    :rowspan="row_level_header.span"
                    :style="{ width: rowLabelWidth }"
                    @mouseover="hover(null, row_level_header)"
                    v-for="(row_level_header, row_level) in row_index"
                    v-if="row_level_header.span > 0"
                >
                    <slot :item="row_level_header" name="row-header"></slot>

                    <div class="debug" v-if="debug">{{ row_index }}</div>
                </td>

                <td
                    :class="{
                        'xo-value-cell': true,
                        'xo-active-row': is_row_active(last_row(row_index)),
                        'xo-active-column': is_col_active(column_header),
                    }"
                    :key="
                        create_cell_key(
                            column_header.id,
                            last_row(row_index).id,
                        )
                    "
                    :style="{ width: cellWidth, height: '1px' }"
                    @mouseover="hover(column_header, last_row(row_index))"
                    v-for="column_header in last_column_header_row"
                >
                    <slot
                        :colHeader="column_header"
                        :itemKey="
                            create_cell_key(
                                column_header.id,
                                last_row(row_index).id,
                            )
                        "
                        :items="
                            getCellValues(column_header, last_row(row_index))
                        "
                        :rowHeader="last_row(row_index)"
                        name="value-cell"
                    ></slot>
                    <span class="debug" v-if="debug"
                        >#
                        {{
                            create_cell_key(
                                column_header.id,
                                last_row(row_index).id,
                            )
                        }}</span
                    >
                </td>

                <td
                    :class="{
                        'xo-total-col': true,
                        'xo-value-cell': true,
                        'xo-active-row': is_row_active(last_row(row_index)),
                        'xo-active-column': is_col_active({
                            id: 'total' + index,
                        }),
                    }"
                    :key="'td-total' + index"
                    :style="{ width: cellWidth }"
                    @mouseover="
                        hover({ id: 'total' + index }, last_row(row_index))
                    "
                    v-for="(totalColHeader, index) in totalCols"
                >
                    <div>
                        <slot
                            :index="index"
                            :rowId="last_row(row_index).id"
                            name="total-col-cell"
                        ></slot>
                    </div>
                </td>
            </tr>
        </tbody>
        <tfoot
            :style="stubScrollbarStyle"
            class="scrollsync"
            name="tfoot"
            ref="tfoot"
        >
            <tr
                :key="'td_total' + index"
                class="'xo-total-row"
                v-for="(total_row_header, index) in totalRows"
            >
                <td
                    :class="{
                        'xo-total-row': true,
                        'xo-row-header': true,
                        'xo-active-row': is_row_active({
                            id: 'totalrow' + index,
                        }),
                    }"
                    :key="'td' + row_level"
                    :rowspan="row_level_header.span"
                    :style="{ width: rowLabelWidth }"
                    @mouseover="hover(null, row_level_header)"
                    v-for="(row_level_header, row_level) in all_row_headers[0]"
                    v-if="row_level_header.span > 0"
                >
                    <slot
                        :item="row_level_header"
                        name="row-total-header"
                    ></slot>

                    <div class="debug" v-if="debug">
                        {{ all_row_headers[0] }}
                    </div>
                </td>
                <td
                    :class="{
                        'xo-total-row': true,
                        'xo-value-cell': true,
                        'xo-active-row': is_row_active({
                            id: 'totalrow' + index,
                        }),
                        'xo-active-column': is_col_active(column_header),
                    }"
                    :key="create_cell_key(column_header.id, 'totalrow' + index)"
                    :style="{ width: cellWidth, height: '1px' }"
                    @mouseover="
                        hover(column_header, { id: 'totalrow' + index })
                    "
                    v-for="column_header in last_column_header_row"
                >
                    <slot
                        :colHeader="column_header"
                        :itemKey="
                            create_cell_key(
                                column_header.id,
                                'totalrow' + index,
                            )
                        "
                        :items="
                            getCellValues(column_header, {
                                id: 'totalrow' + index,
                            })
                        "
                        :rowHeader="{ id: 'totalrow' + index }"
                        name="value-total-cell"
                    ></slot>
                    <span class="debug" v-if="debug"
                        >#
                        {{
                            create_cell_key(column_header.id, {
                                id: "totalrow" + index,
                            })
                        }}</span
                    >
                </td>

                <td
                    :class="{
                        'xo-total-row': true,
                        'xo-total-col': true,
                        'xo-value-cell': true,
                        'xo-active-row': is_row_active({
                            id: 'totalrow' + index,
                        }),
                        'xo-active-column': is_col_active({
                            id: 'total' + total_col_index,
                        }),
                    }"
                    :key="'td-total' + total_col_index"
                    :style="{ width: cellWidth }"
                    @mouseover="
                        hover(
                            { id: 'total' + total_col_index },
                            { id: 'totalrow' + index },
                        )
                    "
                    v-for="(totalColHeader, total_col_index) in totalCols"
                >
                    <div>
                        <slot
                            :index="total_col_index"
                            :rowId="{ id: 'totalrow' + total_col_index }.id"
                            name="total-col-cell"
                        ></slot>
                    </div>
                </td>
            </tr>
        </tfoot>
    </table>
</template>

<script>
import _ from "lodash";

/* NOTE Scroll code from https://github.com/richardtallent/vue-scrolling-table/blob/d445b84f1a6c898a5bcbcb4b4e4da903c985dd9e/src/VueScrollingTable.vue
 */
export default {
    name: "OCrossTable",
    data() {
        return {
            selected_row: null,
            selected_col: null,

            deadAreaColor: "#FFFFF",
        };
    },
    props: {
        rowHeaders: {
            type: Array,
            default: () => [],
        } /* List of Row Headers on Left Hand Side*/,
        rowHeadersConfig: {
            type: Array,
            default: () => [
                {
                    key: "id",
                    children_key: "children",
                    title: "Resource Y - 1",
                    item_key: "res2_id",
                },
            ],
        },

        columnHeaders: {
            type: Array,
            default: () => [],
        } /* List of Column Headers across the Top */,
        /* Config how to read columnHeaders,
                key=Field name for unique field name of the id of the Column header to match with the Value[item_key]
                children_key= Field name for children list for next level or NULL for no children
                title= Title to show for the Level
                item_key: The KEY field on the Value for this level
             */
        columnHeadersConfig: {
            type: Array,
            default: () => [
                {
                    key: "id",
                    children_key: "children",
                    title: "Resource X - 1",
                    item_key: "column_id",
                },
            ],
        },

        values: { type: Array, default: () => [] } /* The Allocation data */,

        cellWidth: {
            type: String,
            default: "100px",
        } /* Width of the Allocation Column */,
        rowLabelWidth: {
            type: String,
            default: "100px",
        } /* Width of the Row Label column */,

        totalRows: { type: Array, default: () => [] },
        totalCols: { type: Array, default: () => [] },

        debug: { type: Boolean, default: false },
    },
    methods: {
        create_cell_key(columnIDs, rowIDs) {
            /*
                    Create a KEY for the allocation
                */
            if (rowIDs && _.isArray(rowIDs)) {
                return rowIDs.join(",") + ":" + (columnIDs || [""]).join(",");
            } else {
                return (columnIDs || [""]).join(",");
            }
        },
        getCellValues(columnID, rowID) {
            /* Find an allocation for the Task and Resource
                return Null/Undefined if not found
                */
            var key = this.create_cell_key(columnID.id, rowID.id);

            var result = this.values_by_key[key];

            return result;
        },
        hover(column_header, row_header) {
            /* highlight the Row + column we are over */

            if (row_header) {
                this.selected_row = row_header.id;
            }

            if (column_header) {
                this.selected_col = column_header.id;
            }

            this.$emit("hover", { col: column_header, row: row_header });
        },

        last_row(row_index) {
            if (row_index) {
                return row_index[row_index.length - 1];
            } else {
                return {};
            }
        },
        is_row_active(row_header) {
            if (row_header && this.selected_row) {
                var match = true;

                for (var i = 0; i < row_header.id.length; i++) {
                    match = match && this.selected_row[i] == row_header.id[i];
                }

                return match;
            }

            return false;
        },
        is_col_active(column_header) {
            if (column_header && this.selected_col) {
                var match = true;

                for (var i = 0; i < column_header.id.length; i++) {
                    match =
                        match && this.selected_col[i] == column_header.id[i];
                }

                return match;
            }

            return false;
        },
        resourcesToRows(resources, resourcesConfig) {
            /*
             * Convert the Resources into a table structure that makes creating the TR/TD's easier..
             * */
            var rows = [];

            /*
                 |             LEVEL 0            |
                 |   0-0          | 0-1           |
                 | 0-0-0 | 0-0-1  | 0-1-0 | 0-1-1 |
                 */
            function collect_row(items, ids, level, resourcesConfig) {
                var cells = [];

                if (rows.length - 1 < level) {
                    rows.push([]); // add new row
                }

                _.each(items, function (item) {
                    var span = 1; // default to 1 for End of the line

                    var new_ids = ids.concat([item.id]); // Add ours to the list

                    // DO the children first
                    var children_key = resourcesConfig[level].children_key;
                    if (children_key) {
                        if (children_key in item) {
                            var child_row = collect_row(
                                item[children_key],
                                new_ids,
                                level + 1,
                                resourcesConfig,
                            );

                            span = _.sumBy(child_row, "span") || 1;
                        }
                    }

                    cells.push({
                        id: new_ids,
                        data: item,
                        title: item.title,
                        span: span,
                    });
                });

                rows[level] = rows[level].concat(cells);

                return cells;
            }

            _.each(resources, function (resource) {
                collect_row([resource], [], 0, resourcesConfig);
            });

            return rows;
        },

        resourcesToCols(resources, resourcesConfig) {
            /*
             * Convert the Resources into a table structure that makes creating the Row Header TR/TD's easier..
             * */
            var columns = [];

            /*
                 |             LEVEL 0            |
                 |   0-0          | 0-1           |
                 | 0-0-0 | 0-0-1  | 0-1-0 | 0-1-1 |
                 */
            function collect_col(items, ids, level, resourcesConfig) {
                var cells = [];

                _.each(items, function (item) {
                    var span = 1; // default to 1 for End of the line

                    var new_ids = ids.concat([item.id]); // Add ours to the list

                    // DO the children first so we can calculate span
                    var children_key = resourcesConfig[level].children_key;
                    if (children_key) {
                        if (children_key in item) {
                            var child_row = collect_col(
                                item[children_key],
                                new_ids,
                                level + 1,
                                resourcesConfig,
                            );

                            span = _.sumBy(child_row, "span") || 1;
                        }
                    }

                    cells.push({
                        id: new_ids,
                        data: item,
                        title: item.title,
                        span: span,
                    });
                    for (var i = 1; i < span; i++) {
                        // starts at 1 so nothing generated if span==1
                        cells.push({ span: 0 }); // empty cell to be skipped
                    }
                });

                while (columns.length - 1 < level) {
                    columns.push([]);
                }

                columns[level] = columns[level].concat(cells);

                return cells;
            }

            _.each(resources, function (resource) {
                collect_col([resource], [], 0, resourcesConfig);
            });

            // Now rotae it so it's side ways and it will be right
            return _.zip(...columns);
        },
        /* Scrolling table */
        updateSyncedScroll() {
            const b = this.$refs.tbody;
            const l = b.scrollLeft;

            const h = this.$refs.thead;
            if (h.scrollLeft !== l) {
                h.scrollLeft = l;
            }

            const f = this.$refs.tfoot;
            if (f.scrollLeft !== l) {
                f.scrollLeft = l;
            }
            this.$emit("scroll", b.scrollTop, l, b.scrollHeight, b.scrollWidth);
        },
        onDragEnterHeader(e) {
            this.$emit("header-dragenter", e);
        },
        onDragOverHeader(e) {
            this.$emit("header-dragover", e);
        },
        onDropHeader(e) {
            this.$emit("header-drop", e);
        },
    },
    watch: {
        columnHeaders() {
            //this.updateSyncedScroll(); // recalc widths?
        },
    },
    computed: {
        values_by_key() {
            /* Take the values and convert them to a dict so we can find them quickly
             */
            var vm = this;

            var results = {};

            // Get the id's for each Resource Level from the allocation
            _.forEach(this.values, function (a_value) {
                var resource_keys = _.map(
                    vm.columnHeadersConfig,
                    function (res_config) {
                        return a_value[res_config.item_key];
                    },
                );

                var task_keys = _.map(
                    vm.rowHeadersConfig,
                    function (res_config) {
                        return a_value[res_config.item_key];
                    },
                );

                var key = vm.create_cell_key(resource_keys, task_keys);

                var found = results[key];
                if (found) {
                    results[key].push(a_value);
                } else {
                    results[key] = [a_value];
                }
            });

            return results;
        },
        last_column_header_row() {
            /* Get the LAST header row this is the one with all the ID's we need
             */
            if (this.all_column_headers) {
                var last_row =
                    this.all_column_headers[this.all_column_headers.length - 1];

                return last_row;
            } else {
                return [];
            }
        },
        all_column_headers() {
            return this.resourcesToRows(
                this.columnHeaders,
                this.columnHeadersConfig,
            );
        },
        all_row_headers() {
            return this.resourcesToCols(this.rowHeaders, this.rowHeadersConfig);
        },
        /* For Scroll table */
        stubScrollbarStyle() {
            return `background-color: ${this.deadAreaColor};
                    scrollbar-base-color: ${this.deadAreaColor};
                    scrollbar-face-color: ${this.deadAreaColor};
                    scrollbar-highlight-color: ${this.deadAreaColor};
                    scrollbar-track-color: ${this.deadAreaColor};
                    scrollbar-arrow-color: ${this.deadAreaColor};
                    scrollbar-shadow-color: ${this.deadAreaColor};
                    scrollbar-darkshadow-color: ${this.deadAreaColor};`;
        },
    },
    mounted: function () {
        this.updateSyncedScroll();
    },
};
</script>

<style scoped>
table.xo-table {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 99%;
    height: 98%;
    border-collapse: collapse;
    overflow: hidden;
    /* Use this to create a "dead" area color if table is too wide for cells */
    background-color: white;
    --dead-area-color: white;
}

table.xo-table,
table.xo-table td,
table.xo-table th {
    border: 1px solid #a8a8a8;
}

.debug {
    font-size: 6pt;
}

tr.ox-header-row {
}

td.xo-row-header {
    background-color: #dee2e68c;
    text-align: left;
}

th.xo-header-cell {
    background-color: #dee2e6;
    font-size: 11pt;
    text-align: center;
}

td.xo-value-cell {
    vertical-align: top;
    padding: 5px;
}

.xo-total-row,
.xo-total-col {
    background-color: rgba(207, 210, 214, 0.55);
    font-weight: bold;
}

td.xo-active-row,
th.xo-active-column,
td.xo-active-column {
    background: #1e7fb038;
}

/* Scrolling table from https://github.com/richardtallent/vue-scrolling-table/blob/master/src/VueScrollingTable.vue#L95*/
table.xo-table thead,
table.xo-table tfoot {
    /* Grow automatically to fit content, don't shrink it proportionately to the body. */
    flex: 0 0 auto;
    display: block;
    /* Horizontal scrolling, when allowed, is controlled by JS, not a scroll bar. */
    overflow: hidden;
}

table.xo-table tbody {
    display: block;
    flex: 1 1 auto;
    /* Disable all scrolling by default */
    overflow: hidden;
    height: 500px;
}

/* Turn on vertical scrolling for all elements so scroll bars take up the same space */
table.xo-table.scrolly tbody,
table.xo-table.scrolly thead.scrollsync,
table.xo-table.scrolly tfoot.scrollsync {
    overflow-y: scroll;
}

/* Turn on horizontal scrolling for the body only */
table.xo-table.scrollx tbody {
    overflow-x: scroll;
}

/*
    For Webkit, use "dead area" color to hide vertical scrollbar functions in the header and footer.
    Since WebKit supports CSS variables and style attributes don't support pseudo-classes, use variables.
    Display is set because otherwise Chrome ignores the other styling.
    TODO: on Chrome/Safari for Mac, scrollbars are not shown anyway and this creates an extra block. No impact on iOS Safari.
    */
table.xo-table.scrolly thead.scrollsync::-webkit-scrollbar {
    display: block;
    background-color: white;
}

table.xo-table.scrolly thead.scrollsync::-webkit-scrollbar-track {
    background-color: white;
}

/* IE11 adds an extra tbody, have to hide it. */
table.xo-table tbody:nth-child(3) {
    display: none;
}

/* The one caveat to scrolling this way: a hard-set width is required. Can override in thead/tbody slot. */
table.xo-table td,
table.xo-table th {
    border: 1px solid #ddd;
    /* Important in case your data is too long for your cell */
    overflow: hidden;
    word-wrap: break-word;
}
</style>
