<template>
    <div class="row">
        <div class="col-sm">
            <div
                :key="section.name"
                class="dashboard-menu pb-4"
                v-for="section in filtered_items"
            >
                <div class="list-group">
                    <label class="list-group-item active">{{
                        section.name
                    }}</label>

                    <router-link
                        :key="child.title"
                        :to="child.to"
                        class="list-group-item list-group-item-action d-flex"
                        v-for="child in section.children"
                    >
                        <div class="align-self-start">
                            <component :is="child.icon + '-icon'"></component>
                        </div>

                        <div class="align-self-start flex-fill">
                            &nbsp; {{ child.title }}
                        </div>

                        <div class="align-self-end">
                            <menu-right-outline-icon />
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MenuRightOutlineIcon from "vue-material-design-icons/MenuRightOutline.vue";
import MenuMixin from "./MenuMixin.js";

export default {
    name: "dashboard-menu",
    mixins: [MenuMixin],
    components: { MenuRightOutlineIcon },
    props: {
        items: {},
    },
    computed: {},
    created() {
        console.error(
            "Consider upgrading to ONavDashboardMenu or OSectionDashboardMenu",
        );
    },
};
</script>
a
