import { localUrls } from "./urls.js";
import LoginPage from "./components/LoginPage.vue";
import LogoutPage from "./components/LogoutPage.vue";

export default [
    {
        path: localUrls.login,
        component: LoginPage,
        name: "login",
        props: true,
    },
    {
        path: localUrls.logout,
        component: LogoutPage,
        name: "logout",
    },
    // {
    //   path: localUrls.account,
    //   component: AccountPage,
    //   name: 'account'
    // },
    // {
    //   path: localUrls.accountCreate,
    //   component: AccountCreatePage,
    //   name: 'account-create'
    // }
];
