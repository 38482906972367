<template>
    <o-form-group
        :form-state="formState"
        :label="label"
        :message="errorMessages"
        :nolabel="nolabel"
        :type="{ 'is-danger': hasError }"
    >
        <BFormCheckboxGroup
            :disabled="_is_readonly"
            :options="the_list"
            :text-field="textField"
            :value-field="valueField"
            stacked
            v-bind="$attrs"
            v-bind:checked="value"
            v-on="inputListeners"
        ></BFormCheckboxGroup>
        <div
            class="custom-control custom-checkbox"
            v-if="allowAdd && !_is_readonly"
        >
            <input
                autocomplete="off"
                class="custom-control-input"
                disabled
                id="addNewCheckBox"
                type="checkbox"
            />
            <label
                class="custom-control-label add-new-label"
                for="addNewCheckBox"
            >
                <input
                    @keydown.enter="enterPressed"
                    class="add-new-input"
                    type="text"
                    v-if="allowAdd"
                    v-model="newItem"
                />
            </label>
        </div>
    </o-form-group>
</template>

<script>
import { BFormCheckboxGroup } from "bootstrap-vue";
import FormComponentMixin from "../../../mixins/form_component_mixin.js";
import _ from "lodash";
import moment from "moment";
import { is_item_active } from "../../../utils/active.js";
import OFormGroup from "../OFormGroup.vue";
import AddNewMixin from "./AddNewMixin.js";

export default {
    name: "OMultiCheckbox",
    mixins: [FormComponentMixin, AddNewMixin],
    components: {
        BFormCheckboxGroup,
        OFormGroup,
    },
    props: {
        items: { type: Array, default: null },
        showAll: { type: Boolean, default: false },
        dontSort: { type: Boolean, default: false },
        textField: {
            type: String,
            default: "description",
        },
        valueField: {
            type: String,
            default: "id",
        },
        disabledSuffix: { type: String, default: "(Disabled)" },
        sortField: { type: String, default: null },

        allowAdd: { type: Boolean, default: false },
        displayOrderField: { type: String, default: null },
    },
    data() {
        return {
            newItem: "",
        };
    },
    methods: {
        enterPressed($event) {
            var vm = this;
            var item = {};

            item[this.textField] = this.newItem;

            if (this.displayOrderField) {
                var newDisplayOrder = _.maxBy(
                    this.items,
                    this.displayOrderField,
                );

                if (newDisplayOrder) {
                    item[this.displayOrderField] =
                        newDisplayOrder[this.displayOrderField] + 1;
                } else {
                    item[this.displayOrderField] = 0;
                }
            }

            vm.create_new_item(item, function (response) {
                vm.value.push(response.id);

                vm.on_change(vm.value); // Update of change

                vm.newItem = "";
            });
        },
    },
    computed: {
        the_list() {
            var vm = this;
            var result = this.items;

            if (!this.showAll) {
                var now = moment();
                var current_id = this.value;

                result = _.filter(result, function (o) {
                    if (!o) {
                        return false;
                    } else {
                        if (current_id) {
                            if (o.id === current_id) {
                                // Add (disabled) suffix if it was disabled but in the list..
                                if (!is_item_active(o, now)) {
                                    if (
                                        !o[vm.textField].endsWith(
                                            vm.disabledSuffix,
                                        )
                                    ) {
                                        o[vm.textField] =
                                            o[vm.textField] +
                                            " " +
                                            vm.disabledSuffix;
                                    }
                                }

                                return true;
                            }
                        }

                        return is_item_active(o, now);
                    }
                });
            }
            if (!this.dontSort) {
                result = _.sortBy(result, this.sortField || this.textField); // Sort by sortField or default to textField
            }

            return result;
        },
    },
};
</script>

<style scoped>
.add-new-label,
.add-new-input {
    width: 100%;
}
</style>
