<template>
    <div>
        <ul>
            <ol v-for="(value, name) in v" :key="name">
                <strong>{{ name }}</strong>
                =
                <BBadge v-if="value.required" variant="info">R</BBadge>
                <BBadge v-if="value.$dirty" variant="warning">D</BBadge>

                <BBadge v-if="value.$invalid" variant="danger">I</BBadge>
                <BBadge v-if="value.$error" variant="danger">E</BBadge>
            </ol>
        </ul>
    </div>
</template>

<script>
import { BBadge } from "bootstrap-vue";

export default {
    components: {
        BBadge,
    },
    name: "OValidateDisplay",
    props: {
        v: { type: Object, default: null },
    },
};
</script>

<style scoped></style>
