<template>
    <o-form-group
        :form-state="formState"
        :label="label"
        :message="errorMessages"
        :nolabel="nolabel"
        :type="{ 'is-danger': hasError }"
    >
        <o-form-select
            :options="options"
            :blank-option-text="nullName"
            text-field="name"
            dont-sort
            :value="value"
            @change="updateSelect"
            value-field="value"
        >
        </o-form-select>
    </o-form-group>
</template>

<script>
import FormComponentMixin from "../../../mixins/form_component_mixin.js";
import SelectInputMixin from "./private/SelectInputMixin.js";
import OFormGroup from "../OFormGroup.vue";

export default {
    name: "OBooleanSelect",
    mixins: [FormComponentMixin, SelectInputMixin],
    components: { OFormGroup },
    model: {
        event: "change",
        prop: "value",
    },
    props: {
        value: { type: Boolean, default: null },
        trueName: { type: String, default: "Yes" },
        falseName: { type: String, default: "No" },
        nullName: { type: String, default: "" },
    },
    methods: {
        updateSelect(value) {
            this.$emit("change", value);
        },
    },
    computed: {
        options() {
            return [
                { value: null, name: this.nullName },
                { value: true, name: this.trueName },
                { value: false, name: this.falseName },
            ];
        },
    },
};
</script>

<style scoped></style>
