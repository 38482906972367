import { mapGetters } from "vuex";
import { prefixAnd } from "@/vue-ocom/query/utils";
import cloneDeep from "lodash";

export default {
  computed: {
    ...mapGetters("GlobalStateModule", { global_data: "data" }),
  },
  methods: {
    OnBeforeGetAssetLocationManCodeList(list_options) {
      var result = cloneDeep(list_options);
      var customer_id = this.global_data.customer_id;

      result.query = prefixAnd(result.query, {
        or: [
          ["customer", "eq", customer_id],
          ["parents", "is_null"],
        ],
      });

      return result;
    },
  },
};
