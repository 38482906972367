<template>
    <ol class="breadcrumb p-0 m-0">
        <li :key="i.id" class="breadcrumb-item" v-for="i in treeData">
            {{ i.label }}
        </li>
    </ol>
</template>

<script>
import TreeComponentMixin from "./TreeComponentMixin.js";

export default {
    name: "OTreeBreadcrumbs",
    mixins: [TreeComponentMixin],
    props: {},
    data() {
        return {
            treeOptions: {
                selectable: true,
                checkboxes: true,
            },
        };
    },
    methods: {},
};
</script>
<style scoped>
ol {
    background-color: transparent;
}
</style>
