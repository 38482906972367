<template>
    <BButtonGroup class="mb-0">
        <slot name="prefix"></slot>

        <BButton
            :disabled="disableUp"
            @click="moveUp()"
            size="sm"
            v-if="!disableMove"
            variant="primary"
        >
            <chevron-up-icon />
        </BButton>

        <BButton
            :disabled="disableDown"
            @click="moveDown()"
            size="sm"
            v-if="!disableMove"
            variant="primary"
        >
            <chevron-down-icon />
        </BButton>

        <BButton
            @click="removeItem()"
            size="sm"
            v-if="!disableDelete"
            variant="danger"
        >
            <delete-forever-icon title="Delete" />
        </BButton>

        <BButton
            v-if="enableInsert"
            size="sm"
            variant="success"
            @click="insertItem()"
        >
            <plus-box-icon />
        </BButton>

        <slot></slot>
        <slot name="suffix"></slot>
    </BButtonGroup>
</template>

<script>
import { BButton, BButtonGroup } from "bootstrap-vue";
import _ from "lodash";
import ChevronUpIcon from "vue-material-design-icons/ChevronUp.vue";
import ChevronDownIcon from "vue-material-design-icons/ChevronDown.vue";
import DeleteForeverIcon from "vue-material-design-icons/DeleteForever.vue";
import PlusBoxIcon from "vue-material-design-icons/PlusBox.vue";

export default {
    name: "OListActions",
    components: {
        BButtonGroup,
        BButton,
        DeleteForeverIcon,
        ChevronDownIcon,
        ChevronUpIcon,
        PlusBoxIcon,
    },
    props: {
        list: { type: Array },
        index: { type: Number },
        disableMove: { type: Boolean, default: false },
        disableDelete: { type: Boolean, default: false },
        enableInsert: { type: Boolean, default: false },
        displayOrder: { type: String, default: null },
        displayOrderMin: { type: Number, default: 0 },
        defaultValue: {
            type: [Object, Function],
            default: () => {},
        },
    },
    model: {
        prop: "list",
        event: "change",
    },
    methods: {
        onChanged() {
            const vm = this;
            if (this.displayOrder) {
                // Update the Display Order if specified
                var displayOrderField = this.displayOrder;

                _.each(this.list, function (item, index) {
                    item[displayOrderField] = index + vm.displayOrderMin;
                });
            }

            this.$emit("change", this.list);
        },
        moveUp() {
            if (!this.disableMove) {
                this.list.splice(
                    this.index - 1,
                    0,
                    this.list.splice(this.index, 1)[0],
                );
                this.onChanged();
            }
        },
        moveDown() {
            if (!this.disableMove) {
                this.list.splice(
                    this.index + 1,
                    0,
                    this.list.splice(this.index, 1)[0],
                );
                this.onChanged();
            }
        },
        removeItem() {
            if (!this.disableDelete) {
                let removeIt = true;

                if ("active_end_date" in this.list[this.index]) {
                    var date = new Date();

                    // minimize race conditions
                    date.setSeconds(0, 0);
                    this.list[this.index].active_end_date = date.toJSON();

                    if (this.list[this.index].id) removeIt = false; // don't remove it as there is an ID
                    if (this.list[this.index].pk) removeIt = false; // don't remove it as there is an pk
                }

                if (removeIt) {
                    this.list.splice(this.index, 1);
                }

                this.onChanged();
            }
        },
        insertItem() {
            var list = this.list || [];

            var newValue = {};

            if (_.isFunction(this.defaultValue)) {
                newValue = this.defaultValue(this.list[this.index]); // call function
            } else {
                newValue = _.cloneDeep(this.defaultValue);
            }

            if (this.index != null) {
                list.splice(this.index + 1, 0, newValue);
            } else {
                list.push(newValue);
            }

            if (this.displayOrder in newValue) {
                const vm = this;

                // Renumber the list
                _.forEach(list, function (val, index) {
                    val[vm.displayOrder] = index = vm.displayOrderMin;
                });
            }
            this.onChanged(list);
        },
    },
    computed: {
        disableUp() {
            return this.disableMove || this.index <= 0;
        },
        disableDown() {
            return this.disableMove || this.index >= this.list.length - 1;
        },
    },
};
</script>
