<template>
    <BButton @click="cloneItem" v-bind="$attrs">
        <slot>Copy</slot>
    </BButton>
</template>

<script>
import { BButton } from "bootstrap-vue";
import { cloneItem } from "../../schema/schema_utils.js";

export default {
    components: {
        BButton,
    },
    name: "OCopyItemButton",
    model: {
        value: "value",
        event: "changed",
    },
    props: {
        /* Name the model for the Item */
        modelName: { type: String, required: true },

        /* The Item to clone */
        value: { type: Object, required: true },
    },
    methods: {
        cloneItem() {
            var newItem = cloneItem(this.value, this.modelName);

            this.$emit("changed", newItem);
        },
    },
};
</script>

<style scoped></style>
