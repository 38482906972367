import Vue from "vue";
import { BFormSelect } from "bootstrap-vue";
import SelectInputMixin from "./SelectInputMixin.js";
import { sort_and_filter_active_list } from "../../../../utils/active.js";
import { isArray } from "bootstrap-vue/src/utils/inspect.js";
import { keys } from "bootstrap-vue/src/utils/object.js";

export default Vue.extend({
    name: "OFormSelect",
    mixins: [BFormSelect, SelectInputMixin],
    computed: {
        formOptions() {
            const options = sort_and_filter_active_list(
                this.options,
                this.value,
                this.showAll,
                this.textField,
                this.disabledSuffix,
                this.dontSort,
                this.sortField,
            );

            // Normalize the given options array
            if (isArray(options)) {
                return options.map((option) => this.normalizeOption(option));
            }
            // Normalize a `options` object to an array of options
            return keys(options).map((key) =>
                this.normalizeOption(options[key] || {}, key),
            );
        },
    },
});
