import CodeColorList from "./pages/ListPage.vue";
import CodeColorDetail from "./pages/FormPage.vue";
import CodeColorIndex from "./pages/IndexPage.vue";

export default [
  {
    path: "/code_color",
    component: CodeColorIndex,
    redirect: "/code_color/list",
    name: "code_color",
    children: [
      {
        path: "list",
        component: CodeColorList,
        name: "code_color.list",
      },
      {
        path: "create",
        component: CodeColorDetail,
        name: "code_color.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: CodeColorDetail,
        name: "code_color.edit",
        props: { create: false },
      },
    ],
  },
];
