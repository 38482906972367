<template>
  <LoginGuard>
    <global-customer-guard name="Assets">
      <router-view></router-view>
    </global-customer-guard>
  </LoginGuard>
</template>

<script>
// this is the Home/index of Brokers
import LoginGuard from "../../../vue-ocom/auth/components/LoginGuard";
import GlobalCustomerGuard from "../../../components/GlobalCustomerGuard";

export default {
  components: {
    GlobalCustomerGuard,
    LoginGuard,
  },
  computed: {},
};
</script>
