<template>
    <o-form-group
        :form-state="formState"
        :label="label"
        :message="errorMessages"
        :nolabel="nolabel"
        :type="{ 'is-danger': hasError }"
    >
        <BFormTags
            :class="input_class"
            :disabled="_is_readonly"
            :value="value"
            @input="on_input"
            remove-on-delete
            separator=",;"
        ></BFormTags>
    </o-form-group>
</template>

<script>
import { BFormTags } from "bootstrap-vue";
import FormComponentMixin from "../../../mixins/form_component_mixin.js";
import OFormGroup from "../OFormGroup.vue";

export default {
    name: "OTagInput",
    components: {
        BFormTags,
        OFormGroup,
    },
    mixins: [FormComponentMixin],
    data() {
        return {};
    },
    props: {},
    methods: {},
    computed: {},
};
</script>

<style scoped></style>
