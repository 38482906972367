<template>
    <div :class="this_class" class="card mt-3">
        <div class="card-header pa-0">
            <div class="input-group">
                <select
                    :value="logic"
                    @change="updateLogic($event)"
                    class="custom-select bg-transparent"
                >
                    <option
                        :key="option.id"
                        v-bind:value="option.id"
                        v-for="option in logic_options"
                    >
                        {{ option.human_name }}
                    </option>
                </select>
                <div class="input-group-append">
                    <BButton
                        @click="addCondition()"
                        class="mx-0"
                        variant="outline-secondary"
                        >Add Field</BButton
                    >
                    <BButton
                        @click="addLogic()"
                        class="mx-0"
                        variant="outline-secondary"
                        >Add Group</BButton
                    >
                    <BButton
                        @click="removeMe()"
                        class="mx-0"
                        v-if="level > 0"
                        variant="outline-danger"
                    >
                        <close-circle-outline-icon></close-circle-outline-icon>
                    </BButton>
                </div>
            </div>
        </div>
        <div class="card-body p-2">
            <div :key="index" class="row" v-for="(row, index) in fields">
                <span class="col-sm-11" v-if="!isField(row)">
                    <query-logic
                        :filter-option="filterOption"
                        :level="level + 1"
                        :meta-data="metaData"
                        :query-select-options="querySelectOptions"
                        :special-options="specialOptions"
                        :value="row"
                        @change="changed"
                        @removeMe="removeField(index)"
                    />
                </span>

                <span class="col-sm-11" v-if="isField(row)">
                    <query-field
                        :filter-option="filterOption"
                        :meta-data="metaData"
                        :query-select-options="querySelectOptions"
                        :special-options="specialOptions"
                        :value="row"
                        @remove="removeField(index)"
                    />
                </span>

                <select
                    :value="logic"
                    @change="updateLogic($event)"
                    class="custom-select"
                    style="width: 5em"
                    v-show="
                        fields &&
                        fields.length > 0 &&
                        index != fields.length - 1
                    "
                >
                    <option
                        :key="option.id"
                        v-bind:value="option.id"
                        v-for="option in logic_options"
                    >
                        {{ option.name }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import _ from "lodash";
import QueryField from "./QueryField.vue";
import CloseCircleOutlineIcon from "vue-material-design-icons/CloseCircleOutline.vue";

export default {
    name: "QueryLogic",
    components: {
        BButton,
        QueryField,
        CloseCircleOutlineIcon,
    },
    data() {
        return {
            logic_options: [
                { id: "and", name: "And", human_name: "Match All" },
                { id: "or", name: "Or", human_name: "Match Any" },
            ],
        };
    },
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        filterOption: {
            type: Object,
            default: () => {},
        },
        value: {},
        metaData: {
            type: Object,
            default: () => {},
        },
        specialOptions: {
            type: Object,
            default: () => {},
        },
        level: { type: Number, default: 0 },
        querySelectOptions: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        changed() {
            this.$emit("change", this.value);
        },
        makeSureRowStructureIsGood(row) {
            if (!row.and && !row.or) {
                this.$set(row, "and", []);
            }
        },
        addCondition() {
            this.makeSureRowStructureIsGood(this.value);

            if (this.value["and"]) this.value.and.push([null, null]);

            if (this.value["or"]) this.value.or.push([null, null]);

            this.changed();
        },
        addLogic() {
            this.makeSureRowStructureIsGood(this.value);

            var toPush = { and: [[null, null]] };

            if (this.value["and"]) this.value.and.push(toPush);

            if (this.value["or"]) this.value.or.push(toPush);

            this.changed();
        },
        isField(field) {
            return _.isArray(field);
        },
        updateLogic(event) {
            var fields = this.fields;

            // remove both
            this.$delete(this.value, "and");
            this.$delete(this.value, "or");

            this.$set(this.value, event.target.value, fields);

            this.changed();
        },
        removeField(index) {
            if (this.value["and"]) this.value.and.splice(index, 1);

            if (this.value["or"]) this.value.or.splice(index, 1);

            this.changed();
        },
        removeMe() {
            this.$emit("removeMe");
        },
    },
    computed: {
        this_class() {
            var result = "ql ";

            if (this.level > 0) result = result + "level" + (this.level % 6); // we only have 6 colors :)

            return result;
        },
        fields() {
            if (this.value.or) {
                return this.value.or;
            }
            if (this.value.and) {
                return this.value.and;
            }

            return [];
        },
        logic() {
            if (this.value && this.value.or) {
                return "or";
            }

            return "and";
        },
    },
};
</script>

<style scoped>
.ql {
    /*background-color: mediumpurple;*/
}

.level0 {
    border-left: rebeccapurple 5px solid;
}

.level1 {
    border-left: greenyellow 5px solid;
}

.level2 {
    border-left: cornflowerblue 5px solid;
}

.level3 {
    border-left: indianred 5px solid;
}

.level4 {
    border-left: slategray 5px solid;
}

.level5 {
    border-left: saddlebrown 5px solid;
}

.level6 {
    border-left: yellow 5px solid;
}
</style>
