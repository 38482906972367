import CodeAssetClassificationList from "./pages/ListPage.vue";
import CodeAssetClassificationDetail from "./pages/FormPage.vue";
import CodeAssetClassificationIndex from "./pages/IndexPage.vue";

export default [
  {
    path: "/code_asset_classification",
    component: CodeAssetClassificationIndex,
    redirect: "/code_asset_classification/list",
    name: "code_asset_classification",
    children: [
      {
        path: "list",
        component: CodeAssetClassificationList,
        name: "code_asset_classification.list",
      },
      {
        path: "create",
        component: CodeAssetClassificationDetail,
        name: "code_asset_classification.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: CodeAssetClassificationDetail,
        name: "code_asset_classification.edit",
        props: { create: false },
      },
    ],
  },
];
