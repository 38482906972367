import { ACTION_TYPES } from "./store_keys";

export function refresh({ state, dispatch }) {
    if (state.working) {
        console.error("Module already loading!");
        return;
    }
    if (!state.last_list_options || !state.last_load) {
        console.error("Module has not been loaded!, Cannot Refresh");
        return;
    }
    var lastLoad = new Date(state.last_load);
    let current_date = new Date();
    let difference = current_date.getTime() - lastLoad.getTime();
    let delaySeconds = 5; // 5 seconds
    if (Math.abs(difference) < delaySeconds * 1000) {
        console.error("Module loaded recently. Not refreshed!");
        return;
    }

    dispatch(ACTION_TYPES.GET_ALL, state.last_list_options);
}
