<template>
    <span :class="get_class">{{ formatted_value }}</span>
</template>

<script>
import DisplayMixin from "../../mixins/display_mixin.js";
import moment from "moment";

export default {
    name: "DateDisplay",
    props: {
        value: { type: [String, Date], default: null },
        format: { type: String, default: "DD/MM/YYYY hh:mm" },
        defaultValue: { type: String, default: "" },
    },
    mixins: [DisplayMixin("formatted_value")],
    computed: {
        formatted_value() {
            if (!this.value) {
                return this.defaultValue;
            }

            return moment(this.value).format(this.the_format);
        },
        the_format() {
            // The format to use - creates a default
            let format = this.format;
            if (!format) return "dd/MM/YYYY hh:mm";

            return format;
        },
    },
};
</script>

<style scoped></style>
