<template>
    <div class="widget-wrapper" v-if="show">
        <slot />
    </div>
</template>

<script>
import { FIELD_TYPES } from "../../schema";

/*
    A wrapper around Widgets to show Errors and hide if permissions is denied
     */
export default {
    name: "OWidgetWrapper",
    props: {
        validators: { type: Object, default: null },
        schema: { type: Object, required: true },
        name: { type: String, required: true },
        serverErrors: {
            type: [Object, Array],
            required: true,
        } /* If the serverErrorsIndex is passed it should be an array */,
        serverErrorsIndex: {
            type: Number,
            default: null,
        } /* If Null then server-errors is not an array */,
        formState: { type: Object, required: false },
    },
    data() {
        return {
            FIELD_TYPES: FIELD_TYPES,
        };
    },
    computed: {
        show() {
            if (this.schema && this.name) {
                var field = this.schema.fields[this.name];
                if (!field) {
                    console.error(
                        "Cannot find the field " +
                            this.name +
                            " in the schema!",
                    );
                } else {
                    if ("hide" in field) {
                        return !this.schema.fields[this.name].hide;
                    }
                }
            }

            return true;
        },
    },
};
</script>

<style scoped>
/*.widget-wrapper:not(:last-child) {*/
/*    margin-bottom: .75rem;*/
/*}*/
</style>
