<template>
  <div>
    <h3 @click="enableDebug($event)" class="display-4">
      Manage Maintenance Records
    </h3>

    <o-list-toolbar
      :filters-list="filter_list"
      :list-options="list_options"
      :new-to="{ name: 'maintenance_record.create', params: { id: 0 } }"
      :query-select-options="options"
      :schema="schema"
      :search-fields-list="search_field_list"
      :selected-filter="selected_filter"
      @filter_changed="update_filter($event)"
      @options_changed="update_table($event)"
      @reset_table="reset_table"
      new-button-text="New Maintenance Record"
    >
      <template slot="middle">
        <o-list-multi-actions
          :schema="schema"
          :selection="selected"
          @delete="doDeleteItems"
          @merge="doMergeItems"
          enable-delete
          enable-merge
        />
      </template>
    </o-list-toolbar>

    <b-table
      :fields="fields"
      :items="items"
      :sort-by="list_options.sort"
      :sort-desc="list_options.order === 'desc'"
      @row-selected="onRowSelected"
      @sort-changed="change_sort"
      bordered
      class="o-list"
      head-variant="light"
      no-local-sorting
      selectable
      striped
      thead-class="o-gradient-header"
    >
      <template v-slot:cell(_selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
      <template
        slot="top-row"
        slot-scope="{ columns, fields }"
        v-if="filterRow"
      >
        <td :key="field.key" class="p-0" v-for="field in fields">
          <span v-if="field.key === 'actual_cost'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.actual_cost"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'asset'">
            <o-form-select
              :options="optionAssetItems"
              :loading="optionAssetWorking"
              @change="filter_changed"
              name="asset"
              sort-field="name"
              text-field="name"
              v-model="list_options.qbe.asset"
              value-field="id"
              ><template v-slot:first>
                <option :value="null"></option>
              </template>
            </o-form-select>
          </span>
          <span v-if="field.key === 'comments'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.comments"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'created_date'">
            <o-flatpickr-wrapper
              @change="filter_changed"
              class="rounded-0 px-0"
              no-buttons
              v-model="list_options.qbe.created_date"
            ></o-flatpickr-wrapper>
          </span>
          <span v-if="field.key === 'date_completed'">
            <o-flatpickr-wrapper
              @change="filter_changed"
              class="rounded-0 px-0"
              no-buttons
              v-model="list_options.qbe.date_completed"
            ></o-flatpickr-wrapper>
          </span>
          <span v-if="field.key === 'description'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.description"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'employee'">
            <o-form-select
              :options="optionEmployeeItems"
              :loading="optionEmployeeWorking"
              @change="filter_changed"
              name="employee"
              sort-field="full_name"
              text-field="full_name"
              v-model="list_options.qbe.employee"
              value-field="id"
              ><template v-slot:first>
                <option :value="null"></option>
              </template>
            </o-form-select>
          </span>
          <span v-if="field.key === 'labour_costs'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.labour_costs"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'labour_minutes'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.labour_minutes"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'maintenance_date'">
            <o-flatpickr-wrapper
              @change="filter_changed"
              class="rounded-0 px-0"
              no-buttons
              v-model="list_options.qbe.maintenance_date"
            ></o-flatpickr-wrapper>
          </span>
          <span v-if="field.key === 'modified_date'">
            <o-flatpickr-wrapper
              @change="filter_changed"
              class="rounded-0 px-0"
              no-buttons
              v-model="list_options.qbe.modified_date"
            ></o-flatpickr-wrapper>
          </span>
          <span v-if="field.key === 'original_id'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.original_id"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'parts_cost'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.parts_cost"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'parts_replaced'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.parts_replaced"
            ></b-form-input>
          </span>
        </td>
      </template>
      <template slot="cell(actual_cost)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'maintenance_record.edit', params: { id: item.id } }"
          :when="first_column_key === 'actual_cost'"
        >
          <number-display :decimals="2" :value="item.actual_cost" />
        </o-link-cell>
      </template>
      <template slot="cell(asset)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'maintenance_record.edit', params: { id: item.id } }"
          :when="first_column_key === 'asset'"
        >
          <lookup-display
            :list="optionAssetItemsById"
            :value="item.asset"
            default-value="-"
            display-field="name"
          ></lookup-display>
        </o-link-cell>
      </template>
      <template slot="cell(comments)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'maintenance_record.edit', params: { id: item.id } }"
          :when="first_column_key === 'comments'"
        >
          <default-display :value="item.comments"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(created_date)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'maintenance_record.edit', params: { id: item.id } }"
          :when="first_column_key === 'created_date'"
        >
          <date-display
            :value="item.created_date"
            format="DD/MM/YYYY hh:mm"
          ></date-display>
        </o-link-cell>
      </template>
      <template slot="cell(date_completed)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'maintenance_record.edit', params: { id: item.id } }"
          :when="first_column_key === 'date_completed'"
        >
          <date-display
            :value="item.date_completed"
            format="DD/MM/YYYY hh:mm"
          ></date-display>
        </o-link-cell>
      </template>
      <template slot="cell(description)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'maintenance_record.edit', params: { id: item.id } }"
          :when="first_column_key === 'description'"
        >
          <default-display :value="item.description"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(employee)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'maintenance_record.edit', params: { id: item.id } }"
          :when="first_column_key === 'employee'"
        >
          <lookup-display
            :list="optionEmployeeItemsById"
            :value="item.employee"
            default-value="-"
            display-field="full_name"
          ></lookup-display>
        </o-link-cell>
      </template>
      <template slot="cell(labour_costs)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'maintenance_record.edit', params: { id: item.id } }"
          :when="first_column_key === 'labour_costs'"
        >
          <number-display :decimals="2" :value="item.labour_costs" />
        </o-link-cell>
      </template>
      <template slot="cell(labour_minutes)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'maintenance_record.edit', params: { id: item.id } }"
          :when="first_column_key === 'labour_minutes'"
        >
          <number-display :decimals="0" :value="item.labour_minutes" />
        </o-link-cell>
      </template>
      <template slot="cell(maintenance_date)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'maintenance_record.edit', params: { id: item.id } }"
          :when="first_column_key === 'maintenance_date'"
        >
          <date-display
            :value="item.maintenance_date"
            format="DD/MM/YYYY hh:mm"
          ></date-display>
        </o-link-cell>
      </template>
      <template slot="cell(modified_date)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'maintenance_record.edit', params: { id: item.id } }"
          :when="first_column_key === 'modified_date'"
        >
          <date-display
            :value="item.modified_date"
            format="DD/MM/YYYY hh:mm"
          ></date-display>
        </o-link-cell>
      </template>
      <template slot="cell(original_id)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'maintenance_record.edit', params: { id: item.id } }"
          :when="first_column_key === 'original_id'"
        >
          <number-display :decimals="0" :value="item.original_id" />
        </o-link-cell>
      </template>
      <template slot="cell(parts_cost)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'maintenance_record.edit', params: { id: item.id } }"
          :when="first_column_key === 'parts_cost'"
        >
          <number-display :decimals="2" :value="item.parts_cost" />
        </o-link-cell>
      </template>
      <template slot="cell(parts_replaced)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'maintenance_record.edit', params: { id: item.id } }"
          :when="first_column_key === 'parts_replaced'"
        >
          <default-display :value="item.parts_replaced"></default-display>
        </o-link-cell>
      </template>

      <template slot="empty">
        <p>
          <emoticon-sad-icon large></emoticon-sad-icon>
        </p>
        No Data Found. Please
        <router-link :to="{ name: 'maintenance_record.create' }"
          >create a new item</router-link
        >
      </template>
    </b-table>

    <o-list-paging
      :limit="list_options.limit"
      :offset="list_options.offset"
      :page-options="rows_per_page_items"
      :total-items="total_items"
      @change-limit="change_limit"
      @change-page="change_page"
    />
  </div>
</template>

<style></style>

<script>
import {
  CreateGroupPermissionMixin,
  CreateListPageMixin,
  MultiActionsMixin,
} from "../../../vue-ocom/mixins";
import FetchAndUseCodeModMixin from "@/vue-ocom/mixins/FetchAndUseCodeModMixin";
import {
  fieldToTableColumn,
  GetSearchFields,
} from "../../../vue-ocom/schema/schema_utils";
import { cleanUpModel } from "../../../vue-ocom/schema/schema_vuetify";
import schema from "../../schema/index";

import OListToolbar from "../../../vue-ocom/components/list/OListToolbar";
import OListPaging from "../../../vue-ocom/components/list/OListPaging";
import DefaultDisplay from "../../../vue-ocom/components/display/DefaultDisplay";
import DateDisplay from "../../../vue-ocom/components/display/DateDisplay";
import NumberDisplay from "../../../vue-ocom/components/display/NumberDisplay";
import BooleanDisplay from "../../../vue-ocom/components/display/BooleanDisplay";
import LookupDisplay from "../../../vue-ocom/components/display/LookupDisplay";
import EmoticonSadIcon from "vue-material-design-icons/EmoticonSad";
import EmailIcon from "vue-material-design-icons/Email";
import LinkIcon from "vue-material-design-icons/Link";

export default {
  components: {
    EmoticonSadIcon,
    EmailIcon,
    LinkIcon,
    OListPaging,
    OListToolbar,
    DateDisplay,
    DefaultDisplay,
    BooleanDisplay,
    NumberDisplay,
    LookupDisplay,
  },
  mixins: [
    CreateListPageMixin("MaintenanceRecord", "maintenance_record"),
    MultiActionsMixin,
    CreateGroupPermissionMixin("MaintenanceRecord"),
    FetchAndUseCodeModMixin(["Employee", "Asset"]),
  ],
  data: function () {
    return {
      schemaModel: cleanUpModel(
        schema.MaintenanceRecord,
        {}
      ) /*Use this.schema instead */,

      search_field_list: GetSearchFields(schema.MaintenanceRecord),

      filterRow: true /* Set to False to disable filter row */,
      filter_list: [
        // This model doesn't have the active_start_date and active_end_date
        // So the Active and Inactive filters wont work.

        /*  {'name': 'Active', 'code': 'active', options: {offset: 0, sort: 'id'}},
          {'name': 'Non Active', 'code': 'inactive', options: {offset: 0, sort: 'id'}}, */
        { name: "All", code: "all", options: { offset: 0, sort: "id" } },
      ],

      user_column_selection: null /* If user can customise the columns set them here */,
      column_override_params: {
        /* Put any column overrides here key by fieldname */
      },
    };
  },
  computed: {
    options() {
      return {
        Employee: {
          options: this.optionEmployeeItems,
          textField: "full_name",
          valueField: "id",
        },
        Asset: {
          options: this.optionAssetItems,
          textField: "name",
          valueField: "id",
        },
      };
    },
    fields() {
      const vm = this;
      const selectedColumns = this.user_column_selection || [
        "maintenance_date",
        "description",
        "date_completed",
        "employee",
        "asset",
      ];
      const fields = selectedColumns.map(function (item) {
        return fieldToTableColumn(
          vm.schema,
          item,
          vm.column_override_params[item]
        );
      });

      return this.addSelectionColums(
        this.filterTableColumns(this.schema, fields)
      );
    },
    first_column_key() {
      if (this.fields) {
        return this.fields[0].key;
      } else {
        return null;
      }
    },
  },
};
</script>
