<template>
    <div>
        <span v-if="!isLoggedIn"
            ><LoginPage :dont-redirect="true"></LoginPage
        ></span>
        <span v-if="!canAccessThisRoute">
            <BAlert show variant="danger">
                <h1>Don't have access to this page.</h1></BAlert
            >
        </span>

        <BAlert
            v-if="checkPageAccess && checkPageAccess.deny"
            show
            variant="danger"
            >{{ checkPageAccess.message }}</BAlert
        >

        <span :key="canSeePage" v-show="canSeePage">
            <BAlert
                v-if="checkPageAccess && !checkPageAccess.deny"
                show
                variant="warning"
                >{{ checkPageAccess.message }}</BAlert
            >

            <slot></slot>
        </span>
    </div>
</template>

<script>
import { BAlert } from "bootstrap-vue";
import { mapGetters } from "vuex";
import LoginPage from "./LoginPage.vue";
import { canAccessState } from "../../grouppermissions/utils.js";
import * as CONFIG from "../../../config.js";

export default {
    components: {
        BAlert,
        LoginPage,
    },

    data() {
        return {};
    },
    computed: {
        ...mapGetters("AuthModule", [
            "isLoggedIn",
            "permissions",
            "use_permissions",
            "currentUser",
        ]),
        checkPageAccess() {
            if ("CheckPageAccess" in CONFIG) {
                var CheckPageAccess = CONFIG["CheckPageAccess"];

                var result = CheckPageAccess(this.$route, this.currentUser);

                return result;
            }
            return null; // All OK
        },
        canSeePage() {
            if (!this.isLoggedIn) {
                return false;
            }
            return this.canAccessThisRoute;
        },
        canAccessThisRoute() {
            if (this.checkPageAccess != null) {
                if (this.checkPageAccess.deny) {
                    return false; // No entry from CheckPageAccess
                }
            }

            if (!this.use_permissions) {
                return true;
            }

            if (this.permissions && this.$router.currentRoute.name) {
                return canAccessState(
                    this.permissions,
                    this.$router.currentRoute.name,
                );
            }
            return false; // no entry
        },
    },
    methods: {},
};
</script>
