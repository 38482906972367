import Vue from "vue";
import { CancelToken } from "axios";
import _ from "lodash";
import * as CONFIG from "@/config.js";

export const MUTATION_TYPES = {
    SET_ITEM: "SET_ITEM",
    START_WORKING: "START_WORKING",
    FINISH_WORKING: "FINISH_WORKING",
    SET_PROGRESS: "SET_PROGRESS",
};

export const CreateFileSystemModule = function (root_path) {
    root_path = root_path || "/filesystem";

    const GET_BY_ID_URL = CONFIG.root_url + root_path + "/file_meta_data/";
    const UPLOAD_URL = CONFIG.root_url + root_path + "/upload";

    const UPDATE_URL = CONFIG.root_url + root_path + "/file_meta_data/";

    return {
        namespaced: true,
        state: {
            item: null,
            working: false,

            progress_uploaded: 0,
            progress_total: 0,
            progress_percentage: 0,

            cancel_token: null,
        },
        persist_reducer(state) {
            return _.pick(state, []); // nothing makes sense here
        },
        mutations: {
            [MUTATION_TYPES.SET_ITEM](state, item) {
                state.item = item;
            },
            [MUTATION_TYPES.START_WORKING](state, cancelToken) {
                state.progress_uploaded = 0;
                state.progress_total = 0;
                state.progress_percentage = 0;
                state.working = true;
                state.cancel_token = cancelToken;
            },
            [MUTATION_TYPES.FINISH_WORKING](state) {
                state.progress_uploaded = state.progress_total;
                state.progress_percentage = 100;
                state.working = false;
            },
            [MUTATION_TYPES.SET_PROGRESS](state, progress) {
                state.progress_uploaded = progress.loaded;
                state.progress_total = progress.total;

                state.progress_percentage = Math.round(
                    (progress.loaded * 100) / progress.total,
                );
            },
        },
        actions: {
            getById({ rootGetters, commit }, id) {
                return new Promise((resolve, reject) => {
                    if (rootGetters["AuthModule/isLoggedIn"]) {
                        commit(MUTATION_TYPES.START_WORKING);

                        console.log("Get By ID", id);

                        Vue.axios
                            .get(GET_BY_ID_URL + id + "/", {})
                            .then((response) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                commit(MUTATION_TYPES.SET_ITEM, response.data);
                                resolve(response.data);
                            })
                            .catch((err) => {
                                commit(MUTATION_TYPES.FINISH_WORKING);

                                reject(err);
                            });
                    } else {
                        reject("NOT LOGGED IN");
                    }
                });
            },
            upload({ commit }, item) {
                const cancel_token_source = CancelToken.source();

                return new Promise((resolve, reject) => {
                    commit(MUTATION_TYPES.START_WORKING, cancel_token_source);

                    var method = "post";
                    var url = UPLOAD_URL;

                    if (item.id) {
                        // Update the Item
                        method = "put";
                        url = UPDATE_URL + item.id + "/";
                    }
                    var config = {
                        onUploadProgress: function (progressEvent) {
                            commit(MUTATION_TYPES.SET_PROGRESS, progressEvent);
                        },
                    };

                    let formData = new FormData();
                    _.each(item, function (val, key) {
                        formData.append(key, val);
                    });

                    Vue.axios
                        .request({
                            url: url,
                            method: method,
                            data: formData,
                            onUploadProgress: config.onUploadProgress,
                            cancelToken: cancel_token_source.token,
                        })
                        .then((response) => {
                            commit(MUTATION_TYPES.FINISH_WORKING);

                            if (response.data.error === true) {
                                reject(response.data.message);
                            } else {
                                commit(MUTATION_TYPES.SET_ITEM, response.data);

                                resolve(response.data);
                            }
                        })
                        .catch((err) => {
                            commit(MUTATION_TYPES.FINISH_WORKING);

                            reject(err);
                        });
                });
            },
            cancel({ commit }) {
                if (this.state.cancel_token != null) {
                    this.state.cancel_token.cancel("Cancelled by User");

                    commit(MUTATION_TYPES.FINISH_WORKING);
                }
            },
            getAll() {
                //DO nothing
            },
        },
        getters: {
            item(state) {
                return state.item;
            },
            working(state) {
                return state.working;
            },
            progress_percentage(state) {
                return state.progress_percentage;
            },
        },
    };
};
