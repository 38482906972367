import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import { AddModuleUsed } from "./globals";
import * as CONFIG from "@/config.js";

/* Create mapActions and mapGetters for a RestModule (and similar modules)
for Code Modules. And loads them!

moduleList is an array of Module names but assume moduleList has the value
['Test']

Then the following actions and getters are created

mapActions({
    getAllTest: 'TestModule/getAll', //called by loadCodeTables and when created
});

mapGetters({
    optionTestWorking: 'TestModule/working',
});

If you Want to "Use" the data from these modules then also mixin "UseCodeModulesMixin"
 */

export default function (moduleList, list_options) {
    var fetchActionList = {};
    var getterList = {};

    var defaultListOptions = list_options || {
        limit: 2000,
        offset: 0,
        _code: true,
    };

    // Ignore duplicates added by Builder.
    moduleList = _.uniq(moduleList);

    _.each(moduleList, function (modName) {
        if (CONFIG.Debug) {
            AddModuleUsed(modName);
        }

        fetchActionList["getAll" + modName] = modName + "Module/getAll";

        getterList["option" + modName + "Working"] = modName + "Module/working";
    });

    /* With a Module name of "Customer" create a Method GetCustomerCodeList(list_options)
       That can be called to load the code options
     */

    var getCodeTablesMethods = {};
    _.each(moduleList, function (moduleName) {
        var function_name = "Get" + moduleName + "CodeList";

        getCodeTablesMethods[function_name] = function (list_options) {
            var vm = this;

            list_options = {
                _code: true /* this could be overwritten below*/,
                ...defaultListOptions,
                ...list_options,
            }; // Get 2000 items - should be all if not we need to use another method
            var onBeforeFilterList = vm["OnBefore" + function_name];
            var onAfterFilterList = vm["OnAfter" + function_name];

            if (onBeforeFilterList) onBeforeFilterList(list_options);
            vm["getAll" + moduleName]({ ...list_options }).then(
                function (results) {
                    if (onAfterFilterList) {
                        onAfterFilterList(results);
                    }
                },
            );
        };
    });

    return {
        methods: {
            ...getCodeTablesMethods,
            OnGetAll(moduleName) {
                if (!moduleName || moduleName === "all") {
                    this.loadCodeTables(); // Get all off them
                } else {
                    const funcFound = this["Get" + moduleName + "CodeList"];
                    if (funcFound) {
                        funcFound();
                    }
                }
            },
            loadCodeTables() {
                const vm = this;

                // Load all
                _.each(moduleList, function (moduleName) {
                    vm["Get" + moduleName + "CodeList"]();
                });
            },
            ...mapActions({ ...fetchActionList }),
        },
        computed: {
            ...mapGetters(getterList),
        },
        created() {
            this.loadCodeTables();
        },
    };
}
