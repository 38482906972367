<template>
    <a :href="mapsURL" target="_blank">{{ display_value }}</a>
</template>

<script>
function truncate(n) {
    return n > 0 ? Math.floor(n) : Math.ceil(n);
}

export default {
    name: "GpsDisplay",
    props: {
        latitude: { default: null },
        longitude: { default: null },
    },
    computed: {
        display_value() {
            if (this.latitude === null || this.longitude === null) {
                return "Unknown"; // nothing
            }

            return (
                this.getDMS(this.latitude, "lat") +
                ", " +
                this.getDMS(this.longitude, "lon")
            );
        },
        mapsURL() {
            if (this.latitude === null || this.longitude === null) {
                return null; // nothing
            }

            return `https://www.google.com/maps/search/?api=1&query=${this.latitude},${this.longitude}&zoom=15`;
        },
    },
    methods: {
        // from https://teknosrc.com/location-latitude-longitude-lat-lon-degrees-minutes-seconds-dms-conversion-javascript/
        getDMS(dd, longOrLat) {
            if (dd == null) {
                return "";
            }

            let hemisphere = /^[WE]|(?:lon)/i.test(longOrLat)
                ? dd < 0
                    ? "W"
                    : "E"
                : dd < 0
                ? "S"
                : "N";

            const absDD = Math.abs(dd);
            const degrees = truncate(absDD);
            const minutes = truncate((absDD - degrees) * 60);
            const seconds = (
                (absDD - degrees - minutes / 60) *
                Math.pow(60, 2)
            ).toFixed(2);

            let dmsArray = [degrees, minutes, seconds, hemisphere];
            return `${dmsArray[0]}°${dmsArray[1]}'${dmsArray[2]}" ${dmsArray[3]}`;
        },
    },
};
</script>

<style scoped></style>
