import RestModule from "../store/RestModule.js";
import schema from "./schema/index.js";

import * as CONFIG from "../../config.js";

var root_url = CONFIG.root_url;

const GroupStateModule = RestModule(
    root_url + "/api/ocom/group_state/",
    schema.GroupState
);
const GroupStateModelFieldModule = RestModule(
    root_url + "/api/ocom/group_state_model_field/",
    schema.GroupStateModelField
);
const GroupPermissionsModule = RestModule(
    root_url + "/api/ocom/group_permissions/",
    schema.GroupPermissions
);
const GroupStateModelModule = RestModule(
    root_url + "/api/ocom/group_state_model/",
    schema.GroupStateModel
);
const PermissionModule = RestModule(
    root_url + "/api/ocom/permission/",
    schema.Permission
);
const GroupModule = RestModule(root_url + "/api/ocom/group/", schema.Group);
const UserModule = RestModule(root_url + "/api/ocom/user/", schema.User);
const UserAccountModule = RestModule(
    root_url + "/api/ocom/user_account/",
    schema.User
);
const ContentTypeModule = RestModule(
    root_url + "/api/ocom/content_type/",
    schema.ContentType
);

export default {
    GroupStateModule,
    GroupStateModelFieldModule,
    GroupPermissionsModule,
    GroupStateModelModule,
    PermissionModule,
    GroupModule,
    UserModule,
    UserAccountModule,
    ContentTypeModule,
    //Add more modules here
};
