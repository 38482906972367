import EmployeeList from "./pages/ListPage.vue";
import EmployeeDetail from "./pages/FormPage.vue";
import EmployeeIndex from "./pages/IndexPage.vue";

export default [
  {
    path: "/employee",
    component: EmployeeIndex,
    redirect: "/employee/list",
    name: "employee",
    children: [
      {
        path: "list",
        component: EmployeeList,
        name: "employee.list",
      },
      {
        path: "create",
        component: EmployeeDetail,
        name: "employee.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: EmployeeDetail,
        name: "employee.edit",
        props: { create: false },
      },
    ],
  },
];
