<template>
    <div class="overlay" v-if="working">
        <div class="text">{{ text }}</div>
    </div>
</template>

<script>
export default {
    name: "OWorkingOverlay",
    props: {
        working: { type: Boolean, default: false },
        text: { type: String, default: "Working..." },
    },
};
</script>

<style scoped>
.overlay {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
}

.overlay .text {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 50px;
    color: white;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}
</style>
