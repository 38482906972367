<template>
    <LoginGuard>
        <BTabs content-class="mt-3">
            <BTab title="User Groups Report" active>
                <table class="table table-condensed table-hover">
                    <thead class="thead-dark">
                        <tr>
                            <th>User Name</th>
                            <th v-for="grp in optionGroupItems" :key="grp.id">
                                {{ grp.name }}
                            </th>
                        </tr>
                    </thead>
                    <tr>
                        <td></td>
                        <td v-for="grp in optionGroupItems" :key="grp.id">
                            <input type="checkbox" v-model="filter[grp.id]" />
                        </td>
                    </tr>
                    <tr v-for="user in filtered_user_list" :key="user.username">
                        <td>
                            <strong
                                >{{ user.first_name }}
                                {{ user.last_name }}</strong
                            >
                            &nbsp;
                            <small>{{ user.username }}</small>
                        </td>
                        <td v-for="grp in optionGroupItems" :key="grp.id">
                            {{ checkUser(grp.id, user.groups) }}
                        </td>
                    </tr>
                </table>
            </BTab>
            <BTab title="Permission Dump">
                <h1>Permission Reports</h1>
                <BFormSelect
                    :options="gp_list"
                    value-field="id"
                    text-field="group_name"
                    v-model="selected_group_permission"
                ></BFormSelect>
                <br />

                <BButton
                    variant="danger"
                    block
                    disabled
                    v-if="current_gp && current_gp.deny_by_default"
                    >Deny All By Default</BButton
                >
                <table class="table table-hover" v-if="user && current_gp">
                    <thead class="thead-dark">
                        <tr>
                            <th>State</th>
                            <th class="text-center">Result</th>
                        </tr>
                    </thead>
                    <tr v-for="s in current_gp.states" :key="s.id">
                        <td>{{ s.state_name }}</td>
                        <td>
                            <span v-if="s.deny" class="text-danger">Deny</span>
                            <span v-if="!s.deny" class="text-success"
                                >Allow</span
                            >
                        </td>
                    </tr>
                </table>
            </BTab>

            <BTab title="User Permissions">
                <h1>Permission Reports</h1>
                <BFormSelect
                    :options="user_list"
                    value-field="id"
                    text-field="username"
                    v-model="user"
                ></BFormSelect>
                <br />

                <table class="table table-hover" v-if="user">
                    <thead class="thead-dark">
                        <tr>
                            <th>Role</th>
                            <th
                                v-for="g in current_user.groups"
                                :key="g"
                                class="text-center"
                            >
                                {{ optionGroupItemsById[g].name }}
                            </th>
                            <th class="text-center">Result</th>
                        </tr>
                    </thead>
                    <tr v-for="r in routes" :key="r.id">
                        <td>{{ r.description }}</td>
                        <td
                            v-for="g in current_user.groups"
                            :key="g"
                            :class="get_perm(g, r.id).class"
                        >
                            {{ get_perm(g, r.id).label }}
                        </td>
                        <td :class="get_result(r.id).class">
                            <strong>{{ get_result(r.id).label }}</strong>
                        </td>
                    </tr>
                </table>
            </BTab>
        </BTabs>
    </LoginGuard>
</template>

<script>
import { BButton, BFormSelect, BTab, BTabs } from "bootstrap-vue";
// this is the Home/index of Brokers
import LoginGuard from "../../../auth/components/LoginGuard.vue";
import { CreateCodeTableFetchMixin } from "@/vue-ocom/mixins";
import _ from "lodash";

export default {
    mixins: [CreateCodeTableFetchMixin(["GroupPermissions", "Group", "User"])],
    components: {
        BTabs,
        BTab,
        BFormSelect,
        BButton,
        LoginGuard,
    },
    data() {
        return {
            user: 1,
            perm_table: {
                null: { label: " - ", class: "bg-white text-center" },
                true: { label: "DENY", class: "bg-danger text-center" },
                false: { label: "ALLOW ", class: "bg-success text-center" },
            },
            perm_table_deny: {
                label: "Deny (By Default) ",
                class: "bg-warning text-center",
            },
            filter: {},
            selected_group_permission: null,
        };
    },
    methods: {
        get_result(route_name) {
            var vm = this;
            22;
            var deny_by_default = false;
            var result = null;

            _.forEach(this.perms, function (g, g_id) {
                if (g.deny_by_default) {
                    deny_by_default = deny_by_default || g.deny_by_default;
                }

                var state = g.states[route_name];
                if (state) {
                    if (result == null) {
                        result = state.deny;
                    } else {
                        result = result || state.deny;
                    }
                }
            });
            if (deny_by_default && result == null) {
                return this.perm_table_deny;
            }
            return this.perm_table[result];
        },
        get_perm(group_id, route_name) {
            var group = this.perms[group_id];
            var result = null;
            if (group) {
                var state = group.states[route_name];

                if (state) {
                    result = state.deny;
                }
                if (result == null && group.deny_by_default) {
                    return this.perm_table_deny;
                }
            }

            return this.perm_table[result];
        },
        checkUser(group_id, user_groups) {
            if (user_groups.includes(group_id)) {
                return "Y";
            } else {
                ("");
            }
        },
    },
    computed: {
        gp_list() {
            const vm = this;
            var result = _.map(this.optionGroupPermissionsItems, function (gp) {
                var group = vm.optionGroupItemsById[gp.group];
                if (group) {
                    return { group_name: group.name, ...gp };
                } else {
                    return { group_name: gp.id, ...gp };
                }
            });
            return result;
        },
        current_gp() {
            return this.optionGroupPermissionsItemsById[
                this.selected_group_permission
            ];
        },
        current_user() {
            return this.optionUserItemsById[this.user];
        },
        group_permissions_by_group() {
            return _.keyBy(this.optionGroupPermissionsItems, "group");
        },
        perms() {
            const vm = this;

            var result = {};

            if (this.current_user) {
                _.forEach(this.current_user.groups, function (g_id) {
                    var gp = vm.group_permissions_by_group[g_id];
                    if (gp) {
                        result[g_id] = {
                            deny_by_default: gp.deny_by_default,
                            states: {},
                        };

                        _.forEach(gp.states, function (state) {
                            result[g_id].states[state.state_name] = {
                                deny: state.deny,
                            };
                        });
                    }
                });
            }

            return result;
        },
        user_list() {
            _.forEach(this.optionUserItems, function (o) {
                o.full_name = o.first_name + " " + o.last_name;
                if (o.full_name.trim() === "") {
                    o.full_name = o.email;
                }
            });

            return _.sortBy(this.optionUserItems, "full_name");
        },
        filtered_user_list() {
            const vm = this;

            var hasFilter = false;
            _.forEach(this.filter, function (do_filter) {
                hasFilter = hasFilter || do_filter;
            });

            if (hasFilter) {
                return _.filter(this.user_list, function (u) {
                    var result = false;

                    _.forEach(u.groups, function (gp_id) {
                        if (vm.filter[gp_id]) {
                            result = true;
                        }
                    });

                    return result;
                });
            } else {
                return this.user_list;
            }
        },
        routes() {
            var result = [];

            // Collect the Menu Items and the titles
            var menuItems = {};
            if (this.MENU_ITEMS) {
                _.forEach(this.MENU_ITEMS, function (section) {
                    if (section && section.children) {
                        _.forEach(section.children, function (child) {
                            if (child.to && child.to.name) {
                                menuItems[child.to.name] = child.title;
                            }
                        });
                    }
                });
            }

            function processRoutes(routes) {
                _.forEach(routes, function (route) {
                    if (route.name) {
                        var title = menuItems[route.name];

                        var description = route.name; // default to route.name
                        if (title) {
                            // use this for SELECT text
                            description = route.name + " - " + title;
                        }

                        var indent = (route.name.match(/\./g) || []).length;
                        if (indent) {
                            var prefix = "\xa0".repeat(indent * 4);

                            description = prefix + description;
                            if (title) {
                                title = prefix + title;
                            }
                        }

                        result.push({
                            id: route.name,
                            description: description,
                            title: title,
                        });
                    }

                    if (route.children) {
                        processRoutes(route.children);
                    }
                });
            }

            processRoutes(this.$router.options.routes);

            result = _.uniqBy(_.sortBy(result, "id"), "id");

            return result;
        },
    },
};
</script>
