import { Field, FIELD_TYPES, Model } from "../../vue-ocom/schema";

export default Model("code_asset_test_status", {
  verbose_name: "Asset Test Status",
  verbose_name_plural: "Asset Test Statues",
  fields: [
    Field("id", {
      label: "ID",
      primary_key: true,
      readonly: true,
      hide: false,
      field_type: FIELD_TYPES.AutoNumber,
    }),

    Field("original_id", {
      label: "Original ID",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      },
    }),
    Field("modified_date", {
      label: "Modified Date",
      primary_key: false,
      readonly: true,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("created_date", {
      label: "Created Date",
      primary_key: false,
      readonly: true,
      hide: false,
      required: true,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("active_start_date", {
      label: "Active Start Date",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return new Date();
      },
    }),
    Field("active_end_date", {
      label: "Active End Date",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Date,
      default: () => {
        return null;
      },
    }),
    Field("code", {
      label: "Code",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("description", {
      label: "Description/Name",
      primary_key: false,
      readonly: false,
      hide: false,
      required: true,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("color", {
      label: "Color",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.String,
      default: () => {
        return null;
      },
    }),
    Field("quick_display_order", {
      label: "Quick Display Order",
      primary_key: false,
      readonly: false,
      hide: false,
      required: false,
      field_type: FIELD_TYPES.Number,
      default: () => {
        return null;
      },
    }),
  ],
});
