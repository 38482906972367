import CodeModelNameList from "./pages/ListPage.vue";
import CodeModelNameDetail from "./pages/FormPage.vue";
import CodeModelNameIndex from "./pages/IndexPage.vue";

export default [
  {
    path: "/code_model_name",
    component: CodeModelNameIndex,
    redirect: "/code_model_name/list",
    name: "code_model_name",
    children: [
      {
        path: "list",
        component: CodeModelNameList,
        name: "code_model_name.list",
      },
      {
        path: "create",
        component: CodeModelNameDetail,
        name: "code_model_name.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: CodeModelNameDetail,
        name: "code_model_name.edit",
        props: { create: false },
      },
    ],
  },
];
