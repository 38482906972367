import CodeAssetStatusList from "./pages/ListPage.vue";
import CodeAssetStatusDetail from "./pages/FormPage.vue";
import CodeAssetStatusIndex from "./pages/IndexPage.vue";

export default [
  {
    path: "/code_asset_status",
    component: CodeAssetStatusIndex,
    redirect: "/code_asset_status/list",
    name: "code_asset_status",
    children: [
      {
        path: "list",
        component: CodeAssetStatusList,
        name: "code_asset_status.list",
      },
      {
        path: "create",
        component: CodeAssetStatusDetail,
        name: "code_asset_status.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: CodeAssetStatusDetail,
        name: "code_asset_status.edit",
        props: { create: false },
      },
    ],
  },
];
