const CodeRepairTypeList = () =>
  import(/* webpackChunkName: "app" */ "./pages/ListPage.vue");
const CodeRepairTypeDetail = () =>
  import(/* webpackChunkName: "app" */ "./pages/FormPage.vue");
const CodeRepairTypeIndex = () =>
  import(/* webpackChunkName: "app" */ "./pages/IndexPage.vue");

export default [
  {
    path: '/code_repair_type',
    component: CodeRepairTypeIndex,
    redirect: '/code_repair_type/list',
    name: 'code_repair_type',
    children: [
      {
        path: 'list',
        component: CodeRepairTypeList,
        name: 'code_repair_type.list'
      },
      {
        path: 'create',
        component: CodeRepairTypeDetail,
        name: 'code_repair_type.create',
        props: { create: true },
      },
      {
        path: 'edit/:id',
        component: CodeRepairTypeDetail,
        name: 'code_repair_type.edit',
        props: { create: false },
      },
    ],
  },
]