<template>
    <o-form-group
        :form-state="formState"
        :label="label"
        :message="errorMessages"
        :nolabel="nolabel"
        :type="{ 'is-danger': hasError }"
    >
        <vue-editor
            :class="input_class"
            v-bind="$attrs"
            v-bind:value="value"
            v-if="!_is_readonly"
            v-on="inputListeners"
        >
        </vue-editor>

        <span v-html="value" v-if="_is_readonly"></span>
    </o-form-group>
</template>

<script>
import FormComponentMixin from "../../../mixins/form_component_mixin.js";
import OFormGroup from "../OFormGroup.vue";
import { VueEditor } from "vue2-editor";

export default {
    name: "OHtmlArea",
    components: { OFormGroup, VueEditor },
    mixins: [FormComponentMixin],
    methods: {
        on_change(event) {
            console.log("OnChange - HTMLEditor");
        },
        on_input(event) {
            if (event !== this.value) {
                // Only if Actually Changed value that we 'change'

                this.makeDirty();
                if (this.validator) {
                    this.validator.$touch();
                }

                this.changed = true;
                this.$emit("reset");
                this.$emit("input", event);
            }
        },
    },
};
</script>

<style scoped></style>
