<template>
    <BButtonGroup>
        <BButton
            :variant="{ false: 'outline-dark', true: 'dark' }[btn.id == value]"
            v-for="btn in buttons"
            :key="btn.id"
            @click="click(btn.id)"
            :style="{ color: btn.color }"
            >{{ btn[textField] }}
        </BButton>

        <BButton
            v-if="other_buttons.length <= 0 && !disableMore && allowUnselect"
            variant="outline-secondary"
            @click="unselect()"
            :style="{ color: 'black' }"
            >Unselect
        </BButton>

        <BDropdown
            v-if="other_buttons.length > 0 && !disableMore"
            right
            text=""
            variant="outline-dark"
        >
            <BDropdownForm
                v-if="all_other_buttons.length > maxMoreItems"
                class="p-0"
            >
                <BFormInput
                    v-model="search"
                    autofocus
                    type="search"
                ></BFormInput>
            </BDropdownForm>
            <BDropdownItemButton
                v-for="o in other_buttons"
                :key="o.id"
                :active="o.id == value"
                @click="click(o.id)"
                ><span v-if="o.id == value">&#10003;</span>{{ o[textField] }}
            </BDropdownItemButton>
            <BDropdownDivider v-if="allowUnselect"></BDropdownDivider>
            <BDropdownItemButton @click="unselect()" v-if="allowUnselect"
                >Unselect
            </BDropdownItemButton>
        </BDropdown>
    </BButtonGroup>
</template>

<script>
import {
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownDivider,
    BDropdownForm,
    BDropdownItemButton,
    BFormInput,
} from "bootstrap-vue";
import _ from "lodash";
import { filter_active_list } from "@/vue-ocom/utils/active.js";

export default {
    components: {
        BButtonGroup,
        BButton,
        BDropdown,
        BDropdownForm,
        BFormInput,
        BDropdownItemButton,
        BDropdownDivider,
    },
    name: "OSpeedSelect",
    model: {
        prop: "value",
        event: "input",
    },
    data() {
        return {
            search: "",
        };
    },
    props: {
        validator: { type: Object, default: null },
        formState: { type: Object, default: null },
        items: { type: Array, default: null },
        value: { type: Number },
        textField: { type: String, default: "description" },
        quickDisplayOrderField: {
            type: String,
            default: "quick_display_order",
        },
        disableMore: { type: Boolean, default: false },
        maxMoreItems: { type: Number, default: 10 },
        allowUnselect: { type: Boolean, default: false },
    },
    methods: {
        unselect() {
            this.$emit("input", null);
        },
        click(btnID) {
            if (this.validator) {
                this.validator.$touch();
            }
            if (this.formState) this.formState.dirty = true;

            this.$emit("input", btnID);
        },
    },
    computed: {
        buttons() {
            const vm = this;

            return _.sortBy(
                _.filter(this.items, function (o) {
                    if (vm.quickDisplayOrderField) {
                        return !!o[vm.quickDisplayOrderField];
                    } else {
                        return true;
                    } // No quickDisplayOrderField so return all
                }),
                vm.quickDisplayOrderField,
            );
        },
        all_other_buttons() {
            const vm = this;

            return _.sortBy(
                _.filter(filter_active_list(this.items), function (o) {
                    if (vm.quickDisplayOrderField) {
                        return !o[vm.quickDisplayOrderField];
                    } else {
                        return false; // no extras
                    }
                }),
                this.textField,
            );
        },
        other_buttons() {
            var items = this.all_other_buttons;
            const vm = this;

            // Filter by search
            items = _.filter(items, function (o) {
                return o[vm.textField].includes(vm.search);
            });

            return _.slice(items, 0, vm.maxMoreItems);
        },
    },
};
</script>

<style scoped></style>
