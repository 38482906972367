//Main routes containing home and error pages
import ONotFound from "../vue-ocom/pages/ONotFound";
import Home from "./Home";

export default [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/404",
    name: "404",
    component: ONotFound,
  },
  {
    path: "*",
    redirect: { name: "404" },
  },
];
