import { openDatabaseWithSchema } from "../vue-ocom/schema/schema_indexeddb"; // Get access to the Database
import CreateReport from "../vue-ocom/reports/ReportsModule";
import schema from "./schema/index";
import CreateUserModule from "../vue-ocom/store/CreateUserModule";
import { FileSystemModule } from "../vue-ocom/filesystem/store";
import {
  CreateRestInMemoryModules,
  CreateRestModules,
} from "../vue-ocom/store/utils";
import GlobalStateModule from "../vue-ocom/store/GlobalStateModule";
import { is_item_active } from "../vue-ocom/utils/active";
import RestActionModule from "../vue-ocom/store/RestActionModule";
import RestModule from "../vue-ocom/store/RestModule.js";

const root_url = process.env.VUE_APP_ROOT_API;

const database = openDatabaseWithSchema(schema, "tts_tag");

export const cache_modules_list = {
  CodeAssetStatus: true,
  CodeAssetTestStatus: true,
  CodeColor: true,
  CodeCustomerStatus: true,
  CodeElectricalClass: true,
  CodeLocationType: true,
  CodePhase: true,
  CodeAssetClassification: true,
  CodeReplacementPartModule: true,
  CodeProductType: true,
  CodeTestFrequency: true,
  Customer: true,
  Employee: true,
  CodeTestCycleColor: true,
  CodeFileTypeModule: true,
};

const rest_modules_list = {
  Asset: true,
  AssetTest: true,
  AssetLocation: true,
  MaintenanceRecord: true,
  Product: true,
  CodeTestCycle: true,
  Testing: true,
  AssetFileModule: true,
  AssetTestFileModule: true,
  CodeRepairType: true,
  CodeMake: true,
  CodeModelName: true,
};

const UserModule = CreateUserModule(root_url);
// const CacheModules = CreateRestIndexedDBModules(schema, database, root_url, {},  cache_modules_list, rest_modules_list);
const CacheModules = CreateRestInMemoryModules(
  schema,
  root_url,
  {
    "*": {
      active: function (item) {
        return is_item_active(item, new Date());
      },
      inactive: function (item) {
        return !is_item_active(item, new Date());
      },
    },
  },
  cache_modules_list,
  rest_modules_list
);

const RestModules = CreateRestModules(
  schema,
  root_url,
  rest_modules_list,
  cache_modules_list
);

const ReportsModule = CreateReport(root_url);

//Add more modules here if needed. These will automatically be included and available in main store.

const AssetLocationManModule = RestModule(
  root_url + "/api/asset_location/",
  schema.AssetLocation
);

const APIActionModule = RestActionModule(root_url + "/api/");
const AssetTestCountModule = RestActionModule(
  root_url + "/api/asset_test_count/"
);

// Reset the Test Date to Today
GlobalStateModule.state.data.test_date = new Date().toISOString();

export default {
  ...CacheModules,
  ...RestModules,
  GlobalStateModule,
  UserModule,
  FileSystemModule,
  ReportsModule,
  AssetLocationManModule,
  //Add more modules here
  APIActionModule,
  AssetTestCountModule,
};
