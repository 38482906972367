<template>
  <div>
    <h3 @click="enableDebug($event)" class="display-4">Manage Customers</h3>

    <o-list-toolbar
      :filters-list="filter_list"
      :list-options="list_options"
      :new-to="{ name: 'customer.create', params: { id: 0 } }"
      :query-select-options="options"
      :schema="schema"
      :search-fields-list="search_field_list"
      :selected-filter="selected_filter"
      @filter_changed="update_filter($event)"
      @options_changed="update_table($event)"
      @reset_table="reset_table"
      new-button-text="New Customer"
    >
      <template slot="middle">
        <o-list-multi-actions
          :schema="schema"
          :selection="selected"
          @delete="doDeleteItems"
          @merge="doMergeItems"
          enable-delete
          enable-merge
        />
      </template>
    </o-list-toolbar>

    <b-table
      :fields="fields"
      :items="items"
      :sort-by="list_options.sort"
      :sort-desc="list_options.order === 'desc'"
      :selectable="selected.selectable"
      @row-selected="onRowSelected"
      @sort-changed="change_sort"
      bordered
      class="o-list"
      head-variant="light"
      no-local-sorting
      striped
      thead-class="o-gradient-header"
    >
      <template v-slot:cell(_selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
      <template
        slot="top-row"
        slot-scope="{ columns, fields }"
        v-if="filterRow"
      >
        <td :key="field.key" class="p-0" v-for="field in fields">
          <span v-if="field.key === 'active_end_date'">
            <o-flatpickr-wrapper
              @change="filter_changed"
              class="rounded-0 px-0"
              no-buttons
              v-model="list_options.qbe.active_end_date"
            ></o-flatpickr-wrapper>
          </span>
          <span v-if="field.key === 'active_start_date'">
            <o-flatpickr-wrapper
              @change="filter_changed"
              class="rounded-0 px-0"
              no-buttons
              v-model="list_options.qbe.active_start_date"
            ></o-flatpickr-wrapper>
          </span>
          <span v-if="field.key === 'address'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.address"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'billing_address'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.billing_address"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'city'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.city"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'company_code'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.company_code"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'company_name'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.company_name"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'contact_fax_no'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.contact_fax_no"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'contact_first_name'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.contact_first_name"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'contact_last_name'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.contact_last_name"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'contact_mobile_phone_no'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.contact_mobile_phone_no"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'contact_phone_no'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.contact_phone_no"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'contact_title'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.contact_title"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'created_date'">
            <o-flatpickr-wrapper
              @change="filter_changed"
              class="rounded-0 px-0"
              no-buttons
              v-model="list_options.qbe.created_date"
            ></o-flatpickr-wrapper>
          </span>
          <span v-if="field.key === 'customer_gone'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.customer_gone"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'customer_on_hold'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.customer_on_hold"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'division'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.division"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'email_address'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.email_address"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'fax_number'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.fax_number"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'last_code'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.last_code"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'logo'">
            <o-form-select
              :options="optionFileMetaDataItems"
              :loading="optionFileMetaDataWorking"
              @change="filter_changed"
              name="logo"
              sort-field="original_filename"
              text-field="original_filename"
              v-model="list_options.qbe.logo"
              value-field="id"
              ><template v-slot:first>
                <option :value="null"></option>
              </template>
            </o-form-select>
          </span>
          <span v-if="field.key === 'modified_date'">
            <o-flatpickr-wrapper
              @change="filter_changed"
              class="rounded-0 px-0"
              no-buttons
              v-model="list_options.qbe.modified_date"
            ></o-flatpickr-wrapper>
          </span>
          <span v-if="field.key === 'notes'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.notes"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'notes_to_show'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.notes_to_show"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'notified'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.notified"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'original_id'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.original_id"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'other_contacts'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.other_contacts"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'phone_number'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.phone_number"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'postal_code'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.postal_code"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'state'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.state"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'status'">
            <o-form-select
              :options="optionCodeCustomerStatusItems"
              :loading="optionCodeCustomerStatusWorking"
              @change="filter_changed"
              name="status"
              sort-field="description"
              text-field="description"
              v-model="list_options.qbe.status"
              value-field="id"
              ><template v-slot:first>
                <option :value="null"></option>
              </template>
            </o-form-select>
          </span>
          <span v-if="field.key === 'web_address'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.web_address"
            ></b-form-input>
          </span>
        </td>
      </template>
      <template slot="cell(active_end_date)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'active_end_date'"
        >
          <date-display
            :value="item.active_end_date"
            format="DD/MM/YYYY hh:mm"
          ></date-display>
        </o-link-cell>
      </template>
      <template slot="cell(active_start_date)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'active_start_date'"
        >
          <date-display
            :value="item.active_start_date"
            format="DD/MM/YYYY hh:mm"
          ></date-display>
        </o-link-cell>
      </template>
      <template slot="cell(address)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'address'"
        >
          <default-display :value="item.address"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(billing_address)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'billing_address'"
        >
          <default-display :value="item.billing_address"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(city)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'city'"
        >
          <default-display :value="item.city"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(company_code)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'company_code'"
        >
          <default-display :value="item.company_code"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(company_name)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'company_name'"
        >
          <default-display :value="item.company_name"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(contact_fax_no)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'contact_fax_no'"
        >
          <default-display :value="item.contact_fax_no"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(contact_first_name)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'contact_first_name'"
        >
          <default-display :value="item.contact_first_name"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(contact_last_name)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'contact_last_name'"
        >
          <default-display :value="item.contact_last_name"></default-display>
        </o-link-cell>
      </template>
      <template
        slot="cell(contact_mobile_phone_no)"
        slot-scope="{ index, item }"
      >
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'contact_mobile_phone_no'"
        >
          <default-display
            :value="item.contact_mobile_phone_no"
          ></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(contact_phone_no)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'contact_phone_no'"
        >
          <default-display :value="item.contact_phone_no"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(contact_title)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'contact_title'"
        >
          <default-display :value="item.contact_title"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(created_date)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'created_date'"
        >
          <date-display
            :value="item.created_date"
            format="DD/MM/YYYY hh:mm"
          ></date-display>
        </o-link-cell>
      </template>
      <template slot="cell(customer_gone)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'customer_gone'"
        >
          <boolean-display :value="item.customer_gone"></boolean-display>
        </o-link-cell>
      </template>
      <template slot="cell(customer_on_hold)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'customer_on_hold'"
        >
          <boolean-display :value="item.customer_on_hold"></boolean-display>
        </o-link-cell>
      </template>
      <template slot="cell(division)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'division'"
        >
          <default-display :value="item.division"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(email_address)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'email_address'"
        >
          <default-display :value="item.email_address"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(fax_number)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'fax_number'"
        >
          <default-display :value="item.fax_number"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(last_code)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'last_code'"
        >
          <number-display :decimals="0" :value="item.last_code" />
        </o-link-cell>
      </template>
      <template slot="cell(logo)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'logo'"
        >
          <lookup-display
            :list="optionFileMetaDataItemsById"
            :value="item.logo"
            default-value="-"
            display-field="original_filename"
          ></lookup-display>
        </o-link-cell>
      </template>
      <template slot="cell(modified_date)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'modified_date'"
        >
          <date-display
            :value="item.modified_date"
            format="DD/MM/YYYY hh:mm"
          ></date-display>
        </o-link-cell>
      </template>
      <template slot="cell(notes)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'notes'"
        >
          <default-display :value="item.notes"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(notes_to_show)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'notes_to_show'"
        >
          <default-display :value="item.notes_to_show"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(notified)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'notified'"
        >
          <boolean-display :value="item.notified"></boolean-display>
        </o-link-cell>
      </template>
      <template slot="cell(original_id)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'original_id'"
        >
          <number-display :decimals="0" :value="item.original_id" />
        </o-link-cell>
      </template>
      <template slot="cell(other_contacts)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'other_contacts'"
        >
          <default-display :value="item.other_contacts"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(phone_number)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'phone_number'"
        >
          <default-display :value="item.phone_number"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(postal_code)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'postal_code'"
        >
          <default-display :value="item.postal_code"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(state)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'state'"
        >
          <default-display :value="item.state"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(status)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'status'"
        >
          <lookup-display
            :list="optionCodeCustomerStatusItemsById"
            :value="item.status"
            default-value="-"
            display-field="description"
          ></lookup-display>
        </o-link-cell>
      </template>
      <template slot="cell(web_address)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'customer.edit', params: { id: item.id } }"
          :when="first_column_key === 'web_address'"
        >
          {{ item.web_address }}
          <a :href="item.web_address" target="openlink"> <link-icon /> </a
        ></o-link-cell>
      </template>

      <template slot="empty">
        <p>
          <emoticon-sad-icon large></emoticon-sad-icon>
        </p>
        No Data Found. Please
        <router-link :to="{ name: 'customer.create' }"
          >create a new item</router-link
        >
      </template>
    </b-table>

    <o-list-paging
      :limit="list_options.limit"
      :offset="list_options.offset"
      :page-options="rows_per_page_items"
      :total-items="total_items"
      @change-limit="change_limit"
      @change-page="change_page"
    />
  </div>
</template>

<style></style>

<script>
import {
  CreateGroupPermissionMixin,
  CreateListPageMixin,
  MultiActionsMixin,
} from "../../../vue-ocom/mixins";
import FetchAndUseCodeModMixin from "@/vue-ocom/mixins/FetchAndUseCodeModMixin";
import {
  fieldToTableColumn,
  GetSearchFields,
} from "../../../vue-ocom/schema/schema_utils";
import { cleanUpModel } from "../../../vue-ocom/schema/schema_vuetify";
import schema from "../../schema/index";

import OListToolbar from "../../../vue-ocom/components/list/OListToolbar";
import OListPaging from "../../../vue-ocom/components/list/OListPaging";
import DefaultDisplay from "../../../vue-ocom/components/display/DefaultDisplay";
import DateDisplay from "../../../vue-ocom/components/display/DateDisplay";
import NumberDisplay from "../../../vue-ocom/components/display/NumberDisplay";
import BooleanDisplay from "../../../vue-ocom/components/display/BooleanDisplay";
import LookupDisplay from "../../../vue-ocom/components/display/LookupDisplay";
import EmoticonSadIcon from "vue-material-design-icons/EmoticonSad";
import EmailIcon from "vue-material-design-icons/Email";
import LinkIcon from "vue-material-design-icons/Link";

export default {
  components: {
    EmoticonSadIcon,
    EmailIcon,
    LinkIcon,
    OListPaging,
    OListToolbar,
    DateDisplay,
    DefaultDisplay,
    BooleanDisplay,
    NumberDisplay,
    LookupDisplay,
  },
  mixins: [
    CreateListPageMixin("Customer", "customer"),
    MultiActionsMixin,
    CreateGroupPermissionMixin("Customer"),
    FetchAndUseCodeModMixin([
      "CodeCustomerStatus",
    ]) /* 'FileSystem', removed so we dont try and download everything */,
  ],
  data: function () {
    return {
      schemaModel: cleanUpModel(
        schema.Customer,
        {}
      ) /*Use this.schema instead */,

      search_field_list: GetSearchFields(schema.Customer),

      filterRow: true /* Set to False to disable filter row */,
      filter_list: [
        { name: "Active", code: "active", options: { offset: 0, sort: "id" } },
        {
          name: "Non Active",
          code: "inactive",
          options: { offset: 0, sort: "id" },
        },
        { name: "All", code: "all", options: { offset: 0, sort: "id" } },
      ],

      user_column_selection: null /* If user can customise the columns set them here */,
      column_override_params: {
        /* Put any column overrides here key by fieldname */
      },
    };
  },
  computed: {
    options() {
      return {
        FileMetaData: {
          options: this.optionFileMetaDataItems,
          textField: "original_filename",
          valueField: "id",
        },
        CodeCustomerStatus: {
          options: this.optionCodeCustomerStatusItems,
          textField: "description",
          valueField: "id",
        },
      };
    },
    fields() {
      const vm = this;
      const selectedColumns = this.user_column_selection || [
        "company_name",
        "company_code",
        "division",
        "address",
        "phone_number",
        "contact_first_name",
        "contact_last_name",
        "contact_phone_no",
        "active_start_date",
        "active_end_date",
      ];
      const fields = selectedColumns.map(function (item) {
        return fieldToTableColumn(
          vm.schema,
          item,
          vm.column_override_params[item]
        );
      });

      return this.addSelectionColums(
        this.filterTableColumns(this.schema, fields)
      );
    },
    first_column_key() {
      if (this.fields) {
        return this.fields[0].key;
      } else {
        return null;
      }
    },
  },
};
</script>
