import _ from "lodash";

export const STATUS_PASS = 1;
export const STATUS_FAIL = -1;
export const STATUS_UNKNOWN = 0;

export var AssetTestStatusList = [
  { description: "Pass", id: STATUS_PASS },
  { description: "Fail", id: STATUS_FAIL },
  { description: "Unknown", id: STATUS_UNKNOWN },
];

export var AssetTestStatusListById = _.keyBy(AssetTestStatusList, "id");
