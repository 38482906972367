<template>
  <o-form-group
    :form-state="formState"
    :label="label"
    :message="errorMessages"
    :nolabel="nolabel"
    :type="{ 'is-danger': hasError, 'has-addons': allowAdd }"
  >
    <b-input-group>
      <o-vue-select
        :class="input_class"
        :clear-search-on-select="false"
        :close-on-select="true"
        :disabled="_is_readonly"
        :get-option-key="getOptionKey"
        :get-option-label="serializer"
        :options="the_list"
        :reduce="reducer"
        :value="value"
        autoscroll
        class="select-style"
        select-on-tab
        @input="select_item"
        v-bind="$attrs"
        v-on="inputListeners"
      >
      </o-vue-select>

      <template slot="append-outer" />

      <b-input-group-append v-if="allowAdd && !nolabel">
        <b-button
          :disabled="_is_readonly"
          @click="show_add_new"
          variant="outline"
        >
          <table-plus-icon />
        </b-button>

        <b-modal
          :title="addTitle"
          hide-footer
          ref="crud_dialog"
          size="lg"
          style="width: 900px"
        >
          <o-crud-dialog
            :store-name="storeName"
            :title="addTitle"
            @change="added_to_list"
            @close="close"
          >
            <slot slot-scope="{ item, formState, serverErrors }">
              <slot
                :form-state="formState"
                :item="item"
                :server-errors="serverErrors"
                name="form"
              ></slot>
            </slot>
          </o-crud-dialog>
        </b-modal>
      </b-input-group-append>
    </b-input-group>
  </o-form-group>
</template>

<script>
import FormComponentMixin from "../vue-ocom/mixins/form_component_mixin";
import OFormGroup from "../vue-ocom/components/forms/OFormGroup";
import OCrudDialog from "../vue-ocom/components/forms/fields/private/OCrudDialog";
import TablePlusIcon from "vue-material-design-icons/TablePlus";
import SelectInputMixin from "../vue-ocom/components/forms/fields/private/SelectInputMixin";
import _ from "lodash";
import moment from "moment";
import {
  is_item_active,
  sort_and_filter_active_list,
} from "../vue-ocom/utils/active";
import "vue-select/dist/vue-select.css";
import OVueSelect from "./OVueSelect";

export default {
  name: "OSelectTypeahead",
  mixins: [FormComponentMixin, SelectInputMixin],
  components: { OVueSelect, TablePlusIcon, OCrudDialog, OFormGroup },
  props: {
    textField: {
      type: [String, Function],
      default: "description",
    },
    /* For Add New Button */
    allowAdd: { type: Boolean, default: false },
    storeName: { type: String, default: null },
    addTitle: { type: String, default: "Create New Item" },
  },
  data() {
    return {
      loadedData: null,
    };
  },
  methods: {
    getOptionKey(o) {
      return o[this.valueField];
    },
    reducer(o) {
      return o[this.valueField];
    },
    serializer(s) {
      var item = s; // the object

      if (!_.isObject(s)) {
        item = this.items_by_id[s];
      }

      if (!item) {
        return null;
      }

      var result = "";
      if (_.isFunction(this.textField)) {
        result = this.textField(item);
      } else {
        result = item[this.textField];
      }

      if (!is_item_active(item, moment())) {
        result = result + " " + this.disabledSuffix;
      }

      return result;
    },
    select_item(item) {
      if (item != null) {
        if (_.isObject(item)) {
          this.on_change(item[this.valueField]);
        } else {
          this.on_change(item);
        }
      } else {
        this.on_change(null);
      }
    },
    show_add_new() {
      this.$refs["crud_dialog"].show();
    },
    close() {
      this.$refs["crud_dialog"].hide();
    },

    added_to_list(new_id) {
      this.on_change(new_id);

      this.$refs["crud_dialog"].hide();
    },
    on_input(event) {
      // Ignore so ONLY on_change is captured and used for o-select
    },
    sortAndFilterList(items) {
      var vm = this;
      var result = items;

      if (!this.showAll) {
        var now = moment();
        var current_id = this.value;

        result = _.filter(result, function (o) {
          if (!o) {
            return false;
          } else {
            if (current_id) {
              if (o.id === current_id) {
                return true;
              }
            }

            return is_item_active(o, now);
          }
        });
      }
      if (!this.dontSort) {
        result = _.sortBy(result, this.sortField || this.textField); // Sort by sortField or default to textField
      }

      return result;
    },
  },
  computed: {
    the_list() {
      /* NOTE overwrites the SelectInputMixin */
      var list = this.items;

      if (this.loadedData) {
        list = this.loadedData;
      }

      return sort_and_filter_active_list(
        list,
        this.value,
        this.showAll,
        this.textField,
        this.disabledSuffix,
        this.dontSort,
        this.sortField
      );
    },
    found_item() {
      const vm = this;
      const result = _.find(this.items, function (item) {
        return item[vm.valueField] === vm.value;
      });

      return result;
    },
  },
};
</script>

<style>
.select-style {
  width: 100%;
  flex: 1 1 0%;
  min-height: 33.1875px;
}

.select-style .vs__selected {
  height: 24px;
  white-space: nowrap;
  overflow: hidden;
}

.select-style .vs__search::placeholder,
.select-style .vs__dropdown-toggle,
.select-style .vs__dropdown-menu {
  background: #ffffff;
  color: #404040;
}

.select-style .vs__dropdown-toggle {
  height: 33px;
}

.select-style .vs__clear,
.select-style .vs__open-indicator {
  color: #404040;
}
</style>
