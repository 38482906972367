<template>
    <span :class="get_class">{{ display_value }}</span>
</template>

<script>
import DisplayMixin from "../../mixins/display_mixin.js";

export default {
    name: "DefaultDisplay",
    props: {
        value: {}, // The value that is looked up in the "list"
        // the dict/Object to look up - List might not be the right name
        defaultValue: { type: String, default: "" }, // The Default value to show if there is no match in the list
    },
    mixins: [DisplayMixin("display_value")],
    computed: {
        display_value() {
            if (this.value) return this.value;
            else return this.defaultValue;
        },
    },
};
</script>

<style scoped></style>
