import _ from "lodash";
import { sort_and_filter_active_list } from "../../../../utils/active.js";

export default {
    props: {
        items: { type: Array, default: null },
        showAll: { type: Boolean, default: false },
        dontSort: { type: Boolean, default: false },
        textField: {
            type: String,
            default: "description",
        },
        valueField: {
            type: String,
            default: "id",
        },
        blankOptionText: { type: String, default: "-- Select an Item --" },
        disabledSuffix: { type: String, default: "(Disabled)" },
        sortField: { type: String, default: null },
    },
    computed: {
        items_by_id() {
            return _.keyBy(this.items, this.valueField);
        },
        the_list() {
            return sort_and_filter_active_list(
                this.items,
                this.value,
                this.showAll,
                this.textField,
                this.disabledSuffix,
                this.dontSort,
                this.sortField,
            );
        },
    },
};
