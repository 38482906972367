<template>
    <o-form-group
        :form-state="formState"
        :label="label"
        :message="errorMessages"
        :nolabel="nolabel"
        :type="{ 'is-danger': hasError }"
    >
        <BButton
            :disabled="_is_readonly"
            :variant="btnVariant"
            @click="on_click"
            v-bind="$attrs"
        >
            <check-icon v-if="isTicked"></check-icon>
            {{ title }}
        </BButton>
    </o-form-group>
</template>

<script>
import { BButton } from "bootstrap-vue";
import FormComponentMixin from "../../../mixins/form_component_mixin.js";
import OFormGroup from "../OFormGroup.vue";
import CheckIcon from "vue-material-design-icons/Check.vue";
/* This component allows the User to "Click a button" to set the date to now
Like a checkbox expect we keep track of the date+time they pressed the button
 */
export default {
    name: "ODateButton",
    components: {
        BButton,
        OFormGroup,
        CheckIcon,
    },
    mixins: [FormComponentMixin],
    model: {
        value: "value",
        event: "input",
    },
    props: {
        title: { type: String, default: "Press Me" },
        trueVariant: { type: String, default: "success" },
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        on_click(event) {
            if (!this.value) {
                const now = new Date();
                this.on_input(now);
                this.$emit("click", now);
            } else {
                this.on_input(null);
                this.$emit("click", null);
            }
        },
    },
    computed: {
        isTicked() {
            return this.value != null;
        },
        btnVariant() {
            if (this.isTicked) {
                return this.trueVariant;
            }

            return "outline-secondary";
        },
    },
};
</script>

<style scoped></style>
