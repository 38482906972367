/* eslint-disable no-unused-vars */
"use strict";

import Vue from "vue";
import axios from "axios";
import NProgress from "nprogress";

import "nprogress/nprogress.css";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.baseURL = import.meta.env.baseURL || import.meta.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
    // baseURL: process.env.baseURL || process.env.apiUrl || ""
    // baseURL: import.meta.env.baseURL || import.meta.env.apiUrl || ""
    // timeout: 60 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        NProgress.start();
        return config;
    },
    function (error) {
        // Do something with request error
        NProgress.done();
        return Promise.reject(error);
    },
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
        // Do something with response data
        NProgress.done();
        return response;
    },
    function (error) {
        // Do something with response error
        NProgress.done();
        return Promise.reject(error);
    },
);

Plugin.install = function (Vue, options) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            },
        },
        $axios: {
            get() {
                return _axios;
            },
        },
    });
};

Vue.use(Plugin);

export default Plugin;
