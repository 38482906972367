<template>
  <div>
    <label :style="isCheck ? 'background-color:green; color:white' : ''" for="">
      <input @change="isCheck = !isCheck" type="checkbox" />
      <span>{{ code }}</span>
    </label>
  </div>
</template>
<script>
export default {
  props: ["code"],
  data() {
    return {
      isCheck: false,
    };
  },
};
</script>