import ContentTypeList from "./pages/ListPage.vue";
import ContentTypeDetail from "./pages/FormPage.vue";
import ContentTypeIndex from "./pages/IndexPage.vue";

export default [
    {
        path: "/content_type",
        component: ContentTypeIndex,
        redirect: "/content_type/list",
        name: "content_type",
        children: [
            {
                path: "list",
                component: ContentTypeList,
                name: "content_type.list",
            },
            {
                path: "create",
                component: ContentTypeDetail,
                name: "content_type.create",
                props: { create: true },
            },
            {
                path: "edit/:id",
                component: ContentTypeDetail,
                name: "content_type.edit",
                props: { create: false },
            },
        ],
    },
];
