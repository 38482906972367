<template>
  <div>
    <h3 @click="enableDebug($event)" class="display-4">Manage Assets</h3>

    <o-list-toolbar
      :filters-list="filter_list"
      :list-options="list_options"
      :new-to="{ name: 'asset.create', params: { id: 0 } }"
      :query-select-options="options"
      :schema="schema"
      :search-fields-list="search_field_list"
      :selected-filter="selected_filter"
      @filter_changed="update_filter($event)"
      @options_changed="update_table($event)"
      @reset_table="reset_table"
      new-button-text="New Asset"
    >
      <template slot="middle">
        <o-list-multi-actions
          :schema="schema"
          :selection="selected"
          @delete="doDeleteItems"
          @merge="doMergeItems"
          enable-delete
          enable-merge
        />
      </template>
    </o-list-toolbar>

    <b-table
      :fields="fields"
      :items="items"
      :sort-by="list_options.sort"
      :sort-desc="list_options.order === 'desc'"
      @row-selected="onRowSelected"
      @sort-changed="change_sort"
      bordered
      class="o-list"
      head-variant="light"
      no-local-sorting
      selectable
      striped
      thead-class="o-gradient-header"
    >
      <template v-slot:cell(_selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
      <template
        slot="top-row"
        slot-scope="{ columns, fields }"
        v-if="filterRow"
      >
        <td :key="field.key" class="p-0" v-for="field in fields">
          <span v-if="field.key === 'active_end_date'">
            <o-flatpickr-wrapper
              @change="filter_changed"
              class="rounded-0 px-0"
              no-buttons
              v-model="list_options.qbe.active_end_date"
            ></o-flatpickr-wrapper>
          </span>
          <span v-if="field.key === 'active_start_date'">
            <o-flatpickr-wrapper
              @change="filter_changed"
              class="rounded-0 px-0"
              no-buttons
              v-model="list_options.qbe.active_start_date"
            ></o-flatpickr-wrapper>
          </span>
          <span v-if="field.key === 'asset_classification'">
            <o-form-select
              :options="optionCodeAssetClassificationItems"
              :loading="optionCodeAssetClassificationWorking"
              @change="filter_changed"
              name="asset_classification"
              sort-field="description"
              text-field="description"
              v-model="list_options.qbe.asset_classification"
              value-field="id"
            >
              <template v-slot:first>
                <option :value="null"></option>
              </template>
            </o-form-select>
          </span>
          <span v-if="field.key === 'asset_color'">
            <o-form-select
              :options="optionCodeColorItems"
              :loading="optionCodeColorWorking"
              @change="filter_changed"
              name="asset_color"
              sort-field="description"
              text-field="description"
              v-model="list_options.qbe.asset_color"
              value-field="id"
            >
              <template v-slot:first>
                <option :value="null"></option>
              </template>
            </o-form-select>
          </span>
          <span v-if="field.key === 'code'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.code"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'comments'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.comments"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'created_date'">
            <o-flatpickr-wrapper
              @change="filter_changed"
              class="rounded-0 px-0"
              no-buttons
              v-model="list_options.qbe.created_date"
            ></o-flatpickr-wrapper>
          </span>
          <span v-if="field.key === 'customer'">
            <o-form-select
              :options="optionCustomerItems"
              :loading="optionCustomerWorking"
              @change="filter_changed"
              name="customer"
              sort-field="name"
              text-field="name"
              v-model="list_options.qbe.customer"
              value-field="id"
            >
              <template v-slot:first>
                <option :value="null"></option>
              </template>
            </o-form-select>
          </span>
          <span v-if="field.key === 'customer_asset_no'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.customer_asset_no"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'customer_gone'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.customer_gone"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'description'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.description"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'disposed'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.disposed"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'elec_class'">
            <o-form-select
              :options="optionCodeElectricalClassItems"
              :loading="optionCodeElectricalClassWorking"
              @change="filter_changed"
              name="elec_class"
              sort-field="description"
              text-field="description"
              v-model="list_options.qbe.elec_class"
              value-field="id"
            >
              <template v-slot:first>
                <option :value="null"></option>
              </template>
            </o-form-select>
          </span>
          <span v-if="field.key === 'last_test_date'">
            <o-flatpickr-wrapper
              @change="filter_changed"
              class="rounded-0 px-0"
              no-buttons
              v-model="list_options.qbe.last_test_date"
            ></o-flatpickr-wrapper>
          </span>
          <span v-if="field.key === 'length'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.length"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'linkedwith'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.linkedwith"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'location'">
            <o-tree-path-select
              :items="optionAssetLocationItems"
              @input="filter_changed"
              nolabel
              only-parents
              v-bind="schema.fields.location"
              value-type="integer"
              v-model="list_options.qbe.location"
            ></o-tree-path-select>
          </span>
          <span v-if="field.key === 'make'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.make"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'missing_in_action'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.missing_in_action"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'model'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.model"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'modified_date'">
            <o-flatpickr-wrapper
              @change="filter_changed"
              class="rounded-0 px-0"
              no-buttons
              v-model="list_options.qbe.modified_date"
            ></o-flatpickr-wrapper>
          </span>
          <span v-if="field.key === 'original_id'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.original_id"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'out_of_service'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.out_of_service"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'phase'">
            <o-form-select
              :options="optionCodePhaseItems"
              :loading="optionCodePhaseWorking"
              @change="filter_changed"
              name="phase"
              sort-field="description"
              text-field="description"
              v-model="list_options.qbe.phase"
              value-field="id"
            >
              <template v-slot:first>
                <option :value="null"></option>
              </template>
            </o-form-select>
          </span>
          <span v-if="field.key === 'product'">
            <o-form-select
              :options="optionProductItems"
              :loading="optionProductWorking"
              @change="filter_changed"
              name="product"
              sort-field="description"
              text-field="description"
              v-model="list_options.qbe.product"
              value-field="id"
            >
              <template v-slot:first>
                <option :value="null"></option>
              </template>
            </o-form-select>
          </span>
          <span v-if="field.key === 'replacement_value'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.replacement_value"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'serial_number'">
            <b-form-input
              @change="filter_changed"
              class="rounded-0 px-0"
              type="search"
              v-model="list_options.qbe.serial_number"
            ></b-form-input>
          </span>
          <span v-if="field.key === 'status'">
            <o-form-select
              :options="optionCodeAssetStatusItems"
              :loading="optionCodeAssetStatusWorking"
              @change="filter_changed"
              name="status"
              sort-field="description"
              text-field="description"
              v-model="list_options.qbe.status"
              value-field="id"
            >
              <template v-slot:first>
                <option :value="null"></option>
              </template>
            </o-form-select>
          </span>
          <span v-if="field.key === 'test_frequency'">
            <o-form-select
              :options="optionCodeTestFrequencyItems"
              :loading="optionCodeTestFrequencyWorking"
              @change="filter_changed"
              name="test_frequency"
              sort-field="description"
              text-field="description"
              v-model="list_options.qbe.test_frequency"
              value-field="id"
            >
              <template v-slot:first>
                <option :value="null"></option>
              </template>
            </o-form-select>
          </span>
        </td>
      </template>
      <template slot="cell(active_end_date)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'active_end_date'"
        >
          <date-display
            :value="item.active_end_date"
            format="DD/MM/YYYY hh:mm"
          ></date-display>
        </o-link-cell>
      </template>
      <template slot="cell(active_start_date)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'active_start_date'"
        >
          <date-display
            :value="item.active_start_date"
            format="DD/MM/YYYY hh:mm"
          ></date-display>
        </o-link-cell>
      </template>
      <template slot="cell(asset_classification)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'asset_classification'"
        >
          <lookup-display
            :list="optionCodeAssetClassificationItemsById"
            :value="item.asset_classification"
            default-value="-"
            display-field="description"
          ></lookup-display>
        </o-link-cell>
      </template>
      <template slot="cell(asset_color)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'asset_color'"
        >
          <lookup-display
            :list="optionCodeColorItemsById"
            :value="item.asset_color"
            default-value="-"
            display-field="description"
          ></lookup-display>
        </o-link-cell>
      </template>
      <template slot="cell(code)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'code'"
        >
          <default-display :value="item.code"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(comments)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'comments'"
        >
          <default-display :value="item.comments"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(created_date)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'created_date'"
        >
          <date-display
            :value="item.created_date"
            format="DD/MM/YYYY hh:mm"
          ></date-display>
        </o-link-cell>
      </template>
      <template slot="cell(customer)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'customer'"
        >
          <lookup-display
            :list="optionCustomerItemsById"
            :value="item.customer"
            default-value="-"
            display-field="name"
          ></lookup-display>
        </o-link-cell>
      </template>
      <template slot="cell(customer_asset_no)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'customer_asset_no'"
        >
          <default-display :value="item.customer_asset_no"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(customer_gone)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'customer_gone'"
        >
          <boolean-display :value="item.customer_gone"></boolean-display>
        </o-link-cell>
      </template>
      <template slot="cell(description)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'description'"
        >
          <default-display :value="item.description"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(disposed)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'disposed'"
        >
          <boolean-display :value="item.disposed"></boolean-display>
        </o-link-cell>
      </template>
      <template slot="cell(elec_class)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'elec_class'"
        >
          <lookup-display
            :list="optionCodeElectricalClassItemsById"
            :value="item.elec_class"
            default-value="-"
            display-field="description"
          ></lookup-display>
        </o-link-cell>
      </template>
      <template slot="cell(last_test_date)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'last_test_date'"
        >
          <date-display
            :value="item.last_test_date"
            format="DD/MM/YYYY hh:mm"
          ></date-display>
        </o-link-cell>
      </template>
      <template slot="cell(length)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'length'"
        >
          <default-display :value="item.length"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(linkedwith)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'linkedwith'"
        >
          <default-display :value="item.linkedwith"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(location)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'location'"
        >
          <o-tree-breadcrumbs
            :items-by-id="optionAssetLocationItemsById"
            :value="item.location"
            value-type="integer"
          ></o-tree-breadcrumbs>
        </o-link-cell>
      </template>
      <template slot="cell(make)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'make'"
        >
          <default-display :value="item.make"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(missing_in_action)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'missing_in_action'"
        >
          <boolean-display :value="item.missing_in_action"></boolean-display>
        </o-link-cell>
      </template>
      <template slot="cell(model)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'model'"
        >
          <default-display :value="item.model"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(modified_date)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'modified_date'"
        >
          <date-display
            :value="item.modified_date"
            format="DD/MM/YYYY hh:mm"
          ></date-display>
        </o-link-cell>
      </template>
      <template slot="cell(original_id)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'original_id'"
        >
          <number-display :decimals="0" :value="item.original_id" />
        </o-link-cell>
      </template>
      <template slot="cell(out_of_service)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'out_of_service'"
        >
          <boolean-display :value="item.out_of_service"></boolean-display>
        </o-link-cell>
      </template>
      <template slot="cell(phase)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'phase'"
        >
          <lookup-display
            :list="optionCodePhaseItemsById"
            :value="item.phase"
            default-value="-"
            display-field="description"
          ></lookup-display>
        </o-link-cell>
      </template>
      <template slot="cell(product)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'product'"
        >
          <lookup-display
            :list="optionProductItemsById"
            :value="item.product"
            default-value="-"
            display-field="description"
          ></lookup-display>
        </o-link-cell>
      </template>
      <template slot="cell(replacement_value)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'replacement_value'"
        >
          <number-display :decimals="2" :value="item.replacement_value" />
        </o-link-cell>
      </template>
      <template slot="cell(serial_number)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'serial_number'"
        >
          <default-display :value="item.serial_number"></default-display>
        </o-link-cell>
      </template>
      <template slot="cell(status)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'status'"
        >
          <lookup-display
            :list="optionCodeAssetStatusItemsById"
            :value="item.status"
            default-value="-"
            display-field="description"
          ></lookup-display>
        </o-link-cell>
      </template>
      <template slot="cell(test_frequency)" slot-scope="{ index, item }">
        <o-link-cell
          :to="{ name: 'asset.edit', params: { id: item.id } }"
          :when="first_column_key === 'test_frequency'"
        >
          <lookup-display
            :list="optionCodeTestFrequencyItemsById"
            :value="item.test_frequency"
            default-value="-"
            display-field="description"
          ></lookup-display>
        </o-link-cell>
      </template>

      <template slot="empty">
        <p>
          <emoticon-sad-icon large></emoticon-sad-icon>
        </p>
        No Data Found. Please
        <router-link :to="{ name: 'asset.create' }"
          >create a new item</router-link
        >
      </template>
    </b-table>

    <o-list-paging
      :limit="list_options.limit"
      :offset="list_options.offset"
      :page-options="rows_per_page_items"
      :total-items="total_items"
      @change-limit="change_limit"
      @change-page="change_page"
    />
  </div>
</template>

<style></style>

<script>
import {
  CreateGroupPermissionMixin,
  CreateListPageMixin,
  MultiActionsMixin,
} from "../../../vue-ocom/mixins";
import FetchAndUseCodeModMixin from "@/vue-ocom/mixins/FetchAndUseCodeModMixin";
import {
  fieldToTableColumn,
  GetSearchFields,
} from "../../../vue-ocom/schema/schema_utils";
import { cleanUpModel } from "../../../vue-ocom/schema/schema_vuetify";
import schema from "../../schema/index";

import OListToolbar from "../../../vue-ocom/components/list/OListToolbar";
import OListPaging from "../../../vue-ocom/components/list/OListPaging";
import OTreePathSelect from "../../../vue-ocom/components/tree/OTreePathSelect";
import OTreeBreadcrumbs from "../../../vue-ocom/components/tree/OTreeBreadcrumbs";

import DefaultDisplay from "../../../vue-ocom/components/display/DefaultDisplay";
import DateDisplay from "../../../vue-ocom/components/display/DateDisplay";
import NumberDisplay from "../../../vue-ocom/components/display/NumberDisplay";
import BooleanDisplay from "../../../vue-ocom/components/display/BooleanDisplay";
import LookupDisplay from "../../../vue-ocom/components/display/LookupDisplay";
import EmoticonSadIcon from "vue-material-design-icons/EmoticonSad";
import EmailIcon from "vue-material-design-icons/Email";
import LinkIcon from "vue-material-design-icons/Link";
import AssetLocationCodeTable from "@/mixins/AssetLocationCodeTable";

export default {
  components: {
    EmoticonSadIcon,
    EmailIcon,
    LinkIcon,
    OTreePathSelect,
    OTreeBreadcrumbs,
    OListPaging,
    OListToolbar,
    DateDisplay,
    DefaultDisplay,
    BooleanDisplay,
    NumberDisplay,
    LookupDisplay,
  },
  mixins: [
    CreateListPageMixin("Asset", "asset"),
    MultiActionsMixin,
    CreateGroupPermissionMixin("Asset"),
    FetchAndUseCodeModMixin([
      "Customer",
      "AssetLocation",
      "Product",
      "CodeColor",
      "CodePhase",
      "CodeTestFrequency",
      "CodeElectricalClass",
      "CodeAssetClassification",
      "CodeAssetStatus",
    ]),
    AssetLocationCodeTable,
  ],
  data: function () {
    return {
      schemaModel: cleanUpModel(schema.Asset, {}) /*Use this.schema instead */,

      search_field_list: GetSearchFields(schema.Asset),

      filterRow: true /* Set to False to disable filter row */,
      filter_list: [
        { name: "Active", code: "active", options: { offset: 0, sort: "id" } },
        {
          name: "Non Active",
          code: "inactive",
          options: { offset: 0, sort: "id" },
        },
        { name: "All", code: "all", options: { offset: 0, sort: "id" } },
      ],

      user_column_selection: null /* If user can customise the columns set them here */,
      column_override_params: {
        /* Put any column overrides here key by fieldname */
      },
    };
  },
  computed: {
    options() {
      return {
        Customer: {
          options: this.optionCustomerItems,
          textField: "name",
          valueField: "id",
        },
        AssetLocation: {
          options: this.optionAssetLocationItems,
          textField: "location_name_sub_1",
          valueField: "id",
        },
        Product: {
          options: this.optionProductItems,
          textField: "product_type",
          valueField: "id",
        },
        CodeColor: {
          options: this.optionCodeColorItems,
          textField: "description",
          valueField: "id",
        },
        CodePhase: {
          options: this.optionCodePhaseItems,
          textField: "description",
          valueField: "id",
        },
        CodeTestFrequency: {
          options: this.optionCodeTestFrequencyItems,
          textField: "description",
          valueField: "id",
        },
        CodeElectricalClass: {
          options: this.optionCodeElectricalClassItems,
          textField: "description",
          valueField: "id",
        },
        CodeAssetClassification: {
          options: this.optionCodeAssetClassificationItems,
          textField: "description",
          valueField: "id",
        },
        CodeAssetStatus: {
          options: this.optionCodeAssetStatusItems,
          textField: "description",
          valueField: "id",
        },
      };
    },
    fields() {
      const vm = this;
      const selectedColumns = this.user_column_selection || [
        "code",
        "customer",
        "location",
        "status",
        "description",
        "make",
        "active_start_date",
        "active_end_date",
      ];
      const fields = selectedColumns.map(function (item) {
        return fieldToTableColumn(
          vm.schema,
          item,
          vm.column_override_params[item]
        );
      });

      return this.addSelectionColums(
        this.filterTableColumns(this.schema, fields)
      );
    },
    first_column_key() {
      if (this.fields) {
        return this.fields[0].key;
      } else {
        return null;
      }
    },
  },
};
</script>
